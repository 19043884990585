import { UserClaims } from '@services/business/identity/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Lashitas } from '../../entities/lashitas';

export type Interface = ApiInterface<{
  response: Lashitas[];
  pathParams: EmptyObject;
  body: {
    lashitas: { id: string; schedulePosition: number }[];
  };
  claims: UserClaims;
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-update-order-lashitas',
  method: 'PUT',
  basePath: 'lashitas',
  path: '/order',
  tablePermission: 'readWrite',
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    lashitas: {
      type: [
        {
          id: { type: String, required: true },
          schedulePosition: { type: Number, required: true },
        },
      ],
    },
  }),
};
