import { TextareaHTMLAttributes } from 'react';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  onChange?: any;
  onBlur?: any;
  error?: string | boolean;
  classlabel?: string;
  classinput?: string;
  classnamecontainer?: string;
  mode?: string;
}

const TextArea = (props: InputProps) => {
  return (
    <div className={`-mb-2 ${props.classnamecontainer ?? ''}`}>
      <span
        className={`mb-1 flex items-center tracking-wide text-sm md:text-base font-medium
        ${props.classlabel ?? ''}`}
      >
        {props.label}
      </span>
      <div className="flex items-start">
        <textarea
          {...props}
          rows={props.rows ? props.rows : 4}
          className={`p-2 border block shadow-sm hover:ring-1 focus:ring-1 text-sm md:text-base font-normal w-full
              ${
                props.error
                  ? 'border-red hover:ring-red focus:ring-red'
                  : 'border-grey hover:ring-primary focus:ring-primary'
              }
              ${props.classinput ?? ''}`}
          onChange={props.onChange}
          readOnly={props.mode == 'view' ? true : false}
        />
      </div>
      <span className="flex items-center font-medium tracking-wide text-red text-xs mt-1 ml-1">{props.error}</span>
    </div>
  );
};

export default TextArea;
