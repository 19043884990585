export const LIST_EMPLOYEE = '[Employee] List Employee';
export const LIST_EMPLOYEE_SUCCESS = '[Employee] List Employee Success';
export const LIST_EMPLOYEE_ERROR = '[Employee] List Employee Error';

export const CREATE_EMPLOYEE = '[Employee] Create Employee';
export const CREATE_EMPLOYEE_SUCCESS = '[Employee] Create Employee Success';
export const CREATE_EMPLOYEE_ERROR = '[Employee] Create Employee Error';

export const UPDATE_EMPLOYEE = '[Employee] Update Employee';
export const UPDATE_EMPLOYEE_SUCCESS = '[Employee] Update Employee Success';
export const UPDATE_EMPLOYEE_ERROR = '[Employee] Update Employee Error';

export const DELETE_EMPLOYEE = '[Employee] Delete Employee';
export const DELETE_EMPLOYEE_SUCCESS = '[Employee] Delete Employee Success';
export const DELETE_EMPLOYEE_ERROR = '[Employee] Delete Employee Error';

export const SET_CURRENT_EMPLOYEE = '[Employee] Current Employee';
