// import { data as dataNewClient } from './data';
// import { data2 as dataNoReturnClient } from './data2';
import * as api from '@services/business/history/catalog/api';

export const normalizeAnnualSale = (
  data: any[],
  dataNewClient: api.historyOldOrNewClient.Interface['response'],
  dataNoReturnClient: api.historyOldOrNewClient.Interface['response']
): Array<{
  january: {
    sucursal: string;
    totalService: number;
    totalProduct: number;
    totalSales: number;
    totalNoReturnClient: number;
    totalNewClient: number;
  };
  febraury: {
    sucursal: string;
    totalService: number;
    totalProduct: number;
    totalSales: number;
    totalNoReturnClient: number;
    totalNewClient: number;
  };
  september: {
    sucursal: string;
    totalService: number;
    totalProduct: number;
    totalSales: number;
    totalNoReturnClient: number;
    totalNewClient: number;
  };
}> => {
  const response = data.map(({ items }) => {
    const [item] = items;
    const getNewClient = dataNewClient.entities?.find(({ id }) => id === item?.clientId);

    const getNoReturnClient = dataNoReturnClient.entities?.find(({ id }) => id === item?.clientId);

    const january = data
      .flatMap(({ items }) => items)
      .filter((item) => new Date(item.dateInitial).getMonth() + 1 === 1);

    const febraury = data
      .flatMap(({ items }) => items)
      .filter((item) => new Date(item.dateInitial).getMonth() + 1 === 2);

    const march = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 3);

    const april = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 4);

    const may = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 5);

    const june = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 6);

    const july = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 7);

    const august = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 8);

    const sep = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 9);

    const oct = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 10);

    const nov = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 11);

    const dec = data.flatMap(({ items }) => items).filter((item) => new Date(item.dateInitial).getMonth() + 1 === 12);

    // GET TOTAL BY MONTH

    const serviceJanuary = january.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productJanuary = january.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesJanuary = january.reduce((a, b) => a + (b.price || 0), 0);
    const newClientJanuary = january
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnJanuary = january
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceFebraury = febraury.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productFebraury = febraury.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesFebraury = febraury.reduce((a, b) => a + (b.price || 0), 0);
    const newClientFebraury = febraury
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnFebraury = febraury
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceMarch = march.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productMarch = march.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesMarch = march.reduce((a, b) => a + (b.price || 0), 0);
    const newClientMarch = march
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnMarch = march
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceApril = april.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productApril = april.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesApril = april.reduce((a, b) => a + (b.price || 0), 0);
    const newClientApril = april
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnApril = april
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceMay = may.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productMay = may.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesMay = may.reduce((a, b) => a + (b.price || 0), 0);
    const newClientMay = may
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnMay = may
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceJune = june.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productJune = june.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesJune = june.reduce((a, b) => a + (b.price || 0), 0);
    const newClientJune = june
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnJune = june
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceJuly = july.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productJuly = july.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesJuly = july.reduce((a, b) => a + (b.price || 0), 0);
    const newClientJuly = july
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnJuly = july
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceAugust = august.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productAugust = august.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesAugust = august.reduce((a, b) => a + (b.price || 0), 0);
    const newClientAugust = august
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnAugust = august
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceSep = sep.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productSep = sep.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesSep = sep.reduce((a, b) => a + (b.price || 0), 0);
    const newClientSep = sep
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnSep = sep
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceOct = oct.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productOct = oct.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesOct = oct.reduce((a, b) => a + (b.price || 0), 0);
    const newClientOct = oct
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnOct = oct
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceNov = nov.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productNov = nov.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesNov = nov.reduce((a, b) => a + (b.price || 0), 0);
    const newClientNov = nov
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnNov = nov
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    const serviceDec = dec.filter(({ type }) => type === 'SERVICE').reduce((a, b) => a + (b.price || 0), 0);
    const productDec = dec.filter(({ type }) => type === 'PRODUCT').reduce((a, b) => a + (b.price || 0), 0);
    const salesDec = dec.reduce((a, b) => a + (b.price || 0), 0);
    const newClientDec = dec
      .filter(({ clientId }) => clientId === getNewClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);
    const noReturnDec = dec
      .filter(({ clientId }) => clientId === getNoReturnClient?.id)
      .reduce((a, b) => a + (b.price || 0), 0);

    return {
      january: {
        sucursal: item?.tenantId,
        totalService: serviceJanuary,
        totalProduct: productJanuary,
        totalSales: salesJanuary,
        totalNoReturnClient: noReturnJanuary,
        totalNewClient: newClientJanuary,
      },
      febraury: {
        sucursal: item?.tenantId,
        totalService: serviceFebraury,
        totalProduct: productFebraury,
        totalSales: salesFebraury,
        totalNoReturnClient: noReturnFebraury,
        totalNewClient: newClientFebraury,
      },
      march: {
        sucursal: item?.tenantId,
        totalService: serviceMarch,
        totalProduct: productMarch,
        totalSales: salesMarch,
        totalNoReturnClient: noReturnMarch,
        totalNewClient: newClientMarch,
      },
      april: {
        sucursal: item?.tenantId,
        totalService: serviceApril,
        totalProduct: productApril,
        totalSales: salesApril,
        totalNoReturnClient: noReturnApril,
        totalNewClient: newClientApril,
      },
      may: {
        sucursal: item?.tenantId,
        totalService: serviceMay,
        totalProduct: productMay,
        totalSales: salesMay,
        totalNoReturnClient: noReturnMay,
        totalNewClient: newClientMay,
      },
      june: {
        sucursal: item?.tenantId,
        totalService: serviceJune,
        totalProduct: productJune,
        totalSales: salesJune,
        totalNoReturnClient: noReturnJune,
        totalNewClient: newClientJune,
      },
      july: {
        sucursal: item?.tenantId,
        totalService: serviceJuly,
        totalProduct: productJuly,
        totalSales: salesJuly,
        totalNoReturnClient: noReturnJuly,
        totalNewClient: newClientJuly,
      },
      august: {
        sucursal: item?.tenantId,
        totalService: serviceAugust,
        totalProduct: productAugust,
        totalSales: salesAugust,
        totalNoReturnClient: noReturnAugust,
        totalNewClient: newClientAugust,
      },
      september: {
        sucursal: item?.tenantId,
        totalService: serviceSep,
        totalProduct: productSep,
        totalSales: salesSep,
        totalNoReturnClient: noReturnSep,
        totalNewClient: newClientSep,
      },
      oct: {
        sucursal: item?.tenantId,
        totalService: serviceOct,
        totalProduct: productOct,
        totalSales: salesOct,
        totalNoReturnClient: noReturnOct,
        totalNewClient: newClientOct,
      },
      nov: {
        sucursal: item?.tenantId,
        totalService: serviceNov,
        totalProduct: productNov,
        totalSales: salesNov,
        totalNoReturnClient: noReturnNov,
        totalNewClient: newClientNov,
      },
      dec: {
        sucursal: item?.tenantId,
        totalService: serviceDec,
        totalProduct: productDec,
        totalSales: salesDec,
        totalNoReturnClient: noReturnDec,
        totalNewClient: newClientDec,
      },
    };
  });

  return response;
};
