import { listBalanceOfDay } from '@webapp/sdk/cash-register';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Actions } from '../../actions';
import {
  ListCashRegisterTransactions,
  ListCashRegisterTransactionsError,
  ListCashRegisterTransactionsSuccess,
} from '../action-creators';
import { LIST_CASH_REGISTER_TRANSACTIONS } from '../actions';

export const listCashRegisterTransactionsEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListCashRegisterTransactions['type'], ListCashRegisterTransactions>(
      LIST_CASH_REGISTER_TRANSACTIONS
    ),
    mergeMap(async (action) => {
      try {
        const result = await listBalanceOfDay(action.date);
        return new ListCashRegisterTransactionsSuccess(result.items);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListCashRegisterTransactionsError(err.message, err.description);
      }
    })
  );
