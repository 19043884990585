import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import Input from '../../components/ui/forms/input';
// store state
import { generateId } from '@services/support/utils/generate-id';
import { CreateClient } from '@webapp/store/client/action-creators';
import { Client } from '@webapp/store/client/state';
import AppState from '@webapp/store/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { NewClientForm } from '@webapp/types/forms/new-client';
import { acceptOnlyLetters } from '@webapp/utils/validateString';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Ingrese el nombre del cliente')
    .matches(/^([a-zA-Z]+\s?)*\s*$/g, 'Nombre invalido'),
  lastName: yup
    .string()
    .required('Ingrese el apellido del cliente')
    .matches(/^([a-zA-Z]+\s?)*\s*$/g, 'Apellido invalido'), //Only allow Alpha char
  email: yup.string().email('Correo invalido'),
  phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'telefono invalido'
    ),
  marketing: yup.array().notRequired(),
  confirm: yup.array().notRequired(),
  rating: yup.array().notRequired(),
  fidelity: yup.array().notRequired(),
  privacyPolicies: yup
    .boolean()
    .required('El cliente debe aceptar las políticas de la empresa')
    .oneOf([true], 'El cliente debe aceptar las políticas de la empresa'),
  sex: yup.string().notRequired(),
  status: yup.string().notRequired(),
});

const ModalNewClient: FunctionComponent<{ selectClient: (client: Client) => void }> = ({ selectClient }) => {
  const cancelButton = useRef(null);
  const { modalOpened, dispatch } = useOpStore((state: AppState) => state.ui);
  const [mode] = useState('add');
  const [isSaving, setIsSaving] = useState(false);

  /**
   * close the modal and sent the info to the parent component
   */
  const saveData = async (rawData: NewClientForm) => {
    const newClient: Client = {
      id: generateId('client'),
      firstName: rawData.firstName,
      lastName: rawData.lastName,
      email: rawData.email,
      phone: rawData.phone,
      sex: rawData.sex as Client['sex'],
      additionalData: {
        marketing: rawData.marketing,
        confirm: rawData.confirm,
        rating: rawData.rating,
        fidelity: rawData.fidelity,
        privacyPolicies: rawData.privacyPolicies,
      },
      status: 'ACTIVE',
      sucursalAt: '',
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    dispatch(new CreateClient(newClient));
    dispatch(new CloseModal('NEW_CLIENT_MODAL'));
    formik.resetForm();

    setTimeout(() => {
      selectClient(newClient);
    }, 3000);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      marketing: [],
      confirm: [],
      privacyPolicies: false,
      rating: [],
      fidelity: [],
      sex: 'FEMALE',
      status: 'ACTIVE',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveData(values);
    },
  });

  /**
   * mark the checkboxes that are active
   * @param array
   * @param value
   * @returns boolean
   */
  const isChecked = (array: string[] | string | undefined, value: string) => {
    let isFind = false;
    if (Array.isArray(array)) {
      array.forEach((v) => {
        if (v == value) {
          isFind = true;
        }
      });
    }
    return isFind;
  };

  /**
   * custom handler for the submit of the form
   * @param selected string
   */
  const onClickButton = (selected: string) => {
    // save the data and redirect to the client list view
    if (selected == 'saveAndCloseClient') {
      setIsSaving(true);
      formik.submitForm();
    }
    // redirect to the client list view
    if (selected == 'close') {
      formik.resetForm();
      dispatch(new CloseModal('NEW_CLIENT_MODAL'));
    }

    if (selected == 'policy') {
      window.open('/privacy-policy', '_blank');
    }
  };

  useEffect(() => {
    const JobErrors = JSON.parse(JSON.stringify(formik.errors));

    const errors = [];
    for (const x in JobErrors) {
      errors.push(x);
    }
  }, [formik.errors, isSaving]);

  return (
    <Transition.Root show={modalOpened?.['NEW_CLIENT_MODAL'] ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('NEW_CLIENT_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#6b7280] bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block align-bottom bg-darkGray rounded-[8px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[772px] sm:w-full">
              <form onSubmit={formik.handleSubmit}>
                <div className="bg-primary py-[25px] pl-[36px] flex justify-left">
                  <Dialog.Title
                    as="span"
                    className="text-white text-[24px] font-bold tracking-[0.185em] leading-[30px]"
                  >
                    Nuevo cliente
                  </Dialog.Title>
                </div>
                <div className="py-[23px] px-[36px] flex flex-col mb-[21px]">
                  <span className="text-[18px] font-normal tracking-[0.185em] leading-[23px] text-left mb-4 text-white">
                    Información general
                  </span>
                  <div className="flex flex-col border border-grey2 rounded-[3px] p-[22px] mb-[40px]">
                    <div className="grid grid-cols-2 gap-[24px] mb-[20px]">
                      <Input
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                        classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-grey"
                        id="firstName"
                        name="firstName"
                        label="* Nombres"
                        type="text"
                        value={formik.values.firstName}
                        mode={mode}
                        onChange={(o: any) => {
                          if (acceptOnlyLetters(o.target.value) === true) {
                            formik.handleChange(o);
                          }
                        }}
                        error={formik.touched.firstName && formik.errors.firstName}
                      />
                      <Input
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                        classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-grey"
                        id="lastName"
                        name="lastName"
                        label="* Apellidos"
                        type="text"
                        value={formik.values.lastName}
                        mode={mode}
                        onChange={(o: any) => {
                          if (acceptOnlyLetters(o.target.value) === true) {
                            formik.handleChange(o);
                          }
                        }}
                        error={formik.touched.lastName && formik.errors.lastName}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-[24px] mb-[20px]">
                      <Input
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                        classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-grey"
                        id="email"
                        name="email"
                        label="* Email"
                        type="text"
                        value={formik.values.email}
                        mode={mode}
                        onChange={formik.handleChange}
                        error={formik.touched.email && formik.errors.email}
                      />
                      <Input
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                        classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-grey"
                        id="phone"
                        name="phone"
                        label="Telefono"
                        type="text"
                        maxLength={10}
                        value={formik.values.phone}
                        mode={mode}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && formik.errors.phone}
                      />
                    </div>
                    <div className="flex">
                      <span className="text-base font-light tracking-[0.185em] leading-[20px] mr-[31px] text-white">
                        Género
                      </span>
                      <Input
                        classnamecontainer="mr-4"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-full border border-[#878A8A] text-primary w-4"
                        id="sex"
                        name="sex"
                        type="radio"
                        label="Mujer"
                        value="FEMALE"
                        checked={formik.values.sex === 'FEMALE'}
                        mode={mode}
                        onChange={formik.handleChange}
                        error={formik.touched.sex && formik.errors.sex}
                      />
                      <Input
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-full border border-[#878A8A] text-primary w-4"
                        id="sex"
                        name="sex"
                        type="radio"
                        label="Hombre"
                        value="MALE"
                        checked={formik.values.sex === 'MALE'}
                        mode={mode}
                        onChange={formik.handleChange}
                        error={formik.touched.sex && formik.errors.sex}
                      />
                    </div>
                  </div>
                  <span className="text-[18px] font-normal tracking-[0.185em] leading-[23px] text-left mb-4 text-white">
                    Contacto
                  </span>
                  <div className="grid grid-cols-2 gap-4 border border-grey2 rounded-[3px] p-[22px] mb-[40px]">
                    <div className="flex justify-start mb-[20px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] text-white">
                        Marketing
                      </span>
                    </div>
                    <div className="flex mb-[20px]">
                      <Input
                        classnamecontainer="flex items-center mr-4"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4 "
                        id="marketing"
                        name="marketing"
                        type="checkbox"
                        label="SMS"
                        value="SMSCheck"
                        checked={isChecked(formik.values.marketing, 'SMSCheck')}
                        mode={mode}
                        onChange={formik.handleChange}
                      />
                      <Input
                        classnamecontainer="flex items-center"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                        id="marketing"
                        name="marketing"
                        type="checkbox"
                        label="Email"
                        value="EmailCheck"
                        checked={isChecked(formik.values.marketing, 'EmailCheck')}
                        mode={mode}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="flex justify-start mb-[20px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] text-white">
                        Confirmación citas
                      </span>
                    </div>
                    <div className="flex mb-[20px]">
                      <Input
                        classnamecontainer="flex items-center mr-4"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                        id="confirm"
                        name="confirm"
                        type="checkbox"
                        label="SMS"
                        value="SMSCheck"
                        checked={isChecked(formik.values.confirm, 'SMSCheck')}
                        mode={mode}
                        onChange={formik.handleChange}
                      />
                      <Input
                        classnamecontainer="flex items-center"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                        id="confirm"
                        name="confirm"
                        type="checkbox"
                        label="Email"
                        value="EmailCheck"
                        checked={isChecked(formik.values.confirm, 'EmailCheck')}
                        mode={mode}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="flex justify-start mb-[20px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] text-white">
                        Confirmación de fidelidad
                      </span>
                    </div>
                    <div className="flex mb-[20px]">
                      <Input
                        classnamecontainer="flex items-center mr-4"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                        id="fidelity"
                        name="fidelity"
                        type="checkbox"
                        label="Email"
                        value="EmailCheck"
                        checked={isChecked(formik.values.fidelity, 'EmailCheck')}
                        mode={mode}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="flex justify-start mb-[20px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] text-white">
                        Valoración y reseñas
                      </span>
                    </div>
                    <div className="flex mb-[20px]">
                      <Input
                        classnamecontainer="flex items-center mr-4"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                        id="rating"
                        name="rating"
                        type="checkbox"
                        label="SMS"
                        value="SMSCheck"
                        checked={isChecked(formik.values.rating, 'SMSCheck')}
                        mode={mode}
                        onChange={formik.handleChange}
                      />
                      <Input
                        classnamecontainer="flex items-center mr-4"
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                        id="rating"
                        name="rating"
                        type="checkbox"
                        label="Email"
                        value="EmailCheck"
                        checked={isChecked(formik.values.rating, 'EmailCheck')}
                        mode={mode}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <span className="text-[18px] font-medium tracking-[0.185em] leading-[23px] text-left mb-4 text-white">
                    Política de privacidad
                  </span>
                  <div className="flex flex-col border border-grey2 rounded-[3px] p-[22px]">
                    <div className="flex justify-between mb-[20px]">
                      <Input
                        classnamecontainer="flex items-center mr-4"
                        classlabel="text-[18px] font-light tracking-[0.185em] leading-[26px] text-left text-white ml-[6px]"
                        classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                        id="privacyPolicies"
                        name="privacyPolicies"
                        type="checkbox"
                        label="El cliente está desacuerdo con las políticas de privacidad"
                        value="privacyPolicies"
                        checked={formik.values.privacyPolicies}
                        mode={mode}
                        onChange={formik.handleChange}
                        error={formik.touched.privacyPolicies && formik.errors.privacyPolicies}
                      />
                    </div>
                    <span
                      className="text-[18px] font-medium tracking-[0.185em] leading-[26px] text-left underline ml-[28px] text-white"
                      onClick={() => onClickButton('policy')}
                      role="button"
                    >
                      Ver políticas de privacidad
                    </span>
                  </div>
                </div>
                <div className="flex justify-end mb-[29px] px-[36px]">
                  <button
                    type="button"
                    className="flex justify-center w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
                    onClick={() => onClickButton('close')}
                    ref={cancelButton}
                  >
                    <span className="text-base leading-[19px] tracking-[0.08rem] text-white">Cancelar</span>
                  </button>
                  <button
                    type="button"
                    className="flex justify-center w-[161px] py-5 ml-[29px] border-primary rounded-[8px] border-2 bg-primary"
                    onClick={() => onClickButton('saveAndCloseClient')}
                  >
                    <span className="text-base leading-[19px] tracking-[0.08rem] text-white">Hecho</span>
                  </button>
                </div>
              </form>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalNewClient;
