import { UserClaims } from '@services/business/identity/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from 'schemy-ts';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { NoteClient } from '../../entities';
export type Interface = ApiInterface<{
  response: NoteClient;
  pathParams: EmptyObject;
  body: NoteClient;
  claims: UserClaims;
  queryParams: { nameId?: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-note-client',
  method: 'POST',
  basePath: 'notes-client',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
  schema: Schemy.schema<Interface['body']>({
    id: { type: String, required: true },
    clientId: { type: String, required: true },
    note: { type: String, required: true },
    createdAt: { type: String, required: false },
    updatedAt: { type: String, required: false },
    sucursalAt: { type: String, required: false },
  }),
};
