import { alpha, useTheme } from '@mui/material';
import Modal from '@webapp/components/ui/modal/Modal';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppState } from '../../hooks/useAppState';

interface CellProps {
  start: Date;
  end: Date;
  resourceKey: string;
  resourceVal: string | number;
  children?: JSX.Element;
}

const Cell = ({
  start,
  //end,
  resourceKey,
  resourceVal,
  children,
}: CellProps) => {
  const { onDrop } = useAppState();
  const theme = useTheme();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const currentTime = format(new Date(), 'HH:mm', { locale: es });
  const evenTime = format(start, 'HH:mm', { locale: es });
  const eventDate = format(start, 'yyyy-MM-dd', { locale: es });
  const hoy = format(new Date(), 'yyyy-MM-dd', { locale: es });

  return (
    <>
      <button
        style={{
          border: 'none',
          color: 'black',
          padding: '0px',
          textDecoration: 'none',
          display: 'block',
          fontSize: '0px',
          margin: '0px',
        }}
        onClick={() => {
          if (eventDate < hoy || (eventDate == hoy && evenTime < currentTime)) {
            setIsOpen(true);
          } else {
            if (resourceVal == 'AWAIT' || resourceVal == 'CANCELED') {
              setIsOpen(true);
            } else {
              history.push('/clientes/' + resourceVal + '/' + start.getTime());
            }
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          const color = alpha(theme.palette.secondary.main, 0.3);
          e.currentTarget.style.backgroundColor = color;
        }}
        onDragEnter={(e) => {
          const color = alpha(theme.palette.secondary.main, 0.3);
          e.currentTarget.style.backgroundColor = color;
        }}
        onDragLeave={(e) => {
          e.currentTarget.style.backgroundColor = '';
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.currentTarget.style.backgroundColor = '';
          const eventId = e.dataTransfer.getData('text');

          if (eventDate < hoy || (eventDate == hoy && evenTime < currentTime)) {
            setIsOpen(true);
          } else {
            if (resourceVal == 'AWAIT' || resourceVal == 'CANCELED') {
              setIsOpen(true);
            } else {
              onDrop(eventId, start, resourceKey, resourceVal);
            }
          }
        }}
      >
        {children}
      </button>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} noCancel title="MLASHES">
        <span className="flex justify-center items-center text-txt text-lg font-medium tracking-widest leading-tight">
          {resourceVal == 'AWAIT' || resourceVal == 'CANCELED'
            ? 'No se puede agendar en la Lista de Espera o Canceladas.'
            : 'Fecha de la Cita debe ser mayor a las ' + currentTime + ' de hoy.'}
        </span>
      </Modal>
    </>
  );
};

export { Cell };
