import { forwardRef, Fragment } from 'react';

import { getPaymentMethodName } from '@webapp/utils/getPaymentMethodName';
import { getSubdomain } from '@webapp/utils/subDomain';
import { rounded } from '@webapp/utils/validateNumber';

const IVA_PERCENTAGE = 0.16;

export const InvoiceToPrint = forwardRef((props, ref) => {
  const newArrItems = [...(props.orderItems || [])].filter((item) => item.type === 'SERVICE');

  props.listProducts?.forEach((product) => {
    const productsInOrder = props.orderItems?.filter((item) => item.serviceId === product.id);

    const productsQuantity = productsInOrder?.map((product) => ({ ...product, quantity: productsInOrder.length }));

    const uniqueItems = [];

    productsQuantity?.filter(function (item) {
      const i = uniqueItems?.findIndex((x) => x.serviceId == item.serviceId);
      if (i <= -1) {
        uniqueItems.push(item);
      }
      return null;
    });

    if (uniqueItems.length >= 1) {
      uniqueItems?.forEach((item) => {
        newArrItems.push(item);
      });
    }
  });

  return (
    <div ref={ref} className="flex flex-col p-2 m-5 bg-white">
      <style type="text/css" media="print">
        {'\
          @page { size: 320px 1100px; margin: 0px 10px 0 0px; }\
        '}
      </style>
      <span className="text-[20px] font-semibold leading-[15px] tracking-[0.185em] mb-5">MLASHES {getSubdomain()}</span>
      <div className="flex justify-between mb-3">
        <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em]">
          {new Date().getDate()}/{new Date().getMonth() + 1}/{new Date().getFullYear()}
        </span>
        <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em]">
          {new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
        </span>
      </div>
      <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em] mb-3">Rec#: {'123123123'}</span>
      <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em] mb-3">
        periférico paseo de la republica Michoacan
      </span>
      <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em] mb-3">Telefono: {'123123123'}</span>
      <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em] mb-3">
        Cobrado por: {props.seller}
      </span>
      <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em] mb-3">
        Cliente: {props.client?.firstName + ' ' + props.client?.lastName}
      </span>
      <span className="text-[13px] font-normal mb-3">Lashista: {props.lashista?.alias}</span>
      <table className="table-auto w-full border-b">
        <thead>
          <tr className="border-b">
            <th className="text-[11px] text-left font-normal leading-[12px] tracking-[0.185em]">Detalle</th>
            <th className="text-[11px] text-left font-normal leading-[12px] tracking-[0.185em]">Cant.</th>
            <th className="text-[11px] text-left font-normal leading-[12px] tracking-[0.185em]">Precio</th>
            <th className="text-[11px] text-left font-normal leading-[12px] tracking-[0.185em]">Desc.</th>
            <th className="text-[11px] text-left font-normal leading-[12px] tracking-[0.185em]">Total</th>
          </tr>
        </thead>
        <tbody>
          {newArrItems?.map((item, i) => (
            <Fragment key={i} className="">
              <tr>
                <td className="text-[10px] text-left font-normal leading-[12px] tracking-[0.185em]">
                  {item?.name ?? ''}
                </td>
                <td className="text-[10px] text-left font-normal leading-[12px] tracking-[0.185em]">
                  {item?.quantity ?? 1}
                </td>
                <td className="text-[10px] text-left font-normal leading-[12px] tracking-[0.185em]">
                  ${rounded(item.price ?? 0)}
                </td>
                <td className="text-[10px] text-left font-normal leading-[12px] tracking-[0.185em]">
                  ${rounded(item.discountAmount ?? 0)}
                </td>
                <td className="text-[10px] text-left font-normal leading-[12px] tracking-[0.185em]">
                  ${rounded((item.price ?? 0) - (item.discountAmount ?? 0))}
                </td>
              </tr>
              <tr>
                <td className="text-[10px] text-left font-normal leading-[12px] tracking-[0.185em] w-full">
                  {`${props.listLashista?.find((d) => d.id === item.lashistaId)?.firstName} ${
                    props.listLashista?.find((d) => d.id === item.lashistaId)?.lastName
                  }`}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end gap-4">
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em]">Total</span>
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em] text-right w-[50px]">
          {rounded(props.orderItems?.reduce((a, b) => a + b.price, 0))}
        </span>
      </div>
      <div className="flex justify-end gap-4">
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em]">Iva(16%)</span>
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em] text-right w-[50px]">
          {rounded(props.orderItems?.reduce((a, b) => a + b.price, 0) * IVA_PERCENTAGE)}
        </span>
      </div>
      <div className="flex justify-end gap-4">
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em]">Servicios</span>
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em] text-right w-[50px]">
          {props.totalServices}
        </span>
      </div>
      <div className="flex justify-end gap-4">
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em]">Venta</span>
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em] text-right w-[50px]">
          {props.totalProducts}
        </span>
      </div>
      <div className="flex justify-end gap-4 mb-4">
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em]">Descuento</span>
        <span className="text-[11px] font-normal leading-[12px] tracking-[0.185em] text-right w-[50px]">
          {props.orderItems?.reduce((a, b) => a + (b.discountAmount ?? 0), 0)}
        </span>
      </div>
      <div className="flex flex-col justify-start gap-2 mb-4">
        <span className="text-[11px] font-medium leading-[12px] tracking-[0.185em]">Métodos de pago</span>
        {props.paymentOrder?.methods?.map(
          (method, i) =>
            method.amount > 0 &&
            (method.type !== 'DOLLAR' ? (
              <span key={i} className="text-[11px] font-normal leading-[12px] tracking-[0.185em]">
                Recibido MXN$ {method.amount} en {getPaymentMethodName(method.type)}
              </span>
            ) : (
              <span key={i} className="text-[11px] font-normal leading-[12px] tracking-[0.185em]">
                Recibido MXN$ {method.amount} en {getPaymentMethodName(method.type)} $ {method.dollarAmount} con tasa{' '}
                {method.conversionRate}
              </span>
            ))
        )}
      </div>
      <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em]">NO ME TIRES!!!</span>
      <span className="text-[13px] font-normal leading-[12px] tracking-[0.185em]">
        RECUERDA QUE JUNTANDO TICKETS DE SERVICIO (SIN CANCELACIONES) OBTIENE GRATIS UN PRODUCTOS O SERVICIOS. TODOS LOS
        TICKETS DEBEN SER DE LA MISMA SUCURSAL
      </span>
    </div>
  );
});
