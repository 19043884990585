import { listBalanceOfDay } from '@webapp/sdk/cash-register';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Actions } from '../../actions';
import { ListCashRegister, ListCashRegisterError, ListCashRegisterSuccess } from '../action-creators';
import { LIST_CASH_REGISTER } from '../actions';

export const listCashRegisterEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListCashRegister['type'], ListCashRegister>(LIST_CASH_REGISTER),
    mergeMap(async (action) => {
      try {
        const result = await listBalanceOfDay(action.date);
        return new ListCashRegisterSuccess(result.items);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListCashRegisterError(err.message, err.description);
      }
    })
  );
