const getSubdomain = () => {
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    return 'localhost';
  }
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  return subdomain;
};

export { getSubdomain };
