import * as api from '@services/business/invoice/catalog/api';
import { Orden } from '@services/business/invoice/entities';
import { CallApi, handleJSONResponse } from './common';
import { CallApi as CallApiV2, parseCommonFields } from './common-v2';
import { dbStore } from './models/db';

const callAPI = CallApi('orden');
const callAPIv2 = CallApiV2('orden');

export const listCalendarSync = async (dateAppointment: string) => {
  callAPIv2<api.listAppointment.Interface>(
    { ...api.listAppointment.config, withCache: false },
    {
      queryParams: { dateAppointment },
    }
  ).then((a) => {
    a.items.forEach((item) => {
      dbStore.createOrUpdate(item);
    });
  });
};
export const listCalendar = async (dateAppointment: string): Promise<api.listAppointment.Interface['response']> => {
  listCalendarSync(dateAppointment);
  const items = await dbStore.orderItem.where('dateAppointment').equals(dateAppointment).toArray();
  // ok
  const body = await parseCommonFields(items);
  return {
    items: body,
    nextToken: '',
  };
};

export const createCalendar = async (
  order: api.createOrden.Interface['body']
): Promise<api.createOrden.Interface['response']> => {
  // ok
  const response = await callAPIv2<api.createOrden.Interface>(api.createOrden.config, {
    body: order,
  });

  return response;
};

export const finishCalendar = async (
  orden: api.finalizeOrden.Interface['body'],
  ordenId: string
): Promise<api.finalizeOrden.Interface['response']> => {
  // check guys

  const response = await callAPI(`/finalize/${ordenId}`, 'POST', orden);
  const body: api.finalizeOrden.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const updateCalendar = async (
  order: api.updateOrdenItem.Interface['body'],
  itemId: string
): Promise<api.updateOrdenItem.Interface['response']> => {
  // ok redux
  const response = await callAPIv2<api.updateOrdenItem.Interface>(api.updateOrdenItem.config, {
    pathParams: { itemId },
    body: order,
  });

  return response;
};

// no used
// export const listOrden = async (nextToken?: string | number | null): Promise<api.listOrden.Interface['response']> => {
//   const response = await callAPI(nextToken ? '/' : `/?nextToken=${nextToken || null}`, 'GET');
//   const body: api.listOrden.Interface['response'] = await handleJSONResponse(response);

//   return body;
// };

export const getOrder = async (orderId?: string): Promise<api.getOrden.Interface['response']> => {
  try {
    const response = await callAPI(`/${orderId}`, 'GET');
    const body: api.getOrden.Interface['response'] = await handleJSONResponse(response);

    return body;
  } catch (error) {
    const item = await dbStore.order.get(orderId as string);
    const getItem = await dbStore.orderItem
      .where('orderId')
      .equals(orderId as string)
      .toArray();

    return {
      orden: item as Orden,
      item: getItem,
    };
  }
};

export const getOrderItem = async (orderItemId?: string): Promise<api.getOrdenItem.Interface['response']> => {
  const response = await callAPI(`/item/${orderItemId}`, 'GET');
  const body: api.getOrdenItem.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const getAppointmentByDate = async (
  dateAppointment: string
): Promise<api.listAppointment.Interface['response']> => {
  const response = await callAPIv2<api.listAppointment.Interface>(api.listAppointment.config, {
    queryParams: { dateAppointment },
  });

  return response;
};
