import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Category } from '../../../entities/category';

export type Interface = ApiInterface<{
  response: Category;
  pathParams: EmptyObject;
  body: Omit<Category, 'tenantId' | 'id' | 'createdAt' | 'updatedAt'>;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-category',
  method: 'POST',
  basePath: 'category',
  path: '/',
  tablePermission: 'readWrite',
  metadataAuth: {
    onlySuperAdmin: true,
  },
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    name: { type: String, required: true },
    icon: { type: String },
  }),
};
