import { FinanceHistory } from '@services/business/client/entities';
import {
  ApiInterface,
  EmptyObject,
  PaginatedQueryParams,
  PaginatedResponse,
} from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
export type Interface = ApiInterface<{
  response: PaginatedResponse<FinanceHistory>;
  pathParams: EmptyObject;
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: PaginatedQueryParams & { clientId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-get-balance-history',
  method: 'GET',
  basePath: 'finance',
  path: '/history',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
