import { FunctionComponent } from 'react';

import SignIn from '@webapp/components/auth/sign-in';
import AuthContainer from '@webapp/components/auth/auth-container';

interface SignInPageProps {
  className?: string;
}

const SignInPage: FunctionComponent<SignInPageProps> = ({ className }) => {
  return (
    <AuthContainer className={className || ''}>
      <SignIn className="content flex justify-center" />
    </AuthContainer>
  );
};

export default SignInPage;
