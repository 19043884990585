import { FunctionComponent, useEffect, useState } from 'react';
// components
import Container from '@webapp/components/ui/container';
import Loading from '@webapp/components/ui/preloader/Loading';

import { useOpStore } from '@webapp/store/useOpStore';

import {
  HistoryBoxRegisterIcon,
  MlashesBoxRegisterIcon,
  PlusGreenRoundIcon,
  ThreeDotButtonGreenIcon,
} from '@webapp/components/ui/shared/icons';
import { GetCashRegister, ListCashRegisterTransactions } from '@webapp/store/cash-register/action-creators';
import { LIST_CASH_REGISTER_TRANSACTIONS } from '@webapp/store/cash-register/actions';
import AppState from '@webapp/store/state';
import { formatFullDateUS } from '@webapp/utils/formatDate';
import { getPaymentMethodName } from '@webapp/utils/getPaymentMethodName';
import { useHistory } from 'react-router-dom';

interface HoveringStatus {
  type: string;
  status: boolean;
}

const initHoveringStatus: HoveringStatus[] = [
  { type: 'HISTORY', status: false },
  { type: 'BOX', status: false },
];

const cashRegisterMovements: FunctionComponent = () => {
  const {
    cashRegister: { cashRegisterTransactions },
    loading,
    dispatch,
  } = useOpStore((state: AppState) => state);
  const [isHovering, setIsHovering] = useState<HoveringStatus[]>(initHoveringStatus);
  const history = useHistory();

  /**
   * this method change the status to true of the current button(hovering) to change the primary color
   * @param type string
   */
  const handleMouseEnter = (type: string) => {
    setIsHovering((current) =>
      current.map((obj) => {
        if (obj.type === type) {
          return { ...obj, status: true };
        }
        return obj;
      })
    );
  };

  /**
   * this method change the status to false of the current button(hovering) to change the default color
   * @param type string
   */
  const handleMouseLeave = (type: string) => {
    setIsHovering((current) =>
      current.map((obj) => {
        if (obj.type === type) {
          return { ...obj, status: false };
        }
        return obj;
      })
    );
  };

  /**
   * get the reports to calculate the amounts per payment method
   */
  useEffect(() => {
    dispatch(new GetCashRegister());
    dispatch(new ListCashRegisterTransactions(formatFullDateUS(new Date().getTime())));
  }, []);

  return (
    <Container className="flex justify-center">
      {loading[LIST_CASH_REGISTER_TRANSACTIONS] ? (
        <Loading />
      ) : (
        <div className="flex flex-col h-min-vh90 justify-between">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="flex justify-start items-center  text-txtWhite">
              <div className="p-3 text-[24px] font-medium leading-[31px] tracking-[0.185em]">
                Transacciones del punto de venta
              </div>
            </div>
            <div className="flex justify-end items-center text-txtWhite">
              <button
                type="button"
                className="flex justify-center items-center border border-transparent bg-transparent hover:border-primary focus:outline-none"
              >
                <ThreeDotButtonGreenIcon />
              </button>
            </div>
          </div>

          <div className="flex mt-[80px] mb-auto">
            <div className="flex flex-col bg-darkGray rounded-[8px] p-4">
              <span className="text-white text-[18px] font-medium leading-[23px] tracking-[0.05em] mb-4">
                Transacciones
              </span>
              <div className="flex overflow-auto max-h-[450px]">
                <table className="table-auto lg:table-fixed w-full shadow overflow-hidden">
                  <tbody className="border-[1px] border-white rounded-[4px]">
                    {cashRegisterTransactions?.map((transaction, i: number) => {
                      return (
                        <tr
                          key={i}
                          className="text-white hover:bg-primary text-[14px] md:text-base font-medium leading-[20px] tracking-[0.185em]"
                        >
                          <th className="flex gap-x-[10px] py-[8px] items-center">
                            <PlusGreenRoundIcon /> <span>1</span>
                          </th>
                          <td className="py-[8px]">{transaction.clientName}</td>
                          <td className="py-[8px]">{getPaymentMethodName(transaction.paymentMethodType)}</td>
                          <td className="py-[8px]">{transaction.amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 p-1 mt-[150px]">
            <div className="flex items-center justify-between gap-[12px]">
              <div className="flex items-center justify-start gap-[12px]">
                <button
                  className="flex flex-col justify-center items-center gap-y-[6px] hover:text-primary border-grey text-white text-base font-medium tracking-[0.185em] leading-[19px] bg-grey border rounded-[5px] w-min-[109px] h-[100px] p-[10px]"
                  onMouseEnter={() => handleMouseEnter('HISTORY')}
                  onMouseLeave={() => handleMouseLeave('HISTORY')}
                >
                  <HistoryBoxRegisterIcon
                    color={
                      isHovering.find((hovering) => hovering.type === 'HISTORY')?.status ? 'var(--primary)' : 'white'
                    }
                  />
                  Historial
                </button>
                <button
                  className={
                    'flex flex-col justify-center items-center gap-y-[6px] hover:text-primary border-grey text-white text-base font-medium tracking-[0.185em] leading-[19px] bg-grey border rounded-[5px] w-min-[109px] h-[100px] p-[10px]'
                  }
                  onMouseEnter={() => handleMouseEnter('BOX')}
                  onMouseLeave={() => handleMouseLeave('BOX')}
                  onClick={() => history.push('caja')}
                >
                  <MlashesBoxRegisterIcon
                    color={isHovering.find((hovering) => hovering.type === 'BOX')?.status ? 'var(--primary)' : 'white'}
                  />
                  Caja del <br /> día
                </button>
              </div>
              <button
                type="button"
                className="flex justify-center items-center p-0 border border-primary bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
              >
                Hecho
              </button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default cashRegisterMovements;
