import { DiscountsActions } from './action-creators';
import * as actions from './actions';
import { initialState } from './initial-state';
import { DiscountState } from './state';

const discountsReducer = (state: DiscountState = initialState, action: DiscountsActions): DiscountState => {
  switch (action.type) {
    case actions.LIST_DISCOUNT: {
      return {
        ...state,
        discounts: action.items,
      };
    }

    default:
      return state;
  }
};

export default discountsReducer;
