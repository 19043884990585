export const LIST_CASH_REGISTER = '[CashRegister] List Cash Register';
export const LIST_CASH_REGISTER_SUCCESS = '[CashRegister] List Cash Register Success';
export const LIST_CASH_REGISTER_ERROR = '[CashRegister] List Cash Register Error';

export const LIST_CASH_REGISTER_TRANSACTIONS = '[CashRegister] List Cash Register Transactions';
export const LIST_CASH_REGISTER_TRANSACTIONS_SUCCESS = '[CashRegister] List Cash Register Transactions Success';
export const LIST_CASH_REGISTER_TRANSACTIONS_ERROR = '[CashRegister] List Cash Register Transactions Error';

export const GET_CASH_REGISTER = '[CashRegister] Get Cash Register';
export const GET_CASH_REGISTER_SUCCESS = '[CashRegister] Get Cash Register Success';
export const GET_CASH_REGISTER_ERROR = '[CashRegister] Get Cash Register Error';

export const CREATE_PRE_CASH_REGISTER = '[CashRegister] Create Pre Cash Register';
export const CREATE_CASH_REGISTER = '[CashRegister] Create Cash Register';
export const CREATE_CASH_REGISTER_SUCCESS = '[CashRegister] Create Cash Register Success';
export const CREATE_CASH_REGISTER_ERROR = '[CashRegister] Create Cash Register Error';

export const UPDATE_CASH_REGISTER = '[CashRegister] Update Cash Register';
export const UPDATE_CASH_REGISTER_SUCCESS = '[CashRegister] Update Cash Register Success';
export const UPDATE_CASH_REGISTER_ERROR = '[CashRegister] Update Cash Register Error';

export const GET_SALES_OF_DAY = '[CashRegister] Get Sales Of Day';
export const GET_SALES_OF_DAY_SUCCESS = '[CashRegister] Get Sales Of Day Success';
export const GET_SALES_OF_DAY_ERROR = '[CashRegister] Get Sales Of Day Error';
