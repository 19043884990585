const SendGreenIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5453 0.457642C18.3402 0.253489 18.0829 0.109969 17.8017 0.0428573C17.5205 -0.0242546 17.2263 -0.0123611 16.9515 0.0772307L1.08965 5.38125C0.795474 5.4747 0.535521 5.65345 0.342622 5.89491C0.149723 6.13638 0.0325313 6.42974 0.00584837 6.73793C-0.0208346 7.04613 0.0441879 7.35534 0.192704 7.62652C0.34122 7.89769 0.566567 8.11866 0.840283 8.26151L7.42137 11.5222L10.674 18.1413C10.8047 18.4004 11.0048 18.618 11.2518 18.7696C11.4987 18.9212 11.783 19.001 12.0726 19H12.181C12.4912 18.9771 12.7873 18.8612 13.031 18.6674C13.2746 18.4735 13.4544 18.2107 13.5471 17.9131L18.9139 2.05537C19.0097 1.7814 19.0259 1.48578 18.9607 1.20292C18.8954 0.920061 18.7514 0.661605 18.5453 0.457642ZM1.76185 6.88116L15.6071 2.25101L7.9201 9.95706L1.76185 6.88116ZM12.1485 17.2827L9.06935 11.1092L16.7563 3.40312L12.1485 17.2827Z"
        fill="#56C4C7"
      />
    </svg>
  );
};

export default SendGreenIcon;
