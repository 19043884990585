import { UserClaims } from '@services/business/identity/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { OrdenItem } from '../../entities';
import { Orden, OrdenCreateAppointment } from '../../entities/orden';

export type Interface = ApiInterface<{
  response: { orden: Orden; item: OrdenItem[] };
  pathParams: EmptyObject;
  body: OrdenCreateAppointment;
  claims: UserClaims;
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-invoice',
  method: 'POST',
  basePath: 'orden',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
