import { Finance, FinanceHistoryCreate } from '@services/business/client/entities';
import { UserClaims } from '@services/business/identity/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
export type Interface = ApiInterface<{
  response: Finance;
  pathParams: EmptyObject;
  body: Omit<FinanceHistoryCreate, 'ejectBy'>;
  claims: UserClaims;
  queryParams: { clientId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-note-credit',
  method: 'POST',
  basePath: 'finance',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    note: { type: String, required: false },
    method: { type: String, required: true, enum: ['CARD', 'CASH'] },
    amount: { type: Number, required: true, max: 10000, min: 0 },
    currency: { type: String, required: true, enum: ['MEX'] },
    type: { type: String, required: true, enum: ['INCOME'] },
  }),
};
