import { FunctionComponent, useEffect, useState } from 'react';

import Container from '@webapp/components/ui/container';
import Input from '../../components/ui/forms/input';
import TexTarea from '../../components/ui/forms/textArea';
import { ThreeDotButtonGreenIcon } from '../../components/ui/shared/icons';

import { EditClientForm } from '../../types/forms/edit-client';

import { useOpStore } from '@webapp/store/useOpStore';

import ModalMessage from '@webapp/components/ui/modal/modal-message';
import { UpdateClient } from '@webapp/store/client/action-creators';
import { UPDATE_CLIENT } from '@webapp/store/client/actions';
import { OpenModal, PageWhereComeFrom } from '@webapp/store/ui/action-creators';
import { acceptOnlyLetters } from '@webapp/utils/validateString';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Ingrese el nombre del cliente'),
  lastName: yup.string().required('Ingrese el apellido del cliente'),
  email: yup.string().email('Correo invalido').required('Ingrese el correo del cliente'),
  phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'telefono invalido'
    ),
  sex: yup.string().notRequired(),
  notes: yup.string().notRequired(),
  street: yup.string().notRequired(),
  houseNumber: yup.string().notRequired(),
  cities: yup.string().notRequired(),
  cp: yup.string().notRequired(),
  marketing: yup.array().notRequired(),
  confirm: yup.array().notRequired(),
  rating: yup.array().notRequired(),
  fidelity: yup.array().notRequired(),
  diseases: yup.array().notRequired(),
});

const FichaClientPage: FunctionComponent = () => {
  const [mode, setMode] = useState('add');
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();
  const { currentClient: currentCustomer, dispatch, loading } = useOpStore((state) => state.client, [UPDATE_CLIENT]);

  const saveData = async (rawData: EditClientForm) => {
    const data: any = {
      // se debe cambiar el tipo a ClientUpdate pero da conflicto con el back usando el ID
      id: currentCustomer?.id,
      firstName: rawData.firstName,
      lastName: rawData.lastName,
      email: rawData.email,
      phone: rawData.phone,
      sex: rawData.sex,
      additionalData: {
        marketing: rawData.marketing,
        confirm: rawData.confirm,
        rating: rawData.rating,
        fidelity: rawData.fidelity,
        address: {
          street: rawData.street,
          cities: rawData.cities,
          houseNumber: rawData.houseNumber,
          cp: rawData.cp,
        },
      },
      health: {
        note: rawData.notes,
        allergies: rawData.diseases,
      },
    };

    dispatch(new UpdateClient(data, data.id));
    dispatch(new PageWhereComeFrom('UPDATE_CLIENT'));

    switch (mode) {
      case 'saveAndCloseClient':
        formik.resetForm();
        history.push('/clientes');
        break;
      case 'saveClient':
        dispatch(
          new OpenModal('MESSAGE_MODAL', {
            title: 'Editar Cliente',
            loading: loading[UPDATE_CLIENT],
            message: 'Actualización exitosa',
          })
        );
        break;

      default:
        formik.resetForm();
        history.push('/clientes');
        break;
    }
  };

  const formik = useFormik({
    initialValues: {
      id: currentCustomer?.id,
      firstName: currentCustomer?.firstName,
      lastName: currentCustomer?.lastName,
      email: currentCustomer?.email,
      phone: currentCustomer?.phone,
      notes: currentCustomer?.health?.note,
      sex: currentCustomer?.sex,
      street: currentCustomer?.additionalData?.address?.street,
      cities: currentCustomer?.additionalData?.address?.cities,
      houseNumber: currentCustomer?.additionalData?.address?.houseNumber,
      cp: currentCustomer?.additionalData?.address?.cp,
      marketing: currentCustomer?.additionalData?.marketing,
      confirm: currentCustomer?.additionalData?.confirm,
      rating: currentCustomer?.additionalData?.rating,
      fidelity: currentCustomer?.additionalData?.fidelity,
      diseases: currentCustomer?.health?.allergies,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      saveData(values);
    },
  });

  /**
   * mark the checkboxes that are active
   * @param array
   * @param value
   * @returns boolean
   */
  const isChecked = (array: string[] | string | undefined, value: string) => {
    let isFind = false;

    if (Array.isArray(array)) {
      array.forEach((v) => {
        if (v == value) {
          isFind = true;
        }
      });
    }
    return isFind;
  };

  /**
   * custom handler for the submit of the form
   * @param selected string
   */
  const onClickButton = (selected: string) => {
    switch (selected) {
      case 'saveClient': // save the data
        setIsSaving(true);
        setMode(selected);
        formik.submitForm();
        break;
      case 'saveAndCloseClient': // save the data and redirect to the client list view
        setIsSaving(true);
        setMode(selected);
        formik.submitForm();
        break;
      case 'close':
        history.push('/clientes');
        break;

      default: // redirect to the client list view
        history.push('/clientes');
        break;
    }
  };

  useEffect(() => {
    const JobErrors = JSON.parse(JSON.stringify(formik.errors));

    const errors = [];
    for (const x in JobErrors) {
      errors.push(x);
    }
  }, [formik.errors, isSaving]);

  return (
    <Container>
      <ModalMessage />
      <div className="flex flex-col">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between items-center mb-[40px]">
            <span className="text-[24px] font-normal leading-[30px] tracking-[0.185rem] text-white">
              Ficha de cliente
            </span>
            <ThreeDotButtonGreenIcon />
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mb-[36px] ">
            <div className="flex-1 flex flex-col bg-darkGray rounded-[8px] ">
              <div className="px-[26px] py-[17px] border-grey2 border-b-[1px]">
                <span className="text-[18px] font-medium leading-[23px] tracking-[0.185rem] text-white">
                  Datos cliente
                </span>
              </div>
              <div className="flex flex-col px-[26px] py-[25px]">
                <div className="grid grid-cols-2 gap-[24px] mb-[30px]">
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-grey bg-darkGray text-white"
                    id="firstName"
                    name="firstName"
                    label="Nombres"
                    type="text"
                    value={formik.values.firstName}
                    mode={mode}
                    onChange={(o: any) => {
                      if (acceptOnlyLetters(o.target.value) === true) {
                        formik.handleChange(o);
                      }
                    }}
                    error={formik.touched.firstName && formik.errors.firstName}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-grey bg-darkGray text-white"
                    id="lastName"
                    name="lastName"
                    label="Apellidos"
                    type="text"
                    value={formik.values.lastName}
                    mode={mode}
                    onChange={(o: any) => {
                      if (acceptOnlyLetters(o.target.value) === true) {
                        formik.handleChange(o);
                      }
                    }}
                    error={formik.touched.lastName && formik.errors.lastName}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-grey bg-darkGray text-white"
                    id="email"
                    name="email"
                    label="Email"
                    type="text"
                    value={formik.values.email}
                    mode={mode}
                    onChange={formik.handleChange}
                    error={formik.touched.email && formik.errors.email}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-grey bg-darkGray text-white"
                    id="phone"
                    name="phone"
                    label="Movil"
                    type="text"
                    maxLength={10}
                    value={formik.values.phone}
                    mode={mode}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && formik.errors.phone}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-base font-light tracking-[0.185em] leading-[20px] mb-[10px] text-white">
                    Género
                  </span>
                  <div className="flex mb-[30px]">
                    <Input
                      classnamecontainer="mr-4"
                      classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                      classinput="py-0 px-0 rounded-full border border-grey text-white w-4 "
                      id="sex"
                      name="sex"
                      type="radio"
                      label="Mujer"
                      value="FEMALE"
                      checked={formik.values.sex === 'FEMALE'}
                      mode={mode}
                      onChange={formik.handleChange}
                      error={formik.touched.sex && formik.errors.sex}
                    />
                    <Input
                      classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                      classinput="py-0 px-0 rounded-full border border-grey text-primary w-4"
                      id="sex"
                      name="sex"
                      type="radio"
                      label="Hombre"
                      value="MALE"
                      checked={formik.values.sex === 'MALE'}
                      mode={mode}
                      onChange={formik.handleChange}
                      error={formik.touched.sex && formik.errors.sex}
                    />
                  </div>
                  <TexTarea
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] mb-[11px] text-white"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] border-[#A8ACAC] rounded-[3px] bg-darkGray text-white"
                    name="notes"
                    label="Notas"
                    value={formik.values.notes}
                    mode={mode}
                    onChange={formik.handleChange}
                    error={formik.touched.notes && formik.errors.notes}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col bg-darkGray rounded-[8px]">
              <div className="px-[26px] py-[17px] border-grey2 border-b-[1px]">
                <span className="text-[18px] font-medium leading-[23px] tracking-[0.185rem] text-white">
                  Detalles de aceptación
                </span>
              </div>
              <div className="flex flex-col px-[26px] py-[25px]">
                <div className="flex justify-start mb-[20px]">
                  <span className="text-base font-medium tracking-[0.185em] leading-[20px] text-white">Marketing</span>
                </div>
                <div className="grid grid-cols-3 mb-[50px]">
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="marketing"
                    name="marketing"
                    type="checkbox"
                    label="SMS"
                    value="SMSCheck"
                    checked={isChecked(formik.values.marketing, 'SMSCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="marketing"
                    name="marketing"
                    type="checkbox"
                    label="Email"
                    value="EmailCheck"
                    checked={isChecked(formik.values.marketing, 'EmailCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="marketing"
                    name="marketing"
                    type="checkbox"
                    label="Telefono"
                    value="PhoneCheck"
                    checked={isChecked(formik.values.marketing, 'PhoneCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="flex justify-start mb-[20px]">
                  <span className="text-base font-medium tracking-[0.185em] leading-[20px] text-white">
                    Confirmaciones
                  </span>
                </div>
                <div className="grid grid-cols-3 mb-[50px]">
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="confirm"
                    name="confirm"
                    type="checkbox"
                    label="SMS"
                    value="SMSCheck"
                    checked={isChecked(formik.values.confirm, 'SMSCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="confirm"
                    name="confirm"
                    type="checkbox"
                    label="Email"
                    value="EmailCheck"
                    checked={isChecked(formik.values.confirm, 'EmailCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="confirm"
                    name="confirm"
                    type="checkbox"
                    label="Telefono"
                    value="PhoneCheck"
                    checked={isChecked(formik.values.confirm, 'PhoneCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="flex justify-start mb-[20px]">
                  <span className="text-base font-medium tracking-[0.185em] leading-[20px] text-white">Fidelidad</span>
                </div>
                <div className="flex justify-start mb-[50px]">
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="fidelity"
                    name="fidelity"
                    type="checkbox"
                    label="Email"
                    value="EmailCheck"
                    checked={isChecked(formik.values.fidelity, 'EmailCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="flex justify-start mb-[20px]">
                  <span className="text-base font-medium tracking-[0.185em] leading-[20px] text-white">
                    Valoraciones y reseñas
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="rating"
                    name="rating"
                    type="checkbox"
                    label="SMS"
                    value="SMSCheck"
                    checked={isChecked(formik.values.rating, 'SMSCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                    classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                    id="rating"
                    name="rating"
                    type="checkbox"
                    label="Email"
                    value="EmailCheck"
                    checked={isChecked(formik.values.rating, 'EmailCheck')}
                    mode={mode}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col bg-darkGray rounded-[8px]">
              <div className="px-[26px] py-[17px] border-grey2 border-b-[1px]">
                <span className="text-[18px] font-medium leading-[23px] tracking-[0.185rem] text-white">
                  Dirección particular
                </span>
              </div>
              <div className="flex flex-col mb-[30px] px-[26px] py-[25px]">
                <div className="flex mb-[23px] gap-[24px]">
                  <Input
                    classnamecontainer="w-9/12"
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-grey bg-darkGray text-white"
                    name="street"
                    label="Dirección"
                    type="text"
                    value={formik.values.street ? formik.values.street : ''}
                    mode={mode}
                    onChange={formik.handleChange}
                    error={formik.touched.street && formik.errors.street}
                  />
                  <Input
                    classnamecontainer="w-3/12"
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-grey bg-darkGray text-white"
                    name="houseNumber"
                    label="Número"
                    type="text"
                    value={formik.values.houseNumber ? formik.values.houseNumber : ''}
                    mode={mode}
                    onChange={formik.handleChange}
                    error={formik.touched.houseNumber && formik.errors.houseNumber}
                  />
                </div>
                <div className="flex gap-[24px]">
                  <Input
                    classnamecontainer="w-9/12"
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] p-1 px-3 border rounded-[3px] border-grey w-full bg-darkGray text-white"
                    name="cities"
                    label="Ciudad"
                    value={formik.values.cities}
                    mode={mode}
                    onChange={formik.handleChange}
                    error={formik.touched.cities && formik.errors.cities}
                  />
                  <Input
                    classnamecontainer="w-3/12"
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-grey bg-darkGray text-white"
                    name="cp"
                    label="CP"
                    type="text"
                    value={formik.values.cp ? formik.values.cp : ''}
                    mode={mode}
                    onChange={formik.handleChange}
                    error={formik.touched.cp && formik.errors.cp}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col bg-darkGray rounded-[8px]">
              <div className="px-[26px] py-[17px] border-grey2 border-b-[1px]">
                <span className="text-[18px] font-medium leading-[23px] tracking-[0.185rem] text-white">
                  Historia clínica
                </span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-[20px] md:gap-[27px] px-[26px] py-[25px]">
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Alergias"
                  value="allergiesCheck"
                  checked={isChecked(formik.values.diseases, 'allergiesCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Alopecia"
                  value="alopeciaCheck"
                  checked={isChecked(formik.values.diseases, 'alopeciaCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Asma"
                  value="asthmaCheck"
                  checked={isChecked(formik.values.diseases, 'asthmaCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Tiroidismo"
                  value="thyroidismCheck"
                  checked={isChecked(formik.values.diseases, 'thyroidismCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Epilepsia"
                  value="epilepsyCheck"
                  checked={isChecked(formik.values.diseases, 'epilepsyCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Cirugía óptica"
                  value="opticalSurgeryCheck"
                  checked={isChecked(formik.values.diseases, 'opticalSurgeryCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Piel sensible"
                  value="sensibleSkinCheck"
                  checked={isChecked(formik.values.diseases, 'sensibleSkinCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Cáncer"
                  value="cancerCheck"
                  checked={isChecked(formik.values.diseases, 'cancerCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Claustrofobia"
                  value="claustrophobiaCheck"
                  checked={isChecked(formik.values.diseases, 'claustrophobiaCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Infección en la piel"
                  value="skinInfectionCheck"
                  checked={isChecked(formik.values.diseases, 'skinInfectionCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Infección ocular"
                  value="ocularInfectionCheck"
                  checked={isChecked(formik.values.diseases, 'ocularInfectionCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
                <Input
                  classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-grey text-primary w-4"
                  id="diseases"
                  name="diseases"
                  type="checkbox"
                  label="Lentes de contacto"
                  value="contactLensCheck"
                  checked={isChecked(formik.values.diseases, 'contactLensCheck')}
                  mode={mode}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              className="flex justify-center min-w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
              onClick={() => onClickButton('close')}
            >
              <span className="text-base font-medium leading-[20px] tracking-[0.08rem] text-white">Cancelar</span>
            </button>
            <div className="flex">
              <button
                type="button"
                className="flex justify-center min-w-[161px] py-5 border-primary rounded-[8px] border-2 bg-transparent mr-[29px]"
                onClick={() => onClickButton('saveClient')}
              >
                <span className="text-base font-medium leading-[20px] tracking-[0.08rem] text-primary">Guardar</span>
              </button>
              <button
                type="button"
                className="flex justify-center min-w-[161px] py-5 border-primary rounded-[8px] border-2 bg-primary"
                onClick={() => onClickButton('saveAndCloseClient')}
              >
                <span className="text-base font-medium leading-[20px] tracking-[0.08rem] text-white">Hecho</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default FichaClientPage;
