/* eslint-disable no-console */
import { FunctionComponent, useEffect } from 'react';

import ListClientComponent from '@webapp/components/cliente/list-client';
import AppointmentNoteModal from '@webapp/components/cliente/modal-appointment';
import Container from '@webapp/components/ui/container';
import ModalCreateNoteCredit from '@webapp/components/ui/modal/modal-create-note-credit';
import ModalListNoteCredit from '@webapp/components/ui/modal/modal-list-note-credit';
import { ListClient } from '@webapp/store/client/action-creators';
import { LIST_CLIENT } from '@webapp/store/client/actions';
import { useOpStore } from '@webapp/store/useOpStore';

const ClientPage: FunctionComponent = () => {
  const { listClient, dispatch, loading } = useOpStore((state) => state.client, [LIST_CLIENT]);

  useEffect(() => {
    dispatch(new ListClient());
  }, []);

  return (
    <Container
      className="flex flex-col"
      title={
        <span className="text-[24px] font-normal leading-[30px] tracking-[0.185rem] text-white">
          Listado de Clientes
        </span>
      }
    >
      <ModalListNoteCredit title={'Historial de Notas de Crédito'} />
      <ModalCreateNoteCredit title="Crear Anticipo" />
      <AppointmentNoteModal />
      <ListClientComponent customers={listClient} loading={loading[LIST_CLIENT]} />
    </Container>
  );
};

export default ClientPage;
