import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import { ListLashista } from '@webapp/store/lashista/action-creators';
import AppState from '@webapp/store/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';

interface ISecondChildProps {
  setLashista: (arg: string, serviceId: string) => void;
}

const SearchLashistaModal: FunctionComponent<ISecondChildProps> = ({ setLashista }) => {
  const cancelButton = useRef(null);
  const {
    ui: { modalOpened },
    lashista: { listLashista },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const [lashistaInputSearch, setLashistaInputSearch] = useState<string>('');

  const { open, currentServiceId } = useMemo(() => {
    return {
      open: modalOpened?.['SEARCH_LASHISTA_MODAL']?.open ?? false,
      currentServiceId: (modalOpened?.['SEARCH_LASHISTA_MODAL']?.value.serviceId as string) ?? {},
    };
  }, [modalOpened?.['SEARCH_LASHISTA_MODAL']]);

  /**
   * search the lashista in the input search
   */
  useEffect(() => {
    const intervalSearch = setTimeout(() => {
      // update the state of clients
      dispatch(new ListLashista(lashistaInputSearch));
    }, 500);
    return () => {
      clearTimeout(intervalSearch);
    };
  }, [lashistaInputSearch]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('SEARCH_LASHISTA_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#6b7280] bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block align-bottom bg-darkGray rounded-[8px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[1024px] sm:w-full">
              <div className="bg-primary py-[25px] px-[36px] flex justify-between">
                <Dialog.Title as="span" className="text-white text-[24px] font-bold tracking-[0.185em] leading-[30px]">
                  Buscar Empleado
                </Dialog.Title>
              </div>
              <div className="my-[17px] px-[36px] flex flex-col">
                <div className="flex justify-between mb-12 gap-[27px] ">
                  <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                    <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                      <label
                        className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                        htmlFor="inputSearch"
                      >
                        Buscar:
                      </label>
                    </div>
                    <div className="flex-1">
                      <input
                        type="text"
                        className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-[#878A8A] border-0 w-full h-full"
                        name="inputSearch"
                        id="inputSearch"
                        value={lashistaInputSearch}
                        onChange={(e) => setLashistaInputSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex rounded-[3px] border-[1px] border-[#A8ACAC]"></div>
                  <div className="flex">
                    <button
                      type="button"
                      className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
                      onClick={() => {
                        setLashistaInputSearch('');
                      }}
                    >
                      <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar campo</span>
                    </button>
                  </div>
                </div>

                <div className="flex flex-col overflow-y-auto max-h-[765px]">
                  <table className="table-auto w-full border-separate text-left">
                    <thead>
                      <tr className="bg-grey rounded-[3px] h-[33px]">
                        <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-white">
                          Nombre
                        </th>
                        <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-white">
                          Apellido
                        </th>
                        <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-white">
                          Disponibilidad
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listLashista &&
                        listLashista.map((lashista, i) => (
                          <tr
                            className="cursor-pointer hover:bg-primary"
                            key={i}
                            onClick={() => setLashista(lashista.id, currentServiceId)}
                          >
                            <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] py-2 text-white">
                              {lashista.firstName}
                            </td>
                            <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] py-2 text-white">
                              {lashista.lastName}
                            </td>
                            <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] py-2 text-white">
                              {lashista.status}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex justify-end mb-[29px] px-[36px]">
                <button
                  type="button"
                  className="flex justify-center w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
                  onClick={() => dispatch(new CloseModal('SEARCH_LASHISTA_MODAL'))}
                  ref={cancelButton}
                >
                  <span className="text-base leading-[19px] tracking-[0.08rem] text-white">Cancelar</span>
                </button>
                <button
                  type="button"
                  className="flex justify-center  w-[161px] py-5 ml-[29px] border-primary rounded-[8px] border-2 bg-primary"
                  onClick={() => dispatch(new CloseModal('SEARCH_LASHISTA_MODAL'))}
                >
                  <span className="text-base leading-[19px] tracking-[0.08rem] text-white ml-[6px]">Hecho</span>
                </button>
              </div>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default SearchLashistaModal;
