import * as api from '@services/business/identity/catalog/api';
import { parseCommonFields } from './common';
import { CallApi } from './common-v2';

// CAMBIAR
const callAPI = CallApi('identity');

export const listEmployee = async (query?: string): Promise<api.listEmployee.Interface['response']> => {
  const response = await callAPI<api.listEmployee.Interface>(
    { ...api.listEmployee.config, withCache: false },
    {
      queryParams: {
        query,
      },
    }
  );
  const body: api.listEmployee.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const createEmployee = async (
  employee: api.createEmployee.Interface['body']
): Promise<api.createEmployee.Interface['response']> => {
  const response = await callAPI<api.createEmployee.Interface>({ ...api.createEmployee.config }, { body: employee });
  const body = await parseCommonFields(response);

  return body;
};

export const updateEmployee = async (
  data: api.updateEmployee.Interface['body'],
  username: string
): Promise<api.updateEmployee.Interface['response']> => {
  const response = await callAPI<api.updateEmployee.Interface>(
    { ...api.updateEmployee.config },
    {
      body: data,
      pathParams: {
        username,
      },
    }
  );
  const body: api.updateEmployee.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const deleteEmployee = async (username: string): Promise<api.deleteEmployee.Interface['response']> => {
  const response = await callAPI<api.deleteEmployee.Interface>(
    { ...api.deleteEmployee.config },
    {
      pathParams: {
        identityId: username,
      },
    }
  );

  return response;
};
