import { api } from '@services/business/history/catalog';
import { CallApi, handleJSONResponse } from './common';

const callAPI = CallApi('history/order/date', true);

export const listReports = async (date: string): Promise<api.historyOrderPerDate.Interface['response']> => {
  const response = await callAPI('', 'GET', {
    dateAppointment: date,
  });

  const body: api.historyOrderPerDate.Interface['response'] = await handleJSONResponse(response);

  return body;
};
