import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useMemo, useRef, useState } from 'react';

import { generateId } from '@services/support/utils/generate-id';
import TextArea from '@webapp/components/ui/forms/textArea';
import { CreateNotes, UpdateNotes } from '@webapp/store/notes/action-creators';
import { Notes } from '@webapp/store/notes/state';
import AppState from '@webapp/store/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { getSubdomain } from '@webapp/utils/subDomain';

const ModalCreateNoteClient: FunctionComponent = () => {
  const cancelButton = useRef(null);
  const {
    client: { currentClient },
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const [note, setNote] = useState('');

  const { open, modalData } = useMemo(() => {
    return {
      open: modalOpened?.['CREATE_NOTE_CLIENT_MODAL']?.open ?? false,
      modalData:
        (modalOpened?.['CREATE_NOTE_CLIENT_MODAL']?.value as {
          currentNote: Notes;
        }) ?? {},
    };
  }, [modalOpened?.['CREATE_NOTE_CLIENT_MODAL']]);

  const saveNoteCredit = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (modalData.hasOwnProperty('currentNote')) {
      dispatch(
        new UpdateNotes(
          {
            clientId: modalData.currentNote.clientId,
            note: note,
            sucursalAt: modalData.currentNote.sucursalAt,
          },
          modalData.currentNote.id
        )
      );
    } else {
      dispatch(
        new CreateNotes({
          id: generateId('noteClient'),
          clientId: currentClient?.id ?? '',
          note: note,
          sucursalAt: getSubdomain(),
          createdAt: new Date(),
          updatedAt: new Date(),
        })
      );
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('CREATE_NOTE_CLIENT_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-darkGray rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <form>
                <div className="bg-primary py-3 pl-9 flex justify-left">
                  <Dialog.Title
                    as="span"
                    className="flex justify-between items-center w-full text-white text-xl md:text-2xl font-bold tracking-widest leading-tight"
                  >
                    {
                      // eslint-disable-next-line no-prototype-builtins
                      modalData.hasOwnProperty('currentNote') ? 'Editar Nota Del Cliente' : 'Crear Nota Del Cliente'
                    }
                  </Dialog.Title>
                </div>
                <div className="py-3 md:py-5 px-4 md:px-8 flex flex-col mb-5">
                  <div className="flex flex-col border border-grey2 rounded-[3px] p-[22px] mb-[40px]">
                    <TextArea
                      classlabel="text-base font-light tracking-[0.185em] leading-[20px] mb-[11px] text-white"
                      classinput="text-base font-light tracking-[0.185em] leading-[20px] border-[#A8ACAC] rounded-[3px] bg-white text-black"
                      name="notes"
                      label="Nota"
                      mode={'add'}
                      defaultValue={
                        // eslint-disable-next-line no-prototype-builtins
                        modalData.hasOwnProperty('currentNote') ? modalData.currentNote.note : ''
                      }
                      onChange={(e: any) => setNote(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-center gap-4 items-center mb-6 px-5">
                  <button
                    type="button"
                    className="flex justify-center items-center p-0 border bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                    onClick={() => {
                      setNote('');
                      dispatch(new CloseModal('CREATE_NOTE_CLIENT_MODAL'));
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="flex justify-center items-center p-0 border bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                    onClick={() => {
                      saveNoteCredit();
                      dispatch(new CloseModal('CREATE_NOTE_CLIENT_MODAL'));
                    }}
                  >
                    Hecho
                  </button>
                </div>
              </form>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalCreateNoteClient;
