import { getSubdomain } from '@webapp/utils/subDomain';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as client from '../../../sdk/client';
import { Actions } from '../../actions';
import { ListClient, ListClientError, ListClientSuccess } from '../action-creators';
import { LIST_CLIENT } from '../actions';
import { Client } from '../state';

export const listClientEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListClient['type'], ListClient>(LIST_CLIENT),
    mergeMap(async (action) => {
      try {
        const result = await client.listClient(action.search);

        return new ListClientSuccess(
          result.items
            .filter((item) => item.id !== 'cus_anonimo')
            .concat({
              id: 'cus_anonimo',
              firstName: 'WALKING',
              lastName: '',
              phone: '',
              sex: 'FEMALE' as Client['sex'],
              additionalData: {
                marketing: ['SMSCheck'],
                confirm: ['SMSCheck'],
                rating: ['SMSCheck'],
                fidelity: ['EmailCheck'],
                privacyPolicies: true,
              },
              status: 'ACTIVE' as Client['status'],
              sucursalAt: getSubdomain(),
              createdAt: new Date(),
              updatedAt: new Date(),
            })
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListClientError(err.message, err.description);
      }
    })
  );
