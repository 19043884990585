import { LashistaActions } from './action-creators';
import * as actions from './actions';
import { lashistaInitialState } from './initial-state';
import { LashistaState } from './state';

const lashistaReducer = (state: LashistaState = lashistaInitialState, action: LashistaActions): LashistaState => {
  switch (action.type) {
    case actions.LIST_LASHISTA_SUCCESS: {
      return {
        ...state,
        listLashista: action.items,
      };
    }
    case actions.SET_CURRENT_LASHISTA: {
      return {
        ...state,
        currentLashista: action.item,
      };
    }

    default:
      return state;
  }
};

export default lashistaReducer;
