import * as api from '@services/business/identity/catalog/api';
import { Action } from 'redux';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';
import { IEmployee } from './state';

export class ListEmployee implements LoadingAction {
  readonly type = actions.LIST_EMPLOYEE;
  readonly isLoading = true;
  constructor(public search?: string) {}
}

export class ListEmployeeSuccess implements LoadingAction {
  readonly type = actions.LIST_EMPLOYEE_SUCCESS;
  readonly isLoading = false;

  constructor(public items: IEmployee[]) {}
}

export class ListEmployeeError implements ErrorAction {
  readonly type = actions.LIST_EMPLOYEE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateEmployee implements LoadingAction {
  readonly type = actions.CREATE_EMPLOYEE;
  readonly isLoading = true;
  constructor(public employee: api.createEmployee.Interface['body'], public updateCurrentId?: boolean) {}
}

export class CreateEmployeeSuccess implements LoadingAction {
  readonly type = actions.CREATE_EMPLOYEE_SUCCESS;
  readonly isLoading = false;
}

export class CreateEmployeeError implements ErrorAction {
  readonly type = actions.CREATE_EMPLOYEE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class SetCurrentEmployee implements Action {
  readonly type = actions.SET_CURRENT_EMPLOYEE;

  constructor(public item: IEmployee | undefined) {}
}

export class DeleteEmployee implements LoadingAction {
  readonly type = actions.DELETE_EMPLOYEE;
  readonly isLoading = true;
  constructor(public employeeId: string) {}
}

export class DeleteEmployeeSuccess implements LoadingAction {
  readonly type = actions.DELETE_EMPLOYEE_SUCCESS;
  readonly isLoading = false;
}

export class DeleteEmployeeError implements ErrorAction {
  readonly type = actions.DELETE_EMPLOYEE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateEmployee implements LoadingAction {
  readonly type = actions.UPDATE_EMPLOYEE;
  readonly isLoading = true;
  constructor(public employee: api.updateEmployee.Interface['body'], public username: string) {}
}

export class UpdateEmployeeSuccess implements LoadingAction {
  readonly type = actions.UPDATE_EMPLOYEE_SUCCESS;
  readonly isLoading = false;
}

export class UpdateEmployeeError implements ErrorAction {
  readonly type = actions.UPDATE_EMPLOYEE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type EmployeeActions =
  | ListEmployee
  | ListEmployeeSuccess
  | ListEmployeeError
  | CreateEmployee
  | CreateEmployeeSuccess
  | CreateEmployeeError
  | SetCurrentEmployee
  | DeleteEmployee
  | DeleteEmployeeSuccess
  | DeleteEmployeeError
  | UpdateEmployee
  | UpdateEmployeeSuccess
  | UpdateEmployeeError;
