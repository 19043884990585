import { SalesOfDay } from '@webapp/store/cash-register/state';
import { getPaymentMethodName } from '@webapp/utils/getPaymentMethodName';
import { FunctionComponent, useState } from 'react';
import ModalStepTwo from './modal-step-two';

const StepTwoComponent: FunctionComponent<{ cashRegisterList: SalesOfDay[] | undefined }> = ({ cashRegisterList }) => {
  const [currentCashInRegister, setCurrentCashInRegister] = useState<SalesOfDay[]>([
    { type: 'CARD', amount: 0 },
    { type: 'CASH', amount: 0 },
    { type: 'TRANSFER', amount: 0 },
  ]);

  return (
    <div className="flex bg-darkGray rounded-[8px] p-[24px]">
      <ModalStepTwo currentCashInRegister={currentCashInRegister} />
      <div className="flex flex-col items-end gap-y-[20px] ">
        <span className="w-full bg-primary py-[8px] rounded-[4px] text-center text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em]">
          Métodos de pago
        </span>
        <div className="flex flex-col overflow-x-auto max-h-[372px]">
          <table className="table-auto lg:table-fixed w-full shadow overflow-hidden rounded-[4px]">
            <thead>
              <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] ">
                <td className="border-l border-white p-[8px] bg-primary">Nombre</td>
                <td className="border-l border-white p-[8px] bg-primary">Valor</td>
              </tr>
            </thead>
            <tbody>
              {cashRegisterList?.map((d, i: number) => {
                return (
                  <tr
                    key={i}
                    className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em]"
                  >
                    <td className="border border-grey p-[8px]">{getPaymentMethodName(d.type)}</td>

                    <td className="border border-grey p-[8px] flex items-center">
                      $
                      <input
                        name="cashAmount"
                        className="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-white"
                        onChange={(e) =>
                          setCurrentCashInRegister((current) =>
                            current.map((obj) => {
                              if (obj.type === d.type) {
                                return { ...obj, amount: Number(e.target.value) };
                              }
                              return obj;
                            })
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <span className="w-[220px] bg-primary py-[8px] px-[32px] rounded-[4px] text-end text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em]">
          {currentCashInRegister?.reduce((a, b) => a + b.amount, 0) ?? 0}
        </span>
      </div>
    </div>
  );
};

export default StepTwoComponent;
