import algoliasearch, { SearchIndex } from 'algoliasearch';
import { SearchType, searchTypes } from '../lib/search-type';

export const algolia = (entityName: SearchType, indexAlgolia?: string): SearchIndex => {
  const client = algoliasearch(process.env.ALGOLIA_APPLICATION_ID!, process.env.ALGOLIA_API_KEY!);

  const index = client.initIndex(indexAlgolia || `${process.env.INDEX_ALGOLIA}_${entityName}`);
  index.setSettings({ ...searchTypes[entityName].settings });
  return index;
};

export const algoliaFrontend = (entityName: SearchType, indexAlgolia?: string): SearchIndex => {
  const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID!, process.env.REACT_APP_ALGOLIA_API_KEY!);

  const index = client.initIndex(indexAlgolia || `${process.env.REACT_APP_INDEX_ALGOLIA}_${entityName}`);

  return index;
};
