import * as api from '@services/business/lashitas/catalog/api';
import { parseCommonFields } from './common';
import { CallApi } from './common-v2';

const callAPI = CallApi('lashitas');

export const listLashita = async (
  _query?: string,
  nextToken?: string
): Promise<api.listLashitas.Interface['response']> => {
  try {
    const response = await callAPI<api.listLashitas.Interface>(
      { ...api.listLashitas.config, withCache: true },
      {
        queryParams: {
          nextToken,
        },
      }
    );
    const body: api.listLashitas.Interface['response'] = await parseCommonFields(response);

    return body;
  } catch (error) {
    return {
      items: [],
      nextToken: '',
    };
  }
};

export const createLashitas = async (
  lashita: api.createLashitas.Interface['body']
): Promise<api.createLashitas.Interface['response']> => {
  const response = await callAPI<api.createLashitas.Interface>(
    { onlyDeleteInLocal: true, ...api.createLashitas.config },
    { body: lashita }
  );
  const body = await parseCommonFields(response);

  return body;
};

export const updateLashitas = async (
  Lashita: api.updateLashitas.Interface['body'] & { id: string }
): Promise<api.updateLashitas.Interface['response']> => {
  // WIP: error con el ID si se envía, debe ser arreglado
  const response = await callAPI<api.updateLashitas.Interface>(
    { onlyDeleteInLocal: true, ...api.updateLashitas.config },
    {
      body: Lashita,
      pathParams: {
        lashitasId: Lashita.id,
      },
    }
  );
  const body: api.updateLashitas.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const deleteLashitas = async (client: string): Promise<api.deleteLashitas.Interface['response']> => {
  const response = await callAPI<api.deleteLashitas.Interface>(
    { onlyDeleteInLocal: true, ...api.deleteLashitas.config },
    {
      pathParams: {
        lashitasId: client,
      },
    }
  );

  return response;
};
