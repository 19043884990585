import * as api from '@services/business/history/catalog/api';

export const normalizeNoReturnClient = (
  data: api.historyOldOrNewClient.Interface['response'],
  dataHistoryReport: api.getHistoryOrder.Interface['response']
): Array<{
  dataClient: {
    clientFullName: string;
    lastVisitTime: string;
    phone: string;
    quantity: number;
  };
  sucursal: string;
  lashistaId: string;
}> => {
  const response = data.entities?.map((entity: any) => {
    const getLashista = dataHistoryReport.filter(({ items }) => {
      return items.find((item) => item.clientId === entity.id);
    });

    const [lashista] = getLashista;

    const quantityBySucursal = data.entities.filter(({ sucursalAt }) => sucursalAt === entity.sucursalAt).length;
    return {
      dataClient: {
        clientFullName: `${entity.firstName} ${entity.lastName}`,
        lastVisitTime: new Date(entity.lastVisitAtTime).toLocaleDateString('es-MX'),
        phone: entity.phone,
        quantity: quantityBySucursal,
      },
      sucursal: entity.sucursalAt,
      lashistaId: lashista?.items[0].clientId,
    };
  });

  return response;
};
