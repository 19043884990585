import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import '@mui/material';
import { useState } from 'react';
import { useAppState } from '../../hooks/useAppState';
import IconButton from '../common/iconButton';
import { DayDateBtn } from './DayDateBtn';

export type View = 'day';

const Navigation = () => {
  const { selectedDate, handleState, calendarIcon } = useAppState();
  const [openDate, setOpenDate] = useState(false);
  const [gColumn, setColumn] = useState(2);

  const CalendarIcon = () => {
    if (calendarIcon) {
      return calendarIcon;
    } else {
      return <></>;
    }
  };

  const scrollColumn = (isSubs: boolean) => {
    let column = gColumn;
    let val = 1;
    if (isSubs) {
      val = -1;
    }
    if (column <= 1) {
      column = 1;
    }
    const ant = document.getElementById(`col${column}`);

    if (ant) {
      setTimeout(() => (ant.style.backgroundColor = 'var(--very-dark-gray)'), 300);
    }
    column = column + val;
    const temp = document.getElementById(`col${column}`);

    if (temp?.style) {
      setTimeout(() => (temp.style.backgroundColor = 'var(--primary)'), 50);
      setTimeout(() => (temp.style.backgroundColor = 'var(--very-dark-gray)'), 1000);
      //  location.href = `#col${column}`;
      setTimeout(() => scroll(0, 0), 50);
    } else {
      column = column + (isSubs ? val : val * -1);
    }
    setColumn(column);
  };

  return (
    <>
      <div className="absolute top-1 left-1 z-30 bg-very-dark-gray rounded-full">
        <IconButton
          className="rounded-full"
          onClick={() => {
            setOpenDate(true);
          }}
        >
          <CalendarIcon />
        </IconButton>
      </div>
      <div className="fixed bottom-0 left-0 w-full bg-veryDarkGray z-40 flex justify-between items-center">
        <div>
          <IconButton className="hidden md:block rounded-full ml-4" onClick={() => scrollColumn(true)}>
            <ArrowLeft sx={{ color: 'var(--txtWhite)' }} fontSize="large" />
          </IconButton>
        </div>
        <DayDateBtn
          selectedDate={selectedDate}
          openDate={openDate}
          setOpenDate={(value: boolean) => setOpenDate(value)}
          onChange={handleState}
        />
        <div>
          <IconButton className="hidden md:block rounded-full mr-4" onClick={() => setOpenDate(true)}>
            <ArrowRight sx={{ color: 'var(--txtWhite)' }} fontSize="large" />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export { Navigation };
