import { api } from '@services/business/history/catalog';
import { CallApi, handleJSONResponse } from './common';

const callAPI = CallApi('history/order', true);

export const listReports = async (
  data: api.getHistoryOrder.Interface['body']
): Promise<api.getHistoryOrder.Interface['response']> => {
  const response = await callAPI('', 'POST', data);

  const body: api.getHistoryOrder.Interface['response'] = await handleJSONResponse(response);

  return body;
};
