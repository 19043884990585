import { Dialog, Transition } from '@headlessui/react';
import { CheckGreenIcon, DeleteSmallIcon } from '@webapp/components/ui/shared/icons';
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// store state
import { generateId } from '@utils/generate-id';
import { dbStore } from '@webapp/sdk/models/db';
import { UpdatePreAppointmentItem } from '@webapp/store/appointments/action-creators';
import { Appointment, CategoryWithService } from '@webapp/store/appointments/state';
import { Category, Service } from '@webapp/store/products/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { lastAppointmentBeforeClose, sumSecondsDate } from '@webapp/utils/formatDate';
import { useLiveQuery } from 'dexie-react-hooks';

interface PageProps {
  products: Service[];
  categories: Category[];
}
type Errors = 'EXIST' | 'DATE';

const ProductModalBodyComponent: FunctionComponent<PageProps> = ({ products, categories }) => {
  const cancelButton = useRef(null);
  const {
    sucursal: { sucursal },
    appointments: { preAppointment },
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state) => state);
  const client = useLiveQuery(() => dbStore.client.get(preAppointment?.clientId ?? ''));
  const { modalData } = useMemo(() => {
    return {
      modalData: (modalOpened?.['ADD_PRODUCT_MODAL']?.value as { orderItemId: string; serviceId: string }) ?? {},
    };
  }, [modalOpened?.['ADD_PRODUCT_MODAL']]);

  const [currentCategory, setCurrentCategory] = useState<Service[]>();
  const [currentServiceList, setCurrentServiceList] = useState<Appointment[]>([]);
  const [categoriesAvailableSearch, setCategoriesAvailableSearch] = useState<string>('');
  const [servicesAvailableSearch, setServicesAvailableSearch] = useState<string>('');
  const [servicesSelectedSearch, setServicesSelectedSearch] = useState<string>('');
  const [productQuantity, setProductQuantity] = useState('1');
  const [messageError, setMessageError] = useState({
    EXIST: { status: false, value: '' },
    DATE: { status: false, value: '' },
  });
  const [clientId] = useState<string | undefined>(preAppointment?.clientId);

  /**
   * delete the selected service on the list
   * @param serviceId string
   */
  const deleteService = (serviceId: string) => {
    setCurrentServiceList((prev) => prev.filter((item) => item.id !== serviceId));
  };

  /**
   * cancel the appointment and go back to the client page
   */
  const cancelAppointment = () => {
    dispatch(new CloseModal('ADD_PRODUCT_MODAL'));
  };

  /**
   * sent the data to the backend to create a appointment
   */
  const createAppointment = useCallback(() => {
    // arreglar problema para actualizar un item
    const toNumberQuantity = productQuantity === '' || productQuantity === '0' ? Number('1') : Number(productQuantity);

    if (toNumberQuantity > 1) {
      const newServiceList = { ...currentServiceList[0], quantity: toNumberQuantity };

      dispatch(new UpdatePreAppointmentItem(newServiceList, modalData.orderItemId));
      dispatch(new CloseModal('ADD_PRODUCT_MODAL'));
    }

    dispatch(
      new UpdatePreAppointmentItem(
        {
          ...currentServiceList[0],
          detailClient: {
            firstName: client?.firstName,
            lastName: client?.lastName,
          },
        },
        modalData.orderItemId
      )
    );
    dispatch(new CloseModal('ADD_PRODUCT_MODAL'));
  }, [preAppointment, currentServiceList, productQuantity]);

  /**
   * set the error to show
   * @param type Errors
   * @param status boolean
   * @param value string
   */
  const setErrors = (type: Errors, status: boolean, value: string) => {
    setMessageError((prev) => ({
      ...prev,
      [type]: { status: status, value: value },
    }));
  };

  /**
   * prepare the services for the posible create/update appointment
   * @param service Appointment | Service | undefined
   */
  const selectService = (service: Appointment | Service | undefined) => {
    const dataService = JSON.parse(JSON.stringify(service));

    setCurrentServiceList([]);

    // check if the selected servicies exist in the current list
    if (
      preAppointment?.orden?.some((ordenItem) => {
        return ordenItem.serviceId === dataService.id;
      })
    ) {
      setErrors('EXIST', true, 'El producto seleccionado ya existe en el listado de productos/servicios');
      return;
    } else {
      setErrors('EXIST', false, '');
    }

    if (lastAppointmentBeforeClose(sucursal?.workingHours, new Date(), service?.duration || 0)) {
      setErrors('EXIST', true, 'El servicio no se puede crear/editar ya que excede el horario de cierre del trabajo');
      return;
    }

    // if (checkIfSucursalIsOpen(sucursal?.workingHours, new Date(initialDate ?? 0), new Date())) {
    //   setErrors('EXIST', true, 'No se puede crear/editar servicios fuera del horario de trabajo');
    //   return;
    // }

    setCurrentServiceList((prev) => {
      delete dataService.id;
      delete dataService.status;
      delete dataService.categoryName;
      delete dataService.availableSucursal;
      delete dataService.createdAt;
      delete dataService.updatedAt;
      delete dataService.lashistaId;
      delete dataService.initialDate;
      const dateInitial = new Date().getTime();

      return prev.concat({
        ...dataService,
        categoryName: categories.find((c) => c.id === service?.categoryId)?.name ?? '',
        serviceId: service?.id,
        dateInitial: dateInitial,
        clientId: clientId,
        dateEnd: sumSecondsDate(new Date(dateInitial ?? 0), service?.duration ?? 0).getTime(),
        id: generateId('orderItem'),
        status: 'IN_PROCESS',
      });
    });
  };

  /**
   * prepare the list of categories with its products
   */
  const listServices = useMemo<CategoryWithService[]>(() => {
    return categories
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((category) => ({
        ...category,
        products: products
          .filter(
            (product) =>
              product.categoryId === category.id && product.type === 'PRODUCT' && product.isForSell === 'ACTIVE'
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [products, categories]);

  useEffect(() => {
    setCurrentServiceList([]);
    setCurrentCategory([]);
    setCategoriesAvailableSearch('');
    setServicesAvailableSearch('');
    setServicesSelectedSearch('');
  }, [modalOpened?.['ADD_PRODUCT_MODAL']]);

  return (
    <Transition.Root show={modalOpened?.['ADD_PRODUCT_MODAL'] ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('ADD_PRODUCT_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#6b7280] bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-black rounded-[8px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[90%] sm:w-full">
              <div className="bg-primary py-[25px] pl-[36px] flex justify-left">
                <Dialog.Title as="span" className="text-white text-[24px] font-bold tracking-[0.185em] leading-[30px]">
                  Seleccione el Servicio
                </Dialog.Title>
              </div>
              <div className="flex justify-between gap-[19px]">
                <div className="flex-1 flex flex-col max-w-[400px] gap-[19px]">
                  <div className="flex flex-col bg-darkGray">
                    <div className="flex flex-col gap-[20px] p-[17px] border-b border-grey2">
                      <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                        Categorías
                      </span>
                      <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                        <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                          <label
                            className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                            htmlFor="categoriesAvailableSearch"
                          >
                            Buscar:
                          </label>
                        </div>
                        <div className="flex-1">
                          <input
                            type="text"
                            className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-grey border-0 w-full h-full"
                            name="categoriesAvailableSearch"
                            id="categoriesAvailableSearch"
                            value={categoriesAvailableSearch}
                            onChange={(e) => setCategoriesAvailableSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          type="button"
                          className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
                          onClick={() => {
                            setCategoriesAvailableSearch('');
                          }}
                        >
                          <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                        </button>
                      </div>
                    </div>
                    <div className="overflow-y-auto max-h-[610px]">
                      {listServices
                        .filter((list) =>
                          list.products.some((product) =>
                            new RegExp(`${categoriesAvailableSearch}.*`, 'i').test(product.name ?? '')
                          )
                        )
                        .map((category, i) => (
                          <div
                            key={i}
                            className="flex px-[34px] p-[15px] items-center border-b last:border-b-0 border-grey2 cursor-pointer gap-[26px]"
                            onClick={() => setCurrentCategory(category.products)}
                          >
                            <img src={category.icon} alt={category.name} width={'50px'} height={'50px'} />
                            <span className="font-light text-[18px] leading-[23px] tracking-[0.185em] text-txtWhite">
                              {category.name}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col gap-[19px]">
                  <div className="flex flex-col rounded-[3px] bg-darkGray">
                    <div className="flex justify-start p-[17px] border-b border-grey2 gap-[24px]">
                      <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                        Servicios disponibles
                      </span>
                      <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                        <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                          <label
                            className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                            htmlFor="servicesAvailableSearch"
                          >
                            Buscar:
                          </label>
                        </div>
                        <div className="flex-1">
                          <input
                            type="text"
                            className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-grey border-0 w-full h-full"
                            name="servicesAvailableSearch"
                            id="servicesAvailableSearch"
                            value={servicesAvailableSearch}
                            onChange={(e) => setServicesAvailableSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          type="button"
                          className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
                          onClick={() => {
                            setServicesAvailableSearch('');
                          }}
                        >
                          <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex-1 p-4 max-h-[200px] overflow-y-auto">
                      <table className="table-auto w-full border-separate text-left">
                        <thead>
                          <tr className="bg-lightDarkGrey rounded-[3px] h-[33px]">
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                              Categoría
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                              Nombre
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                              Último comprado
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentCategory
                            ?.filter((list) => new RegExp(`${servicesAvailableSearch}.*`, 'i').test(list.name ?? ''))
                            .map((service) =>
                              service.status === 'ACTIVE' ? (
                                <tr key={service.id} onClick={() => selectService(service)} className="cursor-pointer">
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                                    {categories.find((category) => category.id == service.categoryId)?.name}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                                    {service.name}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                                    -
                                  </td>
                                </tr>
                              ) : null
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="flex flex-col rounded-[3px] bg-darkGray">
                    <div className="flex justify-start gap-[24px] p-[17px] border-b border-grey2">
                      <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                        Servicios bonos seleccionados
                      </span>
                      <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                        <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                          <label
                            className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                            htmlFor="servicesSelectedSearch"
                          >
                            Buscar:
                          </label>
                        </div>
                        <div className="flex-1">
                          <input
                            type="text"
                            className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-grey border-0 w-full h-full"
                            name="servicesSelectedSearch"
                            id="servicesSelectedSearch"
                            value={servicesSelectedSearch}
                            onChange={(e) => setServicesSelectedSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          type="button"
                          className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
                          onClick={() => {
                            setServicesSelectedSearch('');
                          }}
                        >
                          <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col p-4 max-h-[200px] overflow-auto gap-2">
                      <table className="table-auto w-full border-separate text-left">
                        <thead>
                          <tr className="bg-lightDarkGrey rounded-[3px] h-[33px]">
                            <th></th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                              Categoría
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                              Nombre
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                              Precio
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                              Cantidad
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                              Disponible
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentServiceList
                            .filter((list) => new RegExp(`${servicesSelectedSearch}.*`, 'i').test(list.name ?? ''))
                            .map((service, i) => (
                              <tr key={i}>
                                <td
                                  onClick={() => deleteService(service.id ?? '')}
                                  className="cursor-pointer hover:bg-primary"
                                >
                                  <DeleteSmallIcon />
                                </td>
                                <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                  {categories.find((category) => category.id == service.categoryId)?.name}
                                </td>
                                <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                  {service.name}
                                </td>
                                <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                  ${service.price}
                                </td>
                                <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="bg-veryDarkGray border-[#fff]"
                                    onInput={(e) => {
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      const text = e.target.value.replace(/[^0-9,.]+/g, '');
                                      setProductQuantity(text);
                                    }}
                                    value={productQuantity}
                                  />
                                </td>
                                <td className="text-base font-light tracking-[0.185em] leading-[20px] p-2 pl-[12px]">
                                  <CheckGreenIcon />
                                </td>
                              </tr>
                            ))}
                          <tr></tr>
                        </tbody>
                      </table>
                      {messageError.EXIST.status && (
                        <span className="text-[18px] font-medium tracking-[0.185em] leading-[20px] text-red">
                          {messageError.EXIST.value}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end my-[29px] px-[36px]">
                <button
                  type="button"
                  className="flex justify-center min-w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
                  onClick={() => cancelAppointment()}
                >
                  <span className="text-base leading-[19px] tracking-[0.08rem] text-txtWhite">Cancelar</span>
                </button>
                <button
                  type="button"
                  className="flex justify-center min-w-[161px] py-5 ml-[29px] border-primary rounded-[8px] border-2 bg-primary"
                  onClick={() => createAppointment()}
                >
                  <span className="text-base leading-[19px] tracking-[0.08rem] text-txtWhite">Hecho</span>
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProductModalBodyComponent;
