import { UserClaims } from '@services/business/identity/entities';
import { InventoryManagement, InventoryManagementCreate } from '@services/business/inventory/entities';
import { ApiConfig } from '@services/support/utils/types/api-config';
import { ApiInterface } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';

export type Interface = ApiInterface<{
  response: InventoryManagement;
  pathParams: { productId: string };
  body: Omit<InventoryManagementCreate, 'ejectBy'>;
  claims: UserClaims;
  queryParams: { tenantId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-inventory-management',
  method: 'POST',
  basePath: 'inventory',
  path: '/management/{productId}',
  tablePermission: 'readWrite',
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    operation: { type: String, enum: ['INSERT', 'SELL', 'REFUND', 'DELETE'], required: true },
    orderId: { type: String, required: false },
    quantity: { type: Number, required: true },
    note: { type: String, required: false },
  }),
};
