import { Client } from '@services/business/client/entities';
import { OrdenItem } from '@services/business/invoice/entities';
import { dbStore } from '@webapp/sdk/models/db';
import { Service } from '@webapp/store/products/state';
import { getLocalClient, getLocalProduct } from '@webapp/utils/local';
import { getSubdomain } from '@webapp/utils/subDomain';
import { DateTime } from 'luxon';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Actions } from '../../actions';
import { AddCalendar, CreateAppointment } from '../action-creators';
import { CREATE_APPOINTMENT } from '../actions';
import { Appointment, Orden } from '../state';

export const newOrderAppointmentsEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, CreateAppointment['type'], CreateAppointment>(CREATE_APPOINTMENT),
    mergeMap(async (action) => {
      const createOrdenItem: Appointment[] = await Promise.all(
        action.item.orden.map(async (a) => {
          const getService = getLocalProduct().find((b) => b.id === a.serviceId) as Service;
          const detailClient = (await getLocalClient(action.item.clientId)) as unknown as Client;

          const dateAppointment = DateTime.fromISO(new Date(a.dateInitial as number).toISOString())
            .toFormat('MM-dd-yyyy')
            .valueOf();
          const dateInitial = DateTime.fromISO(new Date(a.dateInitial as number).toISOString()).valueOf();
          const dateEnd = DateTime.fromISO(new Date(dateInitial).toISOString())
            .plus({
              second: getService.duration || 0,
            })
            .valueOf();
          const item: OrdenItem = {
            ...getService,
            ...a,
            tenantId: getSubdomain(),
            dateEnd,
            dateAppointment,
            dateInitial,
            statusAppointment: 'INTIME',
            lashistaId: a.lashistaId as string,
            detailClient,
            orderId: action.item.id,
            clientId: action.item.clientId,
            status: 'IN_PROCESS',
            createdAt: new Date(),
            updatedAt: new Date(),
          };
          return item as Appointment;
        })
      );

      const client = await dbStore.client.get(action.item.clientId ?? '');

      dbStore.order.add({
        clientId: action.item.clientId,
        detailClient: {
          firstName: client?.firstName,
          lastName: client?.lastName,
        },
        dateOrden: DateTime.now().toFormat('MM-dd-yyyy').valueOf(),
        total: createOrdenItem.reduce((a, b) => a + b.price, 0),
        orden: createOrdenItem.map((a) => a.id),
        status: action.item.status as Orden['status'],
        tenantId: getSubdomain(),
        id: action.item.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        startWeb: new Date().getTime(),
      });

      return new AddCalendar(createOrdenItem);
    })
  );
