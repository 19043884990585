import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CalendarPickerView } from '@mui/lab/CalendarPicker';
import Box from '@mui/material/Box';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';

/*--------------------------props------------------------------------
  type = 1 (para fecha sin dia, caso contrario omitir)
  data = new Date() (o cualquier fecha de inicio)
  onChangeData = onChangeData
-------------------------------------------------------------------*/

const DatePicker = (props: any) => {
  const [open, setOpen] = useState(false);
  const vista = ['year', 'month'] as CalendarPickerView[];

  if (props.type != 1) {
    vista.push('day');
  }

  return (
    <MuiDatePicker
      {...props}
      views={vista}
      inputFormat={(props.type != 1 ? 'EE dd' : '') + ' MMMM yyyy'}
      value={props.data ?? new Date()}
      toolbarTitle={'Seleccione Fecha'}
      minDate={props.minData ?? new Date('1900-01-01')}
      maxDate={props.maxData ?? new Date('2099-01-01')}
      okText={'Ok'}
      cancelText={'Cancelar'}
      open={props.openDate || open}
      onOpen={() => {
        setOpen(true);
        if (props.openDate) props.setOpenDate(true);
      }}
      onClose={() => {
        setOpen(false);
        if (props.openDate) props.setOpenDate(false);
      }}
      components={{
        OpenPickerIcon: KeyboardArrowDownIcon,
      }}
      onChange={(newValue: any) => {
        if (newValue != null) {
          if (props.onChangeData) {
            props.onChangeData(newValue);
          }
        }
      }}
      renderInput={({ inputRef, inputProps, InputProps }: any) => (
        <Box className="flex justify-center items-center bg-very-dark-gray">
          <input
            ref={inputRef}
            {...inputProps}
            onClick={() => setOpen(true)}
            className={`text-center border-0 pb-1 text-base text-txtWhite bg-very-dark-gray 
                ${props.className ?? ''}${props.type == 1 ? ' w-44' : ''}`}
            readOnly
          />
          {props.type == 1 ? InputProps?.endAdornment : ''}
        </Box>
      )}
    />
  );
};

export default DatePicker;
