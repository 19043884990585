import AppState from '@webapp/store/state';
import { useOpStore } from '@webapp/store/useOpStore';
import { getPaymentMethodName } from '@webapp/utils/getPaymentMethodName';
import { rounded } from '@webapp/utils/validateNumber';
import { FunctionComponent, useState } from 'react';
import TexTarea from '../../components/ui/forms/textArea';

const StepThreeComponent: FunctionComponent<{ handleNote(e: any): void }> = (props) => {
  const {
    cashRegister: { cashRegisterList, salesOfDay },
  } = useOpStore((state: AppState) => state);
  const [commit, setCommit] = useState('');

  const getAmountExpected = (current: number, expected: number): string => {
    return current - expected < 0 ? `$${rounded(current - expected)} por debajo` : `$${rounded(current - expected)}`;
  };

  const handleLangChange = (value: string) => {
    props.handleNote(value);
  };

  return (
    <div className="flex flex-col gap-y-[24px]">
      <div className="flex bg-darkGray max-h-[372px]  rounded-[8px] p-[24px]">
        <div className="flex overflow-auto">
          <table className="table-auto lg:table-fixed w-full shadow overflow-hidden">
            <thead>
              <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] ">
                <td className="border-spacing-1 mr-[7px] pl-[32px] py-[8px] bg-grey">Método de pago</td>
                <td className="pl-[32px] py-[8px] bg-primary ">Monto</td>
                <td className="border-l border-white pl-[32px] py-[8px] bg-primary">Esperado</td>
                <td className="border-l border-white pl-[32px] py-[8px] bg-primary">Diferencia</td>
              </tr>
            </thead>
            <tbody>
              {cashRegisterList?.map((d, i: number) => {
                return (
                  <tr
                    key={i}
                    className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] rounded-[4px]"
                  >
                    <td className="border-t border-white pl-[32px] py-[8px] bg-primary">
                      {getPaymentMethodName(d.type)}
                    </td>
                    <td className="border border-grey pl-[32px] py-[8px]">
                      {rounded(salesOfDay?.find((sod) => sod.type === d.type)?.amount ?? 0)}
                    </td>
                    <td className="border border-grey pl-[32px] py-[8px]">{d.amount}</td>
                    <td className="border border-grey pl-[32px] py-[8px]">
                      {getAmountExpected(salesOfDay?.find((sod) => sod.type === d.type)?.amount ?? 0, d.amount)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-between bg-primary px-[32px] rounded-[4px]">
        <span className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] py-[8px] pr-[32px] border-r border-white">
          Totales netos
        </span>
        <span className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] py-[8px]">
          Su cálculo: ${rounded(salesOfDay?.reduce((a, b) => a + b.amount, 0) ?? 0)}
        </span>
        <span className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] py-[8px]">
          Esperando: ${rounded(cashRegisterList?.reduce((a, b) => a + b.amount, 0) ?? 0)}
        </span>
        <span className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] py-[8px]">
          Diferencia:
          {getAmountExpected(
            salesOfDay?.reduce((a, b) => a + b.amount, 0) ?? 0,
            cashRegisterList?.reduce((a, b) => a + b.amount, 0) ?? 0
          )}
        </span>
      </div>
      <div className="flex justify-between gap-x-[8px]">
        <TexTarea
          classnamecontainer="flex flex-col w-5/12"
          classlabel="mb-0 bg-primary text-white text-base font-medium tracking-[0.05em] leading-[21px] py-[8px] px-[32px] rounded-t-[4px]"
          classinput="bg-darkGray text-white text-base font-medium tracking-[0.05em] leading-[21px] py-[8px] px-[32px] border-[#A8ACAC] rounded-b-[4px]"
          name="notes"
          label="Comentario"
          value={commit}
          onChange={(e: any) => {
            handleLangChange(e.target.value);
            setCommit(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default StepThreeComponent;
