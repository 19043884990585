export const LIST_REPORTS = '[Reports] List Reports';
export const LIST_REPORTS_SUCCESS = '[Reports] List Reports Success';
export const LIST_REPORTS_ERROR = '[Reports] List Reports Error';

export const LIST_REPORTS_PER_DAY = '[Reports] List Reports Per Day';
export const LIST_REPORTS_PER_DAY_SUCCESS = '[Reports] List Reports Per Day Success';
export const LIST_REPORTS_PER_DAY_ERROR = '[Reports] List Reports Per Day Error';

export const LIST_REPORTS_CLIENT = '[Reports] List Client Reports';
export const LIST_REPORTS_SUCCESS_CLIENT = '[Reports] List Client Reports Success';
export const LIST_REPORTS_ERROR_CLIENT = '[Reports] List Client Reports Error';

export const LIST_NEW_OLD_CLIENT = '[Reports] List New Old Client';
export const LIST_NEW_OLD_SUCCESS_CLIENT = '[Reports] List New Old Client Success';
export const LIST_NEW_OLD_ERROR_CLIENT = '[Reports] List New Old Client Error';

export const LIST_OLD_CLIENT = '[Reports] List Old Client';
export const LIST_OLD_SUCCESS_CLIENT = '[Reports] List Old Client Success';
export const LIST_OLD_ERROR_CLIENT = '[Reports] List Old Client Error';

export const LIST_FIRST_REPORTS = '[Reports] List First Reports';
export const LIST_FIRST_REPORTS_SUCCESS = '[Reports] List First Reports Success';
export const LIST_FIRST_REPORTS_ERROR = '[Reports] List First Reports Error';

export const LIST_SECOND_REPORTS = '[Reports] List Second Reports';
export const LIST_SECOND_REPORTS_SUCCESS = '[Reports] List Second Reports Success';
export const LIST_SECOND_REPORTS_ERROR = '[Reports] List Second Reports Error';

export const LIST_NEW_CLIENT = '[Reports] List New Client';
export const LIST_NEW_SUCCESS_CLIENT = '[Reports] List New Client Success';
export const LIST_NEW_ERROR_CLIENT = '[Reports] List New Client Error';

export const CLEAR_REPORTS_NORMALIZED = '[Reports] Clear Reports Normalized';

export const HISTORY_REPORT_NORMALIZE = '[Reports] History Report Normalize';
export const SALES_REPORT_NORMALIZE = '[Reports] Sales Report Normalize';
export const PRODUCT_SERVICE_REPORT_NORMALIZE = '[Reports] Product Service Report Normalize';
export const PERFORMANCE_EMPLOYEE_NORMALIZE = '[Reports] Performance Employee Normalize';
export const RANKING_SUCURSAL_NORMALIZE = '[Reports] Ranking Sucursal Normalize';
export const DISCOUNT_NORMALIZE = '[Reports] Discount Normalize';
export const NEW_CLIENT_NORMALIZE = '[Reports] New Client Normalize';
export const NO_RETURN_CLIENT_NORMALIZE = '[Reports] No Return Client Normalize';
export const FUTURE_APPOINTMENT_NORMALIZE = '[Reports] Future Appointment Normalize';
export const SCHEDULER_NORMALIZE = '[Reports] Scheduler Normalize';
export const ANNUAL_SALE_NORMALIZE = '[Reports] Annual Sale Normalize';
export const FIRST_YEAR_NORMALIZE = '[Reports] First Year Normalize';
export const SECOND_YEAR_NORMALIZE = '[Reports] Second Year Normalize';
export const MOVEMENTS_SUMMARY_NORMALIZE = '[Reports] Movements Summary Normalize';
