/* eslint-disable no-console */
import { Disclosure } from '@headlessui/react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { navigationMenu } from '@webapp/configuration/menu';
import { GetOrdenItemSuccess, UpdatePreAppointment } from '@webapp/store/appointments/action-creators';
import { GetHistoryAppointmentClientSuccess, SetCurrentClient } from '@webapp/store/client/action-creators';
import { SetCurrentEmployee } from '@webapp/store/employee/action-creators';
import { SetCurrentLashista } from '@webapp/store/lashista/action-creators';
import AppState from '@webapp/store/state';
import { useOpStore } from '@webapp/store/useOpStore';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Suspense, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../../public/assets/logo.png';
import PopMenu from '../ui/popMenu';
import UserGreenIcon from '../ui/shared/icons/UserGreenIcon';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function MenuLayout({ children }: { children: JSX.Element }) {
  const [date, setDate] = useState(new Date());
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const clearIntervalDate = useRef<NodeJS.Timeout>();
  const history = useHistory();

  const {
    auth,
    cashRegister: { preCashRegister },
    dispatch,
  } = useOpStore((state: AppState) => state);

  const isCurrent = () => {
    const isReturn = false;
    return isReturn;
  };

  useEffect(() => {
    const timer = (clearIntervalDate.current = setInterval(() => {
      setDate(new Date());
    }, 1000));
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setShowMenu(auth.loggedIn);
  }, [auth]);

  if (!auth.loggedIn) {
    return (
      <div>
        <div className="absolute inset-0 flex items-center justify-center">{children}</div>
      </div>
    );
  }
  return (
    <div className="flex flex-col min-h-screen bg-black">
      {/* DARK MODE */}

      <div className="sticky top-0 z-40 pl-1 sm:pl-3 py-1 pr-4 flex items-center justify-between bg-veryDarkGray">
        {showMenu ? (
          <Link to="/">
            <img className="h-12 w-auto" src={logo} alt="Workflow" width={100} height={53} />
          </Link>
        ) : (
          <img className="h-12 w-auto" src={logo} alt="Workflow" width={100} height={53} />
        )}
        <div className="flex">
          <div className="text-base leading-4 tracking-wider flex px-2">
            <div className="  bg-transparent rounded-md flex justify-between  items-end py-2 px-2">
              <PopMenu visible selected>
                <UserGreenIcon />
              </PopMenu>
              <div className="flex flex-col">
                <span className="text-[14px] text-white font-medium leading-[17px] tracking-[0.08em]">
                  {auth?.user?.attributes?.['given_name']}
                </span>
                <span className="text-[14px] text-white font-light leading-[17px] tracking-[0.08em]">
                  {auth?.user?.attributes?.['custom:permission']}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <button
              onClick={() => setShowMenu((prev) => !prev)}
              type="button"
              className="text-[16px] font-[400] tracking-wider leading-4 text-primary py-2.5 px-6 bg-transparent border rounded-[6px] border-primary"
            >
              Mlashes
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-row-reverse">
        {showMenu && (
          <>
            <div className="block md:hidden"></div>
            <div
              className="min-w-[200px] max-w-[200px] hidden md:flex md:flex-col md:justify-between border-r  pr-2  bg-veryDarkGray md:h-auto "
              style={{ minHeight: 'calc(100vh - 56px)' }}
            >
              {' '}
              <div className="flex flex-col pt-5 pb-4">
                <div className="flex items-end flex-col flex-shrink-0 px-4">
                  <span className="flex text-base leading-5 tracking-wider font-normal text-white">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M2.5 0C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V3H14V2.5C14 1.83696 13.7366 1.20107 13.2678 0.732233C12.7989 0.263392 12.163 0 11.5 0H2.5ZM14 4H0V11.5C0 12.163 0.263392 12.7989 0.732233 13.2678C1.20107 13.7366 1.83696 14 2.5 14H11.5C12.163 14 12.7989 13.7366 13.2678 13.2678C13.7366 12.7989 14 12.163 14 11.5V4ZM5 7C5 7.26522 4.89464 7.51957 4.70711 7.70711C4.51957 7.89464 4.26522 8 4 8C3.73478 8 3.48043 7.89464 3.29289 7.70711C3.10536 7.51957 3 7.26522 3 7C3 6.73478 3.10536 6.48043 3.29289 6.29289C3.48043 6.10536 3.73478 6 4 6C4.26522 6 4.51957 6.10536 4.70711 6.29289C4.89464 6.48043 5 6.73478 5 7ZM4 11C3.73478 11 3.48043 10.8946 3.29289 10.7071C3.10536 10.5196 3 10.2652 3 10C3 9.73478 3.10536 9.48043 3.29289 9.29289C3.48043 9.10536 3.73478 9 4 9C4.26522 9 4.51957 9.10536 4.70711 9.29289C4.89464 9.48043 5 9.73478 5 10C5 10.2652 4.89464 10.5196 4.70711 10.7071C4.51957 10.8946 4.26522 11 4 11ZM8 7C8 7.26522 7.89464 7.51957 7.70711 7.70711C7.51957 7.89464 7.26522 8 7 8C6.73478 8 6.48043 7.89464 6.29289 7.70711C6.10536 7.51957 6 7.26522 6 7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6C7.26522 6 7.51957 6.10536 7.70711 6.29289C7.89464 6.48043 8 6.73478 8 7ZM7 11C6.73478 11 6.48043 10.8946 6.29289 10.7071C6.10536 10.5196 6 10.2652 6 10C6 9.73478 6.10536 9.48043 6.29289 9.29289C6.48043 9.10536 6.73478 9 7 9C7.26522 9 7.51957 9.10536 7.70711 9.29289C7.89464 9.48043 8 9.73478 8 10C8 10.2652 7.89464 10.5196 7.70711 10.7071C7.51957 10.8946 7.26522 11 7 11ZM11 7C11 7.26522 10.8946 7.51957 10.7071 7.70711C10.5196 7.89464 10.2652 8 10 8C9.73478 8 9.48043 7.89464 9.29289 7.70711C9.10536 7.51957 9 7.26522 9 7C9 6.73478 9.10536 6.48043 9.29289 6.29289C9.48043 6.10536 9.73478 6 10 6C10.2652 6 10.5196 6.10536 10.7071 6.29289C10.8946 6.48043 11 6.73478 11 7Z"
                        fill="white"
                      />
                    </svg>

                    {format(date, 'HH:mm', { locale: es })}
                  </span>
                  <span className="first-letter:uppercase text-[14px] leading-5 tracking-wider font-light text-white">
                    {format(new Date(), 'EE, dd MMM, yyyy', {
                      locale: es,
                    })}
                  </span>
                </div>
                <nav className="mt-5 px-2  bg-veryDarkGray">
                  {navigationMenu.map((item) =>
                    !item.children ? (
                      <div className="flex" key={item.name}>
                        <button
                          onClick={() => {
                            if (item.href != '#') {
                              history.push(item.href);
                            }
                          }}
                          className={classNames(
                            isCurrent()
                              ? 'bg-primary text-white'
                              : 'bg-veryDarkGray   hover:text-grey2 bg-veryDarkGray text-white',
                            'ml-6 group w-full flex items-center pl-2 py-2 text-[16px] leading-4 tracking-wider font-normal rounded-md'
                          )}
                        >
                          {item.name}
                        </button>
                      </div>
                    ) : (
                      <Disclosure as="div" key={item.name} className="space-y-1 " defaultOpen={item.current}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                isCurrent() || open ? 'bg-primary text-white' : '  hover:text-grey2 bg-veryDarkGray',
                                'group w-full flex items-center pl-2 pr-1 py-2 text-left text-[16px] leading-4 tracking-wider font-normal rounded-md'
                              )}
                            >
                              <KeyboardArrowLeftIcon
                                fontSize="small"
                                className={(isCurrent() || 'text-white') + (open ? ' -rotate-90' : '')}
                              />

                              <span className="text-white">{item.name}</span>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {item.children
                                .filter((c) => {
                                  if (!preCashRegister && c.href === '/caja') {
                                    return false;
                                  }
                                  return true;
                                })
                                .filter((c) => {
                                  if (
                                    auth?.user?.attributes?.['custom:role'] === 'EMPLOYEE' &&
                                    c.href.includes('/reportes')
                                  ) {
                                    return false;
                                  }
                                  return true;
                                })
                                .map((subItem: any) => {
                                  return (
                                    <button
                                      key={subItem.name}
                                      onClick={() => {
                                        if (subItem.href != '#') {
                                          dispatch(new SetCurrentEmployee(undefined));
                                          dispatch(new SetCurrentClient(undefined));
                                          dispatch(new SetCurrentLashista(undefined));
                                          dispatch(new GetOrdenItemSuccess(undefined));
                                          dispatch(new UpdatePreAppointment(undefined));
                                          dispatch(new GetHistoryAppointmentClientSuccess(undefined));
                                          history.push(subItem.href);
                                        }
                                      }}
                                      className={classNames(
                                        location.pathname == subItem.href
                                          ? 'text-primary font-semibold'
                                          : ' text-white',
                                        '  w-full text-left pl-4 py-1 text-sm font-normal leading-4 rounded-md hover:text-grey2  bg-veryDarkGray'
                                      )}
                                    >
                                      {'• ' + subItem.name}
                                    </button>
                                  );
                                })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )
                  )}
                </nav>
              </div>
            </div>
          </>
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <main className="grid grid-cols-1 w-full px-0 md:px-6">{children}</main>
        </Suspense>
      </div>
    </div>
  );
}
