import { Appointment } from '@webapp/store/appointments/state';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as client from '../../../sdk/client';
import { Actions } from '../../actions';
import {
  CreateClient,
  CreateClientError,
  CreateClientSuccess,
  CreateNoteCredit,
  CreateNoteCreditError,
  CreateNoteCreditSuccess,
  DeleteClient,
  DeleteClientError,
  DeleteClientSuccess,
  GetBalanceClient,
  GetBalanceClientError,
  GetBalanceClientSuccess,
  GetClient,
  GetClientError,
  GetClientSuccess,
  GetHistoryAppointmentClient,
  GetHistoryAppointmentClientError,
  GetHistoryAppointmentClientSuccess,
  GetRewardClient,
  GetRewardClientError,
  GetRewardClientSuccess,
  ListHistoryNoteCredit,
  ListHistoryNoteCreditError,
  ListHistoryNoteCreditSuccess,
  UpdateClient,
  UpdateClientError,
  UpdateClientSuccess,
} from '../action-creators';
import {
  CREATE_CLIENT,
  CREATE_NOTE_CREDIT,
  DELETE_CLIENT,
  GET_BALANCE_CLIENT,
  GET_CLIENT,
  GET_HISTORY_APPOINTMENT_CLIENT,
  GET_REWARD_CLIENT,
  LIST_HISTORY_NOTE_CREDIT,
  UPDATE_CLIENT,
} from '../actions';

export const crudClientEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      | CreateClient['type']
      | UpdateClient['type']
      | DeleteClient['type']
      | GetClient['type']
      | GetBalanceClient['type']
      | ListHistoryNoteCredit['type']
      | CreateNoteCredit['type']
      | GetRewardClient['type']
      | GetHistoryAppointmentClient['type'],
      | CreateClient
      | UpdateClient
      | DeleteClient
      | GetClient
      | ListHistoryNoteCredit
      | GetBalanceClient
      | CreateNoteCredit
      | GetRewardClient
      | GetHistoryAppointmentClient
    >(
      CREATE_CLIENT,
      UPDATE_CLIENT,
      DELETE_CLIENT,
      GET_CLIENT,
      LIST_HISTORY_NOTE_CREDIT,
      GET_BALANCE_CLIENT,
      CREATE_NOTE_CREDIT,
      GET_REWARD_CLIENT,
      GET_HISTORY_APPOINTMENT_CLIENT
    ),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case UPDATE_CLIENT: {
            await client.updateClient(action.client, action.clientId);
            return new UpdateClientSuccess();
          }
          case CREATE_CLIENT: {
            const result = await client.createClient(action.client);

            // new SetCurrentClient({ ...action.client, sucursalAt: '', createdAt: new Date(), updatedAt: new Date() });
            // new GetBalanceClient(action.client.id);
            // new GetRewardClient(action.client.id);
            // const dateInitial = new Date(Date.now() - 15 * 86400000);
            // dateInitial.setHours(0);
            // dateInitial.setMinutes(0);
            // dateInitial.setSeconds(0);
            // const dateEnd = new Date(Date.now() + 15 * 86400000);
            // dateEnd.setHours(0);
            // dateEnd.setMinutes(0);
            // dateEnd.setSeconds(0);
            // new GetHistoryAppointmentClient({
            //   clientId: action.client.id,
            //   dateInitial: dateInitial.getTime(),
            //   dateEnd: dateEnd.getTime(),
            // });

            return new CreateClientSuccess(result);
          }
          case DELETE_CLIENT: {
            await client.deleteClient(action.clientId);
            return new DeleteClientSuccess();
          }
          case GET_CLIENT: {
            const item = await client.getClient(action.id);
            return new GetClientSuccess(item);
          }
          case LIST_HISTORY_NOTE_CREDIT: {
            const item = await client.getBalanceHistoryClient(action.clientId);
            return new ListHistoryNoteCreditSuccess(item.items);
          }
          case GET_BALANCE_CLIENT: {
            const item = await client.getBalanceClient(action.clientId);
            return new GetBalanceClientSuccess(item.balance);
          }
          case CREATE_NOTE_CREDIT: {
            await client.createNoteCredit(action.clientId, action.noteCredit);
            return new CreateNoteCreditSuccess(action.noteCredit, action.clientId);
          }
          case GET_REWARD_CLIENT: {
            const reward = await client.getRewardClient(action.clientId);
            return new GetRewardClientSuccess(reward.available);
          }
          case GET_HISTORY_APPOINTMENT_CLIENT: {
            const response = await client.getHistoryAppointmentClient(action.body);
            const filterOnlyAppointment = response.map((item) => item) as Appointment[];
            return new GetHistoryAppointmentClientSuccess(filterOnlyAppointment);
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case UPDATE_CLIENT: {
            return new UpdateClientError(err.message, err.description);
          }
          case CREATE_CLIENT: {
            return new CreateClientError(err.message, err.description);
          }
          case DELETE_CLIENT: {
            return new DeleteClientError(err.message, err.description);
          }
          case GET_CLIENT: {
            return new GetClientError(err.message, err.description);
          }
          case LIST_HISTORY_NOTE_CREDIT: {
            return new ListHistoryNoteCreditError(err.message, err.description);
          }
          case GET_BALANCE_CLIENT: {
            return new GetBalanceClientError(err.message, err.description);
          }
          case CREATE_NOTE_CREDIT: {
            return new CreateNoteCreditError(err.message, err.description);
          }
          case GET_REWARD_CLIENT: {
            return new GetRewardClientError(err.message, err.description);
          }
          case GET_HISTORY_APPOINTMENT_CLIENT: {
            return new GetHistoryAppointmentClientError(err.message, err.description);
          }
        }
      }
    })
  );
