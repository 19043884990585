import * as api from '@services/business/sucursal/catalog/api';
import { getSubdomain } from '@webapp/utils/subDomain';
import { CallApi } from './common-v2';

const callAPI = CallApi('sucursal');

export const getSucursal = async (): Promise<api.checkSucursal.Interface['response']> => {
  const response = await callAPI<api.checkSucursal.Interface>(
    { ...api.checkSucursal.config, withCache: true },
    {
      pathParams: {
        alias: getSubdomain(),
      },
    }
  );
  const body: api.checkSucursal.Interface['response'] = response;

  return body;
};
