import { PDFDownloadLink } from '@react-pdf/renderer';
import { MovementSummaryPdf } from '@webapp/components/pdf/movementsSummary';
import { ListLashista } from '@webapp/store/lashista/action-creators';
import {
  ListNewClient,
  ListOldClient,
  ListReportPerDay,
  MovementsSummaryNormalize,
} from '@webapp/store/reports/action-creators';
import { LIST_REPORTS_PER_DAY } from '@webapp/store/reports/actions';
import { useOpStore } from '@webapp/store/useOpStore';
import { getSubdomain } from '@webapp/utils/subDomain';
import { FunctionComponent, useEffect } from 'react';
import Loading from '../ui/preloader/Loading';
import PrintWhiteIcon from '../ui/shared/icons/PrintWhiteIcon';
import ModalStepFinish from './modal-step-finish';

const StepFiveComponent: FunctionComponent = () => {
  const {
    reports: { reportPerDay, listNewClient, listOldClient, movementsSummary },
    lashista: { listLashista },
    dispatch,
    loading,
  } = useOpStore((state) => state, [LIST_REPORTS_PER_DAY]);

  useEffect(() => {
    dispatch(new ListLashista());
    dispatch(new ListNewClient());
    dispatch(new ListOldClient());
    dispatch(new ListReportPerDay(new Date().toLocaleDateString('en-US').replace(/\//g, '-')));
  }, []);

  useEffect(() => {
    dispatch(new MovementsSummaryNormalize(reportPerDay, [getSubdomain()], listNewClient, listOldClient, listLashista));
  }, [reportPerDay]);

  return (
    <div className="flex flex-col items-end gap-y-[24px]">
      <ModalStepFinish />
      <div className="flex flex-col bg-darkGray rounded-[8px] p-[24px] max-h-[372px]">
        <div className="flex overflow-x-auto rounded-[4px]">
          <table className="table-auto lg:table-fixed w-full shadow overflow-hidden">
            <thead>
              <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] ">
                <td className="w-[150px] mr-[7px] pl-[32px] py-[8px] bg-grey">Imprimir</td>
                <td className="border-l border-white pl-[32px] py-[8px] bg-primary ">Nombre del informe</td>
                <td className="border-l border-white pl-[32px] py-[8px] bg-primary">Descripción del informe</td>
              </tr>
            </thead>
            <tbody>
              <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] rounded-[4px]">
                <td className="border-t border-white w-[150px] mr-[7px] pl-[32px] py-[8px] text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] bg-primary">
                  <input
                    className="text-white checkmark-green"
                    type="checkbox"
                    name="info2"
                    id="info2"
                    defaultChecked
                  />
                </td>
                <td className="border border-grey pl-[32px] py-[8px]">Sumario de movimientos</td>
                <td className="border border-grey pl-[32px] py-[8px]">
                  Desglose de las transacciones del día incluyendo el nombre del clien...
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {loading?.[LIST_REPORTS_PER_DAY] ? (
        <Loading height={10} />
      ) : (
        <PDFDownloadLink document={<MovementSummaryPdf data={movementsSummary} />} fileName="sumario-movimientos.pdf">
          <button
            type="button"
            className="flex justify-center items-center p-0 bg-primary rounded-[4px] focus:outline-none p-[24px] shadow-sm"
            // onClick={() => printReports()}
          >
            <PrintWhiteIcon />
          </button>
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default StepFiveComponent;
