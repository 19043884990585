import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import { listLashistaEpic } from './list-lashista';

import AppState from '../../state';
import { crudLashistaEpic } from './lashista';

const lashistaEpic = combineEpics<Actions, Actions, AppState>(listLashistaEpic, crudLashistaEpic);

export default lashistaEpic;
