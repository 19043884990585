/* eslint-disable no-console */
import DatePicker from '@webapp/components/ui/datePicker';
import { addDays, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import IconButton from '../common/iconButton';
import { LocaleArrow } from '../common/LocaleArrow';

interface DayDateBtnProps {
  selectedDate: Date;
  openDate: boolean;
  setOpenDate: any;
  onChange(value: Date, key: 'selectedDate'): void;
}

const DayDateBtn = ({ selectedDate, openDate, setOpenDate, onChange }: DayDateBtnProps) => {
  //const [open, setOpen] = useState(false);
  //const toggleDialog = () => setOpen(!open);
  // const { locale } = useAppState();
  const history = useHistory();

  const handleChange = (e: Date | null) => {
    const currentDay = e || new Date();
    const tDay = format(currentDay, 'MM-dd-yyyy', { locale: es });
    history.push('/dashboard/' + tDay);
    onChange(e || new Date(), 'selectedDate');
  };

  const handleAddDays = (days: number) => {
    const prevDay = addDays(selectedDate, days);
    const tDay = format(prevDay, 'MM-dd-yyyy', { locale: es });
    history.push('/dashboard/' + tDay);
    onChange(prevDay, 'selectedDate');
  };

  return (
    <div className="flex flex-col justify-center">
      <DatePicker
        openDate={openDate}
        toolbarTitle={'Seleccione Fecha'}
        okText={'Ok'}
        cancelText={'Cancelar'}
        setOpenDate={(value: boolean) => setOpenDate(value)}
        /*onClose={() => {
            toggleDialog();
            setOpenDate(false);
          }}*/
        openTo="day"
        views={['month', 'day']}
        data={selectedDate}
        onChangeData={handleChange}
      />
      <div className="flex justify-center items-center">
        <LocaleArrow type="prev" etiqueta="Semana" onClick={() => handleAddDays(-7)} />
        <LocaleArrow type="prev" etiqueta="Día" onClick={() => handleAddDays(-1)} />
        <IconButton
          className="text-xs md:text-sm font-semibold text-txtWhite m-0 mx-6 md:mx-10"
          onClick={() => handleChange(new Date())}
        >
          Hoy
        </IconButton>
        <LocaleArrow type="next" etiqueta="Día" onClick={() => handleAddDays(1)} />
        <LocaleArrow type="next" etiqueta="Semana" onClick={() => handleAddDays(7)} />
      </div>
    </div>
  );
};

export { DayDateBtn };
