import { InputHTMLAttributes } from 'react';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  onChange?: any;
  onBlur?: any;
  error?: string | boolean;
  classlabel?: string;
  classinput?: string;
  classnamecontainer?: string;
  mode?: string;
}
const Input = (props: InputProps) => {
  return (
    <div className={`-mb-2 ${props.classnamecontainer ?? ''}`}>
      {props.type != 'checkbox' && props.type != 'radio' && (
        <span
          className={`mb-1 flex items-center tracking-wide text-white text-sm md:text-base font-medium
        ${props.classlabel ?? ''}`}
        >
          {props.label}
        </span>
      )}
      <div className="flex items-center">
        <input
          {...props}
          type={props.type}
          className={`p-2 border block shadow-sm hover:ring-1 focus:ring-1 text-sm md:text-base font-normal
              ${props.type != 'checkbox' && props.type != 'radio' ? 'w-full' : 'text-primary mt-[2px]'}
              ${
                props.error
                  ? 'border-red hover:ring-red focus:ring-red'
                  : 'border-grey hover:ring-primary focus:ring-primary'
              }
              ${props.classinput ?? ''}`}
          onChange={props.onChange}
          readOnly={props.mode == 'view' ? true : false}
          min="0"
          disabled={props.mode == 'view' && (props.type == 'checkbox' || props.type == 'radio') ? true : false}
        />
        {(props.type == 'checkbox' || props.type == 'radio') && (
          <span
            className={`tracking-wide text-sm md:text-base font-medium pl-1
        ${props.classlabel ?? ''}`}
          >
            {props.label}
          </span>
        )}
      </div>
      <span className="flex items-center font-medium tracking-wide text-red text-xs mt-1 ml-1">{props.error}</span>
    </div>
  );
};
export default Input;
