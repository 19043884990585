import AppState from '@webapp/store/state';
import { useOpStore } from '@webapp/store/useOpStore';
import { getSubdomain } from '@webapp/utils/subDomain';
import { FunctionComponent, useState } from 'react';

const StepFourComponent: FunctionComponent<{ handleFund(e: any): void }> = (props) => {
  const {
    cashRegister: { preCashRegister, cashRegister },
  } = useOpStore((state: AppState) => state);
  const [cashAmount, setCashAmount] = useState(0);

  const handleFundChange = (value: number) => {
    props.handleFund(value);
  };

  return (
    <div className="flex flex-col items-end gap-y-[24px]">
      <div className="flex bg-darkGray max-h-[372px] rounded-[8px] p-[24px]">
        <div className="flex overflow-auto">
          <table className="table-auto lg:table-fixed w-full shadow overflow-hidden">
            <thead>
              <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] ">
                <td className="mr-[7px] pl-[32px] py-[8px] bg-grey">Nombre de registro</td>
                <td className="border-l border-white pl-[32px] py-[8px] bg-primary">Fondo</td>
                <td className="border-l border-white pl-[32px] py-[8px] bg-primary">Nuevo fondo</td>
              </tr>
            </thead>
            <tbody>
              <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] rounded-[4px]">
                <td className="border border-grey pl-[32px] py-[8px]">{getSubdomain()}</td>
                <td className="border border-grey pl-[32px] py-[8px]">{cashRegister?.currentAmount}</td>
                <td className="border border-grey pl-[32px] py-[8px]">
                  <input
                    name="cashAmount"
                    defaultValue={cashRegister?.currentAmount}
                    className="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-white"
                    onChange={(e) => {
                      handleFundChange(Number(e.target.value));
                      setCashAmount(Number(e.target.value));
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col justify-end overflow-auto w-4/5 rounded-[4px]">
        <table className="table-auto lg:table-fixed w-full shadow overflow-hidden rounded-[4px]">
          <tbody>
            <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em]">
              <td className="pl-[32px] py-[8px] bg-primary">Efectivo registrado (incluyendo fondo)</td>
              <td className="border border-grey p-[32px] py-[8px] text-end">
                $ {Number(preCashRegister?.amounts.cash ?? 0) + Number(cashRegister?.currentAmount ?? 0)}
              </td>
            </tr>
            <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em]">
              <td className="pl-[32px] py-[8px] bg-primary">Diferencia de efectivo registrado (incluyendo fondo)</td>
              <td className="border border-grey p-[32px] py-[8px] text-end">
                $ {Number(preCashRegister?.amountsExpected.cash ?? 0) + Number(cashRegister?.currentAmount ?? 0)}
              </td>
            </tr>
            <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em]">
              <td className=" pl-[32px] py-[8px] bg-primary">Fondo de cierre</td>
              <td className="border border-grey p-[32px] py-[8px] text-end">$ {cashAmount}</td>
            </tr>
            <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em]">
              <td className=" pl-[32px] py-[8px] bg-primary">Efectivo neto</td>
              <td className="border border-grey p-[32px] py-[8px] text-end">
                $ {Number(preCashRegister?.amounts.cash ?? 0) - Number(cashRegister?.currentAmount ?? 0)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StepFourComponent;
