import { FunctionComponent, ReactNode } from 'react';

interface AuthContainerProps {
  className?: string;
  children?: ReactNode;
}

const AuthContainer: FunctionComponent<AuthContainerProps> = ({ className, children }) => {
  return <div className={className || ''}>{children}</div>;
};

export default AuthContainer;
