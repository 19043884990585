import { FunctionComponent, useEffect, useMemo, useState } from 'react';
// components
import {
  CashRegisterStepsTitleComponent,
  ModalStepFinish,
  ModalStepFour,
  StepFive,
  StepFour,
  StepOne,
  StepThree,
  StepTwo,
} from '@webapp/components/pagos';
import Container from '@webapp/components/ui/container';

import ModalWarningStatusAppointment from '@webapp/components/pagos/modal-warning-status-appointment';
import {
  FinishStepBoxRegister,
  LeftArrowIcon,
  RightArrowIcon,
  ThreeDotButtonGreenIcon,
} from '@webapp/components/ui/shared/icons';
import { ListCalendar } from '@webapp/store/appointments/action-creators';
import { CreatePreCashRegister, ListCashRegister } from '@webapp/store/cash-register/action-creators';
import AppState from '@webapp/store/state';
import { OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { formatFullDateUS } from '@webapp/utils/formatDate';
import { useHistory } from 'react-router-dom';

const CashRegister: FunctionComponent = () => {
  const {
    auth: { user },
    cashRegister: { preCashRegister, cashRegisterList, cashRegister },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const history = useHistory();
  const [note, setNote] = useState('');
  const [newCashFund, setNewCashFund] = useState(0);
  const [negativeFund, setNegativeFund] = useState(false);

  const handleNote = (value: string) => {
    setNote(value);
  };

  const handleFund = (value: number) => {
    setNewCashFund(value);
  };

  const handleNegativeFund = () => {
    setNegativeFund(true);
  };

  /**
   * move up the steps
   */
  const stepUp = () => {
    if (Number(preCashRegister?.step) === 2) {
      dispatch(new OpenModal('STEP_TWO_MODAL'));
      return;
    }

    if (
      Number(preCashRegister?.step) === 4 &&
      Number(preCashRegister?.amounts.cash ?? 0) - Number(cashRegister?.currentAmount ?? 0) < 0 &&
      negativeFund === false
    ) {
      dispatch(new OpenModal('STEP_FOUR_MODAL'));
      return;
    }

    if (Number(preCashRegister?.step) === 4) {
      dispatch(new OpenModal('STEP_FINISH_MODAL', { noteForIncompleteAmount: note, newFund: newCashFund }));
      return;
    }

    if (preCashRegister === undefined) {
      dispatch(
        new CreatePreCashRegister({
          amounts: { cash: 0, card: 0, transfer: 0 },
          amountsExpected: { cash: 0, card: 0, transfer: 0 },
          createAt: new Date().toString(),
          isIncomplete: false,
          step: 1,
          step2Accepted: false,
        })
      );
    } else {
      dispatch(
        new CreatePreCashRegister({
          ...preCashRegister,
          receptionist: {
            firstName: user.attributes['given_name'],
            lastName: user.attributes['family_name'],
            username: user.username,
            sub: user.attributes['sub'],
          },
          step: preCashRegister.step < 5 ? preCashRegister.step + 1 : preCashRegister.step,
        })
      );
    }
  };

  /**
   * move down the steps
   * only can go down to step 3 if the step 2 modal is accepted
   */
  const stepDown = () => {
    if (preCashRegister === undefined) {
      dispatch(
        new CreatePreCashRegister({
          amounts: { cash: 0, card: 0, transfer: 0 },
          amountsExpected: { cash: 0, card: 0, transfer: 0 },
          createAt: new Date().toISOString(),
          isIncomplete: false,
          step: 1,
          step2Accepted: false,
        })
      );
    } else {
      dispatch(
        new CreatePreCashRegister({
          ...preCashRegister,
          step:
            preCashRegister.step > 1
              ? preCashRegister.step2Accepted === true
                ? preCashRegister.step > 3
                  ? preCashRegister.step - 1
                  : preCashRegister.step
                : preCashRegister.step - 1
              : preCashRegister.step,
        })
      );
    }
  };

  /**
   * get the reports to calculate the amounts per payment method
   */
  useEffect(() => {
    dispatch(new ListCashRegister(formatFullDateUS(new Date().getTime())));
    dispatch(new ListCalendar(formatFullDateUS(new Date().getTime())));
  }, []);

  useEffect(() => {
    if (cashRegister?.status === 'CLOSE') {
      history.push('movimientos');
    }
  }, [cashRegister]);

  /**
   * get the current amount of the cash register to be use for the new cash fund
   */
  useMemo(() => setNewCashFund(cashRegister?.currentAmount ?? 0), [cashRegister]);

  return (
    <Container className="flex justify-center">
      <ModalStepFour onOk={handleNegativeFund} />
      <ModalStepFinish />
      <ModalWarningStatusAppointment />
      <div className="flex flex-col h-min-vh90 justify-between">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex justify-start items-center  text-txtWhite">
            <div className="p-3 text-[24px] font-medium leading-[31px] tracking-[0.185em]">Asistente de caja</div>
          </div>
          <div className="flex justify-end items-center text-txtWhite">
            <button
              type="button"
              className="flex justify-center items-center border border-transparent bg-transparent hover:border-primary focus:outline-none"
            >
              <ThreeDotButtonGreenIcon />
            </button>
          </div>
        </div>

        <div className="flex flex-col mt-[30px] mb-auto">
          <CashRegisterStepsTitleComponent step={Number(preCashRegister?.step ?? 1)} />
          {Number(preCashRegister?.step) === 1 && <StepOne />}
          {Number(preCashRegister?.step) === 2 && <StepTwo cashRegisterList={cashRegisterList} />}
          {Number(preCashRegister?.step) === 3 && <StepThree handleNote={handleNote} />}
          {Number(preCashRegister?.step) === 4 && <StepFour handleFund={handleFund} />}
          {Number(preCashRegister?.step) === 5 && <StepFive />}
        </div>

        <div className="grid grid-cols-1 p-1 mt-[30px]">
          <div className="flex items-center justify-between gap-[12px]">
            <div></div>
            <div className="flex gap-x-[30px]">
              <button
                type="button"
                className={`flex flex-col justify-center items-center gap-y-[4px] text-white text-base font-medium leading-[21px] tracking-[0.05em] ${
                  preCashRegister?.step === 5 || preCashRegister?.step === 1 || preCashRegister?.step === undefined
                    ? ''
                    : 'hover:text-primary'
                }`}
                disabled={
                  preCashRegister?.step === 5 || preCashRegister?.step === 1 || preCashRegister?.step === undefined
                }
                onClick={() => stepDown()}
              >
                <LeftArrowIcon color={Number(preCashRegister?.step ?? 0) > 1 ? 'var(--primary)' : '#393939'} />
                Atrás
              </button>
              <button
                type="button"
                className="flex flex-col justify-center items-center gap-y-[4px] text-white text-base font-medium leading-[21px] tracking-[0.05em] hover:text-primary"
                onClick={() => {
                  stepUp();
                  if (preCashRegister?.step === 5) history.push('/pagos');
                }}
              >
                {Number(preCashRegister?.step) === 5 ? (
                  <>
                    <FinishStepBoxRegister />
                    Finalizar
                  </>
                ) : (
                  <>
                    <RightArrowIcon color={Number(preCashRegister?.step ?? 1) < 5 ? 'var(--primary)' : '#393939'} />
                    Siguiente
                  </>
                )}
              </button>
            </div>
            <button
              type="button"
              disabled={Number(preCashRegister?.step) === 5 ? true : false}
              className={`${
                Number(preCashRegister?.step) === 5
                  ? 'border-[#393939] hover:border-[#393939] bg-[#393939] text-[#8C8C8C]'
                  : 'border-red bg-red hover:border-red text-white'
              } flex justify-center items-center p-0 border border-red bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] font-normal`}
              onClick={() => {
                dispatch(new CreatePreCashRegister(undefined));
                history.push('movimientos');
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CashRegister;
