const DangerExclamationIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10Z"
        fill="#FC6B6B"
      />
      <path
        d="M10 12C9.73478 12 9.48043 11.8946 9.29289 11.7071C9.10536 11.5196 9 11.2652 9 11V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4C10.2652 4 10.5196 4.10536 10.7071 4.29289C10.8946 4.48043 11 4.73478 11 5V11C11 11.2652 10.8946 11.5196 10.7071 11.7071C10.5196 11.8946 10.2652 12 10 12ZM8.5 14.5C8.5 14.1022 8.65804 13.7206 8.93934 13.4393C9.22064 13.158 9.60218 13 10 13C10.3978 13 10.7794 13.158 11.0607 13.4393C11.342 13.7206 11.5 14.1022 11.5 14.5C11.5 14.8978 11.342 15.2794 11.0607 15.5607C10.7794 15.842 10.3978 16 10 16C9.60218 16 9.22064 15.842 8.93934 15.5607C8.65804 15.2794 8.5 14.8978 8.5 14.5Z"
        fill="#FC6B6B"
      />
    </svg>
  );
};

export default DangerExclamationIcon;
