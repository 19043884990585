import * as api from '@services/business/lashitas/catalog/api';
import { Action } from 'redux';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';
import { IListLashista } from './state';

export class ListLashista implements LoadingAction {
  readonly type = actions.LIST_LASHISTA;
  readonly isLoading = true;
  constructor(public search?: string) {}
}

export class ListLashistaSuccess implements LoadingAction {
  readonly type = actions.LIST_LASHISTA_SUCCESS;
  readonly isLoading = false;

  constructor(public items: IListLashista[]) {}
}

export class ListLashistaError implements ErrorAction {
  readonly type = actions.LIST_LASHISTA_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class SetCurrentLashista implements Action {
  readonly type = actions.SET_CURRENT_LASHISTA;

  constructor(public item: IListLashista | undefined) {}
}

export class CreateLashista implements LoadingAction {
  readonly type = actions.CREATE_LASHISTA;
  readonly isLoading = true;
  constructor(public lashista: api.createLashitas.Interface['body'], public updateCurrentId?: boolean) {}
}

export class CreateLashistaSuccess implements LoadingAction {
  readonly type = actions.CREATE_LASHISTA_SUCCESS;
  readonly isLoading = false;
}

export class CreateLashistaError implements ErrorAction {
  readonly type = actions.CREATE_LASHISTA_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateLashista implements LoadingAction {
  readonly type = actions.UPDATE_LASHISTA;
  readonly isLoading = true;
  constructor(public lashista: api.updateLashitas.Interface['body'] & { id: string }) {}
}

export class UpdateLashistaSuccess implements LoadingAction {
  readonly type = actions.UPDATE_LASHISTA_SUCCESS;
  readonly isLoading = false;
}

export class UpdateLashistaError implements ErrorAction {
  readonly type = actions.UPDATE_LASHISTA_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteLashista implements LoadingAction {
  readonly type = actions.DELETE_LASHISTA;
  readonly isLoading = true;
  constructor(public lashistaId: string) {}
}

export class DeleteLashistaSuccess implements LoadingAction {
  readonly type = actions.DELETE_LASHISTA_SUCCESS;
  readonly isLoading = false;
}

export class DeleteLashistaError implements ErrorAction {
  readonly type = actions.DELETE_LASHISTA_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type LashistaActions =
  | ListLashista
  | ListLashistaSuccess
  | ListLashistaError
  | ListLashistaSuccess
  | SetCurrentLashista
  | CreateLashista
  | CreateLashistaSuccess
  | CreateLashistaError
  | UpdateLashista
  | UpdateLashistaSuccess
  | UpdateLashistaError
  | DeleteLashista
  | DeleteLashistaSuccess
  | DeleteLashistaError;
