import { getSubdomain } from '@webapp/utils/subDomain';
import { ClientActions } from './action-creators';
import * as actions from './actions';
import { clientInitialState } from './initial-state';
import { Client, ClientState } from './state';

const clientReducer = (state: ClientState = clientInitialState, action: ClientActions): ClientState => {
  switch (action.type) {
    case actions.LIST_CLIENT_SUCCESS: {
      return {
        ...state,
        listClient: action.items,
      };
    }
    case actions.SET_CURRENT_CLIENT: {
      return {
        ...state,
        currentClient: action.item,
      };
    }
    case actions.GET_CLIENT_SUCCESS: {
      return {
        ...state,
        currentClient: action.items,
      };
    }
    case actions.CREATE_CLIENT_SUCCESS: {
      return {
        ...state,
        listClient: state.listClient
          .filter((item) => item.id !== 'cus_anonimo')
          .concat({
            id: 'cus_anonimo',
            firstName: 'WALKING',
            lastName: '',
            phone: '',
            sex: 'FEMALE' as Client['sex'],
            additionalData: {
              marketing: ['SMSCheck'],
              confirm: ['SMSCheck'],
              rating: ['SMSCheck'],
              fidelity: ['EmailCheck'],
              privacyPolicies: true,
            },
            status: 'ACTIVE' as Client['status'],
            sucursalAt: getSubdomain(),
            createdAt: new Date(),
            updatedAt: new Date(),
          })
          .concat({ ...action.client })
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
      };
    }
    case actions.UPDATE_CLIENT: {
      return {
        ...state,
        listClient: state.listClient.map((client) => {
          if (client.id === action.clientId) {
            return { ...client, ...action.client };
          }

          return client;
        }),
      };
    }

    case actions.LIST_HISTORY_NOTE_CREDIT_SUCCESS: {
      return {
        ...state,
        listHistoryNoteCredit: action.items,
      };
    }
    case actions.GET_BALANCE_CLIENT_SUCCESS: {
      return {
        ...state,
        balanceClient: action.item,
      };
    }
    case actions.SET_BALANCE_CLIENT: {
      return {
        ...state,
        balanceClient: undefined,
      };
    }
    case actions.CREATE_NOTE_CREDIT_SUCCESS: {
      return {
        ...state,
        balanceClient: (state.balanceClient || 0) + action.noteCredit.amount,
      };
    }
    case actions.SET_REWARD_CLIENT: {
      return {
        ...state,
        rewardClient: undefined,
      };
    }
    case actions.GET_REWARD_CLIENT_SUCCESS: {
      return {
        ...state,
        rewardClient: action.item,
      };
    }
    case actions.GET_HISTORY_APPOINTMENT_CLIENT_SUCCESS: {
      return {
        ...state,
        currentClientHistoryAppointment:
          action.item !== undefined
            ? {
                pastAppointment: action.item
                  .filter((app) => {
                    if (new Date(app.createdAt).getTime() <= new Date().getTime()) return app;
                  })
                  .sort((a, b) => b.dateInitial - a.dateInitial),
                futureAppointment: action.item
                  .filter((app) => {
                    if (new Date(app.createdAt).getTime() > new Date().getTime()) return app;
                  })
                  .sort((a, b) => b.dateInitial - a.dateInitial),
                noShow: action.item.filter(({ statusAppointment }) => statusAppointment === 'NOSHOW').length,
                show: action.item.filter(
                  ({ statusAppointment }) =>
                    statusAppointment === 'COMPLETED' ||
                    statusAppointment === 'CONFIRMED' ||
                    statusAppointment === 'SHOW'
                ).length,
                canceled: action.item.filter(({ statusAppointment }) => statusAppointment === 'CANCELED').length,
              }
            : undefined,
      };
    }

    default:
      return state;
  }
};

export default clientReducer;
