const CheckGreenIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#56C4C7" />
      <path
        d="M17.6753 7.3507C17.9142 7.12327 18.2321 6.99754 18.5619 7.00004C18.8917 7.00253 19.2077 7.13306 19.4431 7.36408C19.6785 7.5951 19.815 7.90853 19.8237 8.23825C19.8324 8.56797 19.7127 8.88819 19.4898 9.13132L12.7235 17.5935C12.6071 17.7188 12.4667 17.8194 12.3106 17.8892C12.1545 17.959 11.9859 17.9966 11.8149 17.9998C11.644 18.0029 11.4741 17.9716 11.3155 17.9076C11.1569 17.8436 11.0129 17.7483 10.892 17.6274L6.40481 13.1403C6.27985 13.0238 6.17962 12.8834 6.11011 12.7274C6.04059 12.5714 6.00321 12.403 6.0002 12.2322C5.99718 12.0614 6.0286 11.8918 6.09257 11.7334C6.15654 11.575 6.25175 11.4312 6.37252 11.3104C6.4933 11.1896 6.63716 11.0944 6.79553 11.0304C6.9539 10.9665 7.12353 10.9351 7.29431 10.9381C7.46508 10.9411 7.6335 10.9785 7.78952 11.048C7.94553 11.1175 8.08595 11.2177 8.20239 11.3427L11.7534 14.892L17.6431 7.38801C17.6537 7.37495 17.665 7.3625 17.677 7.3507H17.6753Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckGreenIcon;
