import * as apiCR from '@services/business/cash-register/catalog/api';
import { Orden } from '../appointments/state';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';
import { CashRegisterBalance, PreCashRegister, SalesOfDay } from './state';

export class ListCashRegister implements LoadingAction {
  readonly type = actions.LIST_CASH_REGISTER;
  readonly isLoading = true;
  constructor(public date: string) {}
}

export class ListCashRegisterSuccess implements LoadingAction {
  readonly type = actions.LIST_CASH_REGISTER_SUCCESS;
  readonly isLoading = false;

  constructor(public item: Orden[]) {}
}

export class ListCashRegisterError implements ErrorAction {
  readonly type = actions.LIST_CASH_REGISTER_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListCashRegisterTransactions implements LoadingAction {
  readonly type = actions.LIST_CASH_REGISTER_TRANSACTIONS;
  readonly isLoading = true;
  constructor(public date: string) {}
}

export class ListCashRegisterTransactionsSuccess implements LoadingAction {
  readonly type = actions.LIST_CASH_REGISTER_TRANSACTIONS_SUCCESS;
  readonly isLoading = false;

  constructor(public item: Orden[]) {}
}

export class ListCashRegisterTransactionsError implements ErrorAction {
  readonly type = actions.LIST_CASH_REGISTER_TRANSACTIONS_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetCashRegister implements LoadingAction {
  readonly type = actions.GET_CASH_REGISTER;
  readonly isLoading = true;
}

export class GetCashRegisterSuccess implements LoadingAction {
  readonly type = actions.GET_CASH_REGISTER_SUCCESS;
  readonly isLoading = false;

  constructor(public item: CashRegisterBalance) {}
}

export class GetCashRegisterError implements ErrorAction {
  readonly type = actions.GET_CASH_REGISTER_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreatePreCashRegister implements LoadingAction {
  readonly type = actions.CREATE_PRE_CASH_REGISTER;
  readonly isLoading = true;
  constructor(public register: PreCashRegister | undefined) {}
}
export class CreateCashRegister implements LoadingAction {
  readonly type = actions.CREATE_CASH_REGISTER;
  readonly isLoading = true;
  constructor(public data: apiCR.createCashRegister.Interface['body']) {}
}

export class CreateCashRegisterSuccess implements LoadingAction {
  readonly type = actions.CREATE_CASH_REGISTER_SUCCESS;
  readonly isLoading = false;
}

export class CreateCashRegisterError implements ErrorAction {
  readonly type = actions.CREATE_CASH_REGISTER_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateCashRegister implements LoadingAction {
  readonly type = actions.UPDATE_CASH_REGISTER;
  readonly isLoading = true;
  constructor(public data: apiCR.updateBalance.Interface['body']) {}
}

export class UpdateCashRegisterSuccess implements LoadingAction {
  readonly type = actions.UPDATE_CASH_REGISTER_SUCCESS;
  readonly isLoading = false;
}

export class UpdateCashRegisterError implements ErrorAction {
  readonly type = actions.UPDATE_CASH_REGISTER_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetSalesOfDay implements LoadingAction {
  readonly type = actions.GET_SALES_OF_DAY;
  readonly isLoading = false;

  constructor(public data: SalesOfDay[]) {}
}

export type CashRegisterActions =
  | ListCashRegisterSuccess
  | ListCashRegisterError
  | ListCashRegister
  | ListCashRegisterTransactionsSuccess
  | ListCashRegisterTransactionsError
  | ListCashRegisterTransactions
  | GetCashRegisterSuccess
  | GetCashRegisterError
  | GetCashRegister
  | CreatePreCashRegister
  | CreateCashRegister
  | CreateCashRegisterSuccess
  | CreateCashRegisterError
  | UpdateCashRegisterSuccess
  | UpdateCashRegisterError
  | UpdateCashRegister
  | GetSalesOfDay;
