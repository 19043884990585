import {
  ApiInterface,
  EmptyObject,
  PaginatedQueryParams,
  PaginatedResponse,
} from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { NoteClient } from '../../entities';

export type Interface = ApiInterface<{
  response: PaginatedResponse<NoteClient>;
  pathParams: EmptyObject;
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: PaginatedQueryParams & { clientId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-list-note-client',
  method: 'GET',
  basePath: 'notes-client',
  path: '/',
  tablePermission: 'read',
  scope: 'RECEPTIONIST',
};
