// import { Lashista } from '@enterprise/store/lashista/state';
import * as api from '@services/business/history/catalog/api';
export const normalizeFutureAppoint = (
  data: api.getHistoryOrder.Interface['response'],
  dataLashista: any[]
): Array<{
  sucursal: string;
  finishedOrder: number;
  futureOrder: number;
  totalPercentage: number;
  lashita: string;
  lashistaId: string | undefined;
}> => {
  const response = data.map(({ items }) => {
    const [item] = items;

    const getLashita = dataLashista.find(({ id }) => id === item?.lashistaId);

    const futureOrders = Array.from(
      new Set(
        data
          .flatMap(({ items }) => items)
          .filter(
            (item) =>
              item.finalizeAt === undefined && item.status === 'IN_PROCESS' && item.lashistaId === getLashita?.id
          )
      )
    ).length;

    const finishOrder = Array.from(
      new Set(
        data
          .flatMap(({ items }) => items)
          .filter((item) => item.finalizeAt && item.status === 'PAID' && item.lashistaId === getLashita?.id)
      )
    ).length;

    const totalOrders = data.flatMap(({ items }) => items).filter((item) => item.lashistaId === getLashita?.id).length;

    return {
      sucursal: item?.tenantId,
      finishedOrder: finishOrder,
      futureOrder: futureOrders,
      lashistaId: item?.lashistaId,
      lashita: `${getLashita?.firstName} ${getLashita?.lastName}`,
      totalPercentage: isNaN(Math.floor((finishOrder / totalOrders) * 100))
        ? 0
        : Math.floor((finishOrder / totalOrders) * 100),
    };
  });

  return response;
};
