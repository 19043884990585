import { Navigation } from './components/nav/Navigation';
import { useAppState } from './hooks/useAppState';
import { Table, Wrapper } from './styles/styles';
import { Day } from './views/Day';

const SchedulerComponent = () => {
  const { resources, resourceViewMode } = useAppState();

  return (
    <>
      <Wrapper className="flex-none md:flex justify-start text-txt">
        <div className="rs__outer_table">
          <Table resource_count={resourceViewMode === 'tabs' ? 1 : resources.length}>
            <Day />
          </Table>
        </div>
        <Navigation />
      </Wrapper>
    </>
  );
};

export { SchedulerComponent };
