const DeleteSmallIcon = (props: any) => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.351 3.52411L12.8247 17.7289C12.8158 17.9726 12.601 18.1791 12.3563 18.1791H3.26653C3.02202 18.1791 2.80704 17.9718 2.79812 17.7289L2.27186 3.52411C2.25739 3.13218 1.92789 2.82625 1.53595 2.8407C1.14402 2.85536 0.838086 3.18467 0.852543 3.57661L1.3786 17.7814C1.41605 18.7878 2.25796 19.5994 3.26651 19.5994H12.3563C13.3644 19.5994 14.2067 18.7891 14.2442 17.7814L14.7702 3.57661C14.7847 3.18468 14.4788 2.85536 14.0868 2.8407C13.6949 2.82624 13.3654 3.13217 13.3509 3.52411H13.351Z"
        fill={props.color ?? '#56C4C7'}
      />
      <path
        d="M7.24341 6.39106V16.0489C7.24341 16.3628 7.49762 16.617 7.81147 16.617C8.12531 16.617 8.37953 16.3628 8.37953 16.0489V6.39106C8.37953 6.0772 8.12531 5.823 7.81147 5.823C7.49762 5.823 7.24341 6.07721 7.24341 6.39106Z"
        fill={props.color ?? '#56C4C7'}
      />
      <path
        d="M4.11865 6.40775L4.40279 16.0656C4.4121 16.3793 4.67365 16.6262 4.98728 16.6169C5.30094 16.6077 5.54784 16.346 5.5385 16.0324L5.25437 6.37447C5.24525 6.06082 4.98351 5.81392 4.66987 5.82325C4.35621 5.83237 4.10952 6.09411 4.11865 6.40775Z"
        fill={props.color ?? '#56C4C7'}
      />
      <path
        d="M10.3684 6.37435L10.0842 16.0322C10.0749 16.3459 10.3218 16.6076 10.6355 16.6167C10.9491 16.626 11.2107 16.3792 11.22 16.0655L11.5041 6.40763C11.5132 6.09397 11.2665 5.83221 10.9529 5.82313C10.6392 5.81382 10.3775 6.0607 10.3684 6.37435Z"
        fill={props.color ?? '#56C4C7'}
      />
      <path
        d="M0.710138 3.83458H14.9129C15.305 3.83458 15.623 3.51677 15.623 3.12445C15.623 2.73232 15.305 2.41431 14.9129 2.41431H0.710138C0.318015 2.41431 0 2.73232 0 3.12445C0 3.51677 0.318015 3.83458 0.710138 3.83458Z"
        fill={props.color ?? '#56C4C7'}
      />
      <path
        d="M5.65368 2.89363L5.94931 1.85855C6.01271 1.63703 6.30002 1.42026 6.53086 1.42026H9.09203C9.32286 1.42026 9.61017 1.63683 9.67358 1.85855L9.9692 2.89363L11.3348 2.50329L11.0392 1.46841C10.8014 0.636791 9.95692 0 9.09203 0H6.53086C5.66596 0 4.8213 0.637045 4.58371 1.46841L4.28809 2.50329L5.65368 2.89363Z"
        fill={props.color ?? '#56C4C7'}
      />
    </svg>
  );
};

export default DeleteSmallIcon;
