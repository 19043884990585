import * as api from '@services/business/history/catalog/api';
import { Categories } from '@webapp/store/categories/state';
import { Lashista } from '@webapp/store/lashista/state';

export const normalizePerformanceEmployees = (
  data: api.getHistoryOrder.Interface['response'],
  dataLashistas: Lashista[],
  dataCategories: Categories[]
): any => {
  const response: any = dataLashistas.map((dataLashista) => {
    const orderItems = data.flatMap(({ items }) => items).filter((service) => service.lashistaId === dataLashista.id);

    const countCategories: { [key: string]: number } = dataCategories.reduce((a, b) => ({ ...a, [b.id]: 0 }), {});

    orderItems.forEach(({ categoryId }) => {
      countCategories[categoryId] = (countCategories[categoryId] || 0) + 1;
    });

    return {
      idLashita: Array.from(new Set(orderItems.map(({ lashistaId }) => lashistaId))),
      clientUnique: Array.from(new Set(orderItems.map(({ clientId }) => clientId))).length,
      ...countCategories,
    };
  });

  return response;
};
