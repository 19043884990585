import { FunctionComponent, useEffect, useState } from 'react';

import Container from '@webapp/components/ui/container';
import Input from '@webapp/components/ui/forms/input';
import { ThreeDotButtonGreenIcon } from '@webapp/components/ui/shared/icons';
import { UpdateEmployee } from '@webapp/store/employee/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { EditEmployeeForm } from '@webapp/types/forms/edit-employee';

import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup.string().required('Ingrese el nombre del empleado'),
  lastName: yup.string().required('Ingrese el apellido del empleado'),
  username: yup.string().notRequired(),
  phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'telefono invalido'
    ),
  permission: yup.string().notRequired(),
  password: yup.string().required('Ingrese una contraseña del empleado'),
  role: yup.string().required('Ingrese el role del empleado'),
});

const FichaEmployeePage: FunctionComponent = () => {
  const [mode, setMode] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();
  const {
    employee: { currentEmployee },
    dispatch,
  } = useOpStore((state) => state);

  const saveData = async (rawData: EditEmployeeForm) => {
    const data: any = {
      username: rawData.username,
      name: rawData.name,
      lastName: rawData.lastName,
      phone: rawData.phone,
      permission: rawData.permission === 'true' ? 'ADMIN' : 'RECEPTIONIST',
      role: rawData.role,
      password: rawData.password,
    };

    dispatch(new UpdateEmployee(data, currentEmployee?.Username ?? ''));
    formik.resetForm();

    switch (mode) {
      case 'saveAndCloseClient':
        history.push('/dashboard');
        break;
      case 'saveClient':
        history.push('/employees');
        break;

      default:
        history.push('/employees');
        break;
    }
  };
  const formik = useFormik({
    initialValues: {
      name: currentEmployee?.Attributes?.find(({ Name }) => Name === 'given_name')?.Value,
      lastName: currentEmployee?.Attributes?.find(({ Name }) => Name === 'family_name')?.Value,
      phone: currentEmployee?.Attributes?.find(({ Name }) => Name === 'custom:mobile')?.Value,
      permission: currentEmployee?.Attributes?.find(({ Name }) => Name === 'custom:isAdmin')?.Value,
      role: currentEmployee?.Attributes?.find(({ Name }) => Name === 'custom:role')?.Value,
      username: currentEmployee?.Username,
      password: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      saveData(values);
    },
  });

  /**
   * change the focus of the current input to the next when it press the button enter
   * @param event
   */
  const handleEnter = (event: any) => {
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  /**
   * change the focus of the input
   * @param field string
   */
  const handleFocus = (field: string) => {
    document.getElementById(field)?.focus();
  };

  /**
   * custom handler for the submit of the form
   * @param selected string
   */
  const onClickButton = (selected: string) => {
    switch (selected) {
      case 'saveClient': // save the data
        setIsSaving(true);
        setMode(selected);
        formik.submitForm();
        break;
      case 'saveAndCloseClient': // save the data and redirect to the client list view
        setIsSaving(true);
        setMode(selected);
        formik.submitForm();
        break;
      case 'close':
        history.push('/employees');
        break;

      default: // redirect to the client list view
        history.push('/employees');
        break;
    }
  };

  /**
   * load the data of the client
   */
  useEffect(() => {
    handleFocus('name');
  }, []);

  useEffect(() => {
    const JobjErrors = JSON.parse(JSON.stringify(formik.errors));

    const errors = [];
    for (const x in JobjErrors) {
      errors.push(x);
    }

    if (errors[0] && isSaving) {
      handleFocus(errors[0]);
    }
  }, [formik.errors, isSaving]);

  return (
    <Container>
      <div className="flex flex-col">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between items-center mb-[40px]">
            <span className="text-[24px] font-normal leading-[30px] tracking-[0.185rem]">Ficha de lashista</span>
            <ThreeDotButtonGreenIcon />
          </div>
          <div className="grid grid-cols-1 gap-5 mb-[36px]">
            <div className="flex-1 flex flex-col bg-darkGray rounded-[8px]">
              <div className="px-[26px] py-[17px] border-grey2 border-b-[1px]">
                <span className="text-[18px] font-medium leading-[23px] tracking-[0.185rem] text-white">
                  Información general
                </span>
              </div>
              <div className="flex flex-col px-[26px] py-[25px]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[24px] mb-[30px]">
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] text-grey2 bg-darkGray"
                    id="name"
                    name="name"
                    label="Nombres"
                    type="text"
                    value={formik.values.name}
                    mode={'add'}
                    onKeyDown={handleEnter}
                    onChange={formik.handleChange}
                    error={formik.touched.name && formik.errors.name}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] text-grey2 bg-darkGray"
                    id="lastName"
                    name="lastName"
                    label="Apellidos"
                    type="text"
                    value={formik.values.lastName}
                    mode={'add'}
                    onKeyDown={handleEnter}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && formik.errors.lastName}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] text-grey2 bg-darkGray"
                    id="username"
                    name="username"
                    label="User Name"
                    type="text"
                    value={formik.values.username}
                    mode={'add'}
                    onKeyDown={handleEnter}
                    onChange={formik.handleChange}
                    error={formik.touched.username && formik.errors.username}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] text-grey2 bg-darkGray"
                    id="phone"
                    name="phone"
                    label="Movil"
                    type="text"
                    value={formik.values.phone}
                    mode={'add'}
                    maxLength={10}
                    onKeyDown={handleEnter}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && formik.errors.phone}
                  />
                  <Input
                    classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                    classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] text-grey2 bg-darkGray"
                    id="password"
                    name="password"
                    label="Contraseña"
                    type="text"
                    value={formik.values.password}
                    mode={'add'}
                    onKeyDown={handleEnter}
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-[24px] mb-[20px]">
                  <div className="flex">
                    <span className="text-base font-light tracking-[0.185em] leading-[20px] mr-[31px] text-white">
                      Permisos
                    </span>
                    <Input
                      classnamecontainer="mr-4"
                      classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                      classinput="py-0 px-0 rounded-full border border-[#878A8A] text-[#56C4C7] w-4"
                      id="permission"
                      name="permission"
                      type="radio"
                      label="Administrador"
                      value="ADMIN"
                      checked={formik.values.permission === 'true' || formik.values.permission === 'ADMIN'}
                      mode={'add'}
                      onKeyDown={handleEnter}
                      onChange={formik.handleChange}
                      error={formik.touched.permission && formik.errors.permission}
                    />
                    <Input
                      classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                      classinput="py-0 px-0 rounded-full border border-[#878A8A] text-[#56C4C7] w-4"
                      id="permission"
                      name="permission"
                      type="radio"
                      label="Recepcionista"
                      value="RECEPTIONIST"
                      checked={formik.values.permission === 'false' || formik.values.permission === 'RECEPTIONIST'}
                      mode={'add'}
                      onKeyDown={handleEnter}
                      onChange={formik.handleChange}
                      error={formik.touched.permission && formik.errors.permission}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-[24px] mb-[20px]">
                  <div className="flex">
                    <span className="text-base font-light tracking-[0.185em] leading-[20px] mr-[31px] text-white">
                      Rol
                    </span>
                    <Input
                      classnamecontainer="mr-4"
                      classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                      classinput="py-0 px-0 rounded-full border border-[#878A8A] text-[#56C4C7] w-4"
                      id="role"
                      name="role"
                      type="radio"
                      label="Empleado"
                      value="EMPLOYEE"
                      checked={formik.values.role === 'EMPLOYEE'}
                      mode={'add'}
                      onKeyDown={handleEnter}
                      onChange={formik.handleChange}
                      error={formik.touched.role && formik.errors.role}
                    />
                    <Input
                      classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white ml-[6px]"
                      classinput="py-0 px-0 rounded-full border border-[#878A8A] text-[#56C4C7] w-4"
                      id="role"
                      name="role"
                      type="radio"
                      label="Dueño"
                      value="OWNER"
                      checked={formik.values.role === 'OWNER'}
                      mode={'add'}
                      onKeyDown={handleEnter}
                      onChange={formik.handleChange}
                      error={formik.touched.role && formik.errors.role}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              className="flex justify-center min-w-[161px] py-5 border-[#FC6B6B] rounded-[8px] border-2 bg-[#FC6B6B]"
              onClick={() => onClickButton('close')}
            >
              <span className="text-base font-medium leading-[20px] tracking-[0.08rem] text-[#fff]">Cancelar</span>
            </button>
            <div className="flex">
              <button
                type="button"
                className="flex justify-center min-w-[161px] py-5 border-[#56C4C7] rounded-[8px] border-2 bg-transparent mr-[29px]"
                onClick={() => onClickButton('saveClient')}
              >
                <span className="text-base font-medium leading-[20px] tracking-[0.08rem] text-[#56C4C7]">Guardar</span>
              </button>
              <button
                type="button"
                className="flex justify-center min-w-[161px] py-5 border-[#56C4C7] rounded-[8px] border-2 bg-[#56C4C7]"
                onClick={() => onClickButton('saveAndCloseClient')}
              >
                <span className="text-base font-medium leading-[20px] tracking-[0.08rem] text-[#fff]">Hecho</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default FichaEmployeePage;
