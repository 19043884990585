/* eslint-disable no-empty-pattern */
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({}) => ({
  position: 'relative',
  overflow: 'hidden',
  '& .rs__table_loading': {
    background: 'var(--lighterGrey)',
    opacity: 0.5,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 60,
    '& > span': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& >span': {
        marginBottom: 15,
      },
    },
  },
}));

export const Table = styled('div')<{ resource_count: number }>(({ resource_count }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: `repeat(${resource_count}, 1fr)`,
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
}));
export const TableGrid = styled('div')<{
  days: number;
  indent?: string;
  pos?: number;
}>(({ days, indent = '1', theme, pos }) => ({
  position: 'relative',
  display: 'grid',
  marginBottom: 100,
  gridTemplateColumns: +indent > 0 ? `${pos === 1 ? '35px' : '0px'} repeat(${days}, 1fr)` : `repeat(${days}, 1fr)`,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: +indent > 0 ? `${pos === 1 ? '35px' : '0px'} repeat(${days}, 1fr)` : '',
  },
  '&:first-of-type': {
    borderStyle: 'solid',
    borderColor: 'var(--very-dark-grey)',
    borderWidth: '0px 1px 1px 0px',
  },
  '& .rs__cell': {
    position: 'relative',
    height: 18,
    '&.rs__header > :first-of-type': {
      margin: '0px 5px 0px 5px',
    },
    '&.rs__time': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'var(--txtWhite)',
      [theme.breakpoints.down('md')]: {
        writingMode: 'horizontal-rl',
      },
    },
    borderColor: 'var(--very-dark-gray)',
    borderWidth: '0px 1px 1px 0px',
    borderStyle: 'solid',
    // [`&:nth-of-type(${days + 1}n)`]: {
    //   borderWidth: "1px 1px 0px 1px",
    // },
    '& > button': {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      cursor: 'pointer',
      '&:hover': {
        background: 'var(--grey)',
      },
    },
    '& .rs__event__item': {
      position: 'absolute',
      zIndex: 1,
    },
    '& .rs__multi_day': {
      position: 'absolute',
      zIndex: 1,
      textOverflow: 'ellipsis',
    },
    '& .rs__block_col': {
      display: 'block',
      position: 'relative',
    },
    '& .rs__hover__op': {
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7,
        textDecoration: 'underline',
      },
    },
  },
}));

export const PopperInner = styled('div')(() => ({
  minWidth: 400,
  maxWidth: '95%',
  '& > div': {
    padding: '5px 10px',
    '& .rs__popper_actions': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));
