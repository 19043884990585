import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import { listCategoriesEpic } from './list-categories';

import AppState from '../../state';
import { crudCategoriesEpic } from './categories';

const categoriesEpic = combineEpics<Actions, Actions, AppState>(listCategoriesEpic, crudCategoriesEpic);

export default categoriesEpic;
