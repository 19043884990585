import { apiCategory } from '@services/business/products/catalog';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';
import { Categories } from './state';

export class ListCategories implements LoadingAction {
  readonly type = actions.LIST_CATEGORIES;
  readonly isLoading = true;
  constructor(public search?: string) {}
}

export class ListCategoriesSuccess implements LoadingAction {
  readonly type = actions.LIST_CATEGORIES_SUCCESS;
  readonly isLoading = false;

  constructor(public items: Categories[]) {}
}

export class ListCategoriesError implements ErrorAction {
  readonly type = actions.LIST_CATEGORIES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateCategories implements LoadingAction {
  readonly type = actions.CREATE_CATEGORIES;
  readonly isLoading = true;
  constructor(public category: apiCategory.createdCategory.Interface['body'], public updateCurrentId?: boolean) {}
}

export class CreateCategoriesSuccess implements LoadingAction {
  readonly type = actions.CREATE_CATEGORIES_SUCCESS;
  readonly isLoading = false;
}

export class CreateCategoriesError implements ErrorAction {
  readonly type = actions.CREATE_CATEGORIES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateCategories implements LoadingAction {
  readonly type = actions.UPDATE_CATEGORIES;
  readonly isLoading = true;
  constructor(public category: apiCategory.updatedCategory.Interface['body']) {}
}

export class UpdateCategoriesSuccess implements LoadingAction {
  readonly type = actions.UPDATE_CATEGORIES_SUCCESS;
  readonly isLoading = false;
}

export class UpdateCategoriesError implements ErrorAction {
  readonly type = actions.UPDATE_CATEGORIES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteCategories implements LoadingAction {
  readonly type = actions.DELETE_CATEGORIES;
  readonly isLoading = true;
  constructor(public categoryId: string) {}
}

export class DeleteCategoriesSuccess implements LoadingAction {
  readonly type = actions.DELETE_CATEGORIES_SUCCESS;
  readonly isLoading = false;
}

export class DeleteCategoriesError implements ErrorAction {
  readonly type = actions.DELETE_CATEGORIES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}
export type CategoriesActions =
  | ListCategoriesSuccess
  | ListCategoriesError
  | ListCategories
  | UpdateCategoriesSuccess
  | UpdateCategoriesError
  | UpdateCategories
  | DeleteCategories
  | DeleteCategoriesSuccess
  | DeleteCategoriesError;
