import { forwardRef } from 'react';

export const LashistaTicketToPrint = forwardRef((props, ref) => {
  const strToArr = props.message.split('&');
  return (
    <div ref={ref} className="flex flex-col justify-center p-2 bg-white">
      <style type="text/css" media="print"></style>
      {strToArr.length > 0 && (
        <>
          {strToArr.map((message, index) => (
            <span key={index} className="text-[10px] font-normal leading-[12px] tracking-[0.185em] mb-3 text-center">
              {message}
            </span>
          ))}
        </>
      )}
    </div>
  );
});
