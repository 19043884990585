const RightArrowIcon = (props: any) => {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 0C9.63375 0 0 9.63375 0 21.5C0 33.3662 9.63375 43 21.5 43C33.3662 43 43 33.3662 43 21.5C43 9.63375 33.3662 0 21.5 0ZM16.3674 15.5002L24.7679 21.5001L16.3674 27.5C15.7236 27.96 15.5731 28.8561 16.0332 29.4995C16.4932 30.1433 17.3892 30.2937 18.0327 29.8334L28.0666 22.6669C28.4433 22.3975 28.6672 21.9632 28.6672 21.5001C28.6672 21.0373 28.4433 20.6029 28.0666 20.3335L18.0327 13.1667C17.3892 12.7067 16.4932 12.8572 16.0332 13.5006C15.5731 14.1444 15.7236 15.0401 16.3674 15.5001V15.5002Z"
        fill={props.color ?? '#393939'}
      />
    </svg>
  );
};

export default RightArrowIcon;
