import { OrdenItem } from '@services/business/invoice/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';

export type Interface = ApiInterface<{
  response: OrdenItem;
  pathParams: { itemId: string };
  body: Partial<Omit<OrdenItem, 'tenantId' | 'id' | 'createdAt' | 'updatedAt'>>;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-update-invoice-item',
  method: 'PUT',
  basePath: 'orden',
  path: '/item/{itemId}',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
