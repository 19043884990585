import { UserClaims } from '@services/business/identity/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { CashRegister, CashRegisterBalance } from '../../entities';

export type Interface = ApiInterface<{
  response: { cashRegister: CashRegister; cashBalance: CashRegisterBalance };
  pathParams: EmptyObject;
  body: {
    cashRegisterClose: Omit<CashRegister, 'tenantId'>;
    cashBalance: CashRegisterBalance;
  };
  claims: UserClaims;
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-cash-register',
  method: 'POST',
  basePath: 'cash-register',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
