import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { UserClaims, UserRole } from '../../entities';
import { Cognito } from '../../entities/cognito';

export type Interface = ApiInterface<{
  response: Cognito;
  pathParams: EmptyObject;
  body: {
    name: string;
    lastName: string;
    email: string;
    sucursal: string;
    role: UserRole;
    isSuperAdmin: boolean;
  };
  claims: UserClaims;
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-identity',
  method: 'POST',
  basePath: 'identity',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'SUPER_ADMIN',
  schema: Schemy.schema<Interface['body']>({
    name: { type: String, required: true },
    lastName: { type: String, required: true },
    email: { type: String, required: true },
    sucursal: {
      type: String,
      required: false,
      custom(value, body) {
        if (body.isSuperAdmin === true) {
          return true;
        }
        if (typeof value === 'string' && value.length) {
          return true;
        }
        return false;
      },
    },
    role: {
      type: String,
      enum: ['ADMIN', 'OWNER'],
      required: false,
      custom(value, body) {
        if (body.isSuperAdmin === true) {
          return true;
        }
        if (typeof value === 'string' && value.length) {
          return true;
        }
        return false;
      },
    },
    isSuperAdmin: { type: Boolean, required: false },
  }),
};
