import { Client } from '@services/business/client/entities';
import { Discount } from '@services/business/discount/entities';
import { Group } from '@services/business/group/entities';
import { Cognito } from '@services/business/identity/entities';
import { Invoice } from '@services/business/invoice/entities';
import { Lashitas } from '@services/business/lashitas/entities';
import { Category } from '@services/business/products/entities/category';
import { Servicios } from '@services/business/products/entities/servicios';
import { Sucursal } from '@services/business/sucursal/entities/sucursal';
import { SearchEntity } from '../entities/algolia';

export type SearchType =
  | 'sucursal'
  | 'client'
  | 'category'
  | 'servicios'
  | 'lashitas'
  | 'discounts'
  | 'group'
  | 'invoice'
  | 'identity';

export const searchTypes: Record<SearchType, SearchEntity<SearchType>> = {
  client: {
    entity: 'client',
    excludeMyself: false,
    searchInTenant: false,
    settings: {
      searchableAttributes: ['firstName', 'lastName', 'phone'],
      attributesForFaceting: ['sucursalAt'],
    },
  },
  category: {
    entity: 'category',
    excludeMyself: false,
    searchInTenant: false,
    settings: {
      searchableAttributes: ['name'],
    },
  },
  servicios: {
    entity: 'servicios',
    excludeMyself: false,
    searchInTenant: false,
    settings: {
      searchableAttributes: ['name', 'description'],
      attributesForFaceting: ['type', 'categoryId', 'status'],
    },
  },
  lashitas: {
    entity: 'lashitas',
    excludeMyself: false,
    searchInTenant: true,
    settings: {
      searchableAttributes: ['firstName', 'lastName', 'phone'],
      attributesForFaceting: ['tenantId', 'status'],
    },
  },
  discounts: {
    entity: 'discounts',
    excludeMyself: false,
    searchInTenant: false,
    settings: {
      searchableAttributes: ['name'],
      attributesForFaceting: ['status'],
    },
  },
  group: {
    entity: 'group',
    excludeMyself: false,
    searchInTenant: false,
    settings: {
      searchableAttributes: ['name'],
    },
  },
  invoice: {
    entity: 'invoice',
    excludeMyself: false,
    searchInTenant: true,
    settings: {
      searchableAttributes: ['orderId'],
      attributesForFaceting: ['invoiceItem.statusAppointment', 'invoiceItem.clientId', 'invoiceItem.orderId'],
    },
  },
  sucursal: {
    entity: 'sucursal',
    excludeMyself: false,
    searchInTenant: false,
    settings: {
      searchableAttributes: ['name', 'address', 'adminName', 'adminEmail'],
      attributesForFaceting: ['status'],
    },
  },
  identity: {
    entity: 'identity',
    excludeMyself: false,
    searchInTenant: false,
    settings: {
      searchableAttributes: ['Username'],
      attributesForFaceting: ['UserStatus'],
    },
  },
};

export type SearchTypes = {
  client: {
    entity: Client;
    response: Client;
  };
  category: {
    entity: Category;
    response: Category;
  };
  servicios: {
    entity: Servicios;
    response: Servicios;
  };
  lashitas: {
    entity: Lashitas;
    response: Lashitas;
  };
  discounts: {
    entity: Discount;
    response: Discount;
  };
  group: {
    entity: Group;
    response: Group;
  };
  sucursal: {
    entity: Sucursal;
    response: Sucursal;
  };
  invoice: {
    entity: Invoice;
    response: Invoice;
  };
  identity: {
    entity: {
      id: string;
    } & Cognito;
    response: {
      id: string;
    } & Cognito;
  };
};
