import { ReactChild } from 'react';
import { useAppState } from '../../hooks/useAppState';
import { DefaultRecourse } from '../../types';
import { ResourceHeader } from './ResourceHeader';
import { ButtonTabProps, ButtonTabs } from './Tabs';

interface WithResourcesProps {
  renderChildren(resource: DefaultRecourse, pos: number): ReactChild;
}

const ResourcesTables = ({ renderChildren }: WithResourcesProps) => {
  const { resources, resourceFields } = useAppState();

  return (
    <>
      {resources.map((res: DefaultRecourse, i: number) => (
        <div key={`${res[resourceFields.idField]}_${i}`}>
          <ResourceHeader resource={res} pos={i + 1} />
          {renderChildren(res, i + 1)}
        </div>
      ))}
    </>
  );
};

const ResourcesTabTables = ({ renderChildren }: WithResourcesProps) => {
  const { resources, resourceFields, selectedResource, handleState } = useAppState();

  const tabs: ButtonTabProps[] = resources.map((res, i) => {
    return {
      id: res[resourceFields.idField],
      label: <ResourceHeader resource={res} pos={i + 1} />,
      component: <>{renderChildren(res, i + 1)}</>,
    };
  });

  const setTab = (tab: DefaultRecourse['assignee']) => {
    handleState(tab, 'selectedResource');
  };

  return (
    <ButtonTabs
      tabs={tabs}
      tab={selectedResource || resources[0][resourceFields.idField]}
      setTab={setTab}
      style={{ display: 'grid' }}
    />
  );
};

const WithResources = ({ renderChildren }: WithResourcesProps) => {
  const { resourceViewMode } = useAppState();

  if (resourceViewMode === 'tabs') {
    return <ResourcesTabTables renderChildren={renderChildren} />;
  } else {
    return <ResourcesTables renderChildren={renderChildren} />;
  }
};

WithResources.defaultProps = {
  span: 1,
};

export { WithResources };
