import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppState from './state';

type ArrayToObject<Arr extends any[]> = {
  [Entry in keyof Arr as string]: boolean;
};

export const useOpStore = <State = AppState, TSelected = unknown, Tloading = string>(
  selectorKey: (state: State) => TSelected,
  loading?: string[]
) => {
  const dispatch = useDispatch();
  const store = useSelector(selectorKey);
  const loadingState = useSelector((state: AppState) => state.ui.loading);
  const loadingMemo: ArrayToObject<Tloading[]> = useMemo(() => {
    const load: { [key: string]: boolean } = {};
    loading?.forEach((key) => {
      if (typeof loadingState[key] === 'undefined') {
        load[key as unknown as string] = true;
      }
      load[key as unknown as string] = loadingState[key as unknown as string] === 'local';
    });
    return load;
  }, [loading, loadingState]);

  return {
    dispatch,
    loading: loadingMemo,
    ...store,
  };
};
