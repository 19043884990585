const MlashesBoxRegisterIcon = (props: any) => {
  return (
    <svg width="52" height="48" viewBox="0 0 52 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.714 35.9801V29.5664Z" fill={props.color ?? 'black'} />
      <path
        d="M39.714 35.9801V29.5664"
        stroke={props.color ?? 'black'}
        strokeWidth="1.24942"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.0738 29.8961V14.3087C41.0738 12.9638 39.9699 11.8697 38.6129 11.8697H33.1989V8.12996H34.8395C36.1965 8.12996 37.3004 7.03587 37.3004 5.69099V2.43897C37.3004 1.09401 36.1965 0 34.8395 0H24.9958C23.6388 0 22.5349 1.09409 22.5349 2.43897V5.69099C22.5349 7.03595 23.6388 8.12996 24.9958 8.12996H26.6364V11.8697H18.433V4.06491C18.433 3.61601 18.0656 3.25187 17.6126 3.25187H9.4092C8.95627 3.25187 8.58886 3.61597 8.58886 4.06491V11.8697H4.81548C3.45844 11.8697 2.3546 12.9638 2.3546 14.3087V29.8961C1.39986 30.2317 0.713989 31.1356 0.713989 32.1952V37.561C0.713989 38.906 1.81791 40 3.17487 40H40.2531C41.6101 40 42.714 38.9059 42.714 37.561V32.1952C42.714 31.1356 42.0282 30.2315 41.0734 29.8961H41.0738ZM28.2774 8.13026H31.5586V11.87H28.2774V8.13026ZM24.1758 5.69129V2.43927C24.1758 1.99125 24.5441 1.62623 24.9962 1.62623H34.8399C35.2919 1.62623 35.6602 1.99121 35.6602 2.43927V5.69129C35.6602 6.13931 35.292 6.50433 34.8399 6.50433H24.9962C24.5441 6.50433 24.1758 6.13934 24.1758 5.69129ZM10.2304 4.87824H16.7929V18.3739H10.2304V4.87824ZM3.99612 14.3095C3.99612 13.8614 4.36438 13.4964 4.81646 13.4964H8.58983V18.3744C8.1369 18.3744 7.76949 18.7385 7.76949 19.1875C7.76949 19.6364 8.13687 20.0005 8.58983 20.0005H18.4335C18.8864 20.0005 19.2539 19.6364 19.2539 19.1875C19.2539 18.7385 18.8865 18.3744 18.4335 18.3744V13.4964H38.6129C39.0649 13.4964 39.4332 13.8614 39.4332 14.3095V29.7563H3.99597L3.99612 14.3095ZM41.0744 37.5612C41.0744 38.0092 40.7061 38.3742 40.254 38.3742H3.17577C2.72373 38.3742 2.35543 38.0092 2.35543 37.5612V32.1953C2.35543 31.7473 2.72369 31.3823 3.17577 31.3823H40.254C40.7061 31.3823 41.0744 31.7473 41.0744 32.1953V37.5612Z"
        fill={props.color ?? 'black'}
      />
      <circle cx="39.714" cy="36" r="12" fill="#878A8A" />
      <path
        d="M48.714 36C48.714 40.9706 44.6846 45 39.714 45C34.7434 45 30.714 40.9706 30.714 36C30.714 31.0294 34.7434 27 39.714 27C44.6846 27 48.714 31.0294 48.714 36Z"
        fill="#878A8A"
        stroke={props.color ?? 'black'}
        strokeWidth="1.24942"
        strokeMiterlimit="10"
      />
      <path d="M39.7181 36.0012L44.1119 33.4648Z" fill={props.color ?? 'black'} />
      <path
        d="M39.7181 36.0012L44.1119 33.4648"
        stroke={props.color ?? 'black'}
        strokeWidth="1.24942"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MlashesBoxRegisterIcon;
