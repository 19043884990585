import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as reports from '../../../sdk/new-old-client';
import { Actions } from '../../actions';
import {
  ListNewClient,
  ListNewClientError,
  ListNewClientSuccess,
  ListOldClient,
  ListOldClientError,
  ListOldClientSuccess,
} from '../action-creators';
import { LIST_NEW_CLIENT, LIST_OLD_CLIENT } from '../actions';

export const listNewOldClientEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListNewClient['type'] | ListOldClient['type'], ListNewClient | ListOldClient>(
      LIST_NEW_CLIENT,
      LIST_OLD_CLIENT
    ),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case LIST_OLD_CLIENT: {
            const result = await reports.listOldClient();
            return new ListOldClientSuccess(result);
          }
          case LIST_NEW_CLIENT: {
            const result = await reports.listNewClient();
            return new ListNewClientSuccess(result);
          }
        }
      } catch (err: any) {
        switch (action.type) {
          case LIST_OLD_CLIENT: {
            return new ListOldClientError(err.message, err.description);
          }
          case LIST_NEW_CLIENT: {
            return new ListNewClientError(err.message, err.description);
          }
        }
      }
    })
  );
