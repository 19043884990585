export const LIST_CLIENT = '[Client] List Client';
export const LIST_CLIENT_SUCCESS = '[Client] List Client Success';
export const LIST_CLIENT_ERROR = '[Client] List Client Error';

export const GET_CLIENT = '[Client] Get Client';
export const GET_CLIENT_SUCCESS = '[Client] Get Client Success';
export const GET_CLIENT_ERROR = '[Client] Get Client Error';

export const CREATE_CLIENT = '[Client] Create Client';
export const CREATE_CLIENT_SUCCESS = '[Client] Create Client Success';
export const CREATE_CLIENT_ERROR = '[Client] Create Client Error';

export const UPDATE_CLIENT = '[Client] Update Client';
export const UPDATE_CLIENT_SUCCESS = '[Client] Update Client Success';
export const UPDATE_CLIENT_ERROR = '[Client] Update Client Error';

export const DELETE_CLIENT = '[Client] Delete Client';
export const DELETE_CLIENT_SUCCESS = '[Client] Delete Client Success';
export const DELETE_CLIENT_ERROR = '[Client] Delete Client Error';

export const SET_CURRENT_CLIENT = '[Client] Current Client';

export const CREATE_NOTE_CREDIT = '[Client] Create Note Credit';
export const CREATE_NOTE_CREDIT_SUCCESS = '[Client] Create Note Credit Success';
export const CREATE_NOTE_CREDIT_ERROR = '[Client] Create Note Credit Error';

export const LIST_HISTORY_NOTE_CREDIT = '[Client] List History Note Credit';
export const LIST_HISTORY_NOTE_CREDIT_SUCCESS = '[Client] List History Note Credit Success';
export const LIST_HISTORY_NOTE_CREDIT_ERROR = '[Client] List History Note Credit Error';

export const SET_BALANCE_CLIENT = '[Client] Set Balance Client';
export const GET_BALANCE_CLIENT = '[Client] Get Balance Client';
export const GET_BALANCE_CLIENT_SUCCESS = '[Client] Get Balance Client Success';
export const GET_BALANCE_CLIENT_ERROR = '[Client] Get Balance Client Error';

export const SET_REWARD_CLIENT = '[Client] Set Reward Client';
export const GET_REWARD_CLIENT = '[Client] Get Reward Client';
export const GET_REWARD_CLIENT_SUCCESS = '[Client] Get Reward Client Success';
export const GET_REWARD_CLIENT_ERROR = '[Client] Get Reward Client Error';

export const GET_HISTORY_APPOINTMENT_CLIENT = '[Client] Get History Appointment Client';
export const GET_HISTORY_APPOINTMENT_CLIENT_SUCCESS = '[Client] Get History Appointment Client Success';
export const GET_HISTORY_APPOINTMENT_CLIENT_ERROR = '[Client] Get History Appointment Client Error';
