import { UserClaims } from '@services/business/identity/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { CashRegisterBalance, CashRegisterBalanceCreate } from '../../entities';
export type Interface = ApiInterface<{
  response: CashRegisterBalance;
  pathParams: UserClaims;
  body: CashRegisterBalanceCreate;
  claims: UserClaims;
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-update-cash-balance',
  method: 'PUT',
  basePath: 'cash-register',
  path: '/balance',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
