/* eslint-disable no-undef */
/* eslint-disable no-console */
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import ArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUp';
import NoteRoundedIcon from '@mui/icons-material/NoteOutlined';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import { Button, ButtonBase, Divider, Paper, Popover, Slide, Typography, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import { generateId } from '@services/support/utils/generate-id';
import { ButtonToPrintLashistaTicket } from '@webapp/components/pagos/buttonToPrintLashistaTicket';
import DatePicker from '@webapp/components/ui/datePicker';
import TextArea from '@webapp/components/ui/forms/textArea';
import Modal from '@webapp/components/ui/modal/Modal';
import CircleIcon from '@webapp/components/ui/shared/icons/CircleIcon';
import { UpdateAppointment } from '@webapp/store/appointments/action-creators';
import { CreateNotes } from '@webapp/store/notes/action-creators';
import { LIST_NOTES } from '@webapp/store/notes/actions';
import { useOpStore } from '@webapp/store/useOpStore';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as notes from '../../../../../sdk/notes';
import { useAppState } from '../../hooks/useAppState';
import { PopperInner } from '../../styles/styles';
import IconButton from '../common/iconButton';

const r = getComputedStyle(document.documentElement);

const colorStatus = (event) => {
  let tcolor = r.getPropertyValue('--primary');
  let tstatus = 'Agendado';

  const status = event.finalizeAt ? 'COMPLETED' : event.status;

  switch (status) {
    case 'NOSHOW':
      tcolor = r.getPropertyValue('--red');
      tstatus = 'No llegó';
      break;
    case 'CANCELED':
      tcolor = r.getPropertyValue('--red');
      tstatus = 'Cancelada';
      break;
    case 'DELETED':
      tcolor = r.getPropertyValue('--red');
      tstatus = 'Eliminada';
      break;
    case 'CONFIRMED':
      tcolor = r.getPropertyValue('--green');
      tstatus = 'Confirmada';
      break;
    case 'COMPLETED':
      tcolor = r.getPropertyValue('--grey');
      tstatus = 'Completada';
      break;
    case 'SHOW':
      tcolor = r.getPropertyValue('--blue');
      tstatus = 'Llegó';
      break;
    case 'AWAIT':
      tcolor = r.getPropertyValue('--txtWhite');
      tstatus = 'En Espera';
      break;
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tcolor = r.getPropertyValue('--primary');
  }
  return { vColor: tcolor, vStatus: tstatus };
};

const EventItem = ({ event, multiday, hasPrev, hasNext, showdate, height }) => {
  const { events, viewerExtraComponent, fields, direction, resources, resourceFields, locale, viewerTitleComponent } =
    useAppState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [dayMonthYear, setDayMonthYear] = useState(event.start);
  const [openDate, setOpenDate] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const setDMY = (data) => {
    if (data) {
      setDayMonthYear(data);
    }
  };

  const {
    product: { categories },
    dispatch,
  } = useOpStore((state) => state, [LIST_NOTES]);

  const getNote = async (clientId) => {
    const result = await notes.getLastNote(clientId);
    const notesList = result;
    return notesList['note'];
  };

  const titles = event.title.split('|');

  const [note, setNote] = useState(event.note || '');
  const [noteClient, setNoteClient] = useState('');
  const [isEditNote, setIsEditNote] = useState(false);
  const [isEditNoteClient, setIsEditNoteClient] = useState(false);
  const eventDate = format(event.start, 'yyyy-MM-dd', { locale: es });
  const hoy = format(new Date(), 'yyyy-MM-dd', { locale: es });

  const categoryName = categories.find((category) => category.id === event.categoryId)?.name;

  const menuEvent =
    event.finalizeAt || eventDate != hoy
      ? [{ id: 2, name: 'Ver Ticket', icon: null }]
      : [
          { id: 1, name: 'Deshacer llegado', icon: null },
          { id: 2, name: 'Pasar a Caja', icon: null },
          { id: 3, name: 'Deshacer confirmación', icon: null },
          { id: 4, name: 'No presentado', icon: null },
          { id: 0, name: 'Divider', icon: null },
          { id: 10, name: 'Mover Cita', icon: null },
          { id: 5, name: 'Cambiar servicio', icon: null },
          { id: 6, name: 'Llegó', icon: null },
          { id: 7, name: 'Confirmó', icon: null },
          { id: 8, name: 'Canceló', icon: null },
          { id: 9, name: 'Poner en Lista de espera', icon: null },
          { id: 0, name: 'Divider', icon: null },
          { id: 13, name: 'Borrar', icon: null },
        ];

  if (!event.finalizeAt && eventDate == hoy && event.status2 == 'PAID') {
    menuEvent.push({ id: 11, name: 'Pasar a Completada', icon: null });
  }

  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const updateStatus = (status) => {
    dispatch(
      new UpdateAppointment(
        {
          orderId: event.id,
          lashistaId: status == 'CANCELED' ? 'CANCELED' : event.idWorker,
          dateInitial: event.start.getTime(),
          statusAppointment: status,
          status: status == 'DELETED' ? 'DELETED' : event.satus,
          serviceId: event.sid,
        },
        event.oid
      )
    );

    events.map((e) => {
      if (e.id == event.id && e.sid == event.sid) {
        e.status = status;
        if (status == 'DELETED') {
          e.idWorker = e.idWorker * -1;
        }
      }
      return e;
    });
  };

  const updateNote = () => {
    dispatch(
      new UpdateAppointment(
        {
          orderId: event.id,
          lashistaId: event.idWorker,
          dateInitial: event.start.getTime(),
          statusAppointment: event.status,
          serviceId: event.sid,
          note: note,
        },
        event.oid
      )
    );

    setIsEditNote(false);
  };

  const updateNoteClient = () => {
    dispatch(
      new CreateNotes(
        {
          id: generateId('noteClient'),
          note: noteClient,
          clientId: event.clientId,
        },
        event.clientId
      )
    );

    setIsEditNoteClient(false);
  };

  const updateAppointment = (diff) => {
    dispatch(
      new UpdateAppointment(
        {
          orderId: event.id,
          lashistaId: event.idWorker,
          dateInitial: diff != 0 ? event.start : dayMonthYear.getTime(),
          dateEnd:
            diff != 0
              ? event.end.setMinutes(event.end.getMinutes() + diff)
              : dayMonthYear.setMinutes(dayMonthYear.getMinutes() + event.duration / 60),
          statusAppointment: event.status,
          serviceId: event.sid,
        },
        event.oid
      )
    );

    events.map((e) => {
      if (e.id == event.id && e.sid == event.sid) {
        e.idWorker = event.idWorker;
        e.start = event.start;
        e.end = event.end;
      }
      return e;
    });
  };

  const handleClick = (item) => {
    switch (item.id) {
      case 1:
        updateStatus('CONFIRMED');
        break;
      case 2:
        history.push('/pagos/' + event.id);
        break;
      case 3:
        updateStatus('INTIME');
        break;
      case 4:
        updateStatus('NOSHOW');
        break;
      case 5:
        history.push('/servicios/' + event.oid);
        break;
      case 6:
        updateStatus('SHOW');
        break;
      case 7:
        updateStatus('CONFIRMED');
        break;
      case 8:
        setIsOpen(true);
        break;
      case 9:
        history.push('/clientes/AWAIT/' + event.start.getTime() + '/AWAIT');
        break;
      case 10:
        setIsOpen2(true);
        break;
      case 11:
        updateStatus('COMPLETED');
        break;
      case 13:
        updateStatus('DELETED');
        break;
      default:
        alert(item.name);
    }

    handleClose();
  };

  const NextArrow = direction === 'rtl' ? ArrowLeftRoundedIcon : ArrowRightRoundedIcon;
  const PrevArrow = direction === 'rtl' ? ArrowRightRoundedIcon : ArrowLeftRoundedIcon;

  const triggerViewer = (el) => {
    if (!el && deleteConfirm) {
      setDeleteConfirm(false);
    }
    setAnchorEl(el || null);
  };

  let item = (
    <div style={{ height: height }} className={'flex flex-col justify-start items-start w-full px-1 text-txt'}>
      <div className="flex flex-col w-full">
        <div className="flex flex-row">
          <div className="flex justify-between text-sm font-bold nowrap w-full">
            {`${format(event.start, 'HH:mm', {
              locale: locale,
            })} - ${format(event.end, 'HH:mm', { locale: locale })}`}
          </div>
          {(note || noteClient) && (
            <NoteRoundedIcon
              className="m-0 -mr-[6px] -rotate-90 float-right"
              style={{ height: 20, color: 'var(--blue)' }}
              fontSize="medium"
              ba
            />
          )}
        </div>
        <Divider className="bg-veryDarkGray" />
      </div>
      <div className="flex flex-col justify-start items-start font-normal text-xs w-full">
        <div className="text-left font-bold">{titles[0]}</div>
        <div className="text-left">{categoryName + ' -' + titles[1]}</div>
      </div>
      <div className="absolute right-1 bottom-[10px]">
        <CircleIcon bcolor={colorStatus(event).vColor} />
      </div>
      <div className={`bg-[${event.color}] absolute right-[2px] bottom-0 text-[8px] font-bold nowrap`}>
        {colorStatus(event).vStatus}
      </div>
      <div></div>
    </div>
  );

  if (multiday) {
    item = (
      <div
        style={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: 11 }} noWrap>
          {hasPrev ? (
            <PrevArrow fontSize="small" sx={{ display: 'flex' }} />
          ) : (
            showdate && format(event.start, 'HH:mm', { locale: locale })
          )}
        </Typography>
        <Typography variant="subtitle2" align="center" sx={{ fontSize: 12 }} noWrap>
          {event.title}
        </Typography>
        <Typography sx={{ fontSize: 11 }} noWrap>
          {hasNext ? (
            <NextArrow fontSize="small" sx={{ display: 'flex' }} />
          ) : (
            showdate && format(event.end, 'HH:mm', { locale: locale })
          )}
        </Typography>
      </div>
    );
  }

  const renderViewer = () => {
    const idKey = resourceFields.idField;
    const hasResource = resources.filter((res) =>
      Array.isArray(event[idKey]) ? event[idKey].includes(res[idKey]) : res[idKey] === event[idKey]
    );

    return (
      <PopperInner>
        <div
          style={{
            background: event.color || theme.palette.primary.main,
            color: 'var(--txt)',
          }}
        >
          <div className="rs__popper_actions">
            <div>
              <IconButton
                style={{ color: theme.palette.primary.contrastText }}
                onClick={() => {
                  triggerViewer();
                  if (isEditNoteClient || isEditNote) setIsOpen3(true);
                }}
              >
                <ClearRoundedIcon color="disabled" />
              </IconButton>
            </div>
            {!event.finalizeAt && eventDate == hoy && (
              <div style={{ display: 'inherit' }}>
                <IconButton
                  style={{ color: 'var(--txt)' }}
                  onClick={() => {
                    triggerViewer();
                    history.push('/servicios/' + event.oid);
                    //triggerDialog(true, event);
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
                {!deleteConfirm && (
                  <IconButton style={{ color: 'var(--red)' }} onClick={() => setDeleteConfirm(true)}>
                    <DeleteRoundedIcon />
                  </IconButton>
                )}
                <Slide in={deleteConfirm} direction={direction === 'rtl' ? 'right' : 'left'} mountOnEnter unmountOnExit>
                  <div>
                    <Button
                      style={{ color: theme.palette.error.main }}
                      size="small"
                      onClick={() => updateStatus('DELETED')}
                    >
                      Eliminar
                    </Button>
                    <Button
                      style={{ color: theme.palette.action.disabled }}
                      size="small"
                      onClick={() => setDeleteConfirm(false)}
                    >
                      Cancelar
                    </Button>
                  </div>
                </Slide>
              </div>
            )}
          </div>
          {viewerTitleComponent instanceof Function ? (
            viewerTitleComponent(event)
          ) : (
            <>
              <Typography class=" font-bold" style={{ padding: '5px 0' }} noWrap>
                {titles[0]}
                <div className=" font-medium">{categoryName + ' -' + titles[1]}</div>
              </Typography>
            </>
          )}
        </div>
        <div style={{ padding: '5px 10px' }}>
          <Typography
            style={{ display: 'flex', justifyContent: 'center' }}
            color="textSecondary"
            variant="caption"
            noWrap
          >
            <EventNoteRoundedIcon />{' '}
            {`${format(event.start, 'dd MMMM yyyy HH:mm', {
              locale: locale,
            })} - ${format(event.end, 'dd MMMM yyyy HH:mm', {
              locale: locale,
            })}`}
          </Typography>
          {!event.finalizeAt &&
            eventDate == hoy &&
            (isEditNote ? (
              <div className="float-right">
                <IconButton
                  style={{ color: 'var(--primary)' }}
                  onClick={() => {
                    updateNote();
                  }}
                >
                  <SaveRoundedIcon />
                </IconButton>
              </div>
            ) : (
              <div className="float-right">
                <IconButton
                  style={{ color: 'var(--grey)' }}
                  onClick={() => {
                    setIsEditNote(true);
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
              </div>
            ))}
          <ButtonToPrintLashistaTicket message={note} />
          <TextArea
            classlabel={'text-base font-semibold mb-[11px] text-black'}
            classinput={`text-base font-light border-[#A8ACAC] rounded-[3px] text-txt ${
              isEditNote ? 'bg-white' : 'bg-lighterGrey'
            }`}
            name="notes"
            label="Nota Cita"
            rows={3}
            value={note}
            mode={isEditNote ? 'add' : 'view'}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
          <div className="m-2" />
          {!event.finalizeAt &&
            eventDate == hoy &&
            (isEditNoteClient ? (
              <div className="float-right">
                <IconButton
                  style={{ color: 'var(--primary)' }}
                  onClick={() => {
                    updateNoteClient();
                  }}
                >
                  <SaveRoundedIcon />
                </IconButton>
              </div>
            ) : (
              <div className="float-right">
                <IconButton
                  style={{ color: 'var(--grey)' }}
                  onClick={() => {
                    setIsEditNoteClient(true);
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
              </div>
            ))}
          <ButtonToPrintLashistaTicket message={noteClient} />
          <TextArea
            classlabel={'text-base font-semibold mb-[11px] text-black'}
            classinput={`text-base font-light border-[#A8ACAC] rounded-[3px] text-txt ${
              isEditNote ? 'bg-white' : 'bg-lighterGrey'
            }`}
            name="notes"
            label="Nota General"
            rows={3}
            value={noteClient}
            mode={isEditNoteClient ? 'add' : 'view'}
            onChange={(e) => {
              setNoteClient(e.target.value);
            }}
          />
          {viewerExtraComponent instanceof Function ? viewerExtraComponent(fields, event) : viewerExtraComponent}
        </div>
        <div
          style={{
            background: colorStatus(event).vColor || theme.palette.primary.main,
          }}
        >
          {hasResource.length > 0 && (
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              color="textSecondary"
              variant="caption"
              noWrap
            >
              <div className="flex flex-row justify-between w-full">
                <div>
                  <SupervisorAccountRoundedIcon /> {hasResource.map((res) => res[resourceFields.textField]).join(', ')}
                </div>
                <div className="font-bold">{colorStatus(event).vStatus}</div>
              </div>
            </Typography>
          )}
        </div>
      </PopperInner>
    );
  };

  useEffect(() => {
    /*getNote(event.clientId).then((v) => {
      setNoteClient(v);
    });*/
  }, []);

  return (
    <>
      <Paper
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
          background: event.disabled ? '#d0d0d0' : event.color || theme.palette.primary.main,
          color: event.disabled ? '#808080' : theme.palette.primary.contrastText,
          cursor: event.disabled ? 'not-allowed' : 'pointer',
          overflow: 'hidden',
        }}
      >
        <ButtonBase
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            getNote(event.clientId).then((v) => {
              setNoteClient(v);
            });
            triggerViewer(e.currentTarget);
          }}
          disabled={event.disabled}
          style={{
            width: '100%',
            height: '100%',
            display: 'block',
          }}
        >
          <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
            <div
              className="flex justify-center items-center"
              style={{
                width: '100%',
                height: '100%',
              }}
              draggable
              onDragStart={(e) => {
                const color = 'var(--grey)';
                const texto = event.id.toString();
                //e.stopPropagation();
                e.dataTransfer.setData('text/plain', texto);
                e.currentTarget.style.backgroundColor = color;
              }}
              onDragEnd={(e) => {
                const color = event.color || theme.palette.primary.main;
                e.currentTarget.style.backgroundColor = color;
              }}
              onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onDragEnter={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {item}
            </div>
          </div>
        </ButtonBase>
        <div className={`bg-[${event.color}] absolute flex flex-row -bottom-1.5 left-1/2 transform -translate-x-1/2`}>
          <IconButton
            style={{ color: 'var(--txt)' }}
            onClick={() => {
              updateAppointment(-30);
            }}
          >
            <ArrowUpRoundedIcon fontSize="small" />
          </IconButton>
          <IconButton
            style={{ color: 'var(--txt)' }}
            onClick={() => {
              updateAppointment(30);
            }}
          >
            <ArrowDownRoundedIcon fontSize="small" />
          </IconButton>
        </div>
      </Paper>

      {/* Viewer */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          triggerViewer();
          if (isEditNoteClient || isEditNote) setIsOpen3(true);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {renderViewer()}
      </Popover>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <div className="bg-primary -my-2">
          {menuEvent.map((item, i) =>
            item.id != 0 ? (
              <IconButton key={'me' + i} item primary className="z-50" onMouseDown={() => handleClick(item)}>
                <div className="flex justify-self-start w-64">{item.name}</div>
              </IconButton>
            ) : (
              <Divider className="bg-very-dark-gray" key={i} />
            )
          )}
        </div>
      </Menu>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onOk={() => {
          updateStatus('CANCELED');
        }}
        title="Confirmación"
      >
        <span className="flex justify-center items-center text-txt text-lg font-medium tracking-widest leading-tight">
          {'Está seguro de cancelar la cita de ' + titles[0] + '?'}
        </span>
      </Modal>
      <Modal
        isOpen={isOpen2}
        setIsOpen={setIsOpen2}
        onOk={() => {
          updateAppointment(0);
        }}
        title="Mover Cita"
      >
        <span className="flex justify-center items-center text-txt text-lg font-medium tracking-widest leading-tight mb-4">
          {'Está seguro de cambiar la cita de ' + titles[0] + ' a la siguiente fecha?'}
        </span>
        <DatePicker
          data={dayMonthYear}
          onChangeData={(v) => setDMY(v)}
          openDate={openDate}
          setOpenDate={() => setOpenDate(false)}
        />
      </Modal>
      <Modal
        isOpen={isOpen3}
        setIsOpen={setIsOpen3}
        onOk={() => {
          if (isEditNote) {
            updateNote();
          }
          if (isEditNoteClient) {
            updateNoteClient();
          }
        }}
        onCancel={() => {
          setIsEditNote(false);
          setIsEditNoteClient(false);
        }}
        title="Grabar Nota"
      >
        <span className="flex justify-center items-center text-txt text-lg font-medium tracking-widest leading-tight mb-4">
          {'Desea grabar la nota del cliente ' + titles[0] + '?'}
        </span>
      </Modal>
    </>
  );
};

EventItem.defaultProps = {
  multiday: false,
  showdate: true,
};

export default EventItem;
