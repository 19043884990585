import * as api from '@services/business/history/catalog/api';
import { Invoice } from '@services/business/invoice/entities';

import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';

export class ListReports implements LoadingAction {
  readonly type = actions.LIST_REPORTS;
  readonly isLoading = true;
  constructor(public data: api.getHistoryOrder.Interface['body']) {}
}

export class ListReportsSuccess implements LoadingAction {
  readonly type = actions.LIST_REPORTS_SUCCESS;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class ListReportsError implements ErrorAction {
  readonly type = actions.LIST_REPORTS_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListReportPerDay implements LoadingAction {
  readonly type = actions.LIST_REPORTS_PER_DAY;
  readonly isLoading = true;
  constructor(public date: string) {}
}

export class ListReportPerDaySuccess implements LoadingAction {
  readonly type = actions.LIST_REPORTS_PER_DAY_SUCCESS;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class ListReportPerDayError implements ErrorAction {
  readonly type = actions.LIST_REPORTS_PER_DAY_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListReportsClient implements LoadingAction {
  readonly type = actions.LIST_REPORTS_CLIENT;
  readonly isLoading = true;
  constructor(public clientId: string) {}
}

export class ListReportsClientSuccess implements LoadingAction {
  readonly type = actions.LIST_REPORTS_SUCCESS_CLIENT;
  readonly isLoading = false;

  constructor(public data: Invoice[]) {}
}

export class ListReportsClientError implements ErrorAction {
  readonly type = actions.LIST_REPORTS_ERROR_CLIENT;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListNewClient implements LoadingAction {
  readonly type = actions.LIST_NEW_CLIENT;
  readonly isLoading = true;
  constructor(public tenantId?: string) {}
}

export class ListNewClientSuccess implements LoadingAction {
  readonly type = actions.LIST_NEW_SUCCESS_CLIENT;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class ListNewClientError implements ErrorAction {
  readonly type = actions.LIST_NEW_ERROR_CLIENT;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListOldClient implements LoadingAction {
  readonly type = actions.LIST_OLD_CLIENT;
  readonly isLoading = true;
  constructor(public tenantId?: string) {}
}

export class ListOldClientSuccess implements LoadingAction {
  readonly type = actions.LIST_OLD_SUCCESS_CLIENT;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class ListOldClientError implements ErrorAction {
  readonly type = actions.LIST_NEW_OLD_ERROR_CLIENT;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListFirstYearReport implements LoadingAction {
  readonly type = actions.LIST_FIRST_REPORTS;
  readonly isLoading = true;
  constructor(public data: api.getHistoryOrder.Interface['body']) {}
}

export class ListFirstYearReportSuccess implements LoadingAction {
  readonly type = actions.LIST_FIRST_REPORTS_SUCCESS;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class ListFirstYearReportError implements ErrorAction {
  readonly type = actions.LIST_FIRST_REPORTS_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListSecondYearReport implements LoadingAction {
  readonly type = actions.LIST_SECOND_REPORTS;
  readonly isLoading = true;
  constructor(public data: api.getHistoryOrder.Interface['body']) {}
}

export class ListSecondYearReportSuccess implements LoadingAction {
  readonly type = actions.LIST_SECOND_REPORTS_SUCCESS;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class ListSecondYearReportError implements ErrorAction {
  readonly type = actions.LIST_SECOND_REPORTS_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

/***
 *
 * Clear
 * Reports
 *
 */
export class ClearReportsNormalized implements LoadingAction {
  readonly type = actions.CLEAR_REPORTS_NORMALIZED;
  readonly isLoading = false;

  constructor(public name?: string) {}
}

/***
 *
 * Normalize
 * Reports
 *
 ***/

export class HistoryReportNormalize implements LoadingAction {
  readonly type = actions.HISTORY_REPORT_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class SalesReportNormalize implements LoadingAction {
  readonly type = actions.SALES_REPORT_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any) {}
}
export class ProdAndServiceReportNormalize implements LoadingAction {
  readonly type = actions.PRODUCT_SERVICE_REPORT_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class PerformanceEmployeesNormalize implements LoadingAction {
  readonly type = actions.PERFORMANCE_EMPLOYEE_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public lashitas: any, public categories: any) {}
}

export class RankingSucursalNormalize implements LoadingAction {
  readonly type = actions.RANKING_SUCURSAL_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any) {}
}

export class DiscountNormalize implements LoadingAction {
  readonly type = actions.DISCOUNT_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public discountList: any) {}
}

export class NewClientNormalize implements LoadingAction {
  readonly type = actions.NEW_CLIENT_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public historyClient: any, public selectedSucursal?: string[]) {}
}

export class NoReturnClientNormalize implements LoadingAction {
  readonly type = actions.NO_RETURN_CLIENT_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public historyClient: any, public selectedSucursal?: string[]) {}
}

export class FutureAppointmentNormalize implements LoadingAction {
  readonly type = actions.FUTURE_APPOINTMENT_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public listLashista: any) {}
}
export class SchedulerNormalize implements LoadingAction {
  readonly type = actions.SCHEDULER_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public listLashista: any) {}
}

export class AnnualSaleNormalize implements LoadingAction {
  readonly type = actions.ANNUAL_SALE_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public dataNewClient: any, public dataNoReturnClient: any) {}
}

export class FirstYearNormalize implements LoadingAction {
  readonly type = actions.FIRST_YEAR_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public dataNewClient: any, public dataNoReturnClient: any) {}
}

export class SecondYearNormalize implements LoadingAction {
  readonly type = actions.SECOND_YEAR_NORMALIZE;
  readonly isLoading = false;

  constructor(public data: any, public dataNewClient: any, public dataNoReturnClient: any) {}
}

export class MovementsSummaryNormalize implements LoadingAction {
  readonly type = actions.MOVEMENTS_SUMMARY_NORMALIZE;
  readonly isLoading = false;

  constructor(
    public data?: any,
    public sucursales?: any[],
    public newClient?: any,
    public noReturnClient?: any,
    public listLashista?: any
  ) {}
}

export type ReportActions =
  | ListReports
  | ListReportsError
  | ListReportsSuccess
  | ListReportPerDay
  | ListReportPerDaySuccess
  | ListReportPerDayError
  | HistoryReportNormalize
  | SalesReportNormalize
  | ProdAndServiceReportNormalize
  | ListReportsClient
  | ListReportsClientSuccess
  | ListReportsClientError
  | PerformanceEmployeesNormalize
  | RankingSucursalNormalize
  | DiscountNormalize
  | ListNewClient
  | ListNewClientSuccess
  | ListNewClientError
  | NewClientNormalize
  | NoReturnClientNormalize
  | FutureAppointmentNormalize
  | SchedulerNormalize
  | AnnualSaleNormalize
  | ClearReportsNormalized
  | ListOldClient
  | ListOldClientSuccess
  | ListOldClientError
  | ListFirstYearReport
  | ListFirstYearReportSuccess
  | ListFirstYearReportError
  | ListSecondYearReport
  | ListSecondYearReportSuccess
  | ListSecondYearReportError
  | FirstYearNormalize
  | SecondYearNormalize
  | MovementsSummaryNormalize;
