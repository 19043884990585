import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as lashista from '../../../sdk/lashista';
import { Actions } from '../../actions';
import {
  CreateLashistaSuccess,
  ListLashista,
  ListLashistaError,
  ListLashistaSuccess,
  UpdateLashistaSuccess,
} from '../action-creators';
import { CREATE_LASHISTA_SUCCESS, LIST_LASHISTA, UPDATE_LASHISTA_SUCCESS } from '../actions';

export const listLashistaEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      ListLashista['type'] | CreateLashistaSuccess['type'] | UpdateLashistaSuccess['type'],
      ListLashista | CreateLashistaSuccess | UpdateLashistaSuccess
    >(LIST_LASHISTA, CREATE_LASHISTA_SUCCESS, UPDATE_LASHISTA_SUCCESS),
    mergeMap(async (action) => {
      try {
        if (action.type === LIST_LASHISTA && action.search?.length) {
          const result = await lashista.listLashita(action.search);
          return new ListLashistaSuccess(result.items);
        }
        const { items } = await lashista.listLashita();

        return new ListLashistaSuccess(items);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListLashistaError(err.message, err.description);
      }
    })
  );
