import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useEffect, useMemo, useRef } from 'react';

import { ListHistoryNoteCredit } from '@webapp/store/client/action-creators';
import AppState from '@webapp/store/state';
import { CloseModal, OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';

interface IModal {
  title: string;
  onCancel?: any;
  onOk?: any;
}

const ModalSelectProductService: FunctionComponent<IModal> = (props) => {
  const cancelButton = useRef(null);
  const {
    appointments: { preAppointment },
    client: { currentClient },
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const { title, onCancel, onOk } = props;

  const { open, modalData } = useMemo(() => {
    return {
      open: modalOpened?.['SELECT_PRODUCT_SERVICE_MODAL']?.open ?? false,
      modalData:
        (modalOpened?.['SELECT_PRODUCT_SERVICE_MODAL']?.value as { orderItemId: string; serviceId: string }) ?? {},
    };
  }, [modalOpened?.['SELECT_PRODUCT_SERVICE_MODAL']]);

  /**
   * open the modal to select products
   * @returns
   */
  const OpenProductModal = () => {
    if (!preAppointment || !preAppointment.clientId) {
      dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
      dispatch(
        new OpenModal('SHOW_ERROR_MODAL', {
          messageError: 'Debe seleccionar un cliente',
        })
      );
      return;
    }

    if (
      preAppointment.payment?.methods?.find((method) => {
        if (method.type === 'REWARD' && method.productId === modalData.serviceId) {
          return method;
        }
      })
    ) {
      dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
      dispatch(
        new OpenModal('SHOW_ERROR_MODAL', {
          messageError: 'No se puede cambiar un reward de la lista. se debe borrar primero para cambiarlo',
        })
      );
      return;
    }

    dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
    dispatch(
      new OpenModal('ADD_PRODUCT_MODAL', { orderItemId: modalData.orderItemId, serviceId: modalData.serviceId })
    );
  };

  /**
   * open the modal to select services
   * @returns
   */
  const OpenServiceModal = () => {
    if (!preAppointment || !preAppointment.clientId) {
      dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
      dispatch(
        new OpenModal('SHOW_ERROR_MODAL', {
          messageError: 'Debe seleccionar un cliente',
        })
      );
      dispatch(
        new OpenModal('ADD_SERVICE_MODAL', { orderItemId: modalData.orderItemId, serviceId: modalData.serviceId })
      );
      return;
    }
    if (
      preAppointment.payment?.methods?.find((method) => {
        if (method.type === 'REWARD' && method.productId === modalData.serviceId) {
          return method;
        }
      })
    ) {
      dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
      dispatch(
        new OpenModal('SHOW_ERROR_MODAL', {
          messageError: 'No se puede cambiar un reward de la lista. se debe borrar primero para cambiarlo',
        })
      );
      return;
    }

    dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
    dispatch(
      new OpenModal('ADD_SERVICE_MODAL', { orderItemId: modalData.orderItemId, serviceId: modalData.serviceId })
    );
  };

  /**
   * open the modal to select services
   * @returns
   */
  const OpenRewardModal = () => {
    if (!preAppointment || !preAppointment.clientId) {
      dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
      dispatch(
        new OpenModal('SHOW_ERROR_MODAL', {
          messageError: 'Debe seleccionar un cliente',
        })
      );
      dispatch(
        new OpenModal('SELECT_REWARD_MODAL', { orderItemId: modalData.orderItemId, serviceId: modalData.serviceId })
      );
      return;
    }
    dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
    dispatch(
      new OpenModal('SELECT_REWARD_MODAL', { orderItemId: modalData.orderItemId, serviceId: modalData.serviceId })
    );
  };

  useEffect(() => {
    if (currentClient?.id || '') {
      dispatch(new ListHistoryNoteCredit(currentClient?.id || ''));
    }
  }, [currentClient]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-darkGray rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <div className="bg-primary py-3 pl-9 flex justify-left">
                <Dialog.Title
                  as="span"
                  className="flex justify-between items-center w-full text-white text-xl md:text-2xl font-bold tracking-widest leading-tight"
                >
                  {title}
                </Dialog.Title>
              </div>
              <div className="py-3 md:py-5 px-4 md:px-8 flex flex-col mb-5">
                <div className="flex justify-evenly">
                  <button
                    className="flex flex-col justify-center items-center gap-[6px] hover:text-primary border-grey text-white text-base font-medium tracking-[0.185em] leading-[20px] bg-grey border rounded-[5px] w-[109px] h-[109px] px-[14px] py-[21px]"
                    onClick={() => OpenProductModal()}
                  >
                    Productos
                  </button>
                  <button
                    className="flex flex-col justify-center items-center gap-[6px] hover:text-primary border-grey text-white text-base font-medium tracking-[0.185em] leading-[20px] bg-grey border rounded-[5px] w-[109px] h-[109px] px-[14px] py-[21px]"
                    onClick={() => OpenServiceModal()}
                  >
                    Servicios
                  </button>
                  <button
                    className="flex flex-col justify-center items-center gap-[6px] hover:text-primary border-grey text-white text-base font-medium tracking-[0.185em] leading-[20px] bg-grey border rounded-[5px] w-[109px] h-[109px] px-[14px] py-[21px]"
                    onClick={() => OpenRewardModal()}
                  >
                    Rewards
                  </button>
                </div>
              </div>
              <div className="flex justify-center gap-4 items-center mb-6 px-5">
                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    onCancel ? onCancel() : null;
                    dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
                  }}
                >
                  Cancelar
                </button>

                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    onOk ? onOk() : null;
                    dispatch(new CloseModal('SELECT_PRODUCT_SERVICE_MODAL'));
                  }}
                >
                  Hecho
                </button>
              </div>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalSelectProductService;
