export const LIST_CATEGORIES = '[Categories] List Categories';
export const LIST_CATEGORIES_SUCCESS = '[Categories] List Categories Success';
export const LIST_CATEGORIES_ERROR = '[Categories] List Categories Error';

export const CREATE_CATEGORIES = '[Categories] Create Categories';
export const CREATE_CATEGORIES_SUCCESS = '[Categories] Create Categories Success';
export const CREATE_CATEGORIES_ERROR = '[Categories] Create Categories Error';

export const UPDATE_CATEGORIES = '[Categories] Update Categories';
export const UPDATE_CATEGORIES_SUCCESS = '[Categories] Update Categories Success';
export const UPDATE_CATEGORIES_ERROR = '[Categories] Update Categories Error';

export const DELETE_CATEGORIES = '[Categories] Delete Categories';
export const DELETE_CATEGORIES_SUCCESS = '[Categories] Delete Categories Success';
export const DELETE_CATEGORIES_ERROR = '[Categories] Delete Categories Error';
