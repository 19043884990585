import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as lashista from '../../../sdk/lashista';
import { Actions } from '../../actions';
import {
  CreateLashista,
  CreateLashistaError,
  CreateLashistaSuccess,
  DeleteLashista,
  DeleteLashistaError,
  UpdateLashista,
  UpdateLashistaError,
  UpdateLashistaSuccess,
} from '../action-creators';
import { CREATE_LASHISTA, DELETE_LASHISTA, UPDATE_LASHISTA } from '../actions';

export const crudLashistaEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      CreateLashista['type'] | UpdateLashista['type'] | DeleteLashista['type'],
      CreateLashista | UpdateLashista | DeleteLashista
    >(CREATE_LASHISTA, UPDATE_LASHISTA, DELETE_LASHISTA),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case UPDATE_LASHISTA: {
            await lashista.updateLashitas(action.lashista);

            return new UpdateLashistaSuccess();
          }
          case CREATE_LASHISTA: {
            await lashista.createLashitas(action.lashista);

            return new CreateLashistaSuccess();
          }
          case DELETE_LASHISTA: {
            await lashista.deleteLashitas(action.lashistaId);

            return new CreateLashistaSuccess();
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case UPDATE_LASHISTA: {
            return new UpdateLashistaError(err.message, err.description);
          }
          case CREATE_LASHISTA: {
            return new CreateLashistaError(err.message, err.description);
          }
          case DELETE_LASHISTA: {
            return new DeleteLashistaError(err.message, err.description);
          }
        }
      }
    })
  );
