/* eslint-disable no-console */
import Container from '@webapp/components/ui/container';
import { DayHours } from '@webapp/components/ui/scheduler/types';
import { ListCalendar } from '@webapp/store/appointments/action-creators';
import { LIST_CALENDAR } from '@webapp/store/appointments/actions';
import { Appointment } from '@webapp/store/appointments/state';
import { IListLashista } from '@webapp/store/lashista/state';
import { useOpStore } from '@webapp/store/useOpStore';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import esLocale from 'date-fns/locale/es';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CalendarButtonGreenIcon from '../../components/ui//shared/icons/CalendarButtonGreenIcon';
import { Scheduler } from '../../components/ui/scheduler';
import LashistaCircleWhiteIcon from '../../components/ui/shared/icons/LashistaCircleWhiteIcon';
import { sortJS } from '../../utils/sortJS';

interface IWorker {
  idWorker: string;
  title: string;
  mobile: string | undefined;
  avatar: string;
  color: string;
}

interface IAppointments {
  id: string;
  title: string;
  start: Date;
  end: Date;
  idWorker: string;
  color: string | undefined;
  oid: string;
  status: string;
  status2: string;
  sid: string;
  categoryId: string;
  note: string;
  clientId: string;
  noteGen: string;
}

const getWorker = (item: IListLashista) => {
  return { idWorker: item.id, title: item.alias, mobile: item.phone, avatar: '', color: '' };
};

// const getNote = async (clientId: string) => {
//   const result = await notes.listNotes(clientId);
//   const notesList = result.items;
//   return notesList.length > 0 ? notesList[0]['note'] : '';
// };

const getAppointment = (item: Appointment) => {
  return {
    ...item,
    id: item.orderId,
    oid: item.id,
    title: item?.detailClient?.firstName + ' ' + item?.detailClient?.lastName + ' | ' + item?.name,
    start: new Date(item.dateInitial),
    end: new Date(item.dateEnd),
    idWorker: item.lashistaId,
    color: item.color,
    status: item.statusAppointment,
    status2: item.status,
    sid: item.serviceId,
    categoryId: item.categoryId,
    note: item.note,
    clientId: item.clientId,
    noteGen: '',
  } as IAppointments;
};

const Agenda = () => {
  const {
    lashista: { listLashista },
    appointments: { appointments },
    sucursal: { sucursal },
    dispatch,
  } = useOpStore((state) => state, [LIST_CALENDAR]);

  const { current } = useParams<{ current: string }>();

  const WH = sucursal?.workingHours;
  let WHT;

  switch (new Date().getDay()) {
    case 0:
      WHT = WH?.sunday;
      break;
    case 1:
      WHT = WH?.monday;
      break;
    case 2:
      WHT = WH?.tuesday;
      break;
    case 3:
      WHT = WH?.wednesday;
      break;
    case 4:
      WHT = WH?.thursday;
      break;
    case 5:
      WHT = WH?.friday;
      break;
    default:
      WHT = WH?.saturday;
  }

  const listAppointments = useMemo<IAppointments[]>(() => {
    return appointments.map(getAppointment).filter((a: any) => a.status != 'DELETED');
  }, [appointments]);

  const listLashistas = useMemo<IWorker[]>(() => {
    let listLashistaOrder = listLashista.map((v) => v);
    listLashistaOrder.push({
      alias: 'Lista de Espera',
      firstName: '',
      lastName: '',
      schedulePosition: 99,
      status: 'ACTIVE',
      tenantId: '0',
      id: 'AWAIT',
    });
    listLashistaOrder.push({
      alias: 'Canceladas',
      firstName: '',
      lastName: '',
      schedulePosition: 100,
      status: 'ACTIVE',
      tenantId: '0',
      id: 'CANCELED',
    });
    listLashistaOrder = sortJS(listLashistaOrder, 'schedulePosition', true);
    return listLashistaOrder.map(getWorker);
  }, [listLashista]);

  useEffect(() => {
    const hoy = format(new Date(), 'MM-dd-yyyy', { locale: es });

    dispatch(new ListCalendar(current ? current : hoy));
    const intervalId = setInterval(() => {
      dispatch(new ListCalendar(current ? current : hoy));
    }, 10000);

    return () => clearInterval(intervalId);
  }, [current]);

  // useEffect(() => {
  //   listAppointments.map((e) => {
  //     getNote(e.clientId).then((v) => {
  //       e.noteGen = v;
  //     });
  //     return e;
  //   });
  // }, [appointments]);

  return (
    <Container className="grid grid-cols-1 justify-center">
      <Scheduler
        GloContext={null}
        defaultIcon={<LashistaCircleWhiteIcon />}
        calendarIcon={<CalendarButtonGreenIcon />}
        height={0}
        events={listAppointments}
        resources={listLashistas}
        fields={[
          {
            name: 'idWorker',
            type: 'select',
            default: '',
            options: listLashistas.map((res) => {
              return {
                id: res.idWorker,
                text: `${res.title} (${res.mobile})`,
                value: res.idWorker,
              };
            }),
            config: { label: 'Lashista', required: true },
          },
        ]}
        resourceFields={{
          idField: 'idWorker',
          textField: 'title',
          subTextField: 'mobile',
          avatarField: 'avatar',
          colorField: 'color',
        }}
        resourceViewMode={'default'}
        locale={esLocale}
        day={{
          startHour: Number(WHT?.start.split(':')[0]) as DayHours | 8,
          startMinute: Number(WHT?.start.split(':')[1]) >= 30 ? 30 : 0,
          endHour: Number(WHT?.end.split(':')[0]) as DayHours | 18,
          endMinute: Number(WHT?.end.split(':')[1]) >= 30 ? 30 : 0,
          step: 30,
        }}
        selectedDate={new Date()}
      />
    </Container>
  );
};

export default Agenda;
