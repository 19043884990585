import { Dialog, Transition } from '@headlessui/react';
import { ButtonToPrintInvoice } from '@webapp/components/pagos/buttonToPrintInvoice';
import Input from '@webapp/components/ui/forms/input';
import { Fragment, FunctionComponent, useMemo, useRef, useState } from 'react';

import AppState from '@webapp/store/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';

interface IModalPayment {
  title?: string;
  onCancel?: any;
  onOk?: any;
}

const ModalPayment: FunctionComponent<IModalPayment> = (props) => {
  const cancelButton = useRef(null);
  const {
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const { title, onCancel, onOk } = props;
  const [printInvoiceCheck, setPrintInvoiceCheck] = useState<boolean>(false);

  const { open } = useMemo(() => {
    return {
      open: modalOpened?.['PAYMENT_MODAL']?.open ?? false,
    };
  }, [modalOpened?.['PAYMENT_MODAL']]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('PAYMENT_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <div className="bg-primary py-3 pl-9 flex justify-left">
                <Dialog.Title
                  as="span"
                  className="flex justify-between items-center w-full text-white text-xl md:text-2xl font-bold tracking-widest leading-tight"
                >
                  {title ?? 'Información'}
                </Dialog.Title>
              </div>
              <div className="py-3 md:py-5 px-4 md:px-8 flex flex-col mb-5">
                <div className="text-2xl font-bold mb-4">Desea finalizar el pago?</div>
                <Input
                  classnamecontainer="flex justify-center items-center"
                  classlabel="text-base font-normal tracking-[0.185em] leading-[20px] text-left text-black ml-[6px]"
                  classinput="py-0 px-0 rounded-[3px] border border-[#878A8A] text-primary w-4"
                  id="marketing"
                  name="marketing"
                  type="checkbox"
                  label="Desea imprimir el ticket?"
                  checked={printInvoiceCheck}
                  mode={'add'}
                  onChange={() => setPrintInvoiceCheck(!printInvoiceCheck)}
                />
              </div>
              <div className="flex justify-center gap-4 items-center mb-6 px-5">
                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    onCancel ? onCancel() : null;
                    dispatch(new CloseModal('PAYMENT_MODAL'));
                  }}
                >
                  Cancelar
                </button>

                {printInvoiceCheck ? (
                  <ButtonToPrintInvoice payOrderFunction={() => onOk()} />
                ) : (
                  <button
                    type="button"
                    className="flex justify-center items-center p-0 border bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                    onClick={() => {
                      onOk ? onOk() : null;
                      dispatch(new CloseModal('PAYMENT_MODAL'));
                    }}
                  >
                    Hecho
                  </button>
                )}
              </div>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalPayment;
