import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as calendar from '../../../sdk/calendar';
import { Actions } from '../../actions';
import { ListCalendar, ListCalendarError, ListCalendarSuccess } from '../action-creators';
import { LIST_CALENDAR } from '../actions';
import { Appointment } from '../state';

export const listAppointmentsEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListCalendar['type'], ListCalendar>(LIST_CALENDAR),
    mergeMap(async (action) => {
      try {
        const result = await calendar.listCalendar(action.search);
        const filterOnlyAppointment = result.items.filter(({ type }) => type === 'SERVICE') as Appointment[];

        return new ListCalendarSuccess(filterOnlyAppointment);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListCalendarError(err.message, err.description);
      }
    })
  );
