import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useEffect, useMemo, useRef } from 'react';

import { ListHistoryNoteCredit } from '@webapp/store/client/action-creators';
import AppState from '@webapp/store/state';
import { CloseModal, OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { formatFullDateTime } from '@webapp/utils/formatDate';
import { PlusGreenIcon } from '../shared/icons';

interface IModal {
  title: string;
  onCancel?: any;
  onOk?: any;
}

const ModalListNoteCredit: FunctionComponent<IModal> = (props) => {
  const cancelButton = useRef(null);
  const {
    client: { listHistoryNoteCredit, currentClient },
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const { title, onCancel, onOk } = props;

  const { open } = useMemo(() => {
    return {
      open: modalOpened?.['LIST_NOTE_CREDIT_MODAL']?.open ?? false,
    };
  }, [modalOpened?.['LIST_NOTE_CREDIT_MODAL']]);

  useEffect(() => {
    if (currentClient?.id) {
      dispatch(new ListHistoryNoteCredit(currentClient?.id || ''));
    }
  }, [currentClient]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('LIST_NOTE_CREDIT_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-darkGray rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <div className="bg-primary py-3 pl-9 flex justify-left">
                <Dialog.Title
                  as="span"
                  className="flex justify-between items-center w-full text-white text-xl md:text-2xl font-bold tracking-widest leading-tight"
                >
                  {title}
                </Dialog.Title>
              </div>
              <div className="py-3 md:py-5 px-4 md:px-8 flex flex-col mb-5">
                <div className="flex flex-col overflow-y-auto max-h-[600px]">
                  <table className="bg-darkGray table-auto w-full border-separate text-left">
                    <thead>
                      <tr className="bg-grey rounded-[3px] h-[33px]">
                        <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                          Nota
                        </th>
                        <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                          Monto
                        </th>
                        <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                          Creado por
                        </th>
                        <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                          Fecha de creación
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listHistoryNoteCredit &&
                        listHistoryNoteCredit.map((note, i) => (
                          <tr key={i}>
                            <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                              {note.note}
                            </td>
                            <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                              {note.amount}
                            </td>
                            <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                              {note.ejectBy}
                            </td>
                            <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-white">
                              {formatFullDateTime(new Date(note.createdAt).getTime())}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex justify-center gap-4 items-center mb-6 px-5">
                <button
                  type="button"
                  className="flex justify-center items-center p-0 border-2 bg-white rounded-md border-primary hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-primary font-normal leading-4 tracking-widest"
                  onClick={() => {
                    dispatch(new OpenModal('CREATE_NOTE_CREDIT_MODAL'));
                    dispatch(new CloseModal('LIST_NOTE_CREDIT_MODAL'));
                  }}
                >
                  <PlusGreenIcon /> Crear Nota de Credito
                </button>

                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    onCancel ? onCancel() : null;
                    dispatch(new CloseModal('LIST_NOTE_CREDIT_MODAL'));
                  }}
                >
                  Cancelar
                </button>

                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    onOk ? onOk() : null;
                    dispatch(new CloseModal('LIST_NOTE_CREDIT_MODAL'));
                  }}
                >
                  Hecho
                </button>
              </div>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalListNoteCredit;
