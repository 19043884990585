import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useMemo, useRef } from 'react';

import { generateId } from '@services/support/utils/generate-id';
import { CreateCashRegister } from '@webapp/store/cash-register/action-creators';
import AppState from '@webapp/store/state';
import { CloseModal, OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { getSubdomain } from '@webapp/utils/subDomain';

const ModalStepFinish: FunctionComponent = () => {
  const cancelButton = useRef(null);
  const {
    appointments: { appointments },
    cashRegister: { preCashRegister },
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state: AppState) => state);

  const { open, modalData } = useMemo(() => {
    return {
      open: modalOpened?.['STEP_FINISH_MODAL']?.open ?? false,
      modalData:
        (modalOpened?.['STEP_FINISH_MODAL']?.value as { noteForIncompleteAmount: string; newFund: number }) ?? {},
    };
  }, [modalOpened?.['STEP_FINISH_MODAL']]);

  const closeCashRegister = () => {
    const data = JSON.parse(JSON.stringify(preCashRegister));
    delete data.step;
    delete data.step2Accepted;
    if (
      appointments
        .filter((a) => new Date(a.dateInitial) < new Date())
        .some(
          (a) =>
            a.statusAppointment === 'INTIME' ||
            a.statusAppointment === 'SHOW' ||
            a.statusAppointment === 'CONFIRMED' ||
            a.statusAppointment === 'AWAIT'
        )
    ) {
      dispatch(new OpenModal('WARNING_STATUS_APPOINTMENT_MODAL'));
      return;
    }

    dispatch(
      new CreateCashRegister({
        cashRegisterClose: {
          amounts: {
            cash: preCashRegister?.amounts.cash as number,
            card: preCashRegister?.amounts.card as number,
            transfer: preCashRegister?.amounts.transfer as number,
          },
          amountsExpected: {
            cash: preCashRegister?.amountsExpected.cash as number,
            card: preCashRegister?.amountsExpected.card as number,
            transfer: preCashRegister?.amountsExpected.transfer as number,
          },
          id: generateId('registerCash'),
          noteForIncompleteAmount: modalData.noteForIncompleteAmount,
          isIncomplete: preCashRegister?.isIncomplete as boolean,
          createdAt: new Date(),
          createAt: new Date().toISOString(),
          updatedAt: new Date(),
        },
        cashBalance: {
          tenantId: getSubdomain(),
          currentAmount: modalData.newFund,
          status: 'OPEN',
          lastUsed: new Date().toISOString(),
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      })
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('STEP_FINISH_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-darkGray rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <div className="bg-primary py-3 pl-9 flex justify-left">
                <Dialog.Title
                  as="span"
                  className="w-full text-white text-[24px] font-bold leading-[30px] tracking-[0.185em]"
                >
                  Aviso
                </Dialog.Title>
              </div>
              <div className="py-3 md:py-5 px-4 md:px-8 flex flex-col mb-5">
                <span className="text-[22px] font-light text-white leading-[28px] tracking-[0.035em]">
                  ¿Está seguro que desea cerrar el asistente?
                </span>
              </div>
              <div className="flex justify-center gap-4 items-center mb-6 px-5">
                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    dispatch(new CloseModal('STEP_FINISH_MODAL'));
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    closeCashRegister();
                    dispatch(new CloseModal('STEP_FINISH_MODAL'));
                  }}
                >
                  Hecho
                </button>
              </div>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalStepFinish;
