import * as apiClient from '@services/business/client/catalog/api';
import * as apiHistory from '@services/business/history/catalog/api';
import * as apiReward from '@services/business/rewards/catalog/api';
import { algoliaIndexSearchFront } from '@services/business/search/platform/index-search';
import { CallApi, handleJSONResponse, parseCommonFields } from './common';
import { CallApi as CallApiV2 } from './common-v2';
import { dbStore } from './models/db';
const callAPI = CallApi('client');
const callAPIBalance = CallApiV2('finance');
const callAPIReward = CallApiV2('reward');
const callAPIHistoryClient = CallApiV2('history');

export const listClient = async (
  query?: string,
  nextToken?: string | number | null
): Promise<apiClient.listClient.Interface['response']> => {
  try {
    const { entities, nextToken: nextCurr } = await algoliaIndexSearchFront<'client'>({
      options: {
        entityName: 'client',
      },
      where: {
        query,
        page: nextToken as string,
      },
    });
    const body: apiClient.listClient.Interface['response'] = await parseCommonFields({
      items: entities,
      nextToken: nextCurr,
    });

    body.items.forEach(async (items) => {
      dbStore.createOrUpdateClient(items);
    });

    return body;
  } catch (error) {
    const items = await dbStore.client.toArray();

    const body: apiClient.listClient.Interface['response'] = await parseCommonFields({
      items: items,
      nextToken: '',
    });

    return body;
  }
};

export const createClient = async (
  client: apiClient.createdClient.Interface['body']
): Promise<apiClient.createdClient.Interface['response']> => {
  const response = await callAPI('', 'POST', client);
  const body: apiClient.createdClient.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const updateClient = async (
  client: apiClient.updatedClient.Interface['body'],
  clientId: string
): Promise<apiClient.updatedClient.Interface['response']> => {
  // WIP: error con el ID si se envía, debe ser arreglado
  const response = await callAPI(`/${clientId}`, 'PUT', { ...client, id: undefined });
  const body: apiClient.updatedClient.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const deleteClient = async (client: string): Promise<apiClient.deletedClient.Interface['response']> => {
  const response = await callAPI(`/${client}`, 'DELETE', client);
  const body: apiClient.deletedClient.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const getClient = async (clientId: string): Promise<apiClient.getClient.Interface['response']> => {
  const response = await callAPI(`/${clientId}`, 'GET', clientId);
  const body: apiClient.getClient.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const getBalanceHistoryClient = async (
  clientId: string
): Promise<apiClient.getHistoryCredit.Interface['response']> => {
  const response = await callAPIBalance<apiClient.getHistoryCredit.Interface>(
    { ...apiClient.getHistoryCredit.config, withCache: false },
    {
      queryParams: {
        clientId,
      },
    }
  );
  const body: apiClient.getHistoryCredit.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const getBalanceClient = async (clientId: string): Promise<apiClient.getBalanceCredit.Interface['response']> => {
  const response = await callAPIBalance<apiClient.getBalanceCredit.Interface>(
    { ...apiClient.getBalanceCredit.config, withCache: false },
    {
      queryParams: {
        clientId,
      },
    }
  );
  const body: apiClient.getBalanceCredit.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const createNoteCredit = async (
  clientId: string,
  noteCredit: apiClient.createCredit.Interface['body']
): Promise<apiClient.createCredit.Interface['response']> => {
  const response = await callAPIBalance<apiClient.createCredit.Interface>(
    { ...apiClient.createCredit.config },
    {
      body: noteCredit,
      queryParams: {
        clientId,
      },
    }
  );
  const body = await parseCommonFields(response);

  return body;
};

export const getRewardClient = async (clientId: string): Promise<apiReward.getClientRewards.Interface['response']> => {
  const response = await callAPIReward<apiReward.getClientRewards.Interface>(
    { ...apiReward.getClientRewards.config, withCache: false },
    {
      pathParams: {
        clientId,
      },
    }
  );
  const body: apiReward.getClientRewards.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const getHistoryAppointmentClient = async (
  bodyHistoryClient: apiHistory.historyClient.Interface['body']
): Promise<apiHistory.historyClient.Interface['response']> => {
  const response = await callAPIHistoryClient<apiHistory.historyClient.Interface>(
    { ...apiHistory.historyClient.config },
    {
      body: bodyHistoryClient,
    }
  );
  const body = await parseCommonFields(response);

  return body;
};
