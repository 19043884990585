import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as api from '../../../sdk/notes';
import { Actions } from '../../actions';
import {
  CreateNotes,
  CreateNotesError,
  CreateNotesSuccess,
  DeleteNotes,
  DeleteNotesError,
  DeleteNotesSuccess,
  GetLastNotes,
  GetLastNotesError,
  GetLastNotesSuccess,
  UpdateNotes,
  UpdateNotesError,
  UpdateNotesSuccess,
} from '../action-creators';
import { CREATE_NOTES, DELETE_NOTES, GET_LAST_NOTES, UPDATE_NOTES } from '../actions';

export const crudNotesEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      CreateNotes['type'] | UpdateNotes['type'] | DeleteNotes['type'] | GetLastNotes['type'],
      CreateNotes | UpdateNotes | DeleteNotes | GetLastNotes
    >(CREATE_NOTES, UPDATE_NOTES, DELETE_NOTES, GET_LAST_NOTES),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case GET_LAST_NOTES: {
            const data = await api.getLastNote(action.clientId);
            return new GetLastNotesSuccess(data);
          }
          case UPDATE_NOTES: {
            await api.updateNotes(action.note, action.noteId, action.note.clientId ?? '');
            return new UpdateNotesSuccess();
          }
          case CREATE_NOTES: {
            await api.createNotes(action.note);
            return new CreateNotesSuccess(action.note.clientId);
          }
          case DELETE_NOTES: {
            await api.deleteNotes(action.noteId, action.clientId);
            return new DeleteNotesSuccess();
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case GET_LAST_NOTES: {
            return new GetLastNotesError(err.message, err.description);
          }
          case UPDATE_NOTES: {
            return new UpdateNotesError(err.message, err.description);
          }
          case CREATE_NOTES: {
            return new CreateNotesError(err.message, err.description);
          }
          case DELETE_NOTES: {
            return new DeleteNotesError(err.message, err.description);
          }
        }
      }
    })
  );
