import { UpdateAppointment } from '@webapp/store/appointments/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { addMinutes, differenceInMinutes, isEqual } from 'date-fns';
import { DateTime } from 'luxon';
import { useEffect, useReducer } from 'react';
import { arraytizeFieldVal, getAvailableViews, getOneView } from '../../helpers/generals';
import { defaultProps, StateContext } from './stateContext';
import { stateReducer } from './stateReducer';

const initialState = (initial) => {
  const initialView = initial.view && initial[initial.view] ? initial.view : getOneView(initial);
  return {
    ...initial,
    view: initialView,
    dialog: false,
    mounted: false,
    selectedRange: undefined,
    fields: [...defaultProps.fields, ...(initial.fields || [])],
  };
};

const AppState = ({ initial, children }) => {
  const {
    GloContext,
    defaultIcon,
    calendarIcon,
    events,
    resources,
    resourceViewMode,
    month,
    week,
    day,
    fields,
    locale,
    direction,
    loading,
    onEventDrop,
  } = initial;
  const [state, dispatch] = useReducer(stateReducer, initialState(initial));

  const handleState = (value, name) => {
    dispatch({ type: 'set', payload: { name, value } });
  };

  const updateProps = (updatedProps) => {
    dispatch({ type: 'updateProps', payload: updatedProps });
  };

  const OpStore = useOpStore((state) => state);

  const dispatch2 = OpStore.dispatch;

  const updateAppointment = (event) => {
    if (event.finalizeAt) {
      return;
    }
    const service = OpStore.product.products.find((s) => s.id === event.sid);
    const dateCalendar = event.start.getTime();
    const dateAppointment = DateTime.fromISO(new Date(dateCalendar).toISOString()).toFormat('MM-dd-yyyy').valueOf();
    const dateInitial = DateTime.fromISO(new Date(dateCalendar).toISOString()).valueOf();
    const dateEnd = DateTime.fromISO(new Date(dateInitial).toISOString())
      .plus({
        second: service.duration || 0,
      })
      .valueOf();

    dispatch2(
      new UpdateAppointment(
        {
          dateAppointment,
          orderId: event.id,
          lashistaId: event.idWorker,
          dateInitial: dateCalendar,
          dateEnd,
          statusAppointment: event.status,
          serviceId: event.sid,
        },
        event.oid
      )
    );

    events.map((e) => {
      if (e.id == event.id && e.sid == event.sid) {
        e.idWorker = event.idWorker;
        e.start = event.start;
        e.end = event.end;
      }
      return e;
    });
  };

  useEffect(() => {
    if (state.mounted) {
      updateProps({
        GloContext,
        defaultIcon,
        calendarIcon,
        events,
        resources,
        resourceViewMode,
        month,
        week,
        day,
        fields,
        locale,
        direction,
        loading,
      });
    } else {
      handleState(true, 'mounted');
    }
    //eslint-disable-next-line
  }, [
    GloContext,
    defaultIcon,
    calendarIcon,
    events,
    resources,
    resourceViewMode,
    month,
    week,
    day,
    fields,
    locale,
    direction,
    loading,
  ]);

  const confirmEvent = (event, action) => {
    let updatedEvents;
    if (action === 'edit') {
      updatedEvents = state.events.map((e) => (e.id === event.id ? event : e));
      updateAppointment(event);
    } else {
      updatedEvents = [...state.events, event];
      handleState(updatedEvents, 'events');
    }
  };

  const getViews = () => getAvailableViews(state);

  const triggerDialog = (status, selected) => {
    dispatch({ type: 'triggerDialog', payload: { status, selected } });
  };
  const triggerLoading = (status) => {
    // Trigger if not out-sourced by props
    if (typeof loading === 'undefined') {
      dispatch({ type: 'triggerLoading', payload: status });
    }
  };
  const handleGotoDay = (day) => {
    const views = getViews();
    if (views.includes('day')) {
      handleState('day', 'view');
      handleState(day, 'selectedDate');
    } else if (views.includes('week')) {
      handleState('week', 'view');
      handleState(day, 'selectedDate');
    }
  };
  const onDrop = async (eventId, startTime, resKey, resVal) => {
    // Get dropped event
    const droppedEvent = state.events.find((e) => {
      if (typeof e.id === 'number') {
        return e.id === +eventId;
      }
      return e.id === eventId;
    });

    // Check if has resource and if is multiple
    const resField = state.fields.find((f) => f.name === resKey);
    const isMultiple = !!resField?.config?.multiple;
    let newResource = resVal;
    if (resField) {
      const eResource = droppedEvent[resKey];
      const currentRes = arraytizeFieldVal(resField, eResource, droppedEvent).value;
      if (isMultiple) {
        // if dropped on already owned resource
        if (currentRes.includes(resVal)) {
          // Omit if dropped on same time slot for multiple event
          if (isEqual(droppedEvent.start, startTime)) {
            return;
          }
          newResource = currentRes;
        } else {
          // if have multiple resource ? add other : move to other
          newResource = currentRes.length > 1 ? [...currentRes, resVal] : [resVal];
        }
      }
    }

    // Omit if dropped on same time slot for non multiple events
    if (isEqual(droppedEvent.start, startTime)) {
      if (!newResource || (!isMultiple && newResource === droppedEvent[resKey])) {
        return;
      }
    }

    // Update event time according to original duration & update resources/owners
    const diff = differenceInMinutes(droppedEvent.end, droppedEvent.start);
    const updatedEvent = {
      ...droppedEvent,
      start: startTime,
      end: addMinutes(startTime, diff),
      [resKey]: newResource || '',
    };

    // Local
    if (!onEventDrop || typeof onEventDrop !== 'function') {
      return confirmEvent(updatedEvent, 'edit');
    }
    // Remote
    try {
      triggerLoading(true);
      const _event = await onEventDrop(startTime, updatedEvent, droppedEvent);
      if (_event) {
        confirmEvent(_event, 'edit');
      }
    } finally {
      triggerLoading(false);
    }
  };

  return (
    <StateContext.Provider
      value={{
        ...state,
        handleState,
        getViews,
        triggerDialog,
        triggerLoading,
        handleGotoDay,
        confirmEvent,
        onDrop,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { AppState };
