import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore, Dispatch } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { Actions } from './actions';
import appointmentsEpic from './appointments/effects';
import appointmentsReducer from './appointments/reducer';
import setupAmplifyIntegration from './auth/amplify-integration';
import authEpic from './auth/effects';
import authReducer from './auth/reducer';
import cashRegisterEpic from './cash-register/effects';
import cashRegisterReducer from './cash-register/reducer';
import categoriesEpic from './categories/effects';
import categoriesReducer from './categories/reducer';
import clientEpic from './client/effects';
import clientReducer from './client/reducer';
import discountsReducer from './discounts/reducer';
import employeeEpic from './employee/effects';
import employeeReducer from './employee/reducer';
import lashistaEpic from './lashista/effects';
import lashistaReducer from './lashista/reducer';
import notesEpic from './notes/effects';
import notesReducer from './notes/reducer';
import productEpic from './products/effects';
import productsReducer from './products/reducer';
import reportsEpic from './reports/effects';
import reportsReducer from './reports/reducer';
import AppState from './state';
import sucursalReducer from './sucursal/reducer';
import uiEpic from './ui/effects';
import uiReducer from './ui/reducer';

const epicMiddleware = createEpicMiddleware<Actions, Actions, AppState, unknown>();

const classMiddleware = () => (next: Dispatch<Actions>) => (action: Actions) => next({ ...action });

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME,
});

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(classMiddleware, routerMiddleware(history), epicMiddleware)
)(createStore);

const store = createStoreWithMiddleware(
  combineReducers({
    router: connectRouter(history),
    ui: uiReducer,
    appointments: appointmentsReducer,
    auth: authReducer,
    client: clientReducer,
    lashista: lashistaReducer,
    product: productsReducer,
    discount: discountsReducer,
    employee: employeeReducer,
    reports: reportsReducer,
    categories: categoriesReducer,
    sucursal: sucursalReducer,
    cashRegister: cashRegisterReducer,
    notes: notesReducer,
  })
);

epicMiddleware.run(
  combineEpics(
    authEpic,
    uiEpic,
    appointmentsEpic,
    clientEpic,
    lashistaEpic,
    employeeEpic,
    reportsEpic,
    categoriesEpic,
    productEpic,
    cashRegisterEpic,
    notesEpic
  )
);

setupAmplifyIntegration(store.dispatch);

export default store;
