// import { Discount } from '@enterprise/store/discounts/state';

import * as apiReport from '@services/business/history/catalog/api/.';

export const normalizeDiscount = (
  data: apiReport.getHistoryOrder.Interface['response'],
  dataDiscount: any[]
): Array<{
  total: number | undefined;
  name: string | undefined;
  discountAmount: number | undefined;
  discountPercentage: number | undefined;
  discountType: string | undefined;
  id: string | undefined;
}> => {
  const response = data
    .filter(({ order }) => order.payment?.discountId && typeof order.payment.totalDiscount === 'number')
    .map((dataArray) => {
      const { payment } = dataArray.order;
      const [item] = dataArray.items;

      const getDiscounts = dataDiscount.find(({ id }) => id === payment?.discountId);
      let counter = 0;

      const total = data
        .filter(({ order }) => {
          if (
            order.payment?.discountId &&
            typeof order.payment.totalAmount === 'number' &&
            order.payment?.discountId === getDiscounts?.id
          ) {
            counter++;
            return order;
          }
        })
        .reduce((a, b) => a + (b.order.payment?.totalDiscount || 0), 0);

      return {
        total,
        categoryId: item.categoryId,
        name: getDiscounts?.name,
        discountAmount: getDiscounts?.amount ?? 0,
        discountPercentage: getDiscounts?.percentage ?? 0,
        discountType: getDiscounts?.type,
        id: getDiscounts?.id,
        quantity: counter,
      };
    });

  return response;
};
