import * as apiCR from '@services/business/cash-register/catalog/api';
import { CashRegister, CashRegisterBalance } from '@services/business/cash-register/entities';
import * as apiO from '@services/business/invoice/catalog/api';
import { getSubdomain } from '@webapp/utils/subDomain';
import { parseCommonFields } from './common';
import { CallApi as CallApiV2 } from './common-v2';
import { dbStore } from './models/db';

const callApi = CallApiV2('cash-register');
const callApiO = CallApiV2('orden');

export const listCashRegister = async (): Promise<apiCR.listCashRegister.Interface['response']> => {
  try {
    const response = await callApi<apiCR.listCashRegister.Interface>({
      ...apiCR.listCashRegister.config,
      withCache: false,
    });
    const body: apiCR.listCashRegister.Interface['response'] = await parseCommonFields(response);
    body.items.forEach(async (items) => {
      dbStore.createOrUpdateCashRegister(items);
    });

    return body;
  } catch (error) {
    const items = await dbStore.cashRegister.toArray();

    const body: apiCR.listCashRegister.Interface['response'] = await parseCommonFields({
      items: items,
      nextToken: '',
    });

    return body;
  }
};

export const listBalanceOfDay = async (date: string): Promise<apiO.listOrderPerDay.Interface['response']> => {
  const response = await callApiO<apiO.listOrderPerDay.Interface>(
    {
      ...apiO.listOrderPerDay.config,
      withCache: false,
    },
    { queryParams: { day: date } }
  );
  const body: apiO.listOrderPerDay.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const getCashRegister = async (): Promise<apiCR.getCashRegister.Interface['response']> => {
  try {
    const response = await callApi<apiCR.getCashRegister.Interface>({
      ...apiCR.getCashRegister.config,
      withCache: false,
    });
    const body: apiCR.getCashRegister.Interface['response'] = await parseCommonFields(response);
    dbStore.createOrUpdateCashRegisterBalance(body);
    return body;
  } catch (error) {
    const data = (await dbStore.cashRegisterBalance.get(getSubdomain())) as CashRegisterBalance;
    return data;
  }
};

export const createCashRegisterClose = async (
  client: apiCR.createCashRegister.Interface['body']
): Promise<apiCR.createCashRegister.Interface['response']> => {
  await dbStore.cashRegister.add({ ...client.cashRegisterClose, startWeb: new Date().getTime() });
  await dbStore.createOrUpdateCashRegisterBalance({ ...client.cashBalance });
  try {
    const response = await callApi<apiCR.createCashRegister.Interface>(
      { ...apiCR.createCashRegister.config, withCache: false },
      {
        body: {
          cashRegisterClose: client.cashRegisterClose,
          cashBalance: client.cashBalance,
        },
      }
    );
    const body: apiCR.createCashRegister.Interface['response'] = await parseCommonFields(response);

    return body;
  } catch (error) {
    return { cashRegister: client.cashRegisterClose as CashRegister, cashBalance: client.cashBalance };
  }
};

export const updateCashRegisterBalance = async (
  balance: apiCR.updateBalance.Interface['body']
): Promise<apiCR.updateBalance.Interface['response']> => {
  // await dbStore.cashRegister.add({ ...client.cashRegisterClose, startWeb: new Date().getTime() });
  // await dbStore.createOrUpdateCashRegisterBalance({ ...client.cashBalance });
  // try {
  const response = await callApi<apiCR.updateBalance.Interface>(
    { ...apiCR.updateBalance.config, withCache: false },
    {
      body: balance,
    }
  );
  const body: apiCR.updateBalance.Interface['response'] = await parseCommonFields(response);

  return body;
  // } catch (error) {
  //   return { cashRegister: client.cashRegisterClose as CashRegister, cashBalance: client.cashBalance };
  // }
};
