import Loading from '@webapp/components/ui/preloader/Loading';
import { LoginUser } from '@webapp/store/auth/action-creators';
import { LOGIN_USER } from '@webapp/store/auth/actions';
import { useOpStore } from '@webapp/store/useOpStore';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface SignInProps {
  className?: string;
}

const SignIn: FunctionComponent<SignInProps> = ({ className }) => {
  const { loading } = useOpStore((state) => state, [LOGIN_USER]);

  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const email = data.get('email')?.toString();
    const password = data.get('password')?.toString();

    if (!email || !password) {
      return;
    }

    dispatch(new LoginUser({ email, password }));
  };

  const forgotPass = () => {
    history.push('/forgot-password');
  };

  return (
    <div className={className}>
      <div className="bg-loginPage w-[649px] h-[698px] flex items-center flex-col rounded-[20px] shadow-[0_2px_26px_-2px_rgba(0,0,0,0.1)]">
        <span className="text-4xl leading-[43px] tracking-[0.08em] font-bold text-white mt-[46px]">Iniciar sesión</span>
        <form onSubmit={handleSubmit} className="w-full gap-[31px] flex flex-col items-center mt-[110px] mb-[254px]">
          <input
            name="email"
            type="text"
            placeholder="Usuario"
            required
            className="text-base leading-[19px] font-medium tracking-[0.08em] text-[#9F9F9F] placeholder:text-[#9F9F9F] rounded-[15px] shadow-[0_2px_26px_-2px_rgba(0,0,0,0.1)] w-full md:w-10/12"
          />
          <input
            name="password"
            type="password"
            placeholder="password"
            required
            className="text-base leading-[19px] font-medium tracking-[0.08em] text-[#9F9F9F] placeholder:text-[#9F9F9F] rounded-[15px] shadow-[0_2px_26px_-2px_rgba(0,0,0,0.1)] w-full md:w-10/12"
          />
          <span
            className="text-[18px] leading-[22px] font-bold tracking-[0.08em] text-primary cursor-pointer"
            onClick={() => forgotPass()}
          >
            ¿Olvidaste tu contraseña?
          </span>
          <button type="submit" className="bg-primary px-[20px] py-[10px] w-[169px]">
            <span className="text-[18px] leading-[22px] tracking-[0.08em] font-medium text-white">Iniciar sesión</span>
          </button>
          {loading[LOGIN_USER] && <Loading />}
        </form>
      </div>
    </div>
  );
};

export default SignIn;
