import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as notes from '../../../sdk/notes';
import { Actions } from '../../actions';
import { CreateNotesSuccess, ListNotes, ListNotesError, ListNotesSuccess } from '../action-creators';
import { CREATE_NOTES_SUCCESS, LIST_NOTES } from '../actions';

export const listNotesEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListNotes['type'] | CreateNotesSuccess['type'], ListNotes | CreateNotesSuccess>(
      LIST_NOTES,
      CREATE_NOTES_SUCCESS
    ),
    mergeMap(async (action) => {
      try {
        const result = await notes.listNotes(action.clientId);
        return new ListNotesSuccess(result.items);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListNotesError(err.message, err.description);
      }
    })
  );
