import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import AppState from '../../state';
import { crudAppointmentEpic } from './calendar';
import { getOrden } from './get-orden';
import { getOrdenItem } from './get-orden-item';
import { listAppointmentEpic } from './list-appointment';
import { listAppointmentsEpic } from './list-calendar';
import { newOrderAppointmentsEpic } from './new-order';

const appointmentsEpic = combineEpics<Actions, Actions, AppState>(
  listAppointmentsEpic,
  crudAppointmentEpic,

  getOrden,
  getOrdenItem,
  newOrderAppointmentsEpic,
  listAppointmentEpic
);

export default appointmentsEpic;
