import { rounded } from '@webapp/utils/validateNumber';
import { CashRegisterActions } from './action-creators';
import * as actions from './actions';
import { cashRegisterInitial } from './initial-state';
import { CashRegisterState, PreCashRegister, SalesOfDay } from './state';

const cashRegisterReducer = (
  state: CashRegisterState = cashRegisterInitial,
  action: CashRegisterActions
): CashRegisterState => {
  switch (action.type) {
    case actions.LIST_CASH_REGISTER_SUCCESS: {
      const totalCash: number[] = [];
      const totalCard: number[] = [];
      const totalTransfer: number[] = [];

      action.item?.forEach((o) => {
        const cash = o.payment?.methods.find((method: any) => method.type === 'CASH')?.amount;
        const card = o.payment?.methods.find((method: any) => method.type === 'CARD')?.amount;
        const transference = o.payment?.methods.find((method: any) => method.type === 'TRANSFER')?.amount;

        totalCash.push(cash ?? 0);
        totalCard.push(card ?? 0);
        totalTransfer.push(transference ?? 0);
      });

      const cash = totalCash.length > 0 ? totalCash.reduce((a: number, b: number) => a + b) : 0;
      const card = totalCard.length > 0 ? totalCard.reduce((a: number, b: number) => a + b) : 0;
      const transference = totalTransfer.length > 0 ? totalTransfer.reduce((a: number, b: number) => a + b) : 0;

      const cashRegisterList = [
        {
          type: 'TRANSFER',
          amount: rounded(transference),
        },
        { type: 'CARD', amount: rounded(card) },
        { type: 'CASH', amount: rounded(cash) },
      ] as SalesOfDay[];

      return {
        ...state,
        cashRegisterList: cashRegisterList,
        preCashRegister: {
          ...(state.preCashRegister as PreCashRegister),
          step: state.preCashRegister?.step ? state.preCashRegister.step : 1,
          amountsExpected: {
            cash: cashRegisterList.find((crl) => crl.type === 'CASH')?.amount ?? 0,
            card: cashRegisterList.find((crl) => crl.type === 'CARD')?.amount ?? 0,
            transfer: cashRegisterList.find((crl) => crl.type === 'TRANSFER')?.amount ?? 0,
          },
        },
      };
    }
    case actions.LIST_CASH_REGISTER_TRANSACTIONS_SUCCESS: {
      const data: any = [];

      action.item?.forEach((o) => {
        data.push(
          o.payment?.methods
            .map((method) => {
              if (method.amount > 0) {
                return {
                  clientName: `${o.detailClient?.firstName} ${o.detailClient?.lastName}`,
                  paymentMethodType: method.type,
                  amount: method.amount,
                };
              }
            })
            .filter((d) => d !== undefined)
        );
      });

      return {
        ...state,
        cashRegisterTransactions: data.flat(),
      };
    }
    case actions.GET_CASH_REGISTER_SUCCESS: {
      return {
        ...state,
        cashRegister: action.item,
      };
    }
    case actions.UPDATE_CASH_REGISTER: {
      return {
        ...state,
        cashRegister: action.data,
      };
    }
    case actions.CREATE_CASH_REGISTER: {
      return {
        ...state,
        cashRegister: action.data.cashBalance,
      };
    }
    case actions.CREATE_PRE_CASH_REGISTER: {
      return {
        ...state,
        preCashRegister:
          action.register !== undefined
            ? {
                ...state.preCashRegister,
                ...action.register,
              }
            : undefined,
      };
    }
    case actions.GET_SALES_OF_DAY: {
      return {
        ...state,
        salesOfDay: action.data.map((sod) => {
          return { ...sod, amount: rounded(sod.amount) };
        }),
      };
    }

    default:
      return state;
  }
};

export default cashRegisterReducer;
