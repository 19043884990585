import { Finance } from '@services/business/client/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';

export type Interface = ApiInterface<{
  response: Omit<Finance, 'createdAt' | 'updatedAt'>;
  pathParams: EmptyObject;
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: { clientId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-get-balance-client',
  method: 'GET',
  basePath: 'finance',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
