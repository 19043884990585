import Agenda from '@webapp/pages/agenda/Agenda';
import ForgotPasswordPage from '@webapp/pages/auth/forgot-password';
import SignInPage from '@webapp/pages/auth/sign-in';
import ClientPage from '@webapp/pages/clientes';
import FichaClientPage from '@webapp/pages/clientes/ficha';
import FichaEmployeePage from '@webapp/pages/employees/ficha';
import LLegadas from '@webapp/pages/llegadas';
import Pagos from '@webapp/pages/pagos';
import CashRegister from '@webapp/pages/pagos/caja';
import cashRegisterMovements from '@webapp/pages/pagos/cashRegisterMovements';
import ServicePage from '@webapp/pages/servicios';
import { lazy } from 'react';

const Employee = lazy(() => import('@webapp/pages/employees'));
const InventoryPage = lazy(() => import('@webapp/pages/inventario'));
const LashistasPage = lazy(() => import('@webapp/pages/lashistas'));
const FichaLashistaPage = lazy(() => import('@webapp/pages/lashistas/ficha'));
const ClientHistoryPage = lazy(() => import('@webapp/pages/clientes/history'));
const Privacy = lazy(() => import('@webapp/pages/privacy-terms'));
const AnnualSaleReport = lazy(() => import('@webapp/pages/reportes/AnnualSale'));
const DiscountPage = lazy(() => import('@webapp/pages/reportes/Discount'));
const FutureAppointments = lazy(() => import('@webapp/pages/reportes/FutureAppointments'));
const HistorialPage = lazy(() => import('@webapp/pages/reportes/history-sales'));
const NewClientReport = lazy(() => import('@webapp/pages/reportes/NewClient'));
const NoReturnClientReport = lazy(() => import('@webapp/pages/reportes/NoReturnClient'));
const Reportes = lazy(() => import('@webapp/pages/reportes/Pagos'));
const PerformanceLashista = lazy(() => import('@webapp/pages/reportes/PerformanceLashista'));
const ServiceProductReport = lazy(() => import('@webapp/pages/reportes/product-service'));
const SalesReport = lazy(() => import('@webapp/pages/reportes/Sales'));
const SchedulerReport = lazy(() => import('@webapp/pages/reportes/scheduler'));

interface Route {
  path: string;
  component: any | React.FunctionComponent<unknown> | React.ComponentClass<unknown, unknown>;
}

const Routes: Route[] = [
  {
    path: '/dashboard/:current?',
    component: Agenda,
  },
  {
    path: '/iniciar',
    component: SignInPage,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path: '/llegadas',
    component: LLegadas,
  },
  {
    path: '/clientes/ficha',
    component: FichaClientPage,
  },
  {
    path: '/clientes/historial',
    component: ClientHistoryPage,
  },
  {
    path: '/clientes/:lashistaId?/:start?/:statusAppointment?',
    component: ClientPage,
  },
  {
    path: '/servicios/:orderItemId?',
    component: ServicePage,
  },
  {
    path: '/lashistas',
    component: LashistasPage,
  },
  {
    path: '/lashistas/ficha',
    component: FichaLashistaPage,
  },
  {
    path: '/pagos/:orderId?',
    component: Pagos,
  },
  {
    path: '/caja',
    component: CashRegister,
  },
  {
    path: '/movimientos',
    component: cashRegisterMovements,
  },
  {
    path: '/reportes',
    component: Reportes,
  },
  {
    path: '/privacy-policy',
    component: Privacy,
  },
  {
    path: '/employees',
    component: Employee,
  },
  {
    path: '/employees/ficha',
    component: FichaEmployeePage,
  },
  {
    path: '/reportes-producto-servicio',
    component: ServiceProductReport,
  },
  {
    path: '/reportes-historial-ventas',
    component: HistorialPage,
  },
  {
    path: '/reportes-descuentos',
    component: DiscountPage,
  },
  {
    path: '/reportes-ventas',
    component: SalesReport,
  },
  {
    path: '/reportes-citas',
    component: SchedulerReport,
  },
  {
    path: '/reportes-clientes-nuevos',
    component: NewClientReport,
  },
  {
    path: '/reportes-clientes-no-regreso',
    component: NoReturnClientReport,
  },
  {
    path: '/reportes-anual',
    component: AnnualSaleReport,
  },
  {
    path: '/reportes-citas-futuras',
    component: FutureAppointments,
  },
  {
    path: '/reportes-rendimiento',
    component: PerformanceLashista,
  },
  {
    path: '/inventory',
    component: InventoryPage,
  },
];

export default Routes;
