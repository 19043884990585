import { api } from '@services/business/note-client/catalog';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';
import { Notes } from './state';

export class ListNotes implements LoadingAction {
  readonly type = actions.LIST_NOTES;
  readonly isLoading = true;
  constructor(public clientId: string) {}
}

export class ListNotesSuccess implements LoadingAction {
  readonly type = actions.LIST_NOTES_SUCCESS;
  readonly isLoading = false;

  constructor(public items: Notes[]) {}
}

export class ListNotesError implements ErrorAction {
  readonly type = actions.LIST_NOTES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateNotes implements LoadingAction {
  readonly type = actions.CREATE_NOTES;
  readonly isLoading = true;
  constructor(public note: api.createNoteClient.Interface['body']) {}
}

export class CreateNotesSuccess implements LoadingAction {
  readonly type = actions.CREATE_NOTES_SUCCESS;
  readonly isLoading = false;
  constructor(public clientId: string) {}
}

export class CreateNotesError implements ErrorAction {
  readonly type = actions.CREATE_NOTES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetLastNotes implements LoadingAction {
  readonly type = actions.GET_LAST_NOTES;
  readonly isLoading = true;
  constructor(public clientId: string) {}
}

export class GetLastNotesSuccess implements LoadingAction {
  readonly type = actions.GET_LAST_NOTES_SUCCESS;
  readonly isLoading = false;
  constructor(public data: api.getNoteClient.Interface['response']) {}
}

export class GetLastNotesError implements ErrorAction {
  readonly type = actions.GET_LAST_NOTES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateNotes implements LoadingAction {
  readonly type = actions.UPDATE_NOTES;
  readonly isLoading = true;
  constructor(public note: api.updateNoteClient.Interface['body'], public noteId: string) {}
}

export class UpdateNotesSuccess implements LoadingAction {
  readonly type = actions.UPDATE_NOTES_SUCCESS;
  readonly isLoading = false;
}

export class UpdateNotesError implements ErrorAction {
  readonly type = actions.UPDATE_NOTES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteNotes implements LoadingAction {
  readonly type = actions.DELETE_NOTES;
  readonly isLoading = true;
  constructor(public noteId: string, public clientId: string) {}
}

export class DeleteNotesSuccess implements LoadingAction {
  readonly type = actions.DELETE_NOTES_SUCCESS;
  readonly isLoading = false;
}

export class DeleteNotesError implements ErrorAction {
  readonly type = actions.DELETE_NOTES_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type NotesActions =
  | ListNotesSuccess
  | ListNotesError
  | ListNotes
  | GetLastNotesSuccess
  | GetLastNotesError
  | GetLastNotes
  | UpdateNotesSuccess
  | UpdateNotesError
  | UpdateNotes
  | DeleteNotes
  | DeleteNotesSuccess
  | DeleteNotesError;
