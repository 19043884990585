import * as Api from '@webapp/sdk/cash-register';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Actions } from '../../actions';
import {
  CreateCashRegister,
  CreateCashRegisterError,
  CreateCashRegisterSuccess,
  GetCashRegister,
  GetCashRegisterError,
  GetCashRegisterSuccess,
  UpdateCashRegister,
  UpdateCashRegisterError,
  UpdateCashRegisterSuccess,
} from '../action-creators';
import { CREATE_CASH_REGISTER, GET_CASH_REGISTER, UPDATE_CASH_REGISTER } from '../actions';

export const crudCashRegisterEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      CreateCashRegister['type'] | UpdateCashRegister['type'] | GetCashRegister['type'],
      CreateCashRegister | UpdateCashRegister | GetCashRegister
    >(CREATE_CASH_REGISTER, UPDATE_CASH_REGISTER, GET_CASH_REGISTER),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case UPDATE_CASH_REGISTER: {
            await Api.updateCashRegisterBalance(action.data);
            return new UpdateCashRegisterSuccess();
          }
          case CREATE_CASH_REGISTER: {
            await Api.createCashRegisterClose(action.data);
            return new CreateCashRegisterSuccess();
          }
          case GET_CASH_REGISTER: {
            const data = await Api.getCashRegister();
            return new GetCashRegisterSuccess(data);
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case UPDATE_CASH_REGISTER: {
            return new UpdateCashRegisterError(err.message, err.description);
          }
          case CREATE_CASH_REGISTER: {
            return new CreateCashRegisterError(err.message, err.description);
          }
          case GET_CASH_REGISTER: {
            return new GetCashRegisterError(err.message, err.description);
          }
        }
      }
    })
  );
