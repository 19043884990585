import { getToken } from '@webapp/sdk/auth';
import { dbStore } from '@webapp/sdk/models/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect } from 'react';
import { instanteNotCache } from '../sdk/common-v2';
import { getSubdomain } from './subDomain';
import { useInternet } from './useInternet';

export const useErrorQueue = () => {
  const online = useInternet();
  const queue = useLiveQuery(() => dbStore.queueError.toArray());
  const delay = () => new Promise((resolve) => setTimeout(resolve, 300));

  const requestApi = async (data: { id: number; type: 'AXIOS' | 'FETCH'; data: string }) => {
    await delay();
    const token = await getToken();

    try {
      if (data.type === 'FETCH') {
        const { url, params: paramsBody } = JSON.parse(data.data);
        const headers = new Headers();

        if (token) {
          headers.append('Authorization', token);
        }

        headers.append('Content-Type', 'application/json');
        headers.append('Mlashes-Store', getSubdomain());

        const params: RequestInit = { ...paramsBody, headers };

        await fetch(url, params);
      } else {
        const bodyAxios = JSON.parse(data.data);

        await instanteNotCache({
          headers: {
            'Content-Type': 'application/json',
            'Mlashes-Store': getSubdomain(),
            Authorization: token as string,
            'max-age': '0',
          },
          ...bodyAxios,
        });
      }
    } catch (error) {
      // nothing
    }
    await dbStore.queueError.delete(data.id as unknown as string);
  };

  useEffect(() => {
    if (online && queue?.length) {
      requestApi(queue[0]);
    }
  }, [online, queue]);
};
