import { WorkingHours } from '@services/business/sucursal/entities/sucursal';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';

export type Interface = ApiInterface<{
  response: { alias: string; date: string; workingHours: WorkingHours; utc: string };
  pathParams: { alias: string };
  body: null;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-check-sucursal',
  method: 'GET',
  basePath: 'sucursal',
  path: '/check/{alias}',
  tablePermission: 'readWrite',
  isPublic: true,
  skipValidation: true,
};
