import { SucursalActions } from './action-creators';
import * as actions from './actions';
import { initialState } from './initial-state';
import { SucursalState } from './state';

const sucursalReducer = (state: SucursalState = initialState, action: SucursalActions): SucursalState => {
  switch (action.type) {
    case actions.GET_SUCURSAL: {
      return {
        ...state,
        sucursal: action.items,
      };
    }

    default:
      return state;
  }
};

export default sucursalReducer;
