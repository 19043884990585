import { differenceInMinutes, setHours, setMinutes } from 'date-fns';
import { Fragment } from 'react';
import { BORDER_HEIGHT } from '../../helpers/constants';
import { traversCrossingEvents } from '../../helpers/generals';
import { ProcessedEvent } from '../../types';
import EventItem from './EventItem';

interface TodayEventsProps {
  todayEvents: ProcessedEvent[];
  today: Date;
  startHour: number;
  startMinute: number;
  step: number;
  minuteHeight: number;
  direction: 'rtl' | 'ltr';
}
const TodayEvents = ({
  todayEvents,
  today,
  startHour,
  startMinute,
  step,
  minuteHeight,
  direction,
}: TodayEventsProps) => {
  const crossingIds: Array<number | string> = [];

  minuteHeight = 0.99;

  return (
    <Fragment>
      {todayEvents.map((event, i) => {
        const height = differenceInMinutes(event.end, event.start) * minuteHeight;
        const minutesFromTop = differenceInMinutes(event.start, setHours(setMinutes(today, startMinute), startHour));
        const topSpace = minutesFromTop * (minuteHeight - 0.02); //+ headerHeight;

        const slotsFromTop = minutesFromTop / step;

        const borderFactor = slotsFromTop * BORDER_HEIGHT;
        const top = topSpace + borderFactor;

        const crossingEvents = traversCrossingEvents(todayEvents, event);
        const alreadyRendered = crossingEvents.filter((e) => crossingIds.includes(e.id));
        crossingIds.push(event.id);

        return (
          <div
            key={event.id + '' + i}
            className="rs__event__item"
            style={{
              height,
              top: top,
              width: crossingEvents.length > 0 ? '50%' : '100%',
              [direction === 'rtl' ? 'right' : 'left']:
                alreadyRendered.length > 0 ? `calc(100%/${alreadyRendered.length + 1})` : '',
            }}
          >
            <EventItem event={event} height={height} hasPrev={undefined} hasNext={undefined} />
          </div>
        );
      })}
    </Fragment>
  );
};

export default TodayEvents;
