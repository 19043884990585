import LlegadasComponent from '@webapp/components/llegadas';
import ModalNote from '@webapp/components/llegadas/modal-note';
import Container from '@webapp/components/ui/container';
import { ListCalendar } from '@webapp/store/appointments/action-creators';
import { LIST_CALENDAR } from '@webapp/store/appointments/actions';
import { useOpStore } from '@webapp/store/useOpStore';
import { addDays, format } from 'date-fns';
import { FunctionComponent, useEffect, useState } from 'react';

const LLegadas: FunctionComponent = () => {
  const {
    appointments: { appointments },
    product: { categories },
    lashista,
    dispatch,
    loading,
  } = useOpStore((state) => state, [LIST_CALENDAR]);
  const [date, setDate] = useState(format(new Date(), 'MM-dd-yyyy'));
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const handleDate = (value: number) => {
    if (!selectedDate) {
      const newDate = addDays(new Date(), value);
      setSelectedDate(newDate);

      const formattedDate = format(newDate, 'MM-dd-yyyy');
      setDate(formattedDate);
    }

    const newDate = addDays(selectedDate ?? new Date(), value);

    setSelectedDate(newDate);

    const formattedDate = format(newDate, 'MM-dd-yyyy');
    setDate(formattedDate);
  };

  const resetCounter = () => {
    setDate(format(new Date(), 'MM-dd-yyyy'));
    setSelectedDate(new Date());
  };

  useEffect(() => {
    dispatch(new ListCalendar(date));
  }, [date]);

  return (
    <Container className="flex flex-col">
      <ModalNote />
      <LlegadasComponent
        dateSelected={date}
        appointments={appointments.filter(
          (appointment) => appointment.dateAppointment === date && appointment.statusAppointment !== 'DELETED'
        )}
        handleDate={handleDate}
        resetCounter={resetCounter}
        loading={loading[LIST_CALENDAR]}
        lashistas={lashista.listLashista}
        categories={categories}
      />
    </Container>
  );
};

export default LLegadas;
