import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConnectedRouter } from 'connected-react-router';
import esLocale from 'date-fns/locale/es';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import App from './App';
import MenuLayout from './components/menu';
import './index.css';
import reportWebVitals from './reportWebVitals';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store, { history } from './store';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    type: 'dark',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <LocalizationProvider adapterLocale={esLocale} dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <LocalizeProvider>
              <ThemeProvider theme={theme}>
                <MenuLayout>
                  <App />
                </MenuLayout>
              </ThemeProvider>
            </LocalizeProvider>
          </ConnectedRouter>
        </Provider>
      </LocalizationProvider>
    </Suspense>
  </React.StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

reportWebVitals();

serviceWorkerRegistration.register();
