import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as reports from '../../../sdk/reportsClient';
import { Actions } from '../../actions';
import { ListReportsClient, ListReportsClientError, ListReportsClientSuccess } from '../action-creators';
import { LIST_REPORTS_CLIENT } from '../actions';

export const listReportsClientEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListReportsClient['type'], ListReportsClient>(LIST_REPORTS_CLIENT),
    mergeMap(async (action) => {
      try {
        const result = await reports.historyByClientId(action.clientId);
        return new ListReportsClientSuccess(result);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListReportsClientError(err.message, err.description);
      }
    })
  );
