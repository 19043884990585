import * as actions from './actions';
import { SucursalResponse } from './state';

export class GetSucursal {
  readonly type = actions.GET_SUCURSAL;

  constructor(public items: SucursalResponse) {}
}

export type SucursalActions = GetSucursal;
