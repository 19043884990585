export const LIST_NOTES = '[Notes] List Notes';
export const LIST_NOTES_SUCCESS = '[Notes] List Notes Success';
export const LIST_NOTES_ERROR = '[Notes] List Notes Error';

export const GET_LAST_NOTES = '[Notes] Get Last Notes';
export const GET_LAST_NOTES_SUCCESS = '[Notes] Get Last Notes Success';
export const GET_LAST_NOTES_ERROR = '[Notes] Get Last Notes Error';

export const CREATE_NOTES = '[Notes] Create Notes';
export const CREATE_NOTES_SUCCESS = '[Notes] Create Notes Success';
export const CREATE_NOTES_ERROR = '[Notes] Create Notes Error';

export const UPDATE_NOTES = '[Notes] Update Notes';
export const UPDATE_NOTES_SUCCESS = '[Notes] Update Notes Success';
export const UPDATE_NOTES_ERROR = '[Notes] Update Notes Error';

export const DELETE_NOTES = '[Notes] Delete Notes';
export const DELETE_NOTES_SUCCESS = '[Notes] Delete Notes Success';
export const DELETE_NOTES_ERROR = '[Notes] Delete Notes Error';
