import * as actions from './actions';
import { Discount } from './state';

export class ListDiscount {
  readonly type = actions.LIST_DISCOUNT;

  constructor(public items: Discount[]) {}
}

export type DiscountsActions = ListDiscount;
