import { classNames } from '@webapp/configuration/classNames';
import { Client } from '@webapp/store/client/state';

import { FunctionComponent, useCallback } from 'react';
import { DangerExclamationIcon } from '../ui/shared/icons';

// store state
import { GetOrden } from '@webapp/store/appointments/action-creators';
import { Appointment } from '@webapp/store/appointments/state';
import { OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { formatDateTime, formatFullDateToView } from '@webapp/utils/formatDate';

interface ListClientProps {
  client: Client | undefined;
  appointments?:
    | {
        pastAppointment: Appointment[];
        futureAppointment: Appointment[];
        noShow: number;
        show: number;
        canceled: number;
      }
    | undefined;
  rewardClient?: number;
  balanceClient?: number;
}

const ListClientDetailComponent: FunctionComponent<ListClientProps> = ({
  client,
  appointments,
  rewardClient,
  balanceClient,
}) => {
  const { dispatch } = useOpStore((state) => state);

  const diseases = [
    { id: 'allergiesCheck', name: 'Alergias' },
    { id: 'asthmaCheck', name: 'Asma' },
    { id: 'thyroidismCheck', name: 'Tiroidismo' },
    { id: 'epilepsyCheck', name: 'Epilepsia' },
    { id: 'opticalSurgeryCheck', name: 'Cirugía óptica' },
    { id: 'sensibleSkinCheck', name: 'Piel sensible' },
    { id: 'cancerCheck', name: 'Cáncer' },
    { id: 'claustrophobiaCheck', name: 'Claustrofobia' },
    { id: 'skinInfectionCheck', name: 'Infección en la piel' },
    { id: 'ocularInfectionCheck', name: 'Infección ocular' },
    { id: 'contactLensCheck', name: 'Lentes de contacto' },
    { id: 'alopeciaCheck', name: 'Alopecia' },
  ];

  const getAppointmentNote = useCallback(
    (theAppointment: Appointment | undefined) => {
      if (theAppointment === undefined) {
        return;
      } else {
        dispatch(new GetOrden(theAppointment.orderId));
        dispatch(new OpenModal('APPOINTMENT_CLIENT_MODAL', theAppointment));
      }
    },
    [client]
  );

  /**
   * return the label of the disease
   * @param value
   * @returns string
   */
  const isInArray = (value: string) => {
    return diseases.find((d) => d.id == value)?.name;
  };

  return (
    <>
      {/* start visits */}
      <div className="flex flex-col gap-2 justify-between mb-[15px]">
        <div className="flex items-center gap-3 justify-between p-[5px] bg-veryDarkGray rounded-[3px]">
          <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white">Asistencias</span>
          <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] text-white">
            {appointments?.show}
          </span>
        </div>
        <div className="flex items-center gap-3 justify-between p-[5px] bg-veryDarkGray rounded-[3px]">
          <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white">Inasistencias</span>
          <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] text-white">
            {appointments?.noShow}
          </span>
        </div>
        <div className="flex items-center gap-3 justify-between p-[5px] bg-veryDarkGray rounded-[3px]">
          <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white">Cancelados</span>
          <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] text-white">
            {appointments?.canceled}
          </span>
        </div>
      </div>
      {/* end visits */}
      {/* start expenses */}
      <div className="flex flex-col py-[11px] px-[14px] rounded-[3px] border border-grey2 mb-[10px] ">
        <div className="flex justify-between">
          <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white">
            Pago por adelantado
          </span>
          <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] text-white">{balanceClient}</span>
        </div>
      </div>

      <div className={classNames(client?.health?.allergies ? 'flex flex-col' : 'hidden', 'mb-4')}>
        {client?.health?.allergies &&
          client?.health?.allergies?.map((alergia: any, i: any) => (
            <div key={i} className="flex mb-2">
              <DangerExclamationIcon />
              <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] text-red ml-[11px]">
                {isInArray(alergia)}
              </span>
            </div>
          ))}
      </div>
      <div className="flex flex-col mb-[20px]">
        <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] mb-[9px] text-white">
          Servicios acumulados (tickets)
        </span>
        <div className="flex flex-col rounded-[3px] border border-grey2 py-[11px] px-[14px] overflow-y-auto max-h-[172px]">
          {rewardClient ? (
            <>
              <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white">
                {rewardClient}
              </span>
            </>
          ) : (
            <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white">
              No hay Servicios acumulados
            </span>
          )}
        </div>
      </div>
      {/* end services */}
      {/* start future appointments */}
      <div className="flex flex-col mb-[20px]">
        <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] mb-[9px] text-white">
          appointments futuras{' '}
          {appointments?.futureAppointment?.length ?? 0 > 0 ? appointments?.futureAppointment?.length : ''}
        </span>
        <div className="flex flex-col rounded-[3px] border border-grey2 pr-[14px] overflow-y-auto max-h-[172px]">
          {appointments?.futureAppointment?.length ?? 0 > 0 ? (
            <>
              {appointments?.futureAppointment.map((appointmentsFutura) => (
                <div
                  key={appointmentsFutura?.id}
                  className="flex justify-between border-b-[1px] pb-[10px] pt-[11px] pr-[14px]"
                >
                  <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] ml-[10px] text-white">
                    {formatFullDateToView(appointmentsFutura.dateInitial)} -{' '}
                    {formatDateTime(appointmentsFutura.dateInitial)}
                  </span>
                  <span
                    className="font-normal text-[14px] leading-[18px] tracking-[0.185em] text-primary underline cursor-pointer"
                    onClick={() => getAppointmentNote(appointmentsFutura)}
                  >
                    Ver nota
                  </span>
                </div>
              ))}
            </>
          ) : (
            <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white mx-[14px] my-[11px]">
              No hay appointments futuras
            </span>
          )}
        </div>
      </div>
      {/* end future appointments */}
      {/* start past appointments */}
      <div className="flex flex-col mb-[20px]">
        <span className="font-normal text-[14px] leading-[16px] tracking-[0.08rem] mb-[9px] text-white">
          appointments pasadas{' '}
          {appointments?.pastAppointment?.length ?? 0 > 0 ? appointments?.pastAppointment.length : ''}
        </span>
        <div className="flex flex-col rounded-[3px] border border-grey2 pr-[14px] overflow-y-auto max-h-[172px]">
          {appointments?.pastAppointment.length ?? 0 > 0 ? (
            <>
              {appointments?.pastAppointment.map((appointmentsPasada) => (
                <div
                  key={appointmentsPasada.id}
                  className="flex justify-between border-b-[1px] pb-[10px] pt-[11px] pr-[14px]"
                >
                  <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] ml-[10px] text-white">
                    {formatFullDateToView(appointmentsPasada.dateInitial)} -{' '}
                    {formatDateTime(appointmentsPasada.dateInitial)}
                  </span>
                  <span
                    className="font-normal text-[14px] leading-[18px] tracking-[0.185em] text-primary underline cursor-pointer"
                    onClick={() => getAppointmentNote(appointmentsPasada)}
                  >
                    Ver nota
                  </span>
                </div>
              ))}
            </>
          ) : (
            <span className="font-light text-[14px] leading-[16px] tracking-[0.08rem] text-white mx-[14px] my-[11px]">
              No hay appointments pasadas
            </span>
          )}
        </div>
      </div>
      {/* end past appointments */}
    </>
  );
};

export default ListClientDetailComponent;
