import { Invoice } from '@services/business/invoice/entities';
import { algoliaIndexSearchFront } from '@services/business/search/platform/index-search';

export const historyByClientId = async (clientId: string): Promise<Invoice[]> => {
  try {
    const { entities } = await algoliaIndexSearchFront<'invoice'>({
      options: {
        entityName: 'invoice',
      },
      where: {
        query: '',
        facetFilters: [`invoiceItem.clientId:${clientId}`],
      },
    });

    return entities;
  } catch (error) {
    return [];
  }
};
