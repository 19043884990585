import { GetCashRegister } from '@webapp/store/cash-register/action-creators';
import { GET_CASH_REGISTER } from '@webapp/store/cash-register/actions';
import AppState from '@webapp/store/state';
import { useOpStore } from '@webapp/store/useOpStore';
import { formatFullDateTime } from '@webapp/utils/formatDate';
import { getSubdomain } from '@webapp/utils/subDomain';
import { FunctionComponent, useEffect } from 'react';
import Loading from '../ui/preloader/Loading';

const StepOneComponent: FunctionComponent = () => {
  const {
    cashRegister: { cashRegister },
    dispatch,
    loading,
  } = useOpStore((state: AppState) => state, [GET_CASH_REGISTER]);

  useEffect(() => {
    if (cashRegister === undefined) {
      dispatch(new GetCashRegister());
    }
  }, []);

  return (
    <>
      {loading[GET_CASH_REGISTER] ? (
        <Loading />
      ) : (
        <div className="flex flex-col bg-darkGray max-h-[372px] rounded-[8px] p-[24px]">
          <div className="flex overflow-x-auto rounded-[4px]">
            <table className="table-auto lg:table-fixed w-full shadow overflow-hidden">
              <thead>
                <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] ">
                  <td className="border-spacing-1 w-[150px] mr-[7px] pl-[32px] py-[8px] bg-grey">Balance</td>
                  <td className="pl-[32px] py-[8px] bg-primary ">Nombre de registro</td>
                  <td className="border-l border-white pl-[32px] py-[8px] bg-primary">Fondo</td>
                  <td className="border-l border-white pl-[32px] py-[8px] bg-primary">Último balance</td>
                </tr>
              </thead>
              <tbody>
                <tr className="text-white text-[14px] md:text-base font-medium leading-[21px] tracking-[0.05em] rounded-[4px]">
                  <td className="border-t border-white w-[150px] mr-[7px] pl-[32px] py-[8px] bg-primary"></td>
                  <td className="border border-grey pl-[32px] py-[8px]">{getSubdomain()}</td>
                  <td className="border border-grey pl-[32px] py-[8px]">{cashRegister?.currentAmount}</td>
                  <td className="border border-grey pl-[32px] py-[8px] bg-darkGray">
                    {formatFullDateTime(cashRegister?.lastUsed ?? '')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default StepOneComponent;
