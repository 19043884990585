import { ReactNode, FunctionComponent } from 'react';

interface TooltipProps {
  message?: string;
  children: ReactNode;
}

const Tooltip: FunctionComponent<TooltipProps> = ({ children, message }: { children: ReactNode; message?: string }) => {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute top-[25px] left-0 flex flex-col hidden mb-6 group-hover:flex">
        <span className="relative z-10 py-4 px-[12px] text-base font-light leading-[19px] tracking-[0.186em] text-black whitespace-no-wrap bg-white shadow-lg rounded-[3px]">
          <span className="text-[14px] font-medium text-[#FFB526] tracking-[0.186em] leading-[17px]">Nota:</span>
          {message}
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
