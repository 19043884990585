import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { WarningExclamationYellowIcon } from '../ui/shared/icons';
import Tooltip from '../ui/tooltip/Tooltip';

import { OrdenItem, OrdenItemAppointment } from '@services/business/invoice/entities';
import { SetCurrentAppointment, UpdateAppointment } from '@webapp/store/appointments/action-creators';
import { Appointment } from '@webapp/store/appointments/state';
import { IListLashista } from '@webapp/store/lashista/state';
import { Category } from '@webapp/store/products/state';
import { OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import DetailCardIcon from '../../../public/assets/icons/DetailCardIcon.png';
import IconButton from '../../components/ui/scheduler/components/common/iconButton';
import { LocaleArrow } from '../../components/ui/scheduler/components/common/LocaleArrow';
import Loading from '../ui/preloader/Loading';

interface ArrivalsProps {
  appointments: Array<OrdenItem & OrdenItemAppointment>;
  handleDate: (value: number) => void;
  resetCounter: () => void;
  loading: boolean;
  lashistas: IListLashista[];
  categories: Category[];
  dateSelected?: string;
}

const listStatusAppointment = [
  { label: 'Llegada', value: 'SHOW' },
  { label: 'A Tiempo', value: 'INTIME' },
  { label: 'Confirmada', value: 'CONFIRMED' },
  { label: 'Completada', value: 'COMPLETED' },
  { label: 'Cancelada', value: 'CANCELED' },
  { label: 'No presentado', value: 'NOSHOW' },
];

const LlegadasComponent: FunctionComponent<ArrivalsProps> = ({
  appointments,
  handleDate,
  resetCounter,
  lashistas,
  loading,
  categories,
  dateSelected,
}) => {
  const date = new Date();
  const [filterBy, setFilterBy] = useState<string>('nombre');
  const [filterData, setFilterData] = useState(appointments);
  const [query, setQuery] = useState<string>('');
  const { dispatch } = useOpStore((state) => state);

  /**
   * get the initial time of the service
   * @param rawDate string
   * @returns string
   */
  const getStartTime = (rawDate: number | string | undefined) => {
    if (rawDate !== undefined) {
      const newDate = new Date(rawDate);
      return newDate.getHours() + ':' + newDate.getMinutes();
    }
    return '';
  };

  const getLashistaAlias = (lashistaId: string) => {
    return lashistas.find((lashista) => lashista.id == lashistaId)?.alias;
  };

  const transformSecsToMin = (seconds: number | undefined) => {
    if (seconds) {
      if (seconds / 60 < 1) {
        return '<1';
      }

      return seconds / 60;
    } else {
      return 0;
    }
  };

  const handleSearch = () => {
    if (filterBy == 'nombre') {
      setFilterData(
        filterData.filter((arrival) =>
          arrival?.detailClient?.firstName?.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
      );
    }

    if (filterBy == 'apellido') {
      setFilterData(
        filterData.filter((arrival) =>
          arrival.detailClient?.lastName?.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
      );
    }

    if (filterBy == 'telefono') {
      setFilterData(
        filterData.filter((arrival) =>
          arrival.detailClient?.phone?.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
      );
    }
  };

  const handleFilter = (statusArrival: string) => {
    setFilterData(appointments.filter((arrival) => arrival.statusAppointment === statusArrival));
  };

  const openNote = (appointment: Appointment) => {
    dispatch(new SetCurrentAppointment(appointment));
    dispatch(new OpenModal('LIST_NOTE'));
  };

  /**
   * this
   */
  useEffect(() => {
    if (query.length <= 0) setFilterData(appointments);
  }, [query]);

  useEffect(() => {
    if (appointments.length > 0) setFilterData(appointments);
  }, [appointments]);

  const { show, delay } = useMemo<{ show: number; delay: number }>(() => {
    return {
      show:
        appointments?.filter(({ statusAppointment }) => ['CONFIRMED', 'COMPLETED'].includes(statusAppointment))
          .length || 0,
      delay:
        appointments?.filter(
          ({ dateInitial, statusAppointment }) =>
            new Date().getTime() > new Date(dateInitial).getTime() && ['INTIME'].includes(statusAppointment)
        ).length || 0,
    };
  }, [appointments]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <div className="flex justify-between mb-4 bg-darkGray rounded-lg pl-6">
            <span className="text-[24px] font-medium leading-[33px] tracking-[0.185rem] max-w-[174px] text-txtWhite">
              Llegadas de clientes
            </span>
            <div className="flex  gap-[21px] round-[6px] py-[19px] px-[28px] bg-grey rounded-lg">
              <span className="text-[20px] font-light leading-[25px] tracking-[0.185rem] text-txtWhite">llegadas</span>
              <span className="text-[20px] font-bold leading-[26px] tracking-[0.185rem] text-txtWhite">{show}</span>
            </div>

            <div className="flex  gap-[21px] round-[6px] py-[19px] px-[45px] bg-grey rounded-lg">
              <span className="text-[20px] font-light leading-[25px] tracking-[0.185rem] text-txtWhite">
                Tiempo Actual
              </span>
              <span className="text-[20px] font-bold leading-[26px] tracking-[0.185rem] text-txtWhite">
                {date.getHours() + ':' + date.getMinutes()}
              </span>
            </div>

            <div className="flex  gap-[21px] round-[6px] py-[19px] px-[72px] bg-grey rounded-lg">
              <span className="text-[20px] font-light leading-[25px] tracking-[0.185rem] text-txtWhite">Atrasos</span>
              <span className="text-[20px] font-bold leading-[26px] tracking-[0.185rem] text-txtWhite ">{delay}</span>
            </div>
          </div>
          <div className="flex flex-col  round-[6px] px-[23px] py-[21px] mb-[20px] bg-darkGray rounded-lg">
            <div className="flex justify-between flex-1 mb-[40px] ">
              <div className="flex flex-1 max-w-[1000px]">
                <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC] mr-[20px]">
                  <div className="flex items-center p-1.5 rounded-[3px]  bg-veryDarkGray ">
                    <label
                      className="text-base font-light leading-[19px] tracking-[0.185em] text-txtWhite"
                      htmlFor="inputSearch"
                    >
                      Buscar:
                    </label>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      className="text-base font-light leading-[19px] tracking-[0.185em] text-grey2 border-0 w-full bg-darkGray"
                      name="inputSearch"
                      id="inputSearch"
                      onChange={(e) => {
                        setQuery(e.target.value);
                        handleSearch();
                      }}
                      defaultValue={''}
                    />
                  </div>
                </div>
                <div className="flex rounded-[3px] border-[1px] border-[#A8ACAC]  mr-[20px]">
                  <div className="flex items-center  p-1.5 rounded-[3px] bg-veryDarkGray">
                    <label
                      className="text-base font-light leading-[19px] tracking-[0.185em]  text-txtWhite"
                      htmlFor="orderClientBySelect"
                    >
                      Ordenar por:
                    </label>
                  </div>
                  <div className="flex-1">
                    <select
                      className="text-base font-light leading-[19px] tracking-[0.185em] text-[#878A8A] border-0 w-full bg-darkGray"
                      name="orderClientBySelect"
                      id="orderClientBySelect"
                      onChange={(e) => setFilterBy(e.target.value)}
                    >
                      <option value="nombre">nombre</option>
                      <option value="apellido">apellido</option>
                      <option value="telefono">telefono</option>
                    </select>
                  </div>
                </div>
                <div className="flex rounded-[3px] border-[1px] border-[#A8ACAC]  ">
                  <div className="flex items-center  p-1.5 rounded-[3px] bg-veryDarkGray">
                    <label
                      className="text-base font-light leading-[19px] tracking-[0.185em]  text-txtWhite"
                      htmlFor="orderClientBySelect"
                    >
                      Ordenar por:
                    </label>
                  </div>
                  <div className="flex-1">
                    <select
                      className="text-base font-light leading-[19px] tracking-[0.185em] text-[#878A8A] border-0 w-full bg-darkGray"
                      name="orderClientBySelect"
                      id="orderClientBySelect"
                      onChange={(e) => handleFilter(e.target.value)}
                    >
                      <option value="" hidden>
                        Estatus
                      </option>
                      <option value="DELETED">Cancelado</option>
                      <option value="SHOW">Llegada</option>
                      <option value="CONFIRMED">Confirmada</option>
                      <option value="INTIME">A tiempo</option>
                      <option value="NOSHOW">No presentado</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <span className="text-[14px] font-light leading-[16px] tracking-[0.185em] text-txtWhite">
                  {filterData.length} resultados
                </span>
              </div>
            </div>
            <div className="overflow-auto max-h-[600px] ">
              <table className="table-auto w-full">
                <thead className="">
                  <tr className="h-[40px] border-b-[1px] border-[#EBEBEB]">
                    <th className="text-base font-light leading-[19px] tracking-[0.185em] text-left text-txtWhite">
                      Cliente
                    </th>
                    <th className="text-base font-light leading-[19px] tracking-[0.185em] text-left text-txtWhite">
                      Inicio
                    </th>
                    <th className="text-base font-light leading-[19px] tracking-[0.185em] text-left text-txtWhite">
                      Servicio
                    </th>
                    <th className="text-base font-light leading-[19px] tracking-[0.185em] text-left text-txtWhite">
                      Duración
                    </th>
                    <th className="text-base font-light leading-[19px] tracking-[0.185em] text-left text-txtWhite">
                      Lashista
                    </th>
                    <th className="text-base font-light leading-[19px] tracking-[0.185em] text-left text-txtWhite">
                      Tarjeta
                    </th>
                    <th className="text-base font-light leading-[19px] tracking-[0.185em] text-left text-txtWhite">
                      Estatus
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterData.map((appointment) => {
                    if (appointment.isPrincipal === 'ACTIVE') {
                      return (
                        <tr key={appointment.id} className="border-b-[1px] border-[#EBEBEB] h-[67px]">
                          <td>
                            <div className="flex flex-row">
                              <div className="flex flex-col mr-2">
                                <span className="text-base leading-[24px] tracking-[0.185em] text-white">
                                  {`${appointment.detailClient?.firstName} ${appointment.detailClient?.lastName}`}
                                </span>
                                <span className="text-[14px] leading-[21px] tracking-[0.185em] text-[#A8ACAC]">
                                  {appointment.detailClient?.phone}
                                </span>
                              </div>
                              {appointment.note !== '' && appointment.note !== undefined && (
                                <Tooltip message={appointment.note}>
                                  <WarningExclamationYellowIcon />
                                </Tooltip>
                              )}
                            </div>
                          </td>
                          <td className="text-base font-medium leading-[24px] tracking-[0.185em] text-white">
                            {getStartTime(appointment.dateInitial)}
                          </td>
                          <td
                            className="text-base font-medium leading-[24px] tracking-[0.185em]"
                            style={{ color: appointment.color ?? 'white' }}
                          >
                            {categories.find((category) => category.id === appointment.categoryId)?.name} -{' '}
                            {appointment.name}
                          </td>
                          <td className="text-base font-medium leading-[24px] tracking-[0.185em] text-white">
                            {transformSecsToMin(appointment.duration)} min
                          </td>
                          <td className="text-base font-medium leading-[24px] tracking-[0.185em] text-white">
                            {getLashistaAlias(appointment.lashistaId)}
                          </td>
                          <td>
                            <button onClick={() => openNote(appointment)}>
                              <img src={DetailCardIcon} alt="" />
                            </button>
                          </td>
                          <td className="text-white">
                            <div className="flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                              <div className="flex-1">
                                <select
                                  className="text-base font-light leading-[19px] tracking-[0.185em]  w-full border-0 bg-darkGray text-white"
                                  onChange={(e) =>
                                    dispatch(
                                      new UpdateAppointment(
                                        {
                                          ...appointment,
                                          statusAppointment: e.target
                                            .value as OrdenItemAppointment['statusAppointment'],
                                        },
                                        appointment.id
                                      )
                                    )
                                  }
                                >
                                  <>
                                    {listStatusAppointment.map((option, i) =>
                                      option.value === appointment.statusAppointment ? (
                                        <option key={i} value={option.value} selected={true}>
                                          {option.label}
                                        </option>
                                      ) : (
                                        <option key={i} value={option.value}>
                                          {option.label}
                                        </option>
                                      )
                                    )}
                                  </>
                                </select>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="text-white flex items-center justify-center">{dateSelected}</div>
            <div className="flex items-center justify-center">
              <LocaleArrow type="prev" etiqueta="Semana" onClick={() => handleDate(-7)} />
              <LocaleArrow type="prev" etiqueta="Día" onClick={() => handleDate(-1)} />
              <IconButton
                className="text-xs md:text-sm font-semibold text-black m-4 mx-6 md:mx-10 "
                onClick={() => resetCounter()}
              >
                <span className="text-white">hoy</span>
              </IconButton>
              <LocaleArrow type="next" etiqueta="Día" onClick={() => handleDate(1)} />
              <LocaleArrow type="next" etiqueta="Semana" onClick={() => handleDate(7)} />
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default LlegadasComponent;
