import { CalendarState } from './state';
export const appointmentsInitialState: CalendarState = {
  appointments: [],
  preAppointment: {
    orden: [],
  },
  updateAppointmentPayment: {
    discountId: '',
    totalAmount: 0,
    totalDiscount: 0,
    rewardUsed: 0,
    methods: [
      { id: 'CARD', type: 'CARD', amount: 0 },
      { id: 'CASH', type: 'CASH', amount: 0 },
      { id: 'BALANCE', type: 'BALANCE', amount: 0 },
      { id: 'TRANSFER', type: 'TRANSFER', amount: 0 },
    ],
  },
};
