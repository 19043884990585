/**
 * return the number rounded
 * @param numb number
 * @returns number
 */
export const rounded = (numb: number) => {
  return (Math.round(Number((Math.abs(numb) * 100).toPrecision(15))) / 100) * Math.sign(numb);
};

/**
 * this validate if the input is only positive numbers(0-9) and decimals
 * @param value string
 * @returns number
 */
export const validatePositiveNumberValue = (value: string): number => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;
  const toReturn = value === '' || re.test(value) ? value : '';
  return parseFloat(toReturn);
};

/**
 * this validate if the input is only positive numbers(0-9) with out decimals
 * @param value string
 * @returns number
 */
export const validatePositiveNumberWithoutDecimalValue = (value: string): number => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[+]?([0-9]+(?:[0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;
  const toReturn = value === '' || re.test(value) ? value : '';
  return parseFloat(toReturn);
};

/**
 * calculate the price with the discount type
 * @param price number
 * @param type 'PERCENTAGE' | 'AMOUNT' | 'PERCENTAGE_AMOUNT' | 'AMOUNT_PERCENTAGE'
 * @param amount number
 * @param percentage number
 * @returns number
 */
export const calcDiscountType = (
  price: number,
  type: 'PERCENTAGE' | 'AMOUNT' | 'PERCENTAGE_AMOUNT' | 'AMOUNT_PERCENTAGE' | undefined,
  amount: number,
  percentage: number
): number => {
  let finalPrice = 0;
  switch (type) {
    case 'PERCENTAGE':
      finalPrice = price * (percentage / 100);
      break;
    case 'AMOUNT':
      finalPrice = amount;
      break;
    case 'PERCENTAGE_AMOUNT':
      finalPrice = price * (percentage / 100);
      finalPrice = finalPrice + amount;
      break;
    case 'AMOUNT_PERCENTAGE':
      finalPrice = price - amount;
      finalPrice = finalPrice * (percentage / 100);
      break;

    default:
      break;
  }

  return finalPrice;
};

/**
 * return the number from a range of zero(0) to max
 * @param num number
 * @param max number
 * @returns number
 */
export const limitNumberWithinRangeFromZeroToMax = (num: number, max: number): number => {
  return Math.min(Math.max(num, 0), max);
};
