import { FunctionComponent } from 'react';

import { useHistory } from 'react-router-dom';

interface ForgotPasswordProps {
  className?: string;
}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = ({ className }) => {
  const history = useHistory();

  const back = () => {
    history.push('/iniciar');
  };
  return (
    <div className={className}>
      <div className="bg-loginPage w-[649px] h-[698px] flex items-center flex-col rounded-[20px] shadow-[0_2px_26px_-2px_rgba(0,0,0,0.1)]">
        <span className="text-4xl leading-[43px] tracking-[0.08em] font-bold text-white mt-[46px]">
          ¿Olvidaste tu NIP?
        </span>
        <span className="text-[18px] leading-[22px] tracking-[0.08em] font-medium text-white mt-[36px] text-center">
          Para recuperar su NIP o clave, debe comunicarse con el administrador del sistema
        </span>
        <button type="submit" className="bg-primary px-[20px] py-[10px] w-[169px] mt-[96px]" onClick={() => back()}>
          <span className="text-[18px] leading-[22px] tracking-[0.08em] font-medium text-white">Regresar</span>
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
