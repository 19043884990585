import { ApiConfig } from '@services/support/utils/types/api-config';
import { ApiInterface } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { Inventory } from '../../entities/inventory';

export type Interface = ApiInterface<{
  response: Inventory;
  pathParams: { inventoryId: string };
  body: Omit<Inventory, 'id' | 'createdAt' | 'updatedAt' | 'currentTotal' | 'productId'>;
  claims: { tenantId: string; isAdmin: string };
  queryParams: { tenantId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-update-inventory',
  method: 'PUT',
  basePath: 'inventory',
  path: '/{inventoryId}',
  tablePermission: 'readWrite',
  scope: 'ADMIN',
  metadataAuth: {
    onlySuperAdmin: true,
  },
  schema: Schemy.schema<Interface['body']>({
    tenantId: { type: String, required: true },
    minTotal: { type: Number, required: true, min: 0 },
  }),
};
