import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuIcon from '@mui/icons-material/Menu';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import IconButton from '@webapp/components/ui/iconButton';
import { LogoutUser } from '@webapp/store/auth/action-creators';
import AppState from '@webapp/store/state';
import { useOpStore } from '@webapp/store/useOpStore';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import PopupState from 'material-ui-popup-state';
import { bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { useHistory } from 'react-router-dom';
//import LogoutIcon from '@mui/icons-material/Logout';

/*--------------------------props------------------------------------
  visible
  selected
  navigation = {
                 name: `menu1`, href: `#`,
                 children: [{ name: `children1`, href: `/menu1` }]
               }
-------------------------------------------------------------------*/

export default function PopMenu(props: any) {
  //const [menuContext, setMenuContext] = useContext(MenuContext);
  //const { currentMenu, currentSubMenu } = menuContext;
  const expanded: string[] | undefined = [];
  const selected: string[] | undefined = [];
  const history = useHistory();
  const { auth, dispatch } = useOpStore((state: AppState) => state);

  const StyledTreeItem = styled(TreeItem)(() => ({
    color: 'black',
    [`& .${treeItemClasses.content}`]: {
      color: 'black',
      paddingLeft: 10,
      fontSize: 14,
      height: 35,
      fontWeight: 500,
      [`& .${treeItemClasses.label}`]: {
        fontWeight: 'inherit',
        color: 'inherit',
      },
      '&:hover': {
        backgroundColor: 'var(--lighterGrey)',
        color: 'black',
      },
      '&.Mui-focused': {
        backgroundColor: 'var(--lighterGrey)',
        color: 'black',
      },
      '&.Mui-selected': {
        backgroundColor: 'white',
        color: 'var(--primary)',
        fontWeight: 'bold',
        borderRadius: 6,
      },
      '&.Mui-expanded': {
        backgroundColor: 'var(--primary)',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: 6,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: 10,
        fontSize: 12,
        fontWeight: 400,
      },
    },
  }));

  const renderItem = (popupState: any, item: any) => {
    if (item.children) {
      return item.children.map((child: any) => (
        <StyledTreeItem
          onClick={() => {
            if (child.href != '#') {
              history.push(child.href);
              popupState.close();
            }
          }}
          nodeId={item.name + '-' + child.name}
          key={item.name + '-' + child.name}
          label={'• ' + child.name}
        />
      ));
    } else {
      return null;
    }
  };

  return (
    <PopupState variant="popover" popupId="popup-menu">
      {(popupState) => (
        <>
          <IconButton className={'mx-1 block' + (props.visible ? '' : 'md:hidden')} {...bindTrigger(popupState)}>
            {props.children ?? <MenuIcon className="mx-2 text-primary" />}
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <TreeView
              sx={{ width: 250, padding: 1 }}
              aria-label="controlled"
              defaultExpanded={expanded}
              defaultSelected={selected}
              defaultCollapseIcon={<KeyboardArrowDownIcon />}
              defaultExpandIcon={<KeyboardArrowLeftIcon />}
              multiSelect
            >
              {props.navigation ? (
                props.navigation.map((item: any) => (
                  <StyledTreeItem
                    onClick={() => {
                      if (item.href) {
                        if (item.href != '#') {
                          history.push(item.href);
                          popupState.close();
                        }
                      }
                    }}
                    nodeId={item.name}
                    key={item.name}
                    label={item.name}
                  >
                    {renderItem(popupState, item)}
                  </StyledTreeItem>
                ))
              ) : (
                <StyledTreeItem
                  onClick={() => {
                    dispatch(new LogoutUser());
                    popupState.close();
                  }}
                  nodeId={'logout'}
                  key={'logout'}
                  label={'Cerrar Sesión'}
                ></StyledTreeItem>
              )}
            </TreeView>
            <div className="flex justify-between py-1 px-2 font-normal text-sm">
              <div className="font-bold">{auth?.user?.attributes?.['given_name']}</div>
              <div>
                {format(new Date(), 'EE, dd MMM, yyyy', {
                  locale: es,
                })}
              </div>
            </div>
          </Menu>
        </>
      )}
    </PopupState>
  );
}
