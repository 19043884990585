import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import IconButton from '../iconButton';
import { SendGreenIcon } from '../shared/icons';

interface IModal {
  isOpen: boolean;
  setIsOpen: any;
  title?: string;
  title2?: string;
  title3?: string;
  subTitle?: string;
  children?: any;
  iconExtra?: ReactJSXElement;
  onExtra?: any;
  onCancel?: any;
  onOk?: any;
  noCancel?: boolean;
  noOk?: boolean;
  labelExtra?: string;
  labelCancel?: string;
  labelOk?: string;
}

const Modal = (props: IModal) => {
  const cancelButton = useRef(null);
  const {
    isOpen,
    setIsOpen,
    title,
    title2,
    title3,
    subTitle,
    children,
    iconExtra,
    onExtra,
    onCancel,
    onOk,
    noCancel,
    noOk,
    labelExtra,
    labelCancel,
    labelOk,
  } = props;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => setIsOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <div className="bg-primary py-3 pl-9 flex justify-left">
                <Dialog.Title
                  as="span"
                  className="flex justify-between items-center w-full text-white text-xl md:text-2xl font-bold tracking-widest leading-tight"
                >
                  {title ?? 'Información'}
                  <div className="flex justify-end items-center pr-5">
                    {title2 && (
                      <span className="flex justify-end items-center text-white text-lg font-bold tracking-widest leading-tight border-r-2 border-turquoise pr-5 mr-5">
                        {title2}
                      </span>
                    )}
                    {title3 && (
                      <span className="flex justify-end items-center text-white text-lg font-bold tracking-widest leading-tight">
                        {title3}
                      </span>
                    )}
                  </div>
                </Dialog.Title>
              </div>
              <div className="py-3 md:py-5 px-4 md:px-8 flex flex-col mb-5">
                {subTitle && (
                  <span className="text-lg font-semibold tracking-widest leading-tight text-left mb-4">
                    {subTitle ?? ''}
                  </span>
                )}
                {children}
              </div>
              <div className="flex justify-between items-center mb-6 px-5">
                {onExtra ? (
                  <div className="flex justify-start items-center px-5">
                    <IconButton
                      className="mr-7"
                      btn={true}
                      outline={true}
                      onClick={() => {
                        onExtra ? onExtra() : null;
                      }}
                    >
                      {iconExtra ?? <SendGreenIcon />}
                      <span className="w-1"></span>
                      {labelExtra ?? 'Enviar confirmación'}
                    </IconButton>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="flex justify-center items-center mr-7 px-5">
                  {noCancel ? (
                    <></>
                  ) : (
                    <IconButton
                      className="mr-7"
                      btn={true}
                      secondary={true}
                      onClick={() => {
                        setIsOpen(false);
                        onCancel ? onCancel() : null;
                      }}
                    >
                      {labelCancel ?? 'Cancelar'}
                    </IconButton>
                  )}
                  {noOk ? (
                    <></>
                  ) : (
                    <IconButton
                      btn={true}
                      primary={true}
                      onClick={() => {
                        setIsOpen(false);
                        onOk ? onOk() : null;
                      }}
                    >
                      {labelOk ?? 'Hecho'}
                    </IconButton>
                  )}
                </div>
                {!onExtra && <div></div>}
              </div>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default Modal;
