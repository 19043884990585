import { FunctionComponent } from 'react';

interface Props {
  step: number;
}

const titleSteps = [
  { step: 1, title: 'Por favor selecciona la(s) caja(s) del día que deban ser cuadradas en la siguiente lista' },
  { step: 2, title: 'Por favor entre el contenido de la caja a cuadrar. Esta cantidad incluye el fondo' },
  {
    step: 3,
    title:
      'Intente cuadrar su(s) caja(s) ajustando el recuento manual (paso anterior) o revisando sus transacciones y ajustándolas. Si no puede cuadrar la(s) caja(s) debe escribir un comentario.',
  },
  { step: 4, title: 'Por favor entre los nuevos fondos de las cajas que acaba de cuadrar' },
  { step: 5, title: 'Escoja los informes que desea imprimir y haga click en “imprimir” o “finalizar” para salir. ' },
];

const CashRegisterStepsTitleComponent: FunctionComponent<Props> = ({ step }) => {
  return (
    <>
      {step === 1 ?? (
        <span className="text-white text-[18px] font-medium leading-[23px] tracking-[0.05em] mb-4">
          Bienvenido al asistente para la caja del día. Te guiaremos en los pasos necesarios para cuadrar tu caja.
        </span>
      )}
      <div className="flex items-end gap-x-[25px] mb-4">
        <span className="text-primary text-[48px] font-medium leading-[62px] tracking-[0.05em]">{step}</span>
        <span className="text-white text-[18px] font-medium leading-[23px] tracking-[0.05em] mb-[6px]">
          {titleSteps.find((ts) => ts.step === step)?.title}
        </span>
      </div>
    </>
  );
};

export default CashRegisterStepsTitleComponent;
