import ScaleLoader from 'react-spinners/ScaleLoader';

const Loading = (load) => {
  return (
    <div className="text-lg text-center py-5">
      <ScaleLoader
        color="#34D399"
        loading={load.loading}
        height={load.height ? load.height : 25}
        width={3}
        radius={3}
        margin={4}
      />
    </div>
  );
};

export default Loading;
