import { apiCategory } from '@services/business/products/catalog';
import { CallApi, handleJSONResponse } from './common';

const callAPI = CallApi('category');

export const listCategories = async (
  query?: string,
  nextToken?: string | number | null
): Promise<apiCategory.listCategory.Interface['response']> => {
  const response = await callAPI(
    query ? `?nextToken=${nextToken || null}&query=${query}` : `?nextToken=${nextToken || null}`,
    'GET'
  );
  const body: apiCategory.listCategory.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const createCategories = async (
  categories: apiCategory.createdCategory.Interface['body']
): Promise<apiCategory.createdCategory.Interface['response']> => {
  const response = await callAPI('', 'POST', { ...categories, id: undefined });
  const body: apiCategory.createdCategory.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const updateCategories = async (
  categories: apiCategory.updatedCategory.Interface['body']
): Promise<apiCategory.updatedCategory.Interface['response']> => {
  const response = await callAPI(`/${categories.id}`, 'PUT', { ...categories, id: undefined });
  const body: apiCategory.updatedCategory.Interface['response'] = await handleJSONResponse(response);

  return body;
};

export const deleteCategories = async (
  categoryId: string
): Promise<apiCategory.deletedCategory.Interface['response']> => {
  const response = await callAPI(`/${categoryId}`, 'DELETE', categoryId);
  const body: apiCategory.deletedCategory.Interface['response'] = await handleJSONResponse(response);

  return body;
};
