import * as apiReport from '@services/business/history/catalog/api';

export const normalizeRankingSucursal = (
  data: apiReport.getHistoryOrder.Interface['response']
): Array<{
  sucursal: string;
  products: {
    amount: number;
    sucursal: string | undefined;
  };
  services: {
    amount: number;
    sucursal: string | undefined;
  };
  total: number;
}> => {
  const response = data?.map(({ order }) => {
    const { tenantId } = order;

    const getSucursal = data.filter(({ order }) => order.tenantId == tenantId);
    const serviceInSucursal = getSucursal?.flatMap(({ items }) => items).filter(({ type }: any) => type === 'SERVICE');
    const productInSucursal = getSucursal?.flatMap(({ items }) => items).filter(({ type }: any) => type === 'PRODUCT');
    const getServiceAmount = serviceInSucursal.reduce((a: any, b: any) => a + (b.price || 0), 0);
    const getProductAmount = productInSucursal.reduce((a: any, b: any) => a + (b.price || 0), 0);
    const getTotal = getSucursal.flatMap(({ items }) => items).reduce((a, b) => a + (b.price || 0), 0);
    return {
      sucursal: order.tenantId,
      products: {
        amount: getProductAmount,
        sucursal: getSucursal?.find(({ order }) => order.tenantId === tenantId)?.order.tenantId,
      },
      services: {
        amount: getServiceAmount,
        sucursal: getSucursal?.find(({ order }) => order.tenantId === tenantId)?.order.tenantId,
      },
      total: getTotal,
    };
  });
  return response;
};
