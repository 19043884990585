import { CashRegister, CashRegisterBalance } from '@services/business/cash-register/entities';
import { Orden, OrdenItem } from '@services/business/invoice/entities';
import { NoteClient } from '@services/business/note-client/entities';
import { Client } from '@webapp/types/client';
import Dexie, { Table } from 'dexie';

export class MlashesDb extends Dexie {
  orderItem!: Table<OrdenItem & { startWeb: number }, string>;
  client!: Table<Client & { startWeb: number }, string>;
  queueError!: Table<{ id: number; type: 'AXIOS' | 'FETCH'; data: string }, string>;
  order!: Table<Orden & { startWeb: number }, string>;
  cashRegisterBalance!: Table<CashRegisterBalance & { startWeb: number }, string>;
  cashRegister!: Table<Omit<CashRegister, 'tenantId'> & { startWeb: number }, string>;
  notes!: Table<NoteClient & { startWeb: number }>;
  constructor() {
    super('MlashesDb');
    this.version(1).stores({
      orderItem: '++id, dateAppointment, status,orderId,clientId',
      client: '++id',
      queueError: '++id, type',
      order: '++id,dateOrden',
      cashRegister: '++id',
      cashRegisterBalance: 'tenantId',
      notes: '++id,clientId',
    });
  }
  async createOrUpdate(ordenItem: OrdenItem) {
    const item = await this.orderItem.get(ordenItem.id);
    if (item) {
      this.orderItem.update(ordenItem.id, { ...ordenItem });
    } else {
      this.orderItem.add({ ...ordenItem, startWeb: Date.now() });
    }
  }
  async createOrUpdateNotesClients(noteClient: NoteClient) {
    const item = await this.notes.get(noteClient.id);
    if (item) {
      this.notes.update(noteClient.id, { ...noteClient });
    } else {
      this.notes.add({ ...noteClient, startWeb: Date.now() });
    }
  }
  async createOrUpdateCashRegisterBalance(ordenItem: CashRegisterBalance) {
    const item = await this.cashRegisterBalance.get(ordenItem.tenantId);
    if (item) {
      this.cashRegisterBalance.update(ordenItem.tenantId, { ...ordenItem });
    } else {
      this.cashRegisterBalance.add({ ...ordenItem, startWeb: Date.now() });
    }
  }
  async createOrUpdateCashRegister(ordenItem: CashRegister) {
    const item = await this.cashRegisterBalance.get(ordenItem.id);
    if (item) {
      this.cashRegister.update(ordenItem.id, { ...ordenItem });
    } else {
      this.cashRegister.add({ ...ordenItem, startWeb: Date.now() });
    }
  }
  async createOrUpdateClient(client: Client) {
    const item = await this.client.get(client.id);
    if (item) {
      this.client.update(client.id, { ...client });
    } else {
      this.client.add({ ...client, startWeb: Date.now() });
    }
  }
}

export const dbStore = new MlashesDb();
