import * as api from '@services/business/history/catalog/api';
import { Lashista } from '@webapp/store/lashista/state';
import { secondsToTime } from '../formatTime';

export const normalizeMovementsSummary = (
  data: api.getHistoryOrder.Interface['response'],
  newClientData: api.historyOldOrNewClient.Interface['response'],
  noReturnClientData: api.historyOldOrNewClient.Interface['response'],
  dataLashistas: Lashista[],
  sucursales: any[] | undefined
): any => {
  const getTotal = (firstValue: number, secondValue: number, thirdValue?: number): number => {
    return firstValue + secondValue + (thirdValue ?? 0);
  };

  const rounded = (numb: number) => {
    return (Math.round(Number((Math.abs(numb) * 100).toPrecision(15))) / 100) * Math.sign(numb);
  };

  const dataSales = data.map(({ items, order }) => {
    const [item] = items;

    /**
     * This part is for Sales summary
     *
     * Get type of payments
     * **/
    const cash = order.payment?.methods.find((method: any) => method.type === 'CASH')?.amount;
    const card = order.payment?.methods.find((method: any) => method.type === 'CARD')?.amount;
    const transference = order.payment?.methods.find((method: any) => method.type === 'TRANSFER')?.amount;
    const dateSales = new Date(order?.finalizeAt ?? 1).toLocaleDateString();

    const getProducts = items.filter((item) => item.type === 'PRODUCT');
    const getServices = items.filter((item) => item.type === 'SERVICE');

    const getDiscount = data
      .filter(({ order }) => order.payment?.discountId !== '')
      .reduce((a, b) => a + (b.order.payment?.totalDiscount || 0), 0);

    const amountService = items
      .filter((item: any) => item.type === 'SERVICE')
      ?.map((service: any) => service.price)
      .reduce((a, b) => a + b, 0);

    const amountProduct = items
      .filter((item: any) => item.type === 'PRODUCT')
      ?.map((product: any) => product.price)
      .reduce((a, b) => a + b, 0);

    const getFemaleItems = data.flatMap(({ items }) =>
      items?.filter(({ detailClient }) => detailClient?.sex === 'FEMALE')
    );

    const totalPriceFemale = getFemaleItems.reduce((a, b) => a + (b.price || 0), 0);

    const getMaleItems = data.flatMap(({ items }) => items?.filter(({ detailClient }) => detailClient?.sex === 'MALE'));

    const totalPriceMale = getMaleItems.reduce((a, b) => a + (b.price || 0), 0);

    const getAnonymous = data.flatMap(({ items }) => items?.filter(({ clientId }) => clientId === 'cus_anonimo'));

    const totalPriceAnonymous = getAnonymous.reduce((a, b) => a + (b.price || 0), 0);

    return {
      lashistaId: item?.lashistaId as string,
      sucursal: order?.tenantId,
      femaleQuantity: getFemaleItems.length,
      femaleAmount: totalPriceFemale,
      maleQuantity: getMaleItems.length,
      maleAmount: totalPriceMale,
      anonymousAmount: totalPriceAnonymous,
      anonymousQuantity: getAnonymous.length,
      totalDiscount: getDiscount,
      serviceQuantity: getServices.length,
      productQuantity: getProducts.length,
      sales: {
        cash,
        card,
        transference,
        dateSales,
        amountService,
        amountProduct,
      },
    };
  });

  /**
   * Sales sum of payments
   * and
   * product & service
   */
  const salesCard = dataSales.reduce((a, b) => a + (b.sales.card || 0), 0);
  const salesTransference = dataSales.reduce((a, b) => a + (b.sales.transference || 0), 0);
  const salesCash = dataSales.reduce((a, b) => a + (b.sales.cash || 0), 0);
  const salesProductAmount = dataSales.reduce((a, b) => a + (b.sales.amountProduct || 0), 0);
  const salesServiceAmount = dataSales.reduce((a, b) => a + (b.sales.amountService || 0), 0);
  const totalServiceQuantity = dataSales.reduce((a, b) => a + (b.serviceQuantity || 0), 0);
  const totalProductQuantity = dataSales.reduce((a, b) => a + (b.productQuantity || 0), 0);
  const totalDiscount = dataSales.reduce((a, b) => a + (b.totalDiscount || 0), 0);
  const netIncome = getTotal(salesCard, salesCash, salesTransference);
  const totalProductService = getTotal(salesServiceAmount, salesProductAmount);
  const cashPercentage = rounded((salesCash / netIncome) * 100);
  const transferPercentage = rounded((salesTransference / netIncome) * 100);
  const cardPercentage = rounded((salesCard / netIncome) * 100);
  const productPercentage = rounded((salesProductAmount / totalProductService) * 100);
  const servicePercentage = rounded((salesServiceAmount / totalProductService) * 100);
  const [sale] = dataSales;

  /***
   * Get quantity
   * type of clients
   */

  //This need call to endpoint to newClient
  const newClient = sucursales?.map((sucursal) => {
    const quantity = newClientData.entities?.filter((entity) => entity.sucursalAt === sucursal);
    return quantity;
  });

  //This need call to endpoint to noReturnClient]

  const noReturnClient = sucursales?.map((sucursal) => {
    const quantity = noReturnClientData.entities?.filter((entity) => entity.sucursalAt === sucursal);
    return quantity;
  });

  const lashistaResponse = dataLashistas.map((lashista) => {
    const orderItems = data.flatMap(({ items }) => items).filter((service) => service.lashistaId === lashista.id);

    const order = data.flatMap(({ order }) => order).filter((service) => service.lashistaIds?.[0] === lashista.id);

    const getLashistaId = orderItems.find((id) => id.lashistaId === lashista.id)?.lashistaId;

    const getServiceByLashista = orderItems
      .filter((item) => item.lashistaId === getLashistaId && item.lashistaId !== undefined)
      .filter((item) => item.type === 'SERVICE');

    const getProductByLashista = orderItems
      .filter((item) => item.lashistaId === getLashistaId && item.lashistaId !== undefined)
      .filter((item) => item.type === 'PRODUCT');

    const getRewardByLashista = order
      .filter((item) => item.lashistaIds?.[0] === getLashistaId && item.lashistaIds?.[0] !== undefined)
      .filter((item) => item.payment?.methods?.[0].type === 'REWARD');

    const totalService = getServiceByLashista.reduce((a, b) => a + (b.price || 0), 0);

    const totalProduct = getProductByLashista.reduce((a, b) => a + (b.price || 0), 0);

    const totalReward = getRewardByLashista.reduce((a, b) => a + (b.payment?.methods?.[0].amount || 0), 0);

    const totalServiceHour = orderItems
      .filter((item) => item.isPrincipal === 'ACTIVE')
      .filter((item) => item.type === 'SERVICE')
      .reduce((a, b) => a + (b.price || 0), 0);

    const totalTimeWorked = orderItems.reduce((a, b) => a + (b.duration || 0), 0);
    const totalTickets = getTotal(getServiceByLashista.length, getProductByLashista.length, getRewardByLashista.length);
    const totalOrderItems = data.flatMap(({ items }) => items);
    const orderItemsPrice = totalOrderItems.reduce((a, b) => a + (b.price || 0), 0);
    const totalAmount = getTotal(totalProduct, totalReward, totalService);

    const serviceByHour = rounded(totalServiceHour / secondsToTime(totalTimeWorked).h);

    return {
      lashistaId: getLashistaId,
      fullName: `${lashista?.firstName} ${lashista?.lastName}`,
      serviceAmount: totalService,
      productAmount: totalProduct,
      rewardAmount: totalReward,
      total: totalAmount,
      totalTickets,
      totalHourWorked: secondsToTime(totalTimeWorked ?? 0),
      // ticketPercentage: Math.floor(totalTickets / totalOrderItems.length),
      ticketPercentage: Math.floor((totalTickets / totalOrderItems.length) * 100), //PROMEDIO NO PORCETAJE
      totalPercentage: rounded((totalAmount / orderItemsPrice) * 100),
      serviceByHour,
    };
  });

  /**
   *
   * Return all data
   *
   */

  return new Array({
    sucursal: sale?.sucursal,
    clients: {
      femaleQuantity: sale?.femaleQuantity,
      femaleAmount: sale?.femaleAmount,
      maleQuantity: sale?.maleQuantity,
      maleAmount: sale?.maleAmount,
      anonymousQuantity: sale?.anonymousQuantity,
      anonymousAmount: sale?.anonymousAmount,
    },
    noReturnClient: noReturnClient,
    newClient: newClient,
    lashista: lashistaResponse,
    sales: {
      payments: {
        cash: salesCash,
        transference: salesTransference,
        card: salesCard,
        cashPercentage,
        cardPercentage,
        transferPercentage,
      },
      netIncome: netIncome,
      productAmount: salesProductAmount,
      serviceAmount: salesServiceAmount,
      totalProductService: totalProductService,
      totalServiceQuantity: totalServiceQuantity,
      totalProductQuantity: totalProductQuantity,
      totalDiscount: totalDiscount,
      productPercentage,
      servicePercentage,
    },
  });
};
