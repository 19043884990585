import { WorkingHours } from '@services/business/sucursal/entities/sucursal';

/**
 * format the time to show hours and minutes
 * @param rawDate number
 * @returns string
 */
export const formatDateTime = (rawDate: number | string): string => {
  const newDate = new Date(rawDate);
  return `${String(newDate.getHours()).padStart(2, '0')}:${String(newDate.getMinutes()).padStart(2, '0')}`;
};

/**
 * format the time to show years, months and days
 * @param rawDate number
 * @returns string
 */
export const formatFullDate = (rawDate: number | string): string => {
  const newDate = new Date(rawDate);
  const year = newDate.getFullYear();
  let month = '' + (newDate.getMonth() + 1);
  let day = '' + newDate.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${year}-${month}-${day}`;
};

/**
 * format the time to show months, days and years (US)
 * @param rawDate number
 * @returns string
 */
export const formatFullDateUS = (rawDate: number | string): string => {
  const newDate = new Date(rawDate);
  const year = newDate.getFullYear();
  let month = '' + (newDate.getMonth() + 1);
  let day = '' + newDate.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${month}-${day}-${year}`;
};

/**
 * format the time to show days, months and years
 * @param rawDate number
 * @returns string
 */
export const formatFullDateToView = (rawDate: number | string): string => {
  const newDate = new Date(rawDate);
  const year = newDate.getFullYear();
  let month = '' + (newDate.getMonth() + 1);
  let day = '' + newDate.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day}-${month}-${year}`;
};

/**
 * format the time to show days, months, years, hours and minutes
 * @param rawDate number | string
 * @returns string
 */
export const formatFullDateTime = (rawDate: number | string): string => {
  const newDate = new Date(rawDate);
  const year = newDate.getFullYear();
  let month = '' + (newDate.getMonth() + 1);
  let day = '' + newDate.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day}-${month}-${year} ${newDate.getHours()}:${newDate.getMinutes()}`;
};

/**
 *
 * @param dateAppointment Date
 * @param seconds number
 * @returns Date
 */
export const sumSecondsDate = (dateAppointment: Date, seconds: number): Date => {
  dateAppointment.setSeconds(dateAppointment.getSeconds() + seconds);
  return dateAppointment;
};

/**
 * check the working hours of the current sucursal with the current day and date used for create/update an appointment
 * @param workingHours WorkingHours | undefined
 * @param initialDate Date
 * @param today Date
 * @returns boolean
 */
export const checkIfSucursalIsOpen = (workingHours: WorkingHours | undefined, initialDate: Date): boolean => {
  const workingHourStart = new Date(initialDate);
  const workingHourEnd = new Date(initialDate);

  switch (initialDate.getDay()) {
    case 0: // sunday
      if (workingHours?.sunday.status === false) {
        return false;
      }
      // set the hours and minutes for working
      workingHourStart.setHours(Number(workingHours?.sunday.start.split(':')[0]));
      workingHourStart.setMinutes(Number(workingHours?.sunday.start.split(':')[1]));
      workingHourStart.setSeconds(Number(0));
      workingHourEnd.setHours(Number(workingHours?.sunday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.sunday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));

      if (initialDate > workingHourStart && initialDate < workingHourEnd) {
        return false;
      }

      break;
    case 1: // monday
      if (workingHours?.monday.status === false) {
        return false;
      }
      // set the hours and minutes for working
      workingHourStart.setHours(Number(workingHours?.monday.start.split(':')[0]));
      workingHourStart.setMinutes(Number(workingHours?.monday.start.split(':')[1]));
      workingHourStart.setSeconds(Number(0));
      workingHourEnd.setHours(Number(workingHours?.monday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.monday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));

      if (initialDate > workingHourStart && initialDate < workingHourEnd) {
        return false;
      }
      break;
    case 2: // tuesday
      if (workingHours?.tuesday.status === false) {
        return false;
      }
      // set the hours and minutes for working
      workingHourStart.setHours(Number(workingHours?.tuesday.start.split(':')[0]));
      workingHourStart.setMinutes(Number(workingHours?.tuesday.start.split(':')[1]));
      workingHourStart.setSeconds(Number(0));
      workingHourEnd.setHours(Number(workingHours?.tuesday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.tuesday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));

      if (initialDate > workingHourStart && initialDate < workingHourEnd) {
        return false;
      }
      break;
    case 3: // wednesday
      if (workingHours?.wednesday.status === false) {
        return false;
      }
      // set the hours and minutes for working
      workingHourStart.setHours(Number(workingHours?.wednesday.start.split(':')[0]));
      workingHourStart.setMinutes(Number(workingHours?.wednesday.start.split(':')[1]));
      workingHourStart.setSeconds(Number(0));
      workingHourEnd.setHours(Number(workingHours?.wednesday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.wednesday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));

      if (initialDate > workingHourStart && initialDate < workingHourEnd) {
        return false;
      }
      break;
    case 4: // thursday
      if (workingHours?.thursday.status === false) {
        return false;
      }
      // set the hours and minutes for working
      workingHourStart.setHours(Number(workingHours?.thursday.start.split(':')[0]));
      workingHourStart.setMinutes(Number(workingHours?.thursday.start.split(':')[1]));
      workingHourStart.setSeconds(Number(0));
      workingHourEnd.setHours(Number(workingHours?.thursday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.thursday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));

      if (initialDate > workingHourStart && initialDate < workingHourEnd) {
        return false;
      }

      break;
    case 5: // friday
      if (workingHours?.friday.status === false) {
        return false;
      }

      // set the hours and minutes for working
      workingHourStart.setHours(Number(workingHours?.friday.start.split(':')[0]));
      workingHourStart.setMinutes(Number(workingHours?.friday.start.split(':')[1]));
      workingHourStart.setSeconds(Number(0));
      workingHourEnd.setHours(Number(workingHours?.friday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.friday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));

      if (initialDate > workingHourStart && initialDate < workingHourEnd) {
        return false;
      }
      break;
    case 6: // saturday
      if (workingHours?.saturday.status === false) {
        return false;
      }
      // set the hours and minutes for working
      workingHourStart.setHours(Number(workingHours?.saturday.start.split(':')[0]));
      workingHourStart.setMinutes(Number(workingHours?.saturday.start.split(':')[1]));
      workingHourStart.setSeconds(Number(0));
      workingHourEnd.setHours(Number(workingHours?.saturday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.saturday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));

      if (initialDate > workingHourStart && initialDate < workingHourEnd) {
        return false;
      }
      break;

    default:
      break;
  }
  return true;
};

/**
 *
 * @param workingHours Hours | undefined
 * @param today Date
 * @param serviceDuration number
 * @returns boolean
 */
export const lastAppointmentBeforeClose = (
  workingHours: WorkingHours | undefined,
  today: Date,
  serviceDuration: number
): boolean => {
  const workingHourEnd = new Date();
  switch (today.getDay()) {
    case 0: // sunday
      // set the hours and minutes for working
      workingHourEnd.setHours(Number(workingHours?.sunday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.sunday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));
      // set the duration of the service
      today.setSeconds(serviceDuration);

      if (today < workingHourEnd) {
        return false;
      }

      break;
    case 1: // monday
      // set the hours and minutes for working
      workingHourEnd.setHours(Number(workingHours?.monday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.monday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));
      // set the duration of the service
      today.setSeconds(serviceDuration);

      if (today < workingHourEnd) {
        return false;
      }

      break;
    case 2: // tuesday
      // set the hours and minutes for working
      workingHourEnd.setHours(Number(workingHours?.tuesday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.tuesday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));
      // set the duration of the service
      today.setSeconds(serviceDuration);

      if (today < workingHourEnd) {
        return false;
      }

      break;
    case 3: // wednesday
      // set the hours and minutes for working
      workingHourEnd.setHours(Number(workingHours?.wednesday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.wednesday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));
      // set the duration of the service
      today.setSeconds(serviceDuration);

      if (today < workingHourEnd) {
        return false;
      }

      break;
    case 4: // thursday
      // set the hours and minutes for working
      workingHourEnd.setHours(Number(workingHours?.thursday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.thursday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));
      // set the duration of the service
      today.setSeconds(serviceDuration);

      if (today < workingHourEnd) {
        return false;
      }

      break;
    case 5: // friday
      // set the hours and minutes for working
      workingHourEnd.setHours(Number(workingHours?.friday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.friday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));
      // set the duration of the service
      today.setSeconds(serviceDuration);

      if (today < workingHourEnd) {
        return false;
      }

      break;
    case 6: // saturday
      // set the hours and minutes for working
      workingHourEnd.setHours(Number(workingHours?.saturday.end.split(':')[0]));
      workingHourEnd.setMinutes(Number(workingHours?.saturday.end.split(':')[1]));
      workingHourEnd.setSeconds(Number(0));
      // set the duration of the service
      today.setSeconds(serviceDuration);

      if (today < workingHourEnd) {
        return false;
      }

      break;

    default:
      break;
  }
  return true;
};
