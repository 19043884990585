import { FunctionComponent, useEffect, useMemo } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import ServicePageBodyComponent from '@webapp/components/servicios/service-page-body';
import Container from '@webapp/components/ui/container';
import { ThreeDotButtonGreenIcon } from '@webapp/components/ui/shared/icons';

// store state
import { Lashitas } from '@services/business/lashitas/entities/lashitas';
import AppState from '@webapp/store/state';
import { useOpStore } from '@webapp/store/useOpStore';

const ServicePage: FunctionComponent = () => {
  const {
    lashista: { listLashista },
    appointments: { preAppointment },
    product: { products: products, categories: categories },
  } = useOpStore((state: AppState) => state);
  const { orderItemId } = useParams<{ orderItemId: string }>();
  const history = useHistory();
  const { firstName, lastName, id } = useMemo<Lashitas>(
    () => (listLashista.find(({ id }) => id === preAppointment?.lashistaId) as Lashitas) || {},
    [preAppointment]
  );

  /**
   * redirects to dashboard if not set to create or update a service
   */
  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (!(preAppointment?.hasOwnProperty('clientId') || orderItemId)) {
      history.push('/dashboard');
    }
  }, [preAppointment, orderItemId]);

  return (
    <Container>
      <div className="flex flex-col">
        <div className="flex justify-between mb-[34px]">
          <span className="text-[24px] font-medium leading-[33px] tracking-[0.185em] text-white">
            Selección de servicios {firstName} {lastName}
          </span>
          <div>
            <ThreeDotButtonGreenIcon />
          </div>
        </div>
        <ServicePageBodyComponent
          listLashista={listLashista}
          currentlashistaId={id}
          products={products}
          categories={categories}
          orderItemId={orderItemId}
        />
      </div>
    </Container>
  );
};

export default ServicePage;
