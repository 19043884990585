import { ProductActions } from './action-creators';
import * as actions from './actions';
import { initialState } from './initial-state';
import { ProductState } from './state';

const productsReducer = (state: ProductState = initialState, action: ProductActions): ProductState => {
  switch (action.type) {
    case actions.LIST_PRODUCT: {
      return {
        ...state,
        products: action.items,
      };
    }
    case actions.LIST_CATEGORY: {
      return {
        ...state,
        categories: action.items,
      };
    }
    case actions.LIST_INVENTORY_SUCCESS: {
      return {
        ...state,
        inventory: action.items,
      };
    }
    case actions.INSERT_INVENTORY_SUCCESS: {
      return {
        ...state,
        inventory: state.inventory.map((int) => {
          if (int.productId === action.productId) {
            let currentTotal = 0;
            if (action.item.operation === 'INSERT') {
              currentTotal = int.currentTotal + action.item.quantity;
            } else if (action.item.operation === 'DELETE') {
              currentTotal = int.currentTotal - action.item.quantity;
            }
            return {
              ...int,
              currentTotal: currentTotal,
            };
          }
          return int;
        }),
      };
    }
    case actions.UPDATE_INVENTORY_SUCCESS: {
      return {
        ...state,
        inventory: state.inventory.map((int) => {
          if (int.productId === action.productId) {
            return {
              ...int,
              minTotal: action.item.minTotal,
            };
          }
          return int;
        }),
      };
    }

    default:
      return state;
  }
};

export default productsReducer;
