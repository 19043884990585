import { UserRole } from '@services/business/identity/entities/cognito-user';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Cognito } from '../../entities/cognito';

export type Interface = ApiInterface<{
  response: Cognito;
  pathParams: { username: string };
  body: {
    name: string;
    lastName: string;
    sucursal: string;
    role: UserRole;
    isSuperAdmin?: boolean;
  };
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-update-identity',
  method: 'PUT',
  basePath: 'identity',
  path: '/{username}',
  tablePermission: 'readWrite',
  scope: 'SUPER_ADMIN',
  schema: Schemy.schema<Interface['body']>({
    sucursal: {
      type: String,
      custom(value, body) {
        if (body.isSuperAdmin === true) {
          return true;
        }
        if (typeof value === 'string' && value.length) {
          return true;
        }
        return false;
      },
    },
    name: { type: String, required: true },
    lastName: { type: String, required: true },
    role: {
      type: String,
      enum: ['ADMIN', 'OWNER'],
      custom(value, body) {
        if (body.isSuperAdmin === true) {
          return true;
        }
        if (typeof value === 'string' && value.length) {
          return true;
        }
        return false;
      },
    },
    isSuperAdmin: { type: Boolean, required: false },
  }),
};
