import PrintRoundedIcon from '@mui/icons-material/Print';
import { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import IconButton from '../ui/iconButton';
import { LashistaTicketToPrint } from './lashistaTicketToPrint';

export const ButtonToPrintLashistaTicket = (props) => {
  // export const ButtonToPrintLashistaTicket = (props) => {
  const componentRef = useRef(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Ticket',
    removeAfterPrint: true,
  });

  return (
    <>
      <div className="float-right">
        <IconButton style={{ color: 'var(--grey)' }} onClick={handlePrint}>
          <PrintRoundedIcon />
        </IconButton>
      </div>
      <div className="hidden">
        <LashistaTicketToPrint ref={componentRef} message={props.message} />
      </div>
    </>
  );
};
