import { ApiInterface } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from 'schemy-ts';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { NoteClient } from '../../entities';
export type Interface = ApiInterface<{
  response: NoteClient;
  pathParams: { noteClientId: string };
  body: Partial<Omit<NoteClient, 'createdAt' | 'updatedAt' | 'id'>>;
  claims: { tenantId: string; 'cognito:username': string };
  queryParams: { clientId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-update-note-client',
  method: 'PUT',
  basePath: 'notes-client',
  path: '/{noteClientId}',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
  schema: Schemy.schema<Interface['body']>({
    note: { type: String, required: true },
  }),
};
