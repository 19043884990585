const LeftArrowIcon = (props: any) => {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 0C33.3662 0 43 9.63375 43 21.5C43 33.3662 33.3662 43 21.5 43C9.63375 43 0 33.3662 0 21.5C0 9.63375 9.63375 0 21.5 0ZM26.6326 15.5002L18.2321 21.5001L26.6326 27.5C27.2764 27.96 27.4269 28.8561 26.9668 29.4995C26.5068 30.1433 25.6108 30.2937 24.9673 29.8334L14.9334 22.6669C14.5567 22.3975 14.3328 21.9632 14.3328 21.5001C14.3328 21.0373 14.5567 20.6029 14.9334 20.3335L24.9673 13.1667C25.6108 12.7067 26.5068 12.8572 26.9668 13.5006C27.4269 14.1444 27.2764 15.0401 26.6326 15.5001V15.5002Z"
        fill={props.color ?? '#393939'}
      />
    </svg>
  );
};

export default LeftArrowIcon;
