import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import { listEmployeeEpic } from './list-employee';

import AppState from '../../state';
import { crudEmployeeEpic } from './employee';

const employeeEpic = combineEpics<Actions, Actions, AppState>(listEmployeeEpic, crudEmployeeEpic);

export default employeeEpic;
