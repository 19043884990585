import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as reports from '../../../sdk/reportsPerDay';
import { Actions } from '../../actions';
import { ListReportPerDay, ListReportPerDayError, ListReportPerDaySuccess } from '../action-creators';
import { LIST_REPORTS_PER_DAY } from '../actions';

export const listReportsPerDayEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListReportPerDay['type'], ListReportPerDay>(LIST_REPORTS_PER_DAY),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case LIST_REPORTS_PER_DAY: {
            const result = await reports.listReports(action.date);
            return new ListReportPerDaySuccess(result);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case LIST_REPORTS_PER_DAY: {
            return new ListReportPerDayError(err.message, err.description);
          }
        }
      }
    })
  );
