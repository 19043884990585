import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import { listNotesEpic } from './list-notes';

import AppState from '../../state';
import { crudNotesEpic } from './notes';

const notesEpic = combineEpics<Actions, Actions, AppState>(listNotesEpic, crudNotesEpic);

export default notesEpic;
