import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  btn?: boolean;
  item?: boolean;
  primary?: boolean;
  secondary?: boolean;
  outline?: boolean;
  children?: any;
}

const IconButton = (props: ButtonProps) => {
  return (
    <button
      {...props}
      type="button"
      className={`flex justify-center items-center p-0 border rounded-md leading-4 tracking-widest hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue
        ${
          props.btn
            ? 'min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm hover:border-grey text-base text-white font-normal'
            : ''
        }
        ${
          props.item
            ? 'min-h-[30px] min-w-[160px] px-3 py-2 shadow-sm hover:border-grey text-base text-white font-normal'
            : ''
        }
        ${props.primary ? 'bg-primary' : ''}
        ${props.secondary ? 'bg-red' : ''}
        ${props.outline ? 'bg-white text-primary border-primary border-2' : ''}
        ${props.className ?? ''}`}
    >
      {props.children}
    </button>
  );
};

export default IconButton;
