import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import { listCashRegisterEpic } from './list-cash-register';
import { listCashRegisterTransactionsEpic } from './list-cash-register-transactions';

import AppState from '../../state';
import { crudCashRegisterEpic } from './cash-register';

const cashRegisterEpic = combineEpics<Actions, Actions, AppState>(
  listCashRegisterEpic,
  listCashRegisterTransactionsEpic,
  crudCashRegisterEpic
);

export default cashRegisterEpic;
