import {
  FileGreenIcon,
  HistorySmallIcon,
  PaperSmallIcon,
  PlusGreenIcon,
  WarningExclamationYellowIcon,
} from '@webapp/components/ui/shared/icons';
import { FunctionComponent, useCallback, useState } from 'react';

import ListClientDetailComponent from './list-client-detail';

// store state
import { ModalMessage } from '@webapp/components/ui/modal';
import Loading from '@webapp/components/ui/preloader/Loading';
import { CreatePreAppointment } from '@webapp/store/appointments/action-creators';
import {
  DeleteClient,
  GetBalanceClient,
  GetHistoryAppointmentClient,
  GetHistoryAppointmentClientSuccess,
  GetRewardClient,
  ListClient,
  SetCurrentClient,
} from '@webapp/store/client/action-creators';
import { Client } from '@webapp/store/client/state';
import AppState from '@webapp/store/state';
import { OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import useDebounce from '@webapp/utils/debounce';
import { useHistory, useParams } from 'react-router-dom';
import ModalNewClient from './modal-new-client';

interface ListClientProps {
  customers: Client[];
  loading: boolean;
}

const ListClientComponent: FunctionComponent<ListClientProps> = ({ customers, loading }) => {
  const {
    client: { currentClient: currentCustomer, currentClientHistoryAppointment, rewardClient, balanceClient },
    ui: { pageWhereCameFrom },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const history = useHistory();
  const { lashistaId, start, statusAppointment } = useParams<{
    lashistaId: string;
    start: string;
    statusAppointment: string;
  }>();
  const [customerInputSearch, setCustomerInputSearch] = useState('');
  const [showInactiveClients, setShowInactiveClients] = useState(false);

  /**
   * this method is for render the icons alerts in every client
   */
  const renderWarnings = useCallback((client: Client | undefined) => {
    let yellowIcon = false;

    if (client?.health?.allergies || client?.health?.note) {
      yellowIcon = true;
    }

    return (
      <>
        {yellowIcon && (
          <span className="mr-5">
            <WarningExclamationYellowIcon />
          </span>
        )}
        {!yellowIcon && <span className="mr-5 w-[20px] y-[20px]"> </span>}
      </>
    );
  }, []);

  /**
   * this method is used to render the list of clients
   */
  const renderClientList = useCallback(
    (customersList: Client[]) => {
      return customersList
        .filter((client) => client.status === 'ACTIVE' || showInactiveClients)
        .map((customer, i) => (
          <div
            key={i}
            className={`grid grid-cols-3 py-[13px] last:border-b cursor-pointer 
            ${
              customer.id == currentCustomer?.id
                ? 'bg-veryDarkGray rounded-[3px] border-2 border-primary'
                : 'border-t border-grey2'
            }`}
            onClick={() => {
              selectClient(customer);
            }}
          >
            <div className="flex justify-start ">
              <span className="text-base leading-[19px] tracking-[0.08rem]">
                {customer?.firstName} {customer?.lastName}
              </span>
            </div>
            <div className="flex justify-center">
              <span className="font-light text-base leading-[19px] tracking-[0.08rem]">{customer?.phone}</span>
            </div>
            <div className="flex justify-end">{renderWarnings(customer)}</div>
          </div>
        ));
    },
    [currentCustomer]
  );

  const handleSearch = useDebounce((query: string) => dispatch(new ListClient(query)), 1500);

  /**
   * execute multiple functions to get the info of the client
   * @param client Client
   */
  const selectClient = (client: Client): void => {
    dispatch(new SetCurrentClient(client));
    dispatch(new GetBalanceClient(client.id));
    dispatch(new GetRewardClient(client.id));
    const dateInitial = new Date(Date.now() - 15 * 86400000);
    dateInitial.setHours(0);
    dateInitial.setMinutes(0);
    dateInitial.setSeconds(0);
    const dateEnd = new Date(Date.now() + 15 * 86400000);
    dateEnd.setHours(0);
    dateEnd.setMinutes(0);
    dateEnd.setSeconds(0);
    dispatch(
      new GetHistoryAppointmentClient({
        clientId: client.id,
        dateInitial: dateInitial.getTime(),
        dateEnd: dateEnd.getTime(),
      })
    );
  };

  /**
   * navigate to the ficha of the client previous selected
   */
  const openFicha = () => {
    history.push('/clientes/ficha');
  };

  const openHistory = () => {
    history.push('/clientes/historial');
  };

  /**
   * change the status of the client to DELETED(soft delete)
   */
  const deleteClient = () => {
    if (currentCustomer) {
      dispatch(new DeleteClient(currentCustomer.id));
      dispatch(new GetHistoryAppointmentClientSuccess(undefined));
      dispatch(new SetCurrentClient(undefined));
    }
  };

  /**
   * navigate to the calendar or the service page. this will depend of where the user came
   */
  const handleClickServices = () => {
    if (pageWhereCameFrom === 'PAYMENT') {
      dispatch(
        new CreatePreAppointment({
          clientId: currentCustomer?.id,
          status: 'IN_PROCESS',
          payment: {
            totalAmount: 0,
            totalDiscount: 0,
            rewardUsed: 0,
            methods: [
              { type: 'CARD', amount: 0 },
              { type: 'CASH', amount: 0 },
              { type: 'BALANCE', amount: 0 },
              { type: 'TRANSFER', amount: 0 },
            ],
          },
        })
      );
      history.push('/pagos');
      return;
    }

    if (currentCustomer?.id === 'cus_anonimo') {
      dispatch(
        new OpenModal('MESSAGE_MODAL', {
          title: 'Aviso',
          loading: false,
          message: 'El cliente WALKING no se puede usar para crear citas!',
        })
      );
      return;
    }

    if (lashistaId && start && currentCustomer) {
      if (statusAppointment) {
        dispatch(
          new CreatePreAppointment({
            lashistaId: 'AWAIT',
            initialDate: Number(start),
            clientId: currentCustomer?.id,
            statusAppointment: 'AWAIT',
          })
        );
      } else {
        dispatch(
          new CreatePreAppointment({
            lashistaId: lashistaId,
            initialDate: Number(start),
            clientId: currentCustomer?.id,
          })
        );
      }
      dispatch(new SetCurrentClient(undefined));
      history.push('/servicios');
      return;
    } else {
      dispatch(new SetCurrentClient(undefined));
      history.push('/dashboard');
      return;
    }
  };

  return (
    <div className="flex justify-between">
      <ModalMessage />
      <ModalNewClient selectClient={selectClient} />
      <div className="flex-1 flex flex-col py-6 px-5 mt-5 rounded-[3px] bg-darkGray">
        <div className="flex justify-between mb-12 gap-[27px]">
          <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
            <div className="flex items-center  p-1.5 rounded-[3px] bg-veryDarkGray">
              <label
                className="text-base font-light leading-[20px] tracking-[0.185em] text-white "
                htmlFor="inputSearch"
              >
                Buscar:
              </label>
            </div>
            <div className="flex-1">
              <input
                type="text"
                className="text-base font-light leading-[19px] tracking-[0.185em] text-grey2 border-0 w-full h-full bg-darkGray"
                name="inputSearch"
                id="inputSearch"
                value={customerInputSearch}
                onChange={(e) => {
                  setCustomerInputSearch(e.target.value);
                  handleSearch(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex">
            <button
              type="button"
              className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
              onClick={() => {
                setCustomerInputSearch('');
                handleSearch('');
                setShowInactiveClients(false);
              }}
            >
              <span className="text-base leading-[20px] tracking-[0.185em] text-white">Limpiar campos</span>
            </button>
          </div>
        </div>

        <div className="flex justify-center">
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="flex-1 flex flex-col overflow-y-auto max-h-[650px] text-white">
                {renderClientList(customers)}
              </div>
              {currentCustomer && (
                <div className="flex flex-col w-[275px] ml-[20px] overflow-y-auto max-h-[650px]">
                  <ListClientDetailComponent
                    client={currentCustomer}
                    appointments={currentClientHistoryAppointment}
                    rewardClient={rewardClient}
                    balanceClient={balanceClient}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex-1 flex flex-col  py-6 px-3 mt-5 ml-5 rounded-[3px] max-w-[376px] bg-darkGray">
        <div className="flex justify-between mb-[30px]">
          <button
            type="button"
            className="flex justify-center min-w-[161px] py-5 border-primary rounded-[8px] border-2"
            onClick={() => dispatch(new OpenModal('NEW_CLIENT_MODAL'))}
          >
            <div className="mr-[10px]">
              <PlusGreenIcon />
            </div>
            <span className="text-base leading-[20px] tracking-[0.08rem] font-medium text-primary">Nuevo cliente</span>
          </button>
          <button
            type="button"
            className={`flex justify-center min-w-[161px] py-5 rounded-[8px] border-2 ${
              currentCustomer !== undefined ? 'border-primary' : 'border-grey3'
            }`}
            disabled={currentCustomer === undefined ? true : false}
            onClick={() => openFicha()}
          >
            <div className="mr-[10px]">
              <FileGreenIcon color={currentCustomer !== undefined ? 'var(--primary)' : 'var(--grey3)'} />
            </div>
            <span
              className={`text-base leading-[20px] tracking-[0.08rem] font-medium ${
                currentCustomer !== undefined ? 'text-primary' : 'text-grey3'
              }`}
            >
              Ficha
            </span>
          </button>
        </div>
        <hr className="mb-[30px] border-grey2" />
        <div className="flex justify-between mb-[30px]">
          <button
            type="button"
            className={`flex justify-center min-w-[161px] py-5 rounded-[8px] border-2 ${
              currentCustomer !== undefined ? 'border-primary' : 'border-grey3'
            }`}
            disabled={currentCustomer === undefined ? true : false}
            onClick={() => deleteClient()}
          >
            <div className="mr-[10px]">
              <PaperSmallIcon color={currentCustomer !== undefined ? 'var(--primary)' : 'var(--grey3)'} />
            </div>
            <span
              className={`text-base leading-[20px] tracking-[0.08rem] font-medium ${
                currentCustomer !== undefined ? 'text-primary' : 'text-grey3'
              }`}
            >
              Borrar
            </span>
          </button>
          <button
            type="button"
            className={`flex justify-center min-w-[161px] py-5 rounded-[8px] border-2 ${
              currentCustomer !== undefined ? 'border-primary' : 'border-grey3'
            }`}
            disabled={currentCustomer === undefined ? true : false}
            onClick={() => openHistory()}
          >
            <div className="mr-[10px]">
              <HistorySmallIcon color={currentCustomer !== undefined ? 'var(--primary)' : 'var(--grey3)'} />
            </div>
            <span
              className={`text-base leading-[20px] tracking-[0.08rem] font-medium ${
                currentCustomer !== undefined ? 'text-primary' : 'text-grey3'
              }`}
            >
              Historial
            </span>
          </button>
        </div>
        <div className="flex justify-between mb-[30px]">
          <button
            type="button"
            className={`flex justify-center min-w-[161px] py-5 rounded-[8px] border-2 ${
              currentCustomer !== undefined ? 'border-primary' : 'border-grey3'
            }`}
            disabled={currentCustomer === undefined ? true : false}
            onClick={() => dispatch(new OpenModal('LIST_NOTE_CREDIT_MODAL'))}
          >
            <div className="mr-[10px]">
              <PaperSmallIcon color={currentCustomer !== undefined ? 'var(--primary)' : 'var(--grey3)'} />
            </div>
            <span
              className={`text-base leading-[20px] tracking-[0.08rem] font-medium ${
                currentCustomer !== undefined ? 'text-primary' : 'text-grey3'
              }`}
            >
              Anticipos
            </span>
          </button>
        </div>
        <hr className="mb-[30px] border-grey2" />
        <div className="flex justify-between mb-[30px]">
          <button
            type="button"
            className="flex justify-center min-w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
            onClick={() => {
              history.push('/dashboard');
              dispatch(new SetCurrentClient(undefined));
            }}
          >
            <span className="text-base leading-[20px] tracking-[0.08rem] font-medium text-white">Cancelar</span>
          </button>
          <button
            type="button"
            className="flex justify-center min-w-[161px] py-5 border-primary rounded-[8px] border-2 bg-primary"
            onClick={() => handleClickServices()}
          >
            <span className="text-base leading-[20px] tracking-[0.08rem] font-medium text-white">Hecho</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListClientComponent;
