import {
  ApiInterface,
  EmptyObject,
  PaginatedQueryParams,
  PaginatedResponse,
} from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Discount } from '../../entities/discounts';

export type Interface = ApiInterface<{
  response: PaginatedResponse<Discount>;
  pathParams: EmptyObject;
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: PaginatedQueryParams;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-list-discounts',
  method: 'GET',
  basePath: 'discounts',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
