import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { NoteClient } from '../../entities';

export type Interface = ApiInterface<{
  response: NoteClient;
  pathParams: { noteClientId: string };
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: { clientId: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-get-note-client',
  method: 'GET',
  basePath: 'notes-client',
  path: '/lastNote',
  tablePermission: 'readWrite',
  scope: 'RECEPTIONIST',
};
