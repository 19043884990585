import { ListLashistaSuccess } from '@webapp/store/lashista/action-creators';
import AppState from '@webapp/store/state';
import { useOpStore } from '@webapp/store/useOpStore';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Loading from './components/ui/preloader/Loading';
import Routes from './routes';
import { listDiscounts } from './sdk/discount';
import { listLashita } from './sdk/lashista';
import { dbStore } from './sdk/models/db';
import { listCategory, listInventory, listProduct } from './sdk/product';
import { getSucursal } from './sdk/sucursal';
import { ListDiscount } from './store/discounts/action-creators';
import { ListCategory, ListInventorySuccess, ListProduct } from './store/products/action-creators';
import { GetSucursal } from './store/sucursal/action-creators';
import { getSubdomain } from './utils/subDomain';
import { useErrorQueue } from './utils/useQueueInternet';

function App(): JSX.Element {
  const {
    auth,
    dispatch,
    client: { listClient },
    appointments: { appointments },
  } = useOpStore((state: AppState) => state);
  const [loading, setLoading] = useState(true);
  const [loadingSucursal, setLoadingSucursal] = useState(true);

  const checkSucursal = async () => {
    try {
      const response = await getSucursal();
      dispatch(new GetSucursal(response));
      setLoadingSucursal(false);
    } catch (error) {
      window.location.href = 'https://mlashes.net/';
    }
  };

  const fetchData = async () => {
    try {
      const [
        { items: products },
        { items: lashistas },
        { items: categories },
        { items: discount },
        { items: inventory },
      ] = await Promise.all([
        listProduct(),
        listLashita(),
        listCategory(),
        listDiscounts(),
        listInventory(getSubdomain()),
      ]);
      dispatch(new ListLashistaSuccess(lashistas));
      dispatch(new ListProduct(products));
      dispatch(new ListCategory(categories));
      dispatch(new ListDiscount(discount));
      dispatch(new ListInventorySuccess(inventory));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useErrorQueue();

  useEffect(() => {
    if (!loadingSucursal) {
      fetchData();
    }
  }, [auth, loadingSucursal]);

  useEffect(() => {
    checkSucursal();
  }, []);

  useEffect(() => {
    Promise.all(
      appointments.map(async (items) => {
        await dbStore.createOrUpdate(items);
      })
    );
  }, [appointments]);

  useEffect(() => {
    Promise.all(
      listClient.map(async (items) => {
        await dbStore.createOrUpdateClient(items);
      })
    );
  }, [listClient]);

  if (loading || loadingSucursal) {
    return <Loading loading={loading} />;
  }

  return (
    <Switch>
      {Routes.map((route, index) => (
        <Route
          path={route.path}
          exact
          key={index}
          render={(props) =>
            React.createElement(route.component, {
              ...props,
            } as React.Attributes)
          }
        />
      ))}
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );
}

export default App;
