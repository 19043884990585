const HistoryBoxRegisterIcon = (props: any) => {
  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.429 0.00040236C15.7818 0.00040236 7.57459 6.3285 4.71034 15.1254L2.69161 11.4586C2.43625 10.9773 1.91871 10.7048 1.38662 10.7708C0.947821 10.8215 0.563641 11.0959 0.366091 11.4995C0.168542 11.9032 0.184471 12.3817 0.407969 12.771L3.99714 19.3125C4.33762 19.9403 5.10415 20.1798 5.73049 19.8543L12.1131 16.5209C12.4362 16.3698 12.6848 16.0903 12.8018 15.7476C12.9187 15.4044 12.8937 15.0277 12.7326 14.7041C12.5714 14.3804 12.2879 14.1381 11.9478 14.0326C11.6078 13.9275 11.2405 13.9684 10.9305 14.146L7.13739 16.1252C9.60592 8.33414 16.8456 2.66688 25.4299 2.66688C36.0562 2.66688 44.6197 11.3164 44.6197 22.0002C44.6197 32.684 36.0568 41.3335 25.4299 41.3335C16.3389 41.3335 8.7513 34.9677 6.75052 26.4585V26.459C6.70318 26.0847 6.50335 25.7484 6.19974 25.5331C5.89658 25.3178 5.51832 25.2444 5.15827 25.3304C4.79868 25.416 4.49188 25.6536 4.31299 25.9838C4.13457 26.3144 4.10133 26.7064 4.2215 27.0631C6.50335 36.7669 15.1446 44 25.4295 44C37.4482 44 47.2284 34.1442 47.2284 22C47.2284 9.85583 37.4469 0 25.4295 0L25.429 0.00040236ZM25.5514 9.97945C24.8791 10.0548 24.3747 10.6426 24.3893 11.3336V22.0005V22.0001C24.3889 22.4056 24.5691 22.7887 24.8786 23.0417L31.4042 28.3752C31.9673 28.8356 32.7889 28.7421 33.2395 28.1668C33.6901 27.5916 33.5986 26.7522 33.0356 26.2918L26.9993 21.3541V11.3332V11.3336C27.0075 10.9495 26.8532 10.5807 26.5756 10.3212C26.2983 10.0618 25.9251 9.93713 25.5514 9.97945H25.5514Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
};

export default HistoryBoxRegisterIcon;
