import { Orden, OrdenItem } from '@services/business/invoice/entities';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';

export type Interface = ApiInterface<{
  response: {
    order: Orden;
    items: OrdenItem[];
  }[];
  pathParams: EmptyObject;
  body: {
    tenantId?: string;
    groupIds?: string[];
    dateInitial: number;
    dateEnd: number;
    status?: string;
    lashistaId?: string;
    onlyLashista?: boolean;
    projectionExpression?: string[];
    projectionExpressionItem?: string[];
  };
  claims: { tenantId: string; isAdmin: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-history-order',
  method: 'POST',
  basePath: 'history',
  path: '/order',
  tablePermission: 'readWrite',
  scope: 'SUPER_ADMIN',
  schema: Schemy.schema<Interface['body']>({
    tenantId: { type: String },
    groupIds: { type: [String] },
    dateInitial: { type: Number, required: true },
    dateEnd: { type: Number, required: true },
    status: { type: String, enum: ['PAID', 'UNPAID', 'IN_PROCESS', 'CANCELED'] },
    lashistaId: { type: String },
    projectionExpression: { type: [String] },
    onlyLashista: { type: Boolean },
  }),
};
