import * as apiReport from '@services/business/history/catalog/api';

import { OrdenItem } from '@services/business/invoice/entities';

export const normalizeProductService = (
  data: apiReport.getHistoryOrder.Interface['response'],
  dataProduct: any
): Array<{
  name: string;
  idService: string;
  quantity: number;
  totalSales: number;
  discount: number;
  totalIncome: number;
  categoryId: string;
  productPrice: number;
  color: string;
  type: OrdenItem['type'];
}> => {
  const response = dataProduct?.map(({ name, serviceId, categoryId, type, color }: any) => {
    // Get items from order
    const getServiceInOrder = data.filter(({ items }) => {
      return items.find((item) => item.categoryId === categoryId);
    });

    // This filter by items
    const orderItem = getServiceInOrder
      .flatMap(({ items }) => items)
      .filter((service) => service.categoryId === categoryId);

    const productPrice = orderItem.map(({ price }) => price);

    const discount = orderItem.reduce((a, b) => a + (b.discountAmount || 0), 0);
    const totalSales = orderItem.reduce((a, b) => a + (b.price || 0), 0);
    return {
      type,
      name,
      categoryId,
      idService: serviceId,
      quantity: orderItem.length,
      totalIncome: totalSales - discount,
      discount,
      totalSales,
      productPrice,
      color,
    };
  });
  return response;
};
