import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as api from '../../../sdk/categories';
import { Actions } from '../../actions';
import {
  CreateCategories,
  CreateCategoriesError,
  CreateCategoriesSuccess,
  DeleteCategories,
  DeleteCategoriesError,
  UpdateCategories,
  UpdateCategoriesError,
  UpdateCategoriesSuccess,
} from '../action-creators';
import { CREATE_CATEGORIES, DELETE_CATEGORIES, UPDATE_CATEGORIES } from '../actions';

export const crudCategoriesEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      CreateCategories['type'] | UpdateCategories['type'] | DeleteCategories['type'],
      CreateCategories | UpdateCategories | DeleteCategories
    >(CREATE_CATEGORIES, UPDATE_CATEGORIES, DELETE_CATEGORIES),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case UPDATE_CATEGORIES: {
            await api.updateCategories(action.category);
            return new UpdateCategoriesSuccess();
          }
          case CREATE_CATEGORIES: {
            await api.createCategories(action.category);
            return new CreateCategoriesSuccess();
          }
          case DELETE_CATEGORIES: {
            await api.deleteCategories(action.categoryId);
            return new CreateCategoriesSuccess();
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case UPDATE_CATEGORIES: {
            return new UpdateCategoriesError(err.message, err.description);
          }
          case CREATE_CATEGORIES: {
            return new CreateCategoriesError(err.message, err.description);
          }
          case DELETE_CATEGORIES: {
            return new DeleteCategoriesError(err.message, err.description);
          }
        }
      }
    })
  );
