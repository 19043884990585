import { NotesActions } from './action-creators';
import * as actions from './actions';
import { notesInitial } from './initial-state';
import { NotesState } from './state';

const notesReducer = (state: NotesState = notesInitial, action: NotesActions): NotesState => {
  switch (action.type) {
    case actions.LIST_NOTES_SUCCESS: {
      return {
        ...state,
        notesList: action.items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
      };
    }
    case actions.GET_LAST_NOTES_SUCCESS: {
      return {
        ...state,
        currentNote: action.data,
      };
    }

    default:
      return state;
  }
};

export default notesReducer;
