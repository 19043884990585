import { ApiError } from '@stetamalo/cloud-platform/handler/api/api-error';
import { getSubdomain } from '../utils/subDomain';
import { getToken } from './auth';
import { dbStore } from './models/db';

const API_DOMAIN = process.env.REACT_APP_API_URL;

export type ApiClient = (endpoint: string, method: string, data?: unknown, isPublic?: boolean) => Promise<Response>;

export const getFullUrl = (service: string, endpoint: string): string => `${API_DOMAIN}${service}${endpoint}`;

export const CallApi =
  (service: string, isDataHistorical?: boolean): ApiClient =>
  async (endpoint: string, method: string, data?: unknown, isPublic?: boolean, withCache?: boolean) => {
    const token = await getToken();
    const headers = new Headers();

    if (!token && !isPublic) {
      throw new Error('Auth.Error.MissingToken');
    }

    if (token) {
      headers.append('Authorization', token);
    }

    headers.append('Content-Type', 'application/json');
    headers.append('Mlashes-Store', getSubdomain());
    if (!withCache) {
      headers.append('Cache-Control', 'max-age=0');
    }

    const params: RequestInit = { method, headers };

    if (data) {
      if (method !== 'GET') {
        params.body = JSON.stringify(data);
      } else {
        endpoint = `${endpoint}?${new URLSearchParams(data as Record<string, string>).toString()}`;
      }
    }

    return fetch(getFullUrl(service, endpoint), params).catch((error) => {
      if (!isDataHistorical) {
        dbStore.queueError.add({
          id: new Date().getTime(),
          type: 'FETCH',
          data: JSON.stringify({ url: getFullUrl(service, endpoint), params }),
        });
      }

      throw new ApiError(error.message);
    });
  };

export const handleResponse = async (response: Response): Promise<string> => {
  const data = await response.text();

  if (!response.ok) {
    throw new Error(data);
  }

  return data;
};

export const handleJSONResponse = async <T = unknown>(response: Response): Promise<T> => {
  const data = await response.json();

  if (!response.ok) {
    throw new ApiError(data.code, response.status, data.description);
  }

  return parseCommonFields(data);
};

// eslint-disable-next-line
export const parseCommonFields = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((d) => parseCommonFields(d));
  }

  if ('created_at' in data) {
    data.created_at = new Date(data.created_at);
  }

  if ('updated_at' in data) {
    data.updated_at = new Date(data.updated_at);
  }

  return data;
};
