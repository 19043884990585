import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { tz } from 'moment-timezone';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Sucursal } from '../../entities/sucursal';

export type Interface = ApiInterface<{
  response: Sucursal;
  pathParams: { sucursalId: string };
  body: Omit<Sucursal, 'createdAt' | 'updatedAt' | 'status' | 'alias' | 'country' | 'id'>;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

const timezoneList = tz
  .names()
  .sort((a, b) => a.localeCompare(b))
  .map((tzName) => tzName);

export const config: ApiConfig<Interface> = {
  name: 'api-update-sucursal',
  method: 'PUT',
  basePath: 'sucursal',
  path: '/{sucursalId}',
  tablePermission: 'readWrite',
  metadataAuth: {
    onlySuperAdmin: true,
  },
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    name: { type: String, required: true },
    address: { type: String, required: true },
    phone: { type: String, required: true },
    adminName: { type: String, required: true },
    adminEmail: { type: String, required: true },
    utc: { type: String, required: true, enum: timezoneList },
    workingHours: {
      monday: {
        status: { type: Boolean, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
      },
      tuesday: {
        status: { type: Boolean, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
      },
      wednesday: {
        status: { type: Boolean, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
      },
      thursday: {
        status: { type: Boolean, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
      },
      friday: {
        status: { type: Boolean, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
      },
      saturday: {
        status: { type: Boolean, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
      },
      sunday: {
        status: { type: Boolean, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
      },
    },
  }),
};
