import { useOpStore } from '@webapp/store/useOpStore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { CloseModal } from '@webapp/store/ui/action-creators';
import { InvoiceToPrint } from './invoiceToPrint';

export const ButtonToPrintInvoice = (props) => {
  const componentRef = useRef(null);
  const {
    auth: { user },
    product: { products },
    client: { currentClient },
    appointments: { preAppointment },
    lashista: { listLashista },
    dispatch,
  } = useOpStore((state) => state);
  const [lashista, setLashista] = useState();
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalServices, setTotalServices] = useState(0);

  useEffect(() => {
    if (preAppointment !== undefined && listLashista !== undefined && currentClient !== undefined) {
      setLashista(listLashista.find((l) => l.id === preAppointment?.orden[0]?.lashistaId));
      setTotalProducts(
        preAppointment?.orden
          ?.filter((item) => item.type === 'PRODUCT')
          .reduce((a, b) => a + (b.price - (b.discountAmount ?? 0)), 0)
      );
      setTotalServices(
        preAppointment.orden
          ?.filter((item) => item.type === 'SERVICE')
          .reduce((a, b) => a + (b.price - (b.discountAmount ?? 0)), 0)
      );
    }
  }, [preAppointment, listLashista, currentClient]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = useCallback(() => {
    if (props.payOrderFunction) {
      props.payOrderFunction();
    }
    dispatch(new CloseModal('PAYMENT_MODAL'));
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Nota',
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const listProducts = products.filter((item) => item.type === 'PRODUCT');

  return (
    <div>
      <button
        type="button"
        onClick={handlePrint}
        className="flex justify-center items-center p-0 border border-primary bg-primary rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
      >
        Ver Ticket
      </button>
      <div className="hidden">
        <InvoiceToPrint
          ref={componentRef}
          client={currentClient}
          lashista={lashista}
          listLashista={listLashista}
          seller={user?.attributes?.['given_name']}
          paymentOrder={preAppointment?.payment}
          orderItems={preAppointment?.orden}
          totalServices={totalServices}
          totalProducts={totalProducts}
          listProducts={listProducts}
        />
      </div>
    </div>
  );
};
