import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import { listNewOldClientEpic } from './list-old-new-client';
import { listReportsEpic } from './list-reports';

import AppState from '../../state';
import { listReportsClientEpic } from './list-reports-client';
import { listReportsPerDayEpic } from './list-reports-per-day';

export const reportsEpic = combineEpics<Actions, Actions, AppState>(
  listReportsEpic,
  listNewOldClientEpic,
  listReportsClientEpic,
  listReportsPerDayEpic
);

export default reportsEpic;
