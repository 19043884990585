import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useMemo, useRef } from 'react';

import Loading from '@webapp/components/ui/preloader/Loading';
import { GET_ORDEN } from '@webapp/store/appointments/actions';
import { Appointment } from '@webapp/store/appointments/state';
import AppState from '@webapp/store/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { formatDateTime, formatFullDateToView } from '@webapp/utils/formatDate';

const LlegadasNoteModal: FunctionComponent = () => {
  const cancelButton = useRef(null);
  const {
    ui: { modalOpened },
    lashista: { listLashista },
    product: { products, categories },
    appointments: { currentAppointment },
    dispatch,
    loading,
  } = useOpStore((state: AppState) => state, [GET_ORDEN]);

  const { open } = useMemo(() => {
    return {
      open: modalOpened?.['LIST_NOTE']?.open ?? false,
      appointment: (modalOpened?.['LIST_NOTE']?.value as Appointment) ?? {},
    };
  }, [modalOpened?.['LIST_NOTE']]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('LIST_NOTE'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#6b7280] bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block align-bottom bg-darkGray rounded-[8px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[574px] sm:w-full">
              {loading[GET_ORDEN] ? (
                <Loading />
              ) : (
                <>
                  <div className="bg-primary py-[25px] px-[36px] flex justify-between">
                    <Dialog.Title
                      as="span"
                      className="text-white text-[24px] font-bold tracking-[0.185em] leading-[30px]"
                    >
                      Nota
                    </Dialog.Title>
                    <span className="text-white text-[20px] font-bold tracking-[0.185em] leading-[25px]">
                      lashista : {listLashista.find((la) => la.id === currentAppointment?.lashistaId)?.alias}
                    </span>
                  </div>
                  <div className="my-[17px] px-[36px] flex flex-col">
                    <div className="flex justify-between px-[20px] py-[14px] mb-[44px]">
                      <span className="text-[22px] font-normal tracking-[0.185em] leading-[28px] text-white">
                        {currentAppointment?.detailClient?.firstName} {currentAppointment?.detailClient?.lastName}
                      </span>
                      <span className="text-[18px] font-normal tracking-[0.185em] leading-[28px] text-white">
                        {formatFullDateToView(currentAppointment?.dateInitial ?? 0)}-
                        {formatDateTime(currentAppointment?.dateInitial ?? 0)}
                      </span>
                    </div>

                    <div className="border-t-grey2 border-t-[1px] flex justify-between px-[20px] py-[14px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        Servicio
                      </span>
                      <span className="text-[18px] font-normal tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        {products.find((p) => p.id === currentAppointment?.serviceId)?.name}
                      </span>
                    </div>
                    <div className="border-t-grey2 border-t-[1px] flex justify-between px-[20px] py-[14px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        Categoria
                      </span>
                      <span className="text-[18px] font-normal tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        {categories.find((p) => p.id === currentAppointment?.categoryId)?.name}
                      </span>
                    </div>
                    <div className="border-t-grey2 border-t-[1px] flex justify-between px-[20px] py-[14px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        Precio
                      </span>
                      <span className="text-[18px] font-normal tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        ${currentAppointment?.price}
                      </span>
                    </div>
                    <div className="border-t-grey2 border-t-[1px] flex justify-between px-[20px] py-[14px]">
                      <span className="text-[18px] font-light tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        Duración
                      </span>
                      <span className="text-[18px] font-normal tracking-[0.185em] leading-[23px] w-1/2 text-left text-white">
                        {(products.find((p) => p.id === currentAppointment?.serviceId)?.duration ?? 0) / 60} min
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-end mb-[29px] px-[36px]">
                    <button
                      type="button"
                      className="flex justify-center w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
                      onClick={() => dispatch(new CloseModal('LIST_NOTE'))}
                      ref={cancelButton}
                    >
                      <span className="text-base leading-[19px] tracking-[0.08rem] text-white">Cerrar</span>
                    </button>
                    {/* <button
                      type="button"
                      className="flex justify-center  w-[161px] py-5 ml-[29px] border-primary rounded-[8px] border-2 bg-primary"
                      onClick={() => dispatch(new CloseModal('LIST_NOTE'))}
                    >
                      <PrintWhiteIcon />
                      <span className="text-base leading-[19px] tracking-[0.08rem] text-white ml-[6px]">Imprimir</span>
                    </button> */}
                  </div>
                </>
              )}
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default LlegadasNoteModal;
