import { CheckGreenIcon, CheckRedIcon, DeleteSmallIcon } from '@webapp/components/ui/shared/icons';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

// store state
import { generateId } from '@utils/generate-id';
import SearchLashistaModal from '@webapp/components/ui/modal/modal-search-lashista';
import { dbStore } from '@webapp/sdk/models/db';
import {
  CreateAppointment,
  GetAppointmentByDate,
  GetOrdenItem,
  GetOrdenItemSuccess,
  UpdateAppointment,
  UpdatePreAppointment,
} from '@webapp/store/appointments/action-creators';
import { Appointment, CategoryWithService } from '@webapp/store/appointments/state';
import { IListLashista } from '@webapp/store/lashista/state';
import { Category, Service } from '@webapp/store/products/state';
import { CloseModal, OpenModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import {
  checkIfSucursalIsOpen,
  formatDateTime,
  formatFullDate,
  formatFullDateUS,
  lastAppointmentBeforeClose,
  sumSecondsDate,
} from '@webapp/utils/formatDate';
import { useLiveQuery } from 'dexie-react-hooks';
import { useHistory } from 'react-router-dom';

interface PageProps {
  products: Service[];
  categories: Category[];
  listLashista: IListLashista[];
  currentlashistaId: string;
  orderItemId: string;
}

type Errors = 'EXIST' | 'DATE';

const ServicePageBodyComponent: FunctionComponent<PageProps> = ({
  products,
  categories,
  listLashista,
  currentlashistaId,
  orderItemId,
}) => {
  const {
    sucursal: { sucursal },
    appointments: { preAppointment, updateAppointment, appointmentsOfTheDay },
    ui: { pageWhereCameFrom },
    dispatch,
  } = useOpStore((state) => state);
  const client = useLiveQuery(() => dbStore.client.get(preAppointment?.clientId ?? ''));
  const history = useHistory();
  const [currentCategory, setCurrentCategory] = useState<Service[]>();
  const [disabledButton, setDisabledButton] = useState(false);
  const [currentServiceList, setCurrentServiceList] = useState<Appointment[]>([]);
  const [serviceSummary, setServiceSummary] = useState({
    selected: 0,
    totalPrice: 0,
    totalDuration: 0,
  });
  const [categoriesAvailableSearch, setCategoriesAvailableSearch] = useState<string>('');
  const [servicesAvailableSearch, setServicesAvailableSearch] = useState<string>('');
  const [servicesSelectedSearch, setServicesSelectedSearch] = useState<string>('');
  const [initialDate, setInitialDate] = useState<number | undefined>(
    preAppointment?.initialDate ?? new Date().getTime()
  );
  const [inputDate, setInputDate] = useState<string>(
    formatFullDate(preAppointment?.initialDate ?? new Date().getTime())
  );
  const [inputTime, setInputTime] = useState<string>(
    formatDateTime(preAppointment?.initialDate ?? new Date().getTime())
  );
  const [lashistaId, setLashistaId] = useState<string>(currentlashistaId);
  const [clientId, setClientId] = useState<string | undefined>(preAppointment?.clientId);
  const [orderId, setOrderId] = useState<string>('');
  const [availability, setAvailability] = useState<boolean>(true);
  const [messageError, setMessageError] = useState({
    EXIST: { status: false, value: '' },
    DATE: { status: false, value: '' },
  });

  /**
   * delete the selected service on the list
   * @param serviceId string
   */
  const deleteService = (serviceId: string) => {
    setCurrentServiceList((prev) => prev.filter((item) => item.id !== serviceId));
  };

  /**
   * cancel the appointment and go back to the client page
   */
  const cancelAppointment = () => {
    if (updateAppointment) {
      dispatch(new GetOrdenItemSuccess(undefined));
      history.push('/dashboard');
    } else {
      dispatch(new UpdatePreAppointment(undefined));
      history.push('/clientes');
    }
  };

  /**
   * set the error to show
   * @param type Errors
   * @param status boolean
   * @param value string
   */
  const setErrors = (type: Errors, status: boolean, value: string) => {
    setMessageError((prev) => ({
      ...prev,
      [type]: { status: status, value: value },
    }));
  };

  /**
   * sent the data to the backend to create a appointment
   */
  const createAppointment = useCallback(() => {
    if (currentServiceList.length === 0) {
      setErrors('EXIST', true, 'No tiene un servicio seleccionado, Seleccione un servicio');
      return;
    }

    if (!initialDate || !currentServiceList[0]?.dateInitial) {
      setErrors('DATE', true, 'Fecha Invalida / No Seleccionada');
      return;
    }

    if (availability === false) {
      setErrors('DATE', true, 'Lashista no disponible para la fecha seleccionada');
      return;
    }

    if (lastAppointmentBeforeClose(sucursal?.workingHours, new Date(), currentServiceList[0]?.duration || 0)) {
      setErrors('EXIST', true, 'El servicio no se puede crear/editar ya que excede el horario de cierre del trabajo');
      return;
    }

    if (checkIfSucursalIsOpen(sucursal?.workingHours, new Date(currentServiceList[0]?.dateInitial ?? 0))) {
      setErrors('EXIST', true, 'No se puede crear/editar servicios fuera del horario de trabajo');
      return;
    }

    setErrors('DATE', false, '');
    setErrors('EXIST', false, '');

    if (preAppointment?.clientId) {
      setDisabledButton(true);
      if (preAppointment.statusAppointment === 'AWAIT') {
        dispatch(
          new CreateAppointment({
            id: generateId('order'),
            clientId: preAppointment?.clientId ?? '',
            detailClient: {
              firstName: client?.firstName,
              lastName: client?.lastName,
            },
            orden: currentServiceList.map((a) => ({
              id: a.id,
              serviceId: a.serviceId ?? '',
              lashistaId: 'AWAIT',
              dateInitial: a.dateInitial,
              statusAppointment: 'AWAIT',
            })),
          })
        );
      } else {
        dispatch(
          new CreateAppointment({
            id: generateId('order'),
            clientId: preAppointment?.clientId ?? '',
            detailClient: {
              firstName: client?.firstName,
              lastName: client?.lastName,
            },
            orden: currentServiceList.map((a) => ({
              id: a.id,
              serviceId: a.serviceId ?? '',
              lashistaId: a.lashistaId ?? '',
              dateInitial: a.dateInitial,
              statusAppointment: 'INTIME',
            })),
          })
        );
      }
      dispatch(new UpdatePreAppointment(undefined));
      history.push('/dashboard');
    } else {
      if (!orderItemId) return;
      setDisabledButton(true);

      dispatch(
        new UpdateAppointment(
          {
            ...currentServiceList[0],
            orderId: orderId,
            lashistaId: currentServiceList[0].lashistaId,
            dateInitial: currentServiceList[0].dateInitial ?? 0,
            dateAppointment: currentServiceList[0].dateAppointment ?? 0,
            dateEnd: currentServiceList[0].dateEnd ?? 0,
            serviceId: currentServiceList[0].serviceId,
          },
          orderItemId
        )
      );

      if (pageWhereCameFrom === 'PAYMENT') {
        if (updateAppointment) {
          history.push(`/pagos/${updateAppointment?.orderId}`);
        } else {
          history.push('/pagos');
        }
      } else {
        history.push('/dashboard');
      }
    }
  }, [preAppointment, currentServiceList]);

  /**
   * prepare the services for the posible create/update appointment
   * @param service
   * @param lashistaId
   * @param initialDate
   */
  const selectService = (
    service: Appointment | Service | undefined,
    lashistaId: string,
    initialDate: number | undefined,
    orderItemId: string | undefined
  ) => {
    const dataService = JSON.parse(JSON.stringify(service));

    // clean the current service if is for update appointment
    if (updateAppointment) setCurrentServiceList([]);

    // avoid repeat the same item
    if (currentServiceList.find((currentService) => currentService.serviceId == dataService.id)) return;
    // if return true will dispatch the error
    if (lastAppointmentBeforeClose(sucursal?.workingHours, new Date(), service?.duration || 0)) {
      setErrors('EXIST', true, 'El servicio no se puede crear/editar ya que excede el horario de cierre del trabajo');
      return;
    }
    // if return true will dispatch the error
    if (checkIfSucursalIsOpen(sucursal?.workingHours, new Date(initialDate ?? 0))) {
      setErrors('EXIST', true, 'No se puede crear/editar servicios fuera del horario de trabajo');
      return;
    }

    // avoid having more than one service as principal
    switch (service?.isPrincipal) {
      case 'ACTIVE':
        dispatch(new GetAppointmentByDate(formatFullDateUS(initialDate ?? 0)));

        setCurrentServiceList((prev) => {
          delete dataService.id;
          delete dataService.status;
          delete dataService.categoryName;
          delete dataService.availableSucursal;
          delete dataService.createdAt;
          delete dataService.updatedAt;
          delete dataService.color;
          const lastAppointment = prev[prev.length - 1];
          // WIP revisar horas al guardarse y modificarse xq cambian dependiendo de donde se realicen
          const dateInitial = lastAppointment?.dateEnd ?? initialDate;
          if (prev.length === 0) {
            return prev.concat({
              ...dataService,

              categoryName: categories.find((c) => c.id === service?.categoryId)?.name ?? '',
              serviceId: service?.id,
              dateInitial: dateInitial,
              lashistaId: lashistaId,
              clientId: clientId,
              dateEnd: sumSecondsDate(new Date(dateInitial ?? 0), service?.duration ?? 0).getTime(),
              id: orderItemId ?? generateId('orderItem'),
              status: 'TEMPORAL',
            });
          } else {
            return prev.map((obj) => {
              if (obj.serviceId !== service.id && obj.isPrincipal === service.isPrincipal) {
                return {
                  ...obj,
                  ...dataService,

                  categoryName: categories.find((c) => c.id === service?.categoryId)?.name ?? '',
                  serviceId: service?.id,
                  dateInitial: dateInitial,
                  lashistaId: lashistaId,
                  clientId: clientId,
                  dateEnd: sumSecondsDate(new Date(dateInitial ?? 0), service?.duration ?? 0).getTime(),
                  id: orderItemId ?? generateId('orderItem'),
                  status: 'TEMPORAL',
                };
              }
              return obj;
            });
          }
        });
        break;
      case 'INACTIVE':
        setCurrentServiceList((prev) => {
          delete dataService.id;
          delete dataService.status;
          delete dataService.categoryName;
          delete dataService.availableSucursal;
          delete dataService.createdAt;
          delete dataService.updatedAt;
          delete dataService.color;
          const lastAppointment = prev[prev.length - 1];
          const dateInitial = lastAppointment?.dateEnd ?? initialDate;
          return prev.concat({
            ...dataService,

            categoryName: categories.find((c) => c.id === service?.categoryId)?.name ?? '',
            serviceId: service?.id,
            dateInitial: dateInitial,
            lashistaId: lashistaId,
            clientId: clientId,
            dateEnd: sumSecondsDate(new Date(dateInitial ?? 0), service?.duration ?? 0).getTime(),
            id: orderItemId ?? generateId('orderItem'),
            status: 'TEMPORAL',
          });
        });
        break;

      default:
        break;
    }
  };

  /**
   * update the lashista of current service
   * @param lashistaId string
   * @param serviceId string
   */
  const setLashista = (lashistaId: string, serviceId: string) => {
    setCurrentServiceList((preState) => {
      const newState = preState.map((obj) => {
        // update the lashista id if is the service
        if (obj.id === serviceId) {
          return { ...obj, lashistaId: lashistaId };
        }

        // 👇️ otherwise return object as is
        return obj;
      });

      return newState;
    });
    dispatch(new CloseModal('SEARCH_LASHISTA_MODAL'));
  };

  /**
   * update the initialDate and endDate of the current service
   * @param rawDate string
   * @param serviceId string | undefined
   * @param serviceDuration number | undefined
   */
  const setDMY = (
    rawDate: string,
    type: 'DATE' | 'TIME',
    serviceId: string | undefined,
    serviceDuration: number | undefined
  ) => {
    setErrors('EXIST', false, '');
    // use the new value depending of the type with the last of the other type
    const newDate = type === 'DATE' ? rawDate : inputDate,
      newTime = type === 'TIME' ? rawDate : inputTime,
      dateInitial = new Date(`${newDate} ${newTime}`);

    // replace the value of the input to save the last when the input used is different type(update date and keep time and vice versa)
    switch (type) {
      case 'DATE':
        setInputDate(rawDate);
        break;
      case 'TIME':
        setInputTime(rawDate);
        break;
    }

    const today = new Date();

    if (lastAppointmentBeforeClose(sucursal?.workingHours, today, serviceDuration || 0)) {
      setErrors('EXIST', true, 'El servicio no se puede crear/editar ya que excede el horario de cierre del trabajo');
      return;
    }

    //DELETE IF NECESSARY
    // if (checkIfSucursalIsOpen(sucursal?.workingHours, dateInitial, today)) {
    //   setErrors('EXIST', true, 'No se puede crear/editar servicios fuera del horario de trabajo');
    //   return;
    // }

    if (dateInitial.getTime() > today.getTime()) {
      dispatch(new GetAppointmentByDate(formatFullDateUS(initialDate ?? 0)));

      setErrors('DATE', false, '');

      setCurrentServiceList((preState) => {
        const newState = preState.map((obj) => {
          // update the Lashista id if is the service
          if (obj.id === serviceId) {
            return {
              ...obj,
              dateInitial: dateInitial.getTime(),
              dateEnd: sumSecondsDate(new Date(`${newDate} ${newTime}`), serviceDuration ?? 0).getTime(),
            };
          }

          // 👇️ otherwise return object as is
          return obj;
        });

        return newState;
      });
    } else {
      setErrors('EXIST', true, 'No se puede seleccionar una fecha/hora anterior a la actual');
      return;
    }
  };

  /**
   * prepare the list of categories with its products
   */
  const listServices = useMemo<CategoryWithService[]>(() => {
    return categories
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((category) => ({
        ...category,
        products: products
          .filter((product) => product.categoryId === category.id && product.type === 'SERVICE')
          .sort((a, b) => a.name.localeCompare(b.name)),
      }));
  }, [products, categories]);

  /**
   * get the summary info of the appointment
   */
  useEffect(() => {
    setServiceSummary({
      selected: currentServiceList.length,
      totalPrice: currentServiceList.reduce((a, b) => Number(a) + Number(b.price ?? 0), 0),
      totalDuration: Number(currentServiceList.reduce((a, b) => Number(a) + Number(b?.duration ?? 0), 0)) / 60,
    });
  }, [currentServiceList]);

  /**
   * get the appointment for be updated
   */
  useEffect(() => {
    if (orderItemId) {
      dispatch(new GetOrdenItem(orderItemId));
    }
  }, [orderItemId]);

  /**
   * insert the appointment that was get for update
   */
  useEffect(() => {
    if (updateAppointment && !lashistaId && preAppointment?.orden?.length === 0) {
      const service = listServices.find((category) => {
        return category.products.find((product) => product.id == updateAppointment?.serviceId);
      })?.products[0];
      selectService(service, updateAppointment.lashistaId, updateAppointment.dateInitial, updateAppointment.id);
      setOrderId(updateAppointment.orderId);
      setLashistaId(updateAppointment.lashistaId);
      setInitialDate(updateAppointment.dateInitial);
      setClientId(updateAppointment.clientId);
    }
  }, [updateAppointment]);

  useEffect(() => {
    if ((appointmentsOfTheDay?.length ?? 0) > 0) {
      const noAvailability = appointmentsOfTheDay?.filter((a) => a.lashistaId === preAppointment?.lashistaId);
      if ((noAvailability?.length ?? 0) > 0) {
        noAvailability?.forEach((w) => {
          if (
            !(currentServiceList[0]?.dateInitial >= w.dateInitial && currentServiceList[0]?.dateInitial < w.dateEnd) && //fechaInicialActual dentro del bloque
            !(currentServiceList[0]?.dateEnd < w.dateEnd && currentServiceList[0]?.dateEnd > w.dateInitial) && //fechaFinalActual dentro del bloque
            !(currentServiceList[0]?.dateEnd > w.dateEnd && currentServiceList[0]?.dateInitial < w.dateInitial)
          ) {
            setAvailability(true);
            return;
          }

          setAvailability(false);
          return;
        });
      }
    }
  }, [appointmentsOfTheDay]);

  return (
    <>
      <SearchLashistaModal setLashista={setLashista} />
      <div className="flex justify-between gap-[19px]">
        <div className="flex-1 flex flex-col max-w-[400px] gap-[19px]">
          <div className="flex flex-col bg-darkGray">
            <div className="flex flex-col gap-[20px] p-[17px] border-b border-grey2">
              <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                Categorías
              </span>
              <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                  <label
                    className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                    htmlFor="categoriesAvailableSearch"
                  >
                    Buscar:
                  </label>
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-GREY border-0 w-full h-full"
                    name="categoriesAvailableSearch"
                    id="categoriesAvailableSearch"
                    value={categoriesAvailableSearch}
                    onChange={(e) => setCategoriesAvailableSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
                  onClick={() => {
                    setCategoriesAvailableSearch('');
                  }}
                >
                  <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                </button>
              </div>
            </div>
            <div className="overflow-y-auto max-h-[610px]">
              {listServices
                .filter((list) =>
                  list.products.some((product) =>
                    new RegExp(`${categoriesAvailableSearch}.*`, 'i').test(product.name ?? '')
                  )
                )
                .map((category, i) => (
                  <div
                    key={i}
                    className="flex px-[34px] p-[15px] items-center border-b last:border-b-0 border-grey2 cursor-pointer gap-[26px]"
                    onClick={() => setCurrentCategory(category.products)}
                  >
                    <img src={category.icon} alt={category.name} width={'50px'} height={'50px'} />
                    <span className="font-light text-[18px] leading-[23px] tracking-[0.185em] text-txtWhite">
                      {category.name}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-[19px]">
          <div className="flex flex-col rounded-[3px] bg-darkGray">
            <div className="flex justify-start p-[17px] border-b border-grey2 gap-[24px]">
              <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                Servicios disponibles
              </span>
              <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                  <label
                    className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                    htmlFor="servicesAvailableSearch"
                  >
                    Buscar:
                  </label>
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-GREY border-0 w-full h-full"
                    name="servicesAvailableSearch"
                    id="servicesAvailableSearch"
                    value={servicesAvailableSearch}
                    onChange={(e) => setServicesAvailableSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
                  onClick={() => {
                    setServicesAvailableSearch('');
                  }}
                >
                  <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                </button>
              </div>
            </div>
            <div className="flex-1 p-4 max-h-[200px] overflow-y-auto">
              <table className="table-auto w-full border-separate text-left">
                <thead>
                  <tr className="bg-lightDarkGrey rounded-[3px] h-[33px]">
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                      Categoría
                    </th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite">Nombre</th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite">
                      Último comprado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentCategory
                    ?.filter((list) => new RegExp(`${servicesAvailableSearch}.*`, 'i').test(list.name ?? ''))
                    .map((service) =>
                      service.status === 'ACTIVE' && service.isPrincipal === 'ACTIVE' ? (
                        <tr
                          key={service.id}
                          onClick={() => selectService(service, lashistaId, initialDate, undefined)}
                          className="cursor-pointer hover:bg-primary"
                        >
                          <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                            {categories.find((category) => category.id == service.categoryId)?.name}
                          </td>
                          <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                            {service.name}
                          </td>
                          <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                            -
                          </td>
                        </tr>
                      ) : null
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-col rounded-[3px] bg-darkGray">
            <div className="flex justify-start gap-[24px] p-[17px] border-b border-grey2">
              <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                Servicios bonos seleccionados
              </span>
              <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                  <label
                    className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                    htmlFor="servicesSelectedSearch"
                  >
                    Buscar:
                  </label>
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-GREY border-0 w-full h-full"
                    name="servicesSelectedSearch"
                    id="servicesSelectedSearch"
                    value={servicesSelectedSearch}
                    onChange={(e) => setServicesSelectedSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-red"
                  onClick={() => {
                    setServicesSelectedSearch('');
                  }}
                >
                  <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                </button>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-center gap-2 p-4 max-h-[200px] overflow-auto">
              <table className="table-auto w-full border-separate text-left">
                <thead>
                  <tr className="bg-lightDarkGrey rounded-[3px] h-[33px]">
                    <th></th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                      Categoría
                    </th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                      Nombre
                    </th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                      Lashista
                    </th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                      Precio
                    </th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                      Duración
                    </th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                      Inicio
                    </th>
                    <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                      Disponible
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentServiceList
                    .filter((list) => new RegExp(`${servicesSelectedSearch}.*`, 'i').test(list.name ?? ''))
                    .map((service, i) => (
                      <tr key={i}>
                        <td onClick={() => deleteService(service.id ?? '')} className="cursor-pointer">
                          <DeleteSmallIcon />
                        </td>
                        <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                          {categories.find((category) => category.id == service.categoryId)?.name}
                        </td>
                        <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                          {service.name}
                        </td>
                        <td
                          className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite"
                          onClick={() => dispatch(new OpenModal('SEARCH_LASHISTA_MODAL', { serviceId: service.id }))}
                        >
                          {listLashista.find((lashista) => lashista.id == service.lashistaId)?.alias}
                        </td>
                        <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                          $ {service.price}
                        </td>
                        <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                          {Number((service.duration ?? 0) / 60)} min
                        </td>
                        <td className="flex flex-row gap-2 text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                          {messageError.DATE.status && (
                            <span className="text-[18px] font-medium tracking-[0.185em] leading-[20px] text-red">
                              {messageError.DATE.value}
                            </span>
                          )}
                          <div className="flex flex-row gap-2">
                            <input
                              type="date"
                              className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-txtWhite border-0 w-full h-full"
                              name="inputDate"
                              id="inputDate"
                              value={formatFullDate(new Date(service.dateInitial ?? 0).getTime())}
                              onChange={(e) => {
                                setDMY(e.target.value, 'DATE', service.id, service.duration);
                              }}
                              disabled={service.isPrincipal === 'INACTIVE'}
                            />
                            <input
                              type="time"
                              className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-txtWhite border-0 w-full h-full"
                              name="inputTime"
                              id="inputTime"
                              value={formatDateTime(new Date(service.dateInitial ?? 0).getTime())}
                              onChange={(e) => {
                                setDMY(e.target.value, 'TIME', service.id, service.duration);
                              }}
                              disabled={service.isPrincipal === 'INACTIVE'}
                            />
                          </div>
                        </td>
                        <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2">
                          {availability ? <CheckGreenIcon /> : <CheckRedIcon />}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {messageError.EXIST.status && (
                <span className="text-[18px] font-medium tracking-[0.185em] leading-[20px] text-red">
                  {messageError.EXIST.value}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-between px-[10px] py-[15px] rounded-[3px] bg-primary">
            <div>
              <span className="text-base font-medium leading-[20px] tracking-[0.185em] text-txtWhite">
                Servicios seleccionados <span className="font-bold">{serviceSummary.selected}</span>
              </span>
            </div>
            <div>
              <span className="text-base font-medium leading-[20px] tracking-[0.185em] text-txtWhite">
                Precio total <span className="font-bold">$ {serviceSummary.totalPrice}</span>
              </span>
            </div>
            <div>
              <span className="text-base font-medium leading-[20px] tracking-[0.185em] text-txtWhite">
                Duración total <span className="font-bold">{serviceSummary.totalDuration} min</span>
              </span>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="flex justify-center min-w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
              disabled={disabledButton}
              onClick={() => cancelAppointment()}
            >
              <span className="text-base leading-[19px] tracking-[0.08rem] text-txtWhite">Cancelar</span>
            </button>
            <button
              type="button"
              className="flex justify-center min-w-[161px] py-5 ml-[29px] border-primary rounded-[8px] border-2 bg-primary"
              disabled={disabledButton}
              onClick={() => createAppointment()}
            >
              <span className="text-base leading-[19px] tracking-[0.08rem] text-txtWhite">Hecho</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePageBodyComponent;
