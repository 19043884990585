import { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// components
import Container from '@webapp/components/ui/container';
import Loading from '@webapp/components/ui/preloader/Loading';
import {
  DeleteSmallIcon,
  HistorySmallIcon,
  PlusGreenIcon,
  ThreeDotButtonGreenIcon,
} from '@webapp/components/ui/shared/icons';
import * as payMethodsIcon from '@webapp/components/ui/shared/icons/PayMethodsIcon';
import {
  CreatePreAppointment,
  CreatePreAppointmentItem,
  DeletePreAppointmentPayment,
  FinishAppointment,
  GetOrden,
  ResetPreAppointmentItems,
  ResetPreAppointmentPayment,
  UpdatePreAppointment,
  UpdatePreAppointmentItem,
} from '@webapp/store/appointments/action-creators';
import { FINISH_APPOINTMENT, GET_ORDEN } from '@webapp/store/appointments/actions';
import { CloseModal, OpenModal, PageWhereComeFrom } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
// types
import { FinalizeInvoice } from '@services/business/invoice/entities';
import { generateId } from '@utils/generate-id';
import { ButtonToPrintInvoice } from '@webapp/components/pagos/buttonToPrintInvoice';
import ProductModalBodyComponent from '@webapp/components/servicios/modal-product-page-body';
import RewardModalBodyComponent from '@webapp/components/servicios/modal-reward-page-body';
import ServiceModalBodyComponent from '@webapp/components/servicios/modal-service-page-body';
import {
  ModalCreateNoteCredit,
  ModalMessage,
  ModalNoInventory,
  ModalPayment,
  ModalPaymentBalance,
  ModalPaymentCancel,
  ModalPaymentCard,
  ModalPaymentCash,
  ModalPaymentDiscount,
  ModalPaymentTransfer,
  ModalSelectProductService,
  ModalShowError,
  SearchLashistaModal,
} from '@webapp/components/ui/modal';
import { MethodPayment } from '@webapp/store/appointments/state';
import { GetClient, SetBalanceClient, SetCurrentClient, SetRewardClient } from '@webapp/store/client/action-creators';
import AppState from '@webapp/store/state';
import { formatFullDateUS } from '@webapp/utils/formatDate';
import { rounded } from '@webapp/utils/validateNumber';

interface InvoiceBreakdown {
  totalDiscount: number;
  totalPrice: number;
  totalTax?: number;
  rewardUsed: number;
  paymentReceived: number;
  importToPay: number;
  totalToPay: number;
}

interface HoveringStatus {
  type: string;
  status: boolean;
}

const initHoveringStatus: HoveringStatus[] = [
  { type: 'CASH', status: false },
  { type: 'TRANSFER', status: false },
  { type: 'CARD', status: false },
  { type: 'REWARD', status: false },
  { type: 'BALANCE', status: false },
];

const IVA_PERCENTAGE = 0.16;

export default function NotaVenta() {
  const {
    appointments: { order, preAppointment, updateAppointmentPayment },
    product: { products, categories, inventory },
    client: { currentClient, balanceClient, rewardClient },
    lashista: { listLashista },
    dispatch,
    loading,
  } = useOpStore((state: AppState) => state, [GET_ORDEN, FINISH_APPOINTMENT]);
  const { orderId } = useParams<{ orderId: string }>();
  const history = useHistory();
  const [isHovering, setIsHovering] = useState<HoveringStatus[]>(initHoveringStatus);
  const [loadingComp, setLoadingComp] = useState(true);
  const [readyToPay, setReadyToPay] = useState<boolean>(false);
  const [readyProducts, setReadyProduct] = useState(false);

  useEffect(() => {
    const intervalSearch = setTimeout(() => {
      setLoadingComp(false);
    }, 1000);
    return () => {
      clearTimeout(intervalSearch);
    };
  }, []);

  /**
   * this method change the status to true of the current button(hovering) to change the primary color
   * @param type string
   */
  const handleMouseEnter = (type: string) => {
    setIsHovering((current) =>
      current.map((obj) => {
        if (obj.type === type) {
          return { ...obj, status: true };
        }
        return obj;
      })
    );
  };

  /**
   * this method change the status to false of the current button(hovering) to change the default color
   * @param type string
   */
  const handleMouseLeave = (type: string) => {
    setIsHovering((current) =>
      current.map((obj) => {
        if (obj.type === type) {
          return { ...obj, status: false };
        }
        return obj;
      })
    );
  };

  /**
   * get the name of the Lashista
   * @param id string
   * @returns string | undefined
   */
  const getLashistaAlias = (id: string) => {
    return listLashista.find((lashista) => lashista.id == id)?.alias;
  };

  /**
   * get the name of the category
   * @param id string
   * @returns string | undefined
   */
  const getCategory = (id: string) => {
    return categories.find((category) => category.id == id)?.name;
  };

  /**
   * get the name of the product
   * @param id string
   * @returns string | undefined
   */
  const getProduct = (id: string) => {
    return products.find((product) => product.id == id)?.name;
  };

  /**
   * get and save the payment methods with the amounts to pay
   * @param type PayMethod
   */
  const selectedPaymentMethods = () => {
    dispatch(new UpdatePreAppointment({ payment: updateAppointmentPayment }));
  };

  /**
   * add a row to the current list
   */
  const addRowForProducts = () => {
    dispatch(
      new CreatePreAppointmentItem({
        id: generateId('orderItem'),
        status: 'TEMPORAL',
        serviceId: '',
        lashistaId: preAppointment?.lashistaId ?? '',
      })
    );
  };

  /**
   * delete the selected service on the list
   * @param serviceId string
   */
  const deleteService = (orderItemId: string, serviceId: string) => {
    // ok
    dispatch(new UpdatePreAppointmentItem({ id: undefined }, orderItemId));
    dispatch(new DeletePreAppointmentPayment(serviceId));
  };

  /**
   * finish the order(paid, cancel, etc)
   */
  const payOrder = (status: 'PAID' | 'UNPAID' | 'IN_PROCESS' | 'CANCELED', note?: string) => {
    // check if the amount to pay is correct
    if ((importToPay > 0 || importToPay < 0) && status !== 'CANCELED') {
      dispatch(
        new OpenModal('SHOW_ERROR_MODAL', {
          messageError: 'El monto cancelado no coincide con el monto a pagar',
        })
      );
      return;
    }

    const payment: FinalizeInvoice = {
      discountId: preAppointment?.payment?.discountId,
      totalAmount: status === 'CANCELED' ? 0 : totalToPay,
      totalDiscount: status === 'CANCELED' ? 0 : totalDiscount,
      rewardUsed: status === 'CANCELED' ? 0 : preAppointment?.payment?.rewardUsed,
      methods:
        status === 'CANCELED'
          ? (preAppointment?.payment?.methods
              ?.map((prev: MethodPayment) => {
                return { ...prev, amount: 0 };
              })
              .filter((prev: MethodPayment) => {
                if (prev.type !== 'REWARD') {
                  return prev;
                }
              }) as MethodPayment[])
          : (preAppointment?.payment?.methods as MethodPayment[]),
    };

    let finishAppointment = {
      payment: payment,
      orden: (preAppointment?.orden || []).map((obj) => {
        if (status === 'CANCELED') {
          return {
            ...obj,
            status: 'CANCELED',
            statusAppointment: 'CANCELED',
          };
        }
        return {
          ...obj,
          status: 'PAID',
          statusAppointment: 'CONFIRMED',
          paymentAt: formatFullDateUS(new Date().getTime()),
        };
      }),
      clientId: currentClient?.id ?? '',
      total: totalToPay,
      status: status,
      note: note,
    };

    if (status === 'PAID') {
      finishAppointment = { ...finishAppointment, ...{ paymentAt: formatFullDateUS(new Date().getTime()) } };

      /**
       * If the orden has 2 or more quantity of products
       * this functions separated this products
       * and get unit values
       */
      const newOrderItems = finishAppointment.orden.flatMap((appointment) => {
        if ((appointment?.quantity ?? 1) > 1) {
          return new Array(appointment?.quantity ?? 1)
            .fill(null)
            .map(() => ({ ...appointment, id: generateId('orderItem'), quantity: 1 }));
        }

        return { ...appointment };
      });

      // change the old values orden for the separated products
      finishAppointment.orden = newOrderItems;

      dispatch(new FinishAppointment(finishAppointment, order?.orden.id ?? generateId('order')));
      dispatch(new UpdatePreAppointment(undefined));
      dispatch(new SetCurrentClient(undefined));
      dispatch(new SetBalanceClient());
      dispatch(new SetRewardClient());
    }

    switch (status) {
      case 'CANCELED':
        dispatch(
          new OpenModal('MESSAGE_MODAL', {
            title: 'Cancelación de Orden',
            loading: loading[FINISH_APPOINTMENT],
            message: 'Pago Cancelado',
          })
        );
        break;
      case 'PAID':
        dispatch(
          new OpenModal('MESSAGE_MODAL', {
            title: 'Pago',
            loading: loading[FINISH_APPOINTMENT],
            message: 'Pago exitoso',
          })
        );
        break;

      default:
        break;
    }
  };

  /**
   * navigate to the dashboard page
   */
  const goToDashboardPage = () => {
    dispatch(new UpdatePreAppointment(undefined));
    dispatch(new SetCurrentClient(undefined));
    history.push('/dashboard');
  };

  /**
   * navigate to the client page to select a client if the order do not have a current client
   * @returns
   */
  const goToClientPage = () => {
    if (order) {
      dispatch(
        new OpenModal('SHOW_ERROR_MODAL', {
          messageError: 'Debe cancelar la orden si quiere cambiar de cliente',
        })
      );
      return;
    }

    dispatch(new PageWhereComeFrom('PAYMENT'));
    history.push('/clientes');
  };

  /**
   * update the Lashista of current service
   * @param lashistaId string
   * @param serviceId string
   */
  const setLashista = (lashistaId: string, orderItemId: string) => {
    if ((preAppointment?.orden?.length ?? 0) >= 1 && preAppointment?.lashistaId === undefined) {
      dispatch(
        new CreatePreAppointment({
          ...preAppointment,
          lashistaId: lashistaId,
        })
      );
    }
    if (
      preAppointment?.orden?.some((o) => o.id === orderItemId && o.isPrincipal === 'ACTIVE') &&
      preAppointment.lashistaId !== lashistaId
    ) {
      dispatch(
        new CreatePreAppointment({
          ...preAppointment,
          lashistaId: lashistaId,
        })
      );
    }
    dispatch(new UpdatePreAppointmentItem({ lashistaId }, orderItemId));
    dispatch(new CloseModal('SEARCH_LASHISTA_MODAL'));
  };

  /**
   * redirect to clientes/historial page
   */
  const openHistory = () => {
    dispatch(new PageWhereComeFrom('PAYMENT'));
    history.push('/clientes/historial');
  };

  /**
   *  get the total amount of the current product/service
   * @param price number
   * @param discount number
   * @param quantity number
   * @returns number
   */
  const getTotal = (price: number, discount: number, quantity: number): number => {
    return rounded((price - discount) * quantity);
  };

  const checkToPay = () => {
    if (preAppointment?.orden?.some((o) => o.status === 'TEMPORAL') === true) {
      dispatch(
        new OpenModal('MESSAGE_MODAL', {
          title: 'Aviso',
          loading: false,
          message: 'Alguno de los productos/servicios esta vació. seleccione uno valido',
        })
      );
    } else {
      setReadyToPay(true);
    }

    if (preAppointment?.orden?.some((o) => o.type === 'PRODUCT')) {
      preAppointment?.orden?.forEach((appointment) => {
        const productInStock = inventory?.filter((product) => {
          return appointment.type === 'PRODUCT' && appointment.serviceId === product.productId;
        });

        productInStock.forEach((product) => {
          if (product.currentTotal < (appointment.quantity ?? 1)) {
            dispatch(
              new OpenModal('NO_INVENTORY_MODAL', {
                title: 'Aviso',
                loading: false,
                message: 'Alguno de los productos no se encuentra en el inventario ¿Desea continuar?',
              })
            );
          }
        });
      });
    } else {
      setReadyProduct(true);
    }
  };

  /**
   * get the appointment for be updated
   */
  useEffect(() => {
    if (orderId !== undefined) {
      dispatch(new GetOrden(orderId));
    }
  }, [orderId]);

  /**
   * if the orden exist, fill the values for it
   */
  useEffect(() => {
    if (order && orderId) {
      dispatch(new GetClient(order?.orden.clientId ?? ''));
      dispatch(new UpdatePreAppointment({ payment: order.orden.payment }));
    }
  }, [order, orderId]);

  /**
   * return the products/services with rewards
   */
  const { productsWithRewards } = useMemo(() => {
    const productsWithRewards = products
      .map((product) => {
        if ((product?.rewardPoint ?? 0) > 0) {
          return { ...product, price: 0 };
        }
      })
      .filter((product) => product !== undefined)
      .sort((a, b) => a?.name.localeCompare(b?.name ?? '') as number);
    return { productsWithRewards };
  }, [products]);

  /**
   * initialize total values and update them
   */
  const { totalDiscount, totalPrice, rewardUsed, paymentReceived, importToPay, totalToPay } =
    useMemo<InvoiceBreakdown>(() => {
      const paymentReceived = Object.values(preAppointment?.payment?.methods ?? {}).reduce((a, b) => a + b.amount, 0);

      const totalPrice = preAppointment?.orden?.reduce((a, b) => a + rounded(b.price ?? 0), 0) ?? 0;
      return {
        totalDiscount: preAppointment?.orden?.reduce((a, b) => a + rounded(b.discountAmount ?? 0), 0) ?? 0,
        totalPrice: rounded(totalPrice),
        rewardUsed: preAppointment?.payment?.rewardUsed ?? 0,
        paymentReceived,
        importToPay:
          (preAppointment?.orden?.reduce((a, b) => a + rounded((b.price ?? 0) - (b?.discountAmount ?? 0)), 0) ?? 0) -
          paymentReceived,
        totalToPay:
          preAppointment?.orden?.reduce((a, b) => a + rounded((b.price ?? 0) - (b?.discountAmount ?? 0)), 0) ?? 0,
      };
    }, [preAppointment]);

  return (
    <Container className="flex justify-center">
      <SearchLashistaModal setLashista={setLashista} />
      <ServiceModalBodyComponent products={products} categories={categories} />
      <ProductModalBodyComponent products={products} categories={categories} />
      <RewardModalBodyComponent products={productsWithRewards} categories={categories} />
      <ModalPayment title={'Pago'} onOk={() => payOrder('PAID')} />
      <ModalCreateNoteCredit title="Crear Anticipo" />
      <ModalSelectProductService title="Selección de producto, servicio o reward" />
      <ModalPaymentCash title={'Pago en Efectivo'} importToPay={importToPay} onOk={() => selectedPaymentMethods()} />
      <ModalPaymentCard title={'Pago en Tarjeta'} importToPay={importToPay} onOk={() => selectedPaymentMethods()} />
      <ModalPaymentTransfer
        title={'Pago en Transferencia'}
        importToPay={importToPay}
        onOk={() => selectedPaymentMethods()}
      />
      <ModalPaymentBalance
        title={'Anticipo'}
        importToPay={importToPay}
        balanceClient={balanceClient || 0}
        onOk={() => selectedPaymentMethods()}
      />

      <ModalPaymentDiscount title={'Aplicar Descuento'} />
      <ModalPaymentCancel title={'Pago'} onOk={payOrder} />
      <ModalMessage />
      <ModalNoInventory productIsReady={() => setReadyProduct(true)} productIsNotReady={() => setReadyProduct(false)} />
      <ModalShowError />

      {(order && preAppointment ? loading[GET_ORDEN] : false) || loadingComp ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="flex justify-start items-center  text-txtWhite">
              <div className="p-3 text-lg font-bold">Pago</div>
            </div>
            <div className="flex justify-end items-center text-txtWhite gap-x-2">
              <div className="p-3 text-sm md:text-base font-semibold">
                {`Cliente: ${currentClient?.firstName ?? ''} ${currentClient?.lastName ?? ''} `}
              </div>
              <button
                type="button"
                disabled={orderId !== undefined ? true : false}
                className="flex justify-center items-center border border-transparent bg-transparent hover:border-primary focus:outline-none"
                onClick={() => order?.orden?.status !== 'PAID' && goToClientPage()}
              >
                <ThreeDotButtonGreenIcon />
              </button>
              {currentClient && (
                <button
                  type="button"
                  className="flex justify-center gap-x-[10px] p-2 rounded-[8px] border-2 border-primary"
                  onClick={() => openHistory()}
                >
                  <HistorySmallIcon />
                  <span className="text-base leading-[20px] tracking-[0.08rem] font-medium text-primary">
                    Historial
                  </span>
                </button>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 p-1 overflow-auto max-h-[390px]">
            <table className="table-auto lg:table-fixed w-full bg-darkGray shadow overflow-hidden rounded-[3px]">
              <thead>
                <tr className="bg-grey text-txtWhite">
                  <th className="w-[50px]"></th>
                  <th className="px-4 py-[14px] text-[14px] md:text-[16px] font-light text-left leading-[20px] tracking-[0.185em]">
                    Empleado
                  </th>
                  <th className="px-4 py-[14px] text-[14px] md:text-[16px] font-light text-left leading-[20px] tracking-[0.185em]">
                    Producto/Servicio
                  </th>
                  <th className="px-4 py-[14px] text-[14px] md:text-[16px] font-light text-left leading-[20px] tracking-[0.185em]">
                    Cantidad
                  </th>
                  <th className="px-4 py-[14px] text-[14px] md:text-[16px] font-light text-left leading-[20px] tracking-[0.185em]">
                    Precio
                  </th>
                  <th className="px-4 py-[14px] text-[14px] md:text-[16px] font-light text-left leading-[20px] tracking-[0.185em]">
                    Descuento
                  </th>
                  <th className="px-4 py-[14px] text-[14px] md:text-[16px] font-light text-left leading-[20px] tracking-[0.185em]">
                    Iva(16%)
                  </th>
                  <th className="px-4 py-[14px] text-[14px] md:text-[16px] font-light text-left leading-[20px] tracking-[0.185em]">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {preAppointment?.orden?.map((item, i) => (
                  <tr key={`cabecera${i}`} className="text-txtWhite border-t-[1px]">
                    {order?.orden?.status !== 'PAID' ? (
                      readyToPay === false ? (
                        <th
                          className="px-4 w-[50px] cursor-pointer "
                          onClick={() => deleteService(item.id ?? '', item.serviceId)}
                        >
                          <DeleteSmallIcon />
                        </th>
                      ) : (
                        <th className="px-4 w-[50px] cursor-pointer ">
                          <DeleteSmallIcon />
                        </th>
                      )
                    ) : (
                      <th></th>
                    )}
                    <td
                      className="px-4 py-[14px] text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em] hover:bg-primary"
                      onClick={() => {
                        order?.orden?.status !== 'PAID'
                          ? dispatch(new OpenModal('SEARCH_LASHISTA_MODAL', { serviceId: item.id }))
                          : null;
                      }}
                    >
                      {getLashistaAlias(item?.lashistaId ?? '')}
                    </td>
                    <td
                      className="px-4 py-[14px] text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em] hover:bg-primary"
                      onClick={() => {
                        if (order?.orden?.status === 'PAID') {
                          return;
                        }
                        if (preAppointment?.lashistaId) {
                          dispatch(
                            new OpenModal('SELECT_PRODUCT_SERVICE_MODAL', {
                              orderItemId: item.id,
                              serviceId: item.serviceId,
                            })
                          );
                        } else {
                          dispatch(
                            new OpenModal('MESSAGE_MODAL', {
                              title: 'Aviso',
                              loading: false,
                              message: 'Debe seleccionar un(a) lashista antes del servicio',
                            })
                          );
                        }
                      }}
                    >
                      <p className="text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]">
                        {getProduct(item.serviceId ?? '') ?? ''}
                      </p>
                      <p className="text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em]">
                        {getCategory(item.categoryId ?? '') ?? ''}
                      </p>
                    </td>
                    <td className="px-4 py-[14px] text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em]">
                      {item?.quantity ?? 1}
                    </td>
                    <td className="px-4 py-[14px] text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em]">
                      ${rounded(item.price ?? 0)}
                    </td>
                    <td
                      className={`px-4 py-[14px] text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em] ${
                        rounded(item.discountAmount ?? 0) > 0 ? 'text-red' : ''
                      }`}
                    >
                      ${rounded(item.discountAmount ?? 0)}
                    </td>
                    <td className="px-4 py-[14px] text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em]">
                      ${rounded((item.price || 0) * IVA_PERCENTAGE)}
                    </td>
                    <td className="px-4 py-[14px] text-[14px] md:text-[16px] font-normal leading-[26px] tracking-[0.185em] text-primary">
                      ${getTotal(item.price ?? 0, item.discountAmount ?? 0, item.quantity ?? 1)}
                    </td>
                  </tr>
                ))}
                {(preAppointment?.status === 'IN_PROCESS' || preAppointment?.status === 'UNPAID') && (
                  <tr className="text-txtWhite border-t-[1px] cursor-pointer" onClick={() => addRowForProducts()}>
                    <td className="px-4 py-[14px]">
                      <PlusGreenIcon />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-2">
            <div className="flex justify-start items-center text-txtWhite">
              <div className="p-3 text-lg font-semibold">Desglose</div>
            </div>
          </div>

          <div className="grid grid-cols-1 p-1">
            <table className="table-auto lg:table-fixed w-full bg-darkGray shadow overflow-hidden rounded-md">
              <tbody className="">
                <tr className="text-txtWhite text-sm md:text-base font-medium">
                  {preAppointment?.status === 'IN_PROCESS' || preAppointment?.status === 'UNPAID' ? (
                    <>
                      <td
                        className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]"
                        onClick={() => dispatch(new OpenModal('PAYMENT_DISCOUNT_MODAL'))}
                      >
                        Descuento
                      </td>
                      <td
                        className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em] bg-grey"
                        onClick={() => dispatch(new OpenModal('PAYMENT_DISCOUNT_MODAL'))}
                      >
                        {rounded(totalDiscount)}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]">
                        Descuento
                      </td>
                      <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em] bg-grey">
                        {rounded(totalDiscount)}
                      </td>
                    </>
                  )}
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]">
                    Recompensas
                  </td>
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em] bg-grey">
                    {rewardUsed}
                  </td>
                </tr>
                <tr className="text-txtWhite text-sm md:text-base font-semibold">
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]">
                    Total Ventas
                  </td>
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em] bg-grey">
                    ${rounded(totalPrice)}
                  </td>
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]">
                    Pago Recibido
                  </td>
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em] bg-grey">
                    ${rounded(paymentReceived)}
                  </td>
                </tr>
                <tr className="text-txtWhite text-sm md:text-base font-semibold">
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]">
                    Total de la Operación
                  </td>
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em] bg-grey">
                    ${rounded(totalToPay)}
                  </td>
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em]">
                    Importe Pendiente
                  </td>
                  <td className="px-4 py-1 text-[14px] md:text-[16px] font-medium leading-[26px] tracking-[0.185em] bg-grey">
                    ${rounded(importToPay)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-1 p-1">
            <div className="flex justify-between items-center bg-darkGray shadow overflow-hidden rounded-md text-sm md:text-base p-3">
              <div className="flex items-center gap-[12px]">
                {readyToPay === true && readyProducts === true && (
                  <>
                    <button
                      className="flex flex-col justify-center items-center gap-[6px] hover:text-primary text-white text-base font-medium tracking-[0.185em] leading-[20px] border-grey bg-grey border rounded-[5px] w-min-[109px] h-[100px] p-[10px]"
                      onMouseEnter={() => handleMouseEnter('CASH')}
                      onMouseLeave={() => handleMouseLeave('CASH')}
                      onClick={() => dispatch(new OpenModal('PAYMENT_CASH_MODAL'))}
                    >
                      <payMethodsIcon.EfectivoIcon
                        color={
                          isHovering.find((hovering) => hovering.type === 'CASH')?.status ? 'var(--primary)' : 'white'
                        }
                      />
                      Efectivo <br />
                      {(preAppointment?.payment?.methods?.find((method) => method.type === 'CASH')?.amount ?? 0) > 0 &&
                        preAppointment?.payment?.methods?.find((method) => method.type === 'CASH')?.amount}
                    </button>
                    <button
                      className="flex flex-col justify-center items-center gap-[6px] hover:text-primary border-grey text-white text-base font-medium tracking-[0.185em] leading-[20px] bg-grey border rounded-[5px] w-min-[109px] h-[100px] p-[10px]"
                      onMouseEnter={() => handleMouseEnter('CARD')}
                      onMouseLeave={() => handleMouseLeave('CARD')}
                      onClick={() => dispatch(new OpenModal('PAYMENT_CARD_MODAL'))}
                    >
                      <payMethodsIcon.TDebitIcon
                        color={
                          isHovering.find((hovering) => hovering.type === 'CARD')?.status ? 'var(--primary)' : 'white'
                        }
                      />
                      Tarjeta <br /> crédito/débito <br />
                      {(preAppointment?.payment?.methods?.find((method) => method.type === 'CARD')?.amount ?? 0) > 0 &&
                        preAppointment?.payment?.methods?.find((method) => method.type === 'CARD')?.amount}
                    </button>
                    <button
                      className="flex flex-col justify-center items-center gap-[6px] hover:text-primary border-grey text-white text-base font-medium tracking-[0.185em] leading-[20px] bg-grey border rounded-[5px] w-min-[109px] h-[100px] p-[10px]"
                      onMouseEnter={() => handleMouseEnter('TRANSFER')}
                      onMouseLeave={() => handleMouseLeave('TRANSFER')}
                      onClick={() => dispatch(new OpenModal('PAYMENT_TRANSFER_MODAL'))}
                    >
                      <payMethodsIcon.TransferIcon
                        color={
                          isHovering.find((hovering) => hovering.type === 'TRANSFER')?.status
                            ? 'var(--primary)'
                            : 'white'
                        }
                      />
                      Transferencia <br />
                      {(preAppointment?.payment?.methods?.find((method) => method.type === 'TRANSFER')?.amount ?? 0) >
                        0 && preAppointment?.payment?.methods?.find((method) => method.type === 'TRANSFER')?.amount}
                    </button>
                    <button
                      className={`flex flex-col justify-center items-center gap-[6px] hover:text-primary border-grey text-base font-medium tracking-[0.185em] leading-[20px] border rounded-[5px] w-min-[109px] h-[100px] p-[10px] ${
                        balanceClient ?? 0 > 0 ? 'bg-[#FABC2A] text-black' : 'bg-grey text-white'
                      }`}
                      onMouseEnter={() => handleMouseEnter('BALANCE')}
                      onMouseLeave={() => handleMouseLeave('BALANCE')}
                      onClick={() => dispatch(new OpenModal('PAYMENT_BALANCE_MODAL'))}
                    >
                      <payMethodsIcon.NoteCredit
                        color={
                          isHovering.find((hovering) => hovering.type === 'BALANCE')?.status
                            ? 'var(--primary)'
                            : balanceClient ?? 0 > 0
                            ? 'black'
                            : 'white'
                        }
                      />
                      Anticipo <br />
                      {(preAppointment?.payment?.methods?.find((method) => method.type === 'BALANCE')?.amount ?? 0) >
                        0 && preAppointment?.payment?.methods?.find((method) => method.type === 'BALANCE')?.amount}
                    </button>
                    <button
                      className={`flex flex-col justify-center items-center gap-[6px] hover:text-primary border-grey text-base font-medium tracking-[0.185em] leading-[20px] border rounded-[5px] w-min-[109px] h-[100px] p-[10px] ${
                        rewardClient ?? 0 > 0 ? 'bg-[#FABC2A] text-black' : 'bg-grey text-white'
                      }`}
                      onMouseEnter={() => handleMouseEnter('REWARD')}
                      onMouseLeave={() => handleMouseLeave('REWARD')}
                      onClick={() => dispatch(new OpenModal('SELECT_REWARD_MODAL'))}
                    >
                      <payMethodsIcon.RewardIcon
                        color={
                          isHovering.find((hovering) => hovering.type === 'REWARD')?.status
                            ? 'var(--primary)'
                            : rewardClient ?? 0 > 0
                            ? 'black'
                            : 'white'
                        }
                      />
                      Rewards <br />
                      {rewardUsed > 0 && rewardUsed}
                    </button>
                  </>
                )}
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-end items-center gap-3">
                  {readyToPay === false ? (
                    <button
                      type="button"
                      className="flex justify-center items-center p-0 border border-red bg-red rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                      onClick={() => goToDashboardPage()}
                    >
                      Atrás
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="flex justify-center items-center p-0 border border-red bg-red rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                      onClick={() => setReadyToPay(false)}
                    >
                      Atrás
                    </button>
                  )}
                  {order?.orden.status === 'PAID' ? (
                    <ButtonToPrintInvoice />
                  ) : (
                    <>
                      {(preAppointment?.orden || []).length > 0 ? (
                        <>
                          {readyToPay === false ? (
                            <button
                              type="button"
                              className="flex justify-center items-center p-0 border border-red bg-red rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                              onClick={() => dispatch(new ResetPreAppointmentItems())}
                            >
                              Remover todos <br /> los Productos
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="flex justify-center items-center p-0 border border-red bg-red rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                              onClick={() => dispatch(new ResetPreAppointmentPayment())}
                            >
                              Reiniciar Métodos <br /> de Pago
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="flex justify-center items-center p-0 border border-red bg-transparent rounded-md hover:border-primary focus:outline-none min-h-[40px] min-w-[150px] px-3 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                          >
                            Remover todos <br /> los Productos
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="flex justify-end items-center gap-3">
                  {order?.orden.status === 'PAID' ? null : (
                    <>
                      {(preAppointment?.orden || []).length > 0 ? (
                        <>
                          <button
                            type="button"
                            className="flex justify-center items-center p-0 border border-red bg-red rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                            onClick={() => dispatch(new OpenModal('PAYMENT_CANCEL_MODAL'))}
                          >
                            Cancelar Orden
                          </button>

                          {readyProducts === false || readyToPay === false ? (
                            <button
                              type="button"
                              className="flex justify-center items-center p-0 border border-primary bg-primary rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                              onClick={() => {
                                checkToPay();
                              }}
                            >
                              Ir al Pago
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="flex justify-center items-center p-0 border border-primary bg-primary rounded-md hover:border-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                              onClick={() => {
                                if (importToPay === 0 && paymentReceived === totalToPay) {
                                  dispatch(new OpenModal('PAYMENT_MODAL'));
                                } else {
                                  dispatch(
                                    new OpenModal('MESSAGE_MODAL', {
                                      title: 'Pago',
                                      loading: loading[FINISH_APPOINTMENT],
                                      message: 'Debe aceptar el monto a pagar',
                                    })
                                  );
                                }
                              }}
                            >
                              Finalizar Pago
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="flex justify-center items-center p-0 border border-red bg-transparent rounded-md hover:border-primary focus:outline-none min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px] leading-[20px] 2xl:leading-4 tracking-[0.08em] text-white font-normal"
                          >
                            Cancelar Orden
                          </button>
                          <button
                            type="button"
                            className="flex justify-center items-center p-0 border border-primary bg-transparent rounded-md hover:border-primary focus:outline-none min-h-[40px] min-w-[150px] px-3 py-2 shadow-sm hover:border-grey text-base 2xl:text-[12px leading-[20px] 2xl:leading-4tracking-[0.08em] text-white font-normal"
                          >
                            Ir al Pago
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Container>
  );
}
