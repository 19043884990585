import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Servicios, ServiciosCreate } from '../../../entities/servicios';
export type Interface = ApiInterface<{
  response: Servicios;
  pathParams: EmptyObject;
  body: Omit<ServiciosCreate, 'status'>;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-servicios',
  method: 'POST',
  basePath: 'servicios',
  path: '/',
  tablePermission: 'readWrite',
  metadataAuth: {
    onlySuperAdmin: true,
  },
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    name: { type: String, required: true },
    type: { type: String, required: true, enum: ['SERVICE', 'PRODUCT'] },
    duration: { type: Number },
    pause: { type: Number },
    description: { type: String },
    price: { type: Number, required: true },
    categoryId: { type: String, required: true },
    color: { type: String },
    productsRelation: { type: Array },
    isPrincipal: { type: String, enum: ['ACTIVE', 'INACTIVE'] },
    isForSell: { type: String, enum: ['ACTIVE', 'INACTIVE'] },
    isForSucursal: { type: String, enum: ['ACTIVE', 'INACTIVE'] },
    rewardPoint: { type: Number },
  }),
};
