import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { LashitasCreate } from '../../entities/lashitas';

export type Interface = ApiInterface<{
  response: LashitasCreate;
  pathParams: EmptyObject;
  body: Omit<LashitasCreate, 'tenantId' | 'id' | 'createdAt' | 'updatedAt'>;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-lashitas',
  method: 'POST',
  basePath: 'lashitas',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    alias: { type: String, required: true },
    firstName: { type: String, required: true },
    lastName: { type: String, required: true },
    phone: { type: String, required: true },
    info: {
      type: {
        uriAvatar: { type: String, required: false },
        color: { type: String, required: false },
        sex: { type: String, required: false },
        state: { type: String, required: false },
        city: { type: String, required: false },
        colony: { type: String, required: false },
        street: { type: String, required: false },
        zipCode: { type: String, required: false },
        outerNumber: { type: String, required: false },
        innerNumber: { type: String, required: false },
      },
      required: false,
    },
    schedulePosition: { type: Number, required: true },
    status: { type: String, enum: ['ACTIVE', 'INACTIVE'], required: false, default: 'ACTIVE' },
  }),
};
