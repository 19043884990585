import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { numberWithCommas } from '@webapp/utils/formatPrice';
import titleHeader from '../../../public/reportes/sumarioPDF.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderRadius: '5px',
    flexDirection: 'column',
  },
  text: {
    fontSize: '10px',
    color: '#000000',
  },
  textTable: {
    fontSize: '9px',
    color: '#000000',
    width: '100%',
    textAlign: 'left',
  },
  textTableBody: {
    fontSize: '8px',
    color: '#000000',
    textAlign: 'left',
    width: '100%',
  },
  textTotal: {
    fontSize: '10px',
    color: '#000000',
    fontWeight: 'black',
  },
  sectionData: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginVertical: '10px',
  },
  sectionTable: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginVertical: '10px',
  },
  sectionSales: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  sectionTotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
  },
});

// Create Document Component
export const MovementSummaryPdf = ({ data }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={{ width: '100%', height: '100px', marginBottom: '20px' }}>
            <Image source={titleHeader} style={{ width: 'auto', objectFit: 'cover' }} />
          </View>
          <View style={{ flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
            {data?.map((report: any, index: number) => (
              <>
                <View style={{ backgroundColor: '#FFFFFF', width: '100%', marginVertical: '10px' }}>
                  <Text style={{ fontWeight: 'bold', color: '#FFFFFF' }}>{report[index]?.sucursal}</Text>
                </View>
                <View style={styles.sectionData}>
                  {/* !---LEFT SIDE ----! */}
                  <View style={{ width: '49%' }}>
                    <View style={{ width: '100%' }}>
                      <Text style={styles.textTotal}>Recaudaciones</Text>
                    </View>
                    <View style={{ width: '100%' }}>
                      <View style={styles.sectionSales}>
                        <Text style={styles.text}>Efectivo</Text>
                        <Text style={styles.text}>${numberWithCommas(report[index]?.sales?.payments?.cash ?? 0)}</Text>
                      </View>
                      <View style={styles.sectionSales}>
                        <Text style={styles.text}>T. debito</Text>
                        <Text style={styles.text}>${numberWithCommas(report[index]?.sales?.payments?.card ?? 0)}</Text>
                      </View>
                      <View style={styles.sectionSales}>
                        <Text style={styles.text}>Transferencia</Text>
                        <Text style={styles.text}>
                          ${numberWithCommas(report[index]?.sales?.payments?.transference ?? 0)}
                        </Text>
                      </View>
                      <View style={styles.sectionTotal}>
                        <Text style={styles.textTotal}>Total recibido</Text>
                        <Text style={styles.textTotal}>${numberWithCommas(report[index]?.sales?.netIncome ?? 0)}</Text>
                      </View>
                    </View>
                  </View>
                  {/* !--- RIGHT SIDE ---! */}
                  <View style={{ width: '49%' }}>
                    <View style={{ width: '100%', justifyContent: 'space-between' }}>
                      <Text style={styles.textTotal}>Ventas</Text>
                      <Text style={styles.textTotal}>Cant.</Text>
                    </View>
                    <View style={{ width: '100%' }}>
                      <View style={styles.sectionSales}>
                        <Text style={styles.text}>Servicios</Text>
                        <Text style={styles.text}>${numberWithCommas(report[index]?.sales?.serviceAmount ?? 0)}</Text>
                      </View>
                      <View style={styles.sectionSales}>
                        <Text style={styles.text}>Productos</Text>
                        <Text style={styles.text}>${numberWithCommas(report[index]?.sales?.productAmount ?? 0)}</Text>
                      </View>
                      <hr />
                      <View style={styles.sectionTotal}>
                        <Text style={styles.textTotal}>Total ventas (producción)</Text>
                        <Text style={styles.textTotal}>
                          ${numberWithCommas(report[index]?.sales?.totalProductService ?? 0)}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                {/* !---DISCOUNTS---! */}
                <View style={styles.sectionData}>
                  <View style={{ width: '50%' }}>
                    <View>
                      <Text style={styles.textTotal}>Estadisticas</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Descuento discrecional</Text>
                      <Text style={styles.text}>${report[index]?.sales?.totalDiscount}</Text>
                    </View>
                  </View>
                </View>
                {/* !--- TICKETS ---!*/}
                <View style={styles.sectionData}>
                  <View style={{ width: '50%' }}>
                    <View style={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
                      <Text style={styles.textTotal}>Medias de tiquet</Text>
                      <Text style={styles.textTotal}>No.</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Servicio (todos los tiquets)</Text>
                      <Text style={styles.text}>{report[index]?.sales?.totalServiceQuantity}</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Productos (todos los tiquets)</Text>
                      <Text style={styles.text}>{report[index]?.sales?.totalProductQuantity}</Text>
                    </View>
                  </View>
                </View>
                {/* !--- CLIENTS ---!*/}
                <View style={styles.sectionData}>
                  <View>
                    <View style={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
                      <Text style={styles.textTotal}>Clientes</Text>
                      <Text style={styles.textTotal}>No. Clientes</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Walkin</Text>
                      <Text style={styles.text}>0</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Nuevos</Text>
                      <Text style={styles.text}>0</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Regresando</Text>
                      <Text style={styles.text}>0</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.sectionData}>
                  <View style={{ width: '50%' }}>
                    <View style={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
                      <Text style={styles.textTotal}>Clientes</Text>
                      <Text style={styles.textTotal}>No. Clientes</Text>
                      <Text style={styles.textTotal}>Valor</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Hombre</Text>
                      <Text style={styles.text}>{report[index]?.clients?.maleQuantity}</Text>
                      <Text style={styles.text}>${numberWithCommas(report[index]?.clients?.maleAmount ?? 0)}</Text>
                    </View>
                    <View style={styles.sectionSales}>
                      <Text style={styles.text}>Mujer</Text>
                      <Text style={styles.text}>{report[index]?.clients?.femaleQuantity}</Text>
                      <Text style={styles.text}>${numberWithCommas(report[index]?.clients?.femaleAmount ?? 0)}</Text>
                    </View>
                  </View>
                </View>
                {/* !--- TABLE ---! */}
                <View style={styles.sectionTable}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: '5px',
                      borderTop: '1px solid #CCCCCC',
                      borderBottom: '1px solid #CCCCCC',
                    }}
                  >
                    <Text style={styles.textTable}>Empleado</Text>
                    <Text style={styles.textTable}>Productos</Text>
                    <Text style={styles.textTable}>Servicios</Text>
                    <Text style={styles.textTable}>Extras + V.R</Text>
                    <Text style={styles.textTable}>Total</Text>
                    <Text style={styles.textTable}>% Total</Text>
                    <Text style={styles.textTable}>Tiquet 1/2</Text>
                    <Text style={styles.textTable}>Tiquet (p/s/vr)</Text>
                    <Text style={styles.textTable}>Tiempo empleado</Text>
                    <Text style={styles.textTable}>Ventas serv./ho</Text>
                  </View>
                  {report[index]?.lashista.map((data: any, index: number) => (
                    <View
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        textAlign: 'left',
                      }}
                    >
                      <Text style={styles.textTableBody}>{data?.fullName}</Text>
                      <Text style={styles.textTableBody}>${numberWithCommas(data?.productAmount ?? 0)}</Text>
                      <Text style={styles.textTableBody}>${numberWithCommas(data?.serviceAmount ?? 0)}</Text>
                      <Text style={styles.textTableBody}>${numberWithCommas(data?.rewardAmount ?? 0)}</Text>
                      <Text style={styles.textTableBody}>${numberWithCommas(data?.total ?? 0)}</Text>
                      <Text style={styles.textTableBody}>{data?.totalPercentage}%</Text>
                      <Text style={styles.textTableBody}>{data?.ticketPercentage}</Text>
                      <Text style={styles.textTableBody}>{numberWithCommas(data?.totalTickets ?? 0)}</Text>
                      <Text style={styles.textTableBody}>
                        {`${data?.totalHourWorked?.h ?? 0} hr ${data?.totalHourWorked?.m ?? 0} min`}
                      </Text>
                      <Text style={styles.textTableBody}>${numberWithCommas(data?.serviceByHour ?? 0)}</Text>
                    </View>
                  ))}
                </View>
              </>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
