const PaperSmallIcon = (props: any) => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4545 8.73466V18.5C16.4545 19.8807 15.3722 21 14.0142 21H3.44318C2.08239 21 1 19.8807 1 18.5V3.5C1.00388 2.83815 1.26851 2.20452 1.73652 1.73652C2.20452 1.26851 2.83815 1.00388 3.5 1H8.84773C9.03283 1.00028 9.21603 1.0374 9.38664 1.1092C9.55725 1.18099 9.71187 1.28603 9.84148 1.41818L16.0432 7.725C16.307 7.99482 16.4547 8.35727 16.4545 8.73466V8.73466Z"
        stroke={props.color ?? '#56C4C7'}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.72729 1V7.13636C8.73231 7.55674 8.90153 7.95849 9.19881 8.25576C9.49608 8.55304 9.89783 8.72226 10.3182 8.72727H16.4546"
        stroke={props.color ?? '#56C4C7'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaperSmallIcon;
