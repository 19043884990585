import {
  ApiInterface,
  EmptyObject,
  PaginatedQueryParams,
  PaginatedResponse,
} from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Category } from '../../../entities/category';

export type Interface = ApiInterface<{
  response: PaginatedResponse<Category>;
  pathParams: EmptyObject;
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: PaginatedQueryParams & { query?: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-list-category',
  method: 'GET',
  basePath: 'category',
  path: '/',
  tablePermission: 'read',
  scope: 'RECEPTIONIST',
};
