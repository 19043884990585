import { FinalizeInvoice } from '@services/business/invoice/entities';
import { calcDiscountType } from '@webapp/utils/validateNumber';
import { CalendarActions } from './action-creators';
import * as actions from './actions';
import { appointmentsInitialState } from './initial-state';
import { CalendarState, MethodPayment } from './state';

const appointmentsReducer = (
  state: CalendarState = appointmentsInitialState,
  action: CalendarActions
): CalendarState => {
  switch (action.type) {
    case actions.UPDATE_APPOINTMENT: {
      return {
        ...state,
        appointments: state.appointments.map((a) => {
          if (a.id === action.ordenItemId && a.status === 'IN_PROCESS') {
            return { ...a, ...action.appointment };
          }
          return a;
        }),
      };
    }
    case actions.ADD_CALENDAR: {
      return {
        ...state,
        appointments: state.appointments.concat(action.items),
      };
    }
    case actions.LIST_CALENDAR_SUCCESS: {
      let lastIds = state.appointments.map((a) => a.id);

      return {
        ...state,
        appointments: action.items
          .map((a) => {
            lastIds = lastIds.filter((id) => id !== a.id);
            return a;
          })
          .concat(state.appointments.filter((a) => lastIds.includes(a.id))),
      };
    }
    case actions.CREATE_APPOINTMENT: {
      return {
        ...state,
        appointment: action.item,
      };
    }
    case actions.CREATE_PRE_APPOINTMENT: {
      return {
        ...state,
        preAppointment: { ...state.preAppointment, ...action.item },
      };
    }
    case actions.GET_ORDEN_SUCCESS: {
      return {
        ...state,
        order: action.item,
        preAppointment: {
          ...action.item.orden,
          orden: action.item.item,
        },
      };
    }
    case actions.GET_ORDEN_ITEM_SUCCESS: {
      return {
        ...state,
        updateAppointment: action.item,
      };
    }

    case actions.CREATE_PRE_APPOINTMENT_ITEM: {
      const orden = state?.preAppointment?.orden || [];
      const checkTemp = orden.find((item) => item.status === 'TEMPORAL');
      if (checkTemp) {
        return state;
      }

      return {
        ...state,
        preAppointment: {
          ...state.preAppointment,
          orden: orden.concat([action.appointment]),
        },
      };
    }

    case actions.UPDATE_PRE_APPOINTMENT_ITEM: {
      return {
        ...state,
        preAppointment: {
          ...state.preAppointment,
          ...action,
          orden: state.preAppointment?.orden
            ?.map((ordenItem) => {
              if (ordenItem.id === action.ordenItemId) {
                return {
                  ...ordenItem,
                  ...action.appointment,
                };
              }
              return ordenItem;
            })
            .filter(({ id }) => Boolean(id)),
        },
      };
    }

    case actions.UPDATE_DISCOUNT_PRE_APPOINTMENT: {
      return {
        ...state,
        preAppointment: {
          ...state.preAppointment,
          orden: state.preAppointment?.orden?.map((ordenItem) => {
            if (action.discount?.products?.some((product) => product === ordenItem.serviceId)) {
              return {
                ...ordenItem,
                discountAmount: calcDiscountType(
                  ordenItem.price ?? 0,
                  action.discount.type,
                  action.discount.amount ?? 0,
                  action.discount.percentage ?? 0
                ),
              };
            }
            return { ...ordenItem, discountAmount: 0 };
          }),
          payment: {
            ...state.preAppointment?.payment,
            totalAmount: state.preAppointment?.payment?.totalAmount ?? 0,
            totalDiscount: state.preAppointment?.payment?.totalDiscount ?? 0,
            methods: state.preAppointment?.payment?.methods as MethodPayment[],
            discountId: action.discount?.id,
          },
        },
        updateAppointmentPayment: {
          ...state.updateAppointmentPayment,
          discountId: action.discount?.id,
        } as FinalizeInvoice,
      };
    }

    case actions.UPDATE_PRE_APPOINTMENT: {
      return {
        ...state,
        preAppointment:
          action.item !== undefined
            ? {
                ...state.preAppointment,
                ...action.item,
              }
            : undefined,
      };
    }

    case actions.UPDATE_PRE_APPOINTMENT_PAYMENT: {
      let usedId = state.updateAppointmentPayment?.methods
        .filter(({ id }) => (action.value.type === 'REWARD' ? !id?.includes('reward-payment') : id))
        .map(({ id }) => id)
        .concat(action.value.id);

      let newState = state.updateAppointmentPayment?.methods
        .filter(({ id }) => (action.value.type === 'REWARD' ? !id?.includes('reward-payment') : id))
        .map((method) => {
          usedId = usedId?.filter((id) => method.id !== id);
          if (method.id === action.method) {
            return {
              ...method,
              ...action.value,
            };
          }
          return method;
        }) as MethodPayment[];

      usedId?.forEach(() => {
        newState = newState.concat(action.value);
      });

      return {
        ...state,
        updateAppointmentPayment: {
          ...state.updateAppointmentPayment,
          methods: newState as MethodPayment[],
        } as FinalizeInvoice,
      };
    }

    case actions.UPDATE_PRE_APPOINTMENT_PAYMENT_REWARD_POINT: {
      return {
        ...state,
        updateAppointmentPayment: {
          ...state.updateAppointmentPayment,
          rewardUsed: (state.updateAppointmentPayment?.rewardUsed ?? 0) + action.rewardPoint,
        } as FinalizeInvoice,
      };
    }

    case actions.DELETE_PRE_APPOINTMENT_PAYMENT: {
      let rewardPoint = 0;
      // delete the rewards methods
      const newMethod = state.updateAppointmentPayment?.methods.filter((method) => {
        if (method.type === 'REWARD' && method.productId === action.productId) {
          rewardPoint = method.rewardPoint;
          return false;
        }
        return true;
      });

      return {
        ...state,
        updateAppointmentPayment: {
          ...state.updateAppointmentPayment,
          methods: newMethod as MethodPayment[],
          rewardUsed: (state.preAppointment?.payment?.rewardUsed ?? 0) - rewardPoint,
        } as FinalizeInvoice,
        preAppointment: {
          ...state.preAppointment,
          payment: {
            ...state.preAppointment?.payment,
            methods: newMethod as MethodPayment[],
            rewardUsed: (state.preAppointment?.payment?.rewardUsed ?? 0) - rewardPoint,
          } as FinalizeInvoice,
        },
      };
    }

    case actions.RESET_PRE_APPOINTMENT_PAYMENT: {
      const paymentMethods = state.preAppointment?.payment?.methods.map((method) => {
        if (method.type === 'REWARD') {
          return method;
        }

        return {
          id: method.id,
          type: method.type,
          amount: 0,
        } as MethodPayment;
      });

      return {
        ...state,
        updateAppointmentPayment: {
          discountId: '',
          totalAmount: 0,
          totalDiscount: 0,
          rewardUsed: state.preAppointment?.payment?.rewardUsed,
          methods: paymentMethods as MethodPayment[],
        },
        preAppointment: {
          ...state.preAppointment,
          payment: {
            discountId: '',
            totalAmount: 0,
            totalDiscount: 0,
            rewardUsed: state.preAppointment?.payment?.rewardUsed,
            methods: paymentMethods as MethodPayment[],
          },
        },
      };
    }

    case actions.RESET_PRE_APPOINTMENT_ITEMS: {
      return {
        ...state,
        preAppointment: {
          ...state.preAppointment,
          orden: [],
          payment: {
            discountId: '',
            totalAmount: 0,
            totalDiscount: 0,
            rewardUsed: 0,
            methods: [
              { id: 'CARD', type: 'CARD', amount: 0 },
              { id: 'CASH', type: 'CASH', amount: 0 },
              { id: 'BALANCE', type: 'BALANCE', amount: 0 },
              { id: 'TRANSFER', type: 'TRANSFER', amount: 0 },
            ],
          },
        },
        updateAppointmentPayment: {
          discountId: '',
          totalAmount: 0,
          totalDiscount: 0,
          rewardUsed: 0,
          methods: [
            { id: 'CARD', type: 'CARD', amount: 0 },
            { id: 'CASH', type: 'CASH', amount: 0 },
            { id: 'BALANCE', type: 'BALANCE', amount: 0 },
            { id: 'TRANSFER', type: 'TRANSFER', amount: 0 },
          ],
        },
      };
    }

    case actions.GET_APPOINTMENT_BY_DATE_SUCCESS: {
      return {
        ...state,
        appointmentsOfTheDay: action.item,
      };
    }

    case actions.SET_CURRENT_APPOINTMENT: {
      return {
        ...state,
        currentAppointment: action.item,
      };
    }

    default:
      return state;
  }
};

export default appointmentsReducer;
