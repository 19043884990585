import { ApiConfig } from '@services/support/utils/types/api-config';
import { ApiInterface, ApiInterfaceKeys } from '@stetamalo/cloud-platform/catalog/api';
import axios from 'axios';
import { DateTime } from 'luxon';
import { getSubdomain } from '../utils/subDomain';
import { getToken } from './auth';
import { dbStore } from './models/db';

const API_DOMAIN = process.env.REACT_APP_API_URL;

export type ApiClient = <T extends ApiInterfaceKeys>(
  config: ApiConfig<ApiInterface<T>> & { withCache?: boolean; onlyDeleteInLocal?: boolean },
  configData?: { queryParams?: T['queryParams']; pathParams?: T['pathParams']; body?: T['body'] }
) => Promise<T['response']>;

export const instante = axios.create({
  baseURL: API_DOMAIN,
});
export const instanteNotCache = axios.create({
  baseURL: API_DOMAIN,
});

instante.interceptors.response.use(
  function (response) {
    if (response.status === 200 && response.config.method?.toLowerCase() === 'get') {
      const keyUrl = response.request.responseURL.split('/');
      localStorage.setItem(
        `cache_${getSubdomain()}_${keyUrl[keyUrl.length - 1]}_mlashes`,
        JSON.stringify({
          data: response.data,
          maxAge: DateTime.now().plus({ minute: 30 }).valueOf(),
        })
      );
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// eslint-disable-next-line
export const parseCommonFields = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((d) => parseCommonFields(d));
  }

  if ('created_at' in data) {
    data.created_at = new Date(data.created_at);
  }

  if ('updated_at' in data) {
    data.updated_at = new Date(data.updated_at);
  }

  return data;
};

export const CallApi =
  (service: string): ApiClient =>
  async <T extends ApiInterfaceKeys>(
    config: ApiConfig<ApiInterface<T>> & { withCache?: boolean; onlyDeleteInLocal?: boolean },
    configData?: { queryParams?: T['queryParams']; pathParams?: T['pathParams']; body?: T['body'] }
  ): Promise<T['response']> => {
    const token = await getToken();

    if (!token && !config.isPublic) {
      throw new Error('Auth.Error.MissingToken');
    }

    const url = configData?.pathParams
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Unreachable code error
        config.path.replace(/\{(.*?)\}/g, (_match: any, p1: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Unreachable code error
          return configData.pathParams[p1];
        })
      : config.path === '/'
      ? ''
      : config.path;

    const urlFull = API_DOMAIN + service + url;

    const keyUrl = urlFull.split('/');
    const keyLocal = `cache_${getSubdomain()}_${keyUrl[keyUrl.length - 1]}_mlashes`;
    const getLocal = localStorage.getItem(keyLocal);

    if (getLocal && config.withCache) {
      const localData = JSON.parse(getLocal) as { data: T['response']; maxAge: number };
      if (localData.maxAge > DateTime.now().valueOf()) {
        return parseCommonFields(localData.data);
      }
    }
    const axiosRequest = config.withCache ? instante : instanteNotCache;

    const bodyAxios = {
      method: config.method,
      url: service + url,
      params: configData?.queryParams,
      data: configData?.body,
    };

    try {
      const { data } = await axiosRequest({
        headers: {
          'Content-Type': 'application/json',
          'Mlashes-Store': getSubdomain(),
          Authorization: token as string,
          'max-age': '0',
        },
        ...bodyAxios,
      });
      if (config.onlyDeleteInLocal) {
        localStorage.removeItem(keyLocal);
      }

      return parseCommonFields(data) as T['response'];
    } catch (error) {
      if (config.method.toLowerCase() !== 'get') {
        dbStore.queueError.add({
          id: new Date().getTime(),
          type: 'AXIOS',
          data: JSON.stringify(bodyAxios),
        });
      }

      if (getLocal && config.withCache) {
        const localData = JSON.parse(getLocal) as { data: T['response']; maxAge: number };
        if (localData.maxAge > DateTime.now().valueOf()) {
          return parseCommonFields(localData.data);
        }
      }
      return Promise.reject(error);
    }
  };
