import { normalizeAnnualSale } from '@webapp/utils/reports/annualSale';
import { normalizeDiscount } from '@webapp/utils/reports/discount';
import { normalizeFutureAppoint } from '@webapp/utils/reports/futureAppoint';
import { normalizeNewClient } from '@webapp/utils/reports/newClient';
import { normalizeNoReturnClient } from '@webapp/utils/reports/noreturnClient';
import { normalizePerformanceEmployees } from '@webapp/utils/reports/performance-employee';
import { normalizeProductService } from '@webapp/utils/reports/product-service';
import { normalizeRankingSucursal } from '@webapp/utils/reports/ranking-sucursal';
import { normalizeScheduler } from '@webapp/utils/reports/scheduler';
import { getSubdomain } from '@webapp/utils/subDomain';

import * as apiProduct from '@services/business/products/catalog/api/servicios';
// import { Sucursal } from '../sucursal/state';
import { normalizeMovementsSummary } from '@webapp/utils/reports/movementsSummary';
import { ReportActions } from './action-creators';
import * as actions from './actions';
import { reportsInitial } from './initial-state';
import { LoggerState } from './state';
const CASH = 'CASH';
const BALANCE = 'BALANCE';
const CARD = 'CARD';
const DISCOUNT = 'DISCOUNT';
const DOLLAR = 'DOLLAR';
const SERVICE = 'SERVICE';
const PRODUCT = 'PRODUCT';
const TRANSFER = 'TRANSFER';
const REWARD = 'REWARD';

const reportsReducer = (state: LoggerState = reportsInitial, action: ReportActions): LoggerState => {
  switch (action.type) {
    case actions.LIST_REPORTS_SUCCESS: {
      return {
        ...state,
        reportList: action.data,
      };
    }

    case actions.LIST_REPORTS_PER_DAY_SUCCESS: {
      return {
        ...state,
        reportPerDay: action.data,
      };
    }

    case actions.LIST_REPORTS_SUCCESS_CLIENT: {
      return {
        ...state,
        reportListClient: action.data,
      };
    }

    case actions.LIST_NEW_SUCCESS_CLIENT: {
      return {
        ...state,
        listNewClient: action.data,
      };
    }
    case actions.LIST_OLD_SUCCESS_CLIENT: {
      return {
        ...state,
        listOldClient: action.data,
      };
    }
    case actions.LIST_FIRST_REPORTS_SUCCESS: {
      return {
        ...state,
        firstYearList: action.data,
      };
    }

    case actions.LIST_SECOND_REPORTS_SUCCESS: {
      return {
        ...state,
        secondYearList: action.data,
      };
    }
    case actions.CLEAR_REPORTS_NORMALIZED: {
      return {
        ...state,
        salesReport: [],
        historyReport: [],
        discountReport: [],
        serviceReport: [],
        productReport: [],
        performanceLashita: [],
        rankingSucursal: [],
        newClient: [],
        noReturnClient: [],
        futureAppointment: [],
        schedulerReport: [],
        annualReport: [],
        firstYearReport: [],
        secondYearReport: [],
        reportList: [],
      };
    }

    case actions.HISTORY_REPORT_NORMALIZE: {
      const arr: any[] = [];
      //
      for (const data of action.data) {
        const { order, items } = data;
        const [item] = items;

        const cash = order.payment.methods.find((method: any) => method.type === CASH)?.amount;

        const card = order.payment.methods.find((method: any) => method.type === CARD)?.amount;

        const balance = order.payment.methods.find((method: any) => method.type === BALANCE)?.amount;

        const discount = order.payment.methods.find((method: any) => method.type === DISCOUNT)?.amount;

        const dollar = order.payment.methods.find((method: any) => method.type === DOLLAR)?.amount;

        const transfer = order.payment.methods.find((method: any) => method.type === TRANSFER)?.amount;

        const categoryId = order?.categoryIds[0];

        const lashistaId = order?.lashistaIds?.[0];

        const total = order?.total;

        const date = new Date(order.finalizeAt).toLocaleDateString();

        arr.push({
          payments: {
            cash: cash ?? 0,
            card: card ?? 0,
            balance: balance ?? 0,
            discount: discount ?? 0,
            dollar: dollar ?? 0,
            transfer: transfer ?? 0,
          },
          name: item?.name ?? '',
          lashistaId: lashistaId,
          categoryId: categoryId ?? '',
          totalPayment: total ?? 0,
          date: date ?? '',
        });
      }

      return {
        ...state,
        historyReport: arr,
      };
    }
    case actions.SALES_REPORT_NORMALIZE: {
      const arr: any[] = [];
      const totalService: number[] = [];
      const totalProduct: number[] = [];
      const totalCash: number[] = [];
      const totalCard: number[] = [];
      const totalAmount: number[] = [];
      const totalTransfer: number[] = [];
      const totalReward: number[] = [];
      for (const data of action.data) {
        const { order, items } = data;
        /**
         *      Payments
         */
        const cash = order.payment.methods.find((method: any) => method.type === CASH)?.amount;
        const card = order.payment.methods.find((method: any) => method.type === CARD)?.amount;
        const transference = order.payment.methods.find((method: any) => method.type === TRANSFER)?.amount;
        const reward = order.payment.methods.find((method: any) => method.type === REWARD)?.amount;
        const total = order.total;

        /**
         *      Get Service/Product and check if is a empty array
         * **/
        const services = items.filter((item: any) => item.type === SERVICE)?.map((service: any) => service.price);

        if (services.length === 0) {
          totalService.push(0);
        } else {
          const total = services.reduce((a: number, b: number) => a + b);
          totalService.push(total);
        }

        const products = items.filter((item: any) => item.type === PRODUCT)?.map((product: any) => product.price);

        if (products.length === 0) {
          totalProduct.push(0);
        } else {
          const total = products.reduce((a: number, b: number) => a + b);
          totalProduct.push(total);
        }

        totalCash.push(cash ?? 0);
        totalCard.push(card ?? 0);
        totalAmount.push(total ?? 0);
        totalTransfer.push(transference ?? 0);
        totalReward.push(reward ?? 0);
      }

      const getTotal = (firstValue: number, secondValue: number, thirdValue?: number): number => {
        return firstValue + secondValue + (thirdValue ?? 0);
      };

      /**
       *      Sum of values
       **/
      if (
        totalService.length > 0 &&
        totalCard.length > 0 &&
        totalCash.length > 0 &&
        totalAmount.length > 0 &&
        totalProduct.length > 0 &&
        totalTransfer.length > 0 &&
        totalReward.length > 0
      ) {
        const service = totalService.reduce((a: number, b: number) => a + b);
        const card = totalCard.reduce((a: number, b: number) => a + b);
        const cash = totalCash.reduce((a: number, b: number) => a + b);
        const amount = totalAmount.reduce((a: number, b: number) => a + b);
        const product = totalProduct.reduce((a: number, b: number) => a + b);
        const reward = totalReward.reduce((a: number, b: number) => a + b);
        const transfer = totalTransfer.reduce((a: number, b: number) => a + b);

        /**
         *     Set all values to array
         **/
        arr.push({
          totalSales: getTotal(product, service),
          netIncome: getTotal(cash, card, transfer),
          total: {
            service,
            card,
            cash,
            amount,
            product,
            reward,
            transfer,
          },
        });
      }

      return {
        ...state,
        salesReport: arr,
      };
    }
    case actions.PRODUCT_SERVICE_REPORT_NORMALIZE: {
      const product = action.data.flatMap(({ items }: apiProduct.listServicios.Interface['response']) => items.flat());

      const newData = normalizeProductService(action.data, product);

      const resArr: any[] = [];
      newData.filter(function (item) {
        const i = resArr.findIndex((x) => x.name == item.name && x.categoryId == item.categoryId);
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });

      const services = resArr.filter(({ type }) => type === SERVICE);
      const products = resArr.filter(({ type }) => type === PRODUCT);

      return {
        ...state,
        productReport: products,
        serviceReport: services,
      };
    }

    case actions.PERFORMANCE_EMPLOYEE_NORMALIZE: {
      const newData = normalizePerformanceEmployees(action.data, action.lashitas, action.categories);

      return {
        ...state,
        performanceLashita: newData,
      };
    }

    case actions.RANKING_SUCURSAL_NORMALIZE: {
      const newData = normalizeRankingSucursal(action.data);
      const resArr: any[] = [];
      newData.filter(function (item) {
        const i = resArr.findIndex((x) => x.sucursal == item.sucursal);
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return {
        ...state,
        rankingSucursal: resArr,
      };
    }

    case actions.DISCOUNT_NORMALIZE: {
      const newData = normalizeDiscount(action.data, action.discountList);
      const uniqueDiscount: any[] = [];
      newData.filter(function (item) {
        const i = uniqueDiscount.findIndex((x) => x.id == item.id);
        if (i <= -1) {
          uniqueDiscount.push(item);
        }
        return null;
      });

      return {
        ...state,
        discountReport: uniqueDiscount,
      };
    }

    case actions.NEW_CLIENT_NORMALIZE: {
      const newData = normalizeNewClient(action.historyClient, action.data);

      const clientInSucursal = newData?.filter((data) => data.sucursal === getSubdomain());

      return {
        ...state,
        newClient: clientInSucursal,
      };
    }

    case actions.NO_RETURN_CLIENT_NORMALIZE: {
      const newData = normalizeNoReturnClient(action.historyClient, action.data);

      const clientInSucursal = newData?.filter((data) => data.sucursal === getSubdomain());

      return {
        ...state,
        noReturnClient: clientInSucursal,
      };
    }

    case actions.FUTURE_APPOINTMENT_NORMALIZE: {
      const newData = normalizeFutureAppoint(action.data, action.listLashista);
      const uniqueDiscount: any[] = [];

      newData.filter(function (item) {
        const i = uniqueDiscount.findIndex((x) => x.lashistaId == item.lashistaId);
        if (i <= -1) {
          uniqueDiscount.push(item);
        }
        return null;
      });
      return {
        ...state,
        futureAppointment: uniqueDiscount.filter(
          (data) => data.lashistaId !== undefined && data.lashita !== 'undefined undefined'
        ),
      };
    }

    case actions.SCHEDULER_NORMALIZE: {
      const newData = normalizeScheduler(action.data, action.listLashista);
      const uniqueLashista: any[] = [];

      newData.filter(function (item) {
        const i = uniqueLashista.findIndex((x) => x.lashistaId == item.lashistaId);
        if (i <= -1) {
          uniqueLashista.push(item);
        }
        return null;
      });

      return {
        ...state,
        schedulerReport: uniqueLashista.filter(
          (data) => data.lashistaId !== undefined && data.lashista !== 'undefined undefined'
        ),
      };
    }

    case actions.ANNUAL_SALE_NORMALIZE: {
      const newData = normalizeAnnualSale(action.data, action.dataNewClient, action.dataNoReturnClient);
      const uniqueDiscount: any[] = [];

      newData.filter(function (item) {
        const i = uniqueDiscount.findIndex(
          (x) => x.january.sucursal == item.january.sucursal && x.september.sucursal == item.september.sucursal
        );
        if (i <= -1) {
          uniqueDiscount.push(item);
        }
        return null;
      });

      return {
        ...state,
        annualReport: uniqueDiscount,
      };
    }

    case actions.FIRST_YEAR_NORMALIZE: {
      const newData = normalizeAnnualSale(action.data, action.dataNewClient, action.dataNoReturnClient);
      const uniqueDiscount: any[] = [];

      newData.filter(function (item) {
        const i = uniqueDiscount.findIndex(
          (x) => x.january.sucursal == item.january.sucursal && x.september.sucursal == item.september.sucursal
        );
        if (i <= -1) {
          uniqueDiscount.push(item);
        }
        return null;
      });

      return {
        ...state,
        firstYearReport: uniqueDiscount,
      };
    }

    case actions.SECOND_YEAR_NORMALIZE: {
      const newData = normalizeAnnualSale(action.data, action.dataNewClient, action.dataNoReturnClient);
      const uniqueDiscount: any[] = [];

      newData.filter(function (item) {
        const i = uniqueDiscount.findIndex(
          (x) => x.january.sucursal == item.january.sucursal && x.september.sucursal == item.september.sucursal
        );
        if (i <= -1) {
          uniqueDiscount.push(item);
        }
        return null;
      });

      return {
        ...state,
        secondYearReport: uniqueDiscount,
      };
    }

    case actions.MOVEMENTS_SUMMARY_NORMALIZE: {
      const newData = normalizeMovementsSummary(
        action.data,
        action.newClient,
        action.noReturnClient,
        action.listLashista,
        action.sucursales
      );

      return {
        ...state,
        movementsSummary: newData,
      };
    }

    default:
      return state;
  }
};

export default reportsReducer;
