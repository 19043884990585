const FileGreenIcon = (props: any) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1V3V1ZM13 7V9V7ZM13 13V15V13ZM3 1C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V6C1.53043 6 2.03914 6.21071 2.41421 6.58579C2.78929 6.96086 3 7.46957 3 8C3 8.53043 2.78929 9.03914 2.41421 9.41421C2.03914 9.78929 1.53043 10 1 10V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H17C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V10C18.4696 10 17.9609 9.78929 17.5858 9.41421C17.2107 9.03914 17 8.53043 17 8C17 7.46957 17.2107 6.96086 17.5858 6.58579C17.9609 6.21071 18.4696 6 19 6V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3Z"
        stroke={props.color ?? '#56C4C7'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileGreenIcon;
