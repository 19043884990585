/**
 * return the readable name of the payments methods
 * @param type string
 * @returns string
 */
export const getPaymentMethodName = (type: string): string => {
  let readableType = '';
  switch (type) {
    case 'CARD':
      readableType = 'Tarjeta';
      break;
    case 'CASH':
      readableType = 'Efectivo';
      break;
    case 'BALANCE':
      readableType = 'Nota de Crédito';
      break;
    case 'TRANSFER':
      readableType = 'Transferencia';
      break;
    case 'DISCOUNT':
      readableType = 'Descuento';
      break;
    case 'DOLLAR':
      readableType = 'Dólares';
      break;
    case 'REWARD':
      readableType = 'Recompensa';
      break;

    default:
      break;
  }
  return readableType;
};
