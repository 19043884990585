import { LoggerState } from './state';

export const reportsInitial: LoggerState = {
  //Data reports
  reportList: [],
  reportPerDay: [],
  //Data for different year
  firstYearList: [],
  secondYearList: [],
  historyReport: [],
  salesReport: [],
  productReport: [],
  serviceReport: [],
  //History Client
  reportListClient: [],
  allServices: [],
  rankingSucursal: [],
  discountReport: [],
  performanceLashita: [],
  // Client old or new
  listNewClient: [],
  listOldClient: [],
  newClient: [],
  noReturnClient: [],
  futureAppointment: [],
  schedulerReport: [],
  annualReport: [],
  firstYearReport: [],
  secondYearReport: [],
  movementsSummary: [],
};
