import { ChartBarIcon, UsersIcon } from '@heroicons/react/outline';

const navigationMenu = [
  {
    name: 'Principal',
    icon: UsersIcon,
    current: true,
    href: '#',
    children: [
      { name: 'Clientes', href: '/clientes', current: false },
      { name: 'Dashboard', href: '/dashboard', current: true },
      { name: 'Empleados', href: '/employees', current: false },
      { name: 'Lashistas', href: '/lashistas', current: false },
      { name: 'LLegadas', href: '/llegadas', current: false },
      { name: 'Inventario', href: '/inventory', current: false },
      { name: 'Pagos', href: '/pagos', current: false },
      { name: 'Caja del dia', href: '/caja', current: false },
      { name: 'Punto de venta', href: '/movimientos', current: false },
    ],
  },
  {
    href: '#',
    name: 'Reportes',
    icon: ChartBarIcon,
    children: [
      { name: 'Horarios y citas', href: '/reportes-citas', current: false },
      { name: 'Citas futuras', href: '/reportes-citas-futuras', current: false },
      { name: 'Clientes que no regresan', href: '/reportes-clientes-no-regreso', current: false },
      { name: 'Descuentos', href: '/reportes-descuentos', current: false },
      { name: 'Diario', href: '/reportes', current: true },
      { name: 'Historial de ventas', href: '/reportes-historial-ventas', current: false },
      { name: 'Lashista', href: '/llegadas', current: false },
      { name: 'Nuevos clientes', href: '/reportes-clientes-nuevos', current: false },
      { name: 'Productos y servicios', href: '/reportes-producto-servicio', current: false },
      { name: 'Rendimiento de empleados', href: '/reportes-rendimiento', current: false },
      { name: 'Ventas', href: '/reportes-ventas', current: false },
      { name: 'Venta anual', href: '/reportes-anual', current: false },
    ],
    current: false,
  },
];

export { navigationMenu };
