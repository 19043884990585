import { ApiConfig } from '@services/support/utils/types/api-config';
import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Cognito } from '../../entities/cognito';

export type Interface = ApiInterface<{
  response: Cognito[];
  pathParams: EmptyObject;
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: { query?: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-list-employee',
  method: 'GET',
  basePath: 'identity',
  path: '/employee',
  tablePermission: 'read',
  scope: 'RECEPTIONIST',
};
