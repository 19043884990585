import { Orden } from '@services/business/invoice/entities';
import {
  ApiInterface,
  EmptyObject,
  PaginatedQueryParams,
  PaginatedResponse,
} from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
export type Interface = ApiInterface<{
  response: PaginatedResponse<Orden>;
  pathParams: EmptyObject;
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: PaginatedQueryParams & { day: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-list-invoice-order-per-day',
  method: 'GET',
  basePath: 'orden',
  path: '/order-finalize-day',
  tablePermission: 'read',
  scope: 'RECEPTIONIST',
};
