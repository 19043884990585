import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as employee from '../../../sdk/employee';
import { Actions } from '../../actions';
import {
  CreateEmployee,
  CreateEmployeeError,
  CreateEmployeeSuccess,
  DeleteEmployee,
  DeleteEmployeeError,
  DeleteEmployeeSuccess,
  UpdateEmployee,
  UpdateEmployeeError,
  UpdateEmployeeSuccess,
} from '../action-creators';
import { CREATE_EMPLOYEE, DELETE_EMPLOYEE, UPDATE_EMPLOYEE } from '../actions';

export const crudEmployeeEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      CreateEmployee['type'] | DeleteEmployee['type'] | UpdateEmployee['type'],
      CreateEmployee | DeleteEmployee | UpdateEmployee
    >(CREATE_EMPLOYEE, DELETE_EMPLOYEE, UPDATE_EMPLOYEE),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case CREATE_EMPLOYEE: {
            await employee.createEmployee(action.employee);

            return new CreateEmployeeSuccess();
          }
          case DELETE_EMPLOYEE: {
            await employee.deleteEmployee(action.employeeId);

            return new DeleteEmployeeSuccess();
          }
          case UPDATE_EMPLOYEE: {
            await employee.updateEmployee(action.employee, action.username);

            return new UpdateEmployeeSuccess();
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case CREATE_EMPLOYEE: {
            return new CreateEmployeeError(err.message, err.description);
          }
          case DELETE_EMPLOYEE: {
            return new DeleteEmployeeError(err.message, err.description);
          }
          case UPDATE_EMPLOYEE: {
            return new UpdateEmployeeError(err.message, err.description);
          }
        }
      }
    })
  );
