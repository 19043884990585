// import { Lashista } from '@enterprise/store/lashista/state';
import * as api from '@services/business/history/catalog/api';

export const normalizeScheduler = (
  data: api.getHistoryOrder.Interface['response'],
  dataLashista: any[]
): Array<{
  sucursal: string;
  futureOrders: number;
  paidOrders: number;
  canceledOrders: number;
  lashistaId: string | undefined;
  lashista: string;
}> => {
  const response = data.map(({ items }) => {
    const [item] = items;

    const getLashita = dataLashista.find(({ id }) => id === item?.lashistaId);

    const futureOrders = Array.from(
      new Set(
        data
          .flatMap(({ items }) => items)
          .filter(
            (item) =>
              item.finalizeAt === undefined && item.status === 'IN_PROCESS' && item.lashistaId === getLashita?.id
          )
      )
    ).length;

    const paidOrder = Array.from(
      new Set(
        data
          .flatMap(({ items }) => items)
          .filter((item) => item.finalizeAt && item.status === 'PAID' && item.lashistaId === getLashita?.id)
      )
    ).length;

    const canceledOrder = Array.from(
      new Set(
        data
          .flatMap(({ items }) => items)
          .filter((item) => item.finalizeAt && item.status === 'CANCELED' && item.lashistaId === getLashita?.id)
      )
    ).length;

    return {
      sucursal: item?.tenantId,
      futureOrders: futureOrders,
      paidOrders: paidOrder,
      canceledOrders: canceledOrder,
      lashistaId: item?.lashistaId,
      lashista: `${getLashita?.firstName} ${getLashita?.lastName}`,
    };
  });

  return response;
};
