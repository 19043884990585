import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as calendar from '../../../sdk/calendar';
import { Actions } from '../../actions';
import { GetOrdenItem, GetOrdenItemError, GetOrdenItemSuccess } from '../action-creators';
import { GET_ORDEN_ITEM } from '../actions';

export const getOrdenItem = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, GetOrdenItem['type'], GetOrdenItem>(GET_ORDEN_ITEM),
    mergeMap(async (action) => {
      try {
        const rawResult = await calendar.getOrderItem(action.orderItemId);
        // is require to change the type of the data from getOrderItem because the type missing some values
        const result: any = rawResult;
        return new GetOrdenItemSuccess(result);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new GetOrdenItemError(err.message, err.description);
      }
    })
  );
