import { dbStore } from '@webapp/sdk/models/db';
import { Categories } from '@webapp/store/categories/state';
import { Service } from '@webapp/store/products/state';
import { getSubdomain } from './subDomain';

const keyLocal = (key: string) => {
  return `cache_${getSubdomain()}_${key}_mlashes`;
};

export const getLocalProduct = (): Service[] => {
  const a = JSON.parse(localStorage.getItem(keyLocal('servicios')) as string);

  return a.data.items;
};

export const getLocalCategories = (): Categories[] => {
  const a = JSON.parse(localStorage.getItem(keyLocal('category')) as string);
  return a.data.items;
};

export const getLocalClient = async (id: string) => {
  const clientData = await dbStore.client.get(id);

  return clientData;
};
