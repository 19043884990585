import { FunctionComponent, ReactNode } from 'react';

interface AuthContainerProps {
  title?: string | JSX.Element;
  children: ReactNode;
  className?: string;
}

const Container: FunctionComponent<AuthContainerProps> = ({
  children,
  title,
  className,
}: {
  children: ReactNode;
  title?: string | JSX.Element;
  className?: string;
}) => {
  return (
    <main className="flex-1 bg-black dark:bg-black">
      <div className="py-6 px-6">
        <div className="mx-auto">
          <div className={className}>
            {title && typeof title === 'string' ? (
              <h1 className="text-2xl font-semibold text-primary">{title}</h1>
            ) : (
              title
            )}
            <div> {children}</div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Container;
