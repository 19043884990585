import { AlgoliaFind, AlgoliaSearchResponse } from '../entities/algolia';
import { SearchTypes, searchTypes } from '../lib/search-type';
import { algolia, algoliaFrontend } from './index-algolia';

export const algoliaIndexSearch = async <T extends keyof SearchTypes>({
  claims,
  options,
  where: { query, geoCode, page, facetFilters = [] },
}: AlgoliaFind<T>): Promise<AlgoliaSearchResponse<SearchTypes[T]['response']>> => {
  const searchConfig = searchTypes[options.entityName];
  const filters: string[] = [];
  if (searchConfig.excludeMyself) filters.push(`NOT id:${claims?.sub}`);

  const aroundLatLng = geoCode?.lat && geoCode.lng && `${geoCode.lat},${geoCode.lng}`;
  const results = await algolia(options.entityName, options.indexAlgolia).search(query || '', {
    facetFilters: facetFilters.join(' AND '),
    filters: filters.join(' AND '),
    page: parseInt(page || '0'),
    hitsPerPage: 50,
    aroundLatLng,
    aroundRadius: aroundLatLng
      ? (geoCode?.maxDistance || searchConfig.geoCode?.defaultDistance || 0) * 1000
      : undefined,
  });

  const nextToken = page || 0 >= results.nbPages - 1 ? undefined : String(parseInt(page || '0') + 1);
  const total = results.nbHits;
  const entities = results.hits.map((e) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { objectID, _highlightResult, ...att } = e;
    return att;
  }) as SearchTypes[T]['response'][];

  return { nextToken, total, entities };
};

export const algoliaIndexSearchFront = async <T extends keyof SearchTypes>({
  options,
  where: { query, geoCode, page, facetFilters = [] },
}: AlgoliaFind<T>): Promise<AlgoliaSearchResponse<SearchTypes[T]['response']>> => {
  const searchConfig = searchTypes[options.entityName];
  const filters: string[] = [];

  const aroundLatLng = geoCode?.lat && geoCode.lng && `${geoCode.lat},${geoCode.lng}`;
  const results = await algoliaFrontend(options.entityName, options.indexAlgolia).search(query || '', {
    facetFilters: facetFilters.join(' AND '),
    filters: filters.join(' AND '),
    page: parseInt(page || '0'),
    hitsPerPage: 50,
    aroundLatLng,
    aroundRadius: aroundLatLng
      ? (geoCode?.maxDistance || searchConfig.geoCode?.defaultDistance || 0) * 1000
      : undefined,
  });

  const nextToken = page || 0 >= results.nbPages - 1 ? undefined : String(parseInt(page || '0') + 1);
  const total = results.nbHits;
  const entities = results.hits.map((e) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { objectID, _highlightResult, ...att } = e;
    return att;
  }) as SearchTypes[T]['response'][];

  return { nextToken, total, entities };
};
