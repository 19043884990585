import * as api from '@services/business/invoice/catalog/api';
import { OrdenCreateAppointment, OrdenItem, OrdenItemAppointment } from '@services/business/invoice/entities';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import { Discount } from '../discounts/state';
import * as actions from './actions';
import { Appointment, MethodPayment, Orden, PreAppointment } from './state';

export class ListCalendar implements LoadingAction {
  readonly type = actions.LIST_CALENDAR;
  readonly isLoading = true;

  constructor(public search: string) {}
}

export class ListCalendarSuccess implements LoadingAction {
  readonly type = actions.LIST_CALENDAR_SUCCESS;
  readonly isLoading = false;

  constructor(public items: Appointment[]) {}
}

export class ListCalendarError implements ErrorAction {
  readonly type = actions.LIST_CALENDAR_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListOrden implements LoadingAction {
  readonly type = actions.LIST_ORDEN;
  readonly isLoading = true;

  constructor(public search?: string) {}
}

export class ListOrdenSuccess implements LoadingAction {
  readonly type = actions.LIST_ORDEN_SUCCESS;
  readonly isLoading = false;

  constructor(public items: Orden[]) {}
}

export class ListOrdenError implements ErrorAction {
  readonly type = actions.LIST_ORDEN_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetOrden implements LoadingAction {
  readonly type = actions.GET_ORDEN;
  readonly isLoading = true;

  constructor(public id: string) {}
}

export class GetOrdenError implements ErrorAction {
  readonly type = actions.GET_ORDEN_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetOrdenSuccess implements LoadingAction {
  readonly type = actions.GET_ORDEN_SUCCESS;
  readonly isLoading = false;

  constructor(public item: { orden: Orden; item: api.getOrden.Interface['response']['item'] }) {}
}

export class CreatePreAppointment implements LoadingAction {
  readonly type = actions.CREATE_PRE_APPOINTMENT;
  readonly isLoading = false;

  constructor(public item: PreAppointment | undefined) {}
}

export class CreateAppointment implements LoadingAction {
  readonly type = actions.CREATE_APPOINTMENT;
  readonly isLoading = false;

  constructor(public item: OrdenCreateAppointment) {}
}

export class CreateAppointmentSuccess implements LoadingAction {
  readonly type = actions.CREATE_APPOINTMENT_SUCCESS;
  readonly isLoading = false;
}

export class CreateAppointmentError implements ErrorAction {
  readonly type = actions.CREATE_APPOINTMENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateAppointment implements LoadingAction {
  readonly type = actions.UPDATE_APPOINTMENT;
  readonly isLoading = true;
  constructor(public appointment: OrdenItem & OrdenItemAppointment, public ordenItemId: string) {}
}

export class UpdatePreAppointmentItem implements LoadingAction {
  readonly type = actions.UPDATE_PRE_APPOINTMENT_ITEM;
  readonly isLoading = true;
  constructor(public appointment: Partial<OrdenItem>, public ordenItemId: string) {}
}

export class UpdateDiscountPreAppointment implements LoadingAction {
  readonly type = actions.UPDATE_DISCOUNT_PRE_APPOINTMENT;
  readonly isLoading = true;
  constructor(public discount: Partial<Discount> | undefined) {}
}

export class UpdatePreAppointment implements LoadingAction {
  readonly type = actions.UPDATE_PRE_APPOINTMENT;
  readonly isLoading = true;
  constructor(public item: PreAppointment | undefined) {}
}

export class CreatePreAppointmentItem implements LoadingAction {
  readonly type = actions.CREATE_PRE_APPOINTMENT_ITEM;
  readonly isLoading = true;
  constructor(
    public appointment: Partial<OrdenItem> & {
      id?: string;
      serviceId: string;
      lashistaId?: string;
      dateAppointment?: string;
      dateInitial?: number;
      note?: string;
    }
  ) {}
}

export class ListPreAppointmentItems implements LoadingAction {
  readonly type = actions.LIST_PRE_APPOINTMENT_ITEM;
  readonly isLoading = true;
  constructor(public appointment: Array<Partial<Appointment>>) {}
}

export class UpdateAppointmentSuccess implements LoadingAction {
  readonly type = actions.UPDATE_APPOINTMENT_SUCCESS;
  readonly isLoading = false;
}

export class UpdateAppointmentError implements ErrorAction {
  readonly type = actions.UPDATE_APPOINTMENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetOrdenItem implements LoadingAction {
  readonly type = actions.GET_ORDEN_ITEM;
  readonly isLoading = true;

  constructor(public orderItemId: string) {}
}

export class GetOrdenItemError implements ErrorAction {
  readonly type = actions.GET_ORDEN_ITEM_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetOrdenItemSuccess implements LoadingAction {
  readonly type = actions.GET_ORDEN_ITEM_SUCCESS;
  readonly isLoading = false;

  constructor(public item: Appointment | undefined) {}
}

export class FinishAppointment implements LoadingAction {
  readonly type = actions.FINISH_APPOINTMENT;
  readonly isLoading = false;

  constructor(public item: api.finalizeOrden.Interface['body'], public ordenId: string) {}
}

export class FinishAppointmentSuccess implements LoadingAction {
  readonly type = actions.FINISH_APPOINTMENT_SUCCESS;
  readonly isLoading = false;
}

export class FinishAppointmentError implements ErrorAction {
  readonly type = actions.FINISH_APPOINTMENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdatePreAppointmentPayment implements LoadingAction {
  readonly type = actions.UPDATE_PRE_APPOINTMENT_PAYMENT;
  readonly isLoading = false;

  constructor(public method: string, public value: MethodPayment) {}
}

export class UpdatePreAppointmentPaymentRewardPoint implements LoadingAction {
  readonly type = actions.UPDATE_PRE_APPOINTMENT_PAYMENT_REWARD_POINT;
  readonly isLoading = false;

  constructor(public rewardPoint: number) {}
}

export class DeletePreAppointmentPayment implements LoadingAction {
  readonly type = actions.DELETE_PRE_APPOINTMENT_PAYMENT;
  readonly isLoading = false;

  constructor(public productId: string) {}
}

export class ResetPreAppointmentPayment implements LoadingAction {
  readonly type = actions.RESET_PRE_APPOINTMENT_PAYMENT;
  readonly isLoading = false;
}
export class ResetPreAppointmentItems implements LoadingAction {
  readonly type = actions.RESET_PRE_APPOINTMENT_ITEMS;
  readonly isLoading = false;
}

export class AddCalendar implements LoadingAction {
  readonly type = actions.ADD_CALENDAR;
  readonly isLoading = false;

  constructor(public items: Appointment[]) {}
}

export class GetAppointmentByDate implements LoadingAction {
  readonly type = actions.GET_APPOINTMENT_BY_DATE;
  readonly isLoading = true;

  constructor(public date: string) {}
}

export class GetAppointmentByDateError implements ErrorAction {
  readonly type = actions.GET_APPOINTMENT_BY_DATE_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetAppointmentByDateSuccess implements LoadingAction {
  readonly type = actions.GET_APPOINTMENT_BY_DATE_SUCCESS;
  readonly isLoading = false;

  constructor(public item: Appointment[]) {}
}

export class SetCurrentAppointment implements LoadingAction {
  readonly type = actions.SET_CURRENT_APPOINTMENT;
  readonly isLoading = false;

  constructor(public item: Appointment) {}
}

export type CalendarActions =
  | ListCalendarSuccess
  | ListCalendarError
  | ListCalendar
  | CreateAppointment
  | CreateAppointmentSuccess
  | CreateAppointmentError
  | UpdateAppointment
  | UpdateAppointmentSuccess
  | UpdateAppointmentError
  | CreatePreAppointment
  | ListOrden
  | ListOrdenSuccess
  | ListOrdenError
  | GetOrdenSuccess
  | GetOrdenError
  | GetOrden
  | GetOrdenItemSuccess
  | GetOrdenItemError
  | GetOrdenItem
  | FinishAppointmentSuccess
  | FinishAppointmentError
  | FinishAppointment
  | UpdatePreAppointmentItem
  | UpdateDiscountPreAppointment
  | UpdatePreAppointment
  | CreatePreAppointmentItem
  | UpdatePreAppointmentPayment
  | DeletePreAppointmentPayment
  | UpdatePreAppointmentPaymentRewardPoint
  | ResetPreAppointmentPayment
  | ResetPreAppointmentItems
  | AddCalendar
  | GetAppointmentByDate
  | GetAppointmentByDateSuccess
  | GetAppointmentByDateError
  | SetCurrentAppointment;
