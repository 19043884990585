const PlusGreenRoundIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#0ABAB5" />
      <path
        d="M14.2857 10.5006C14.2857 10.8956 14.2514 11.2148 13.8564 11.2148H10.7143V14.357C10.7143 14.7513 10.395 14.7863 10 14.7863C9.60501 14.7863 9.28572 14.7513 9.28572 14.357V11.2148H6.14358C5.74929 11.2148 5.71429 10.8956 5.71429 10.5006C5.71429 10.1056 5.74929 9.78627 6.14358 9.78627H9.28572V6.64413C9.28572 6.24913 9.60501 6.21484 10 6.21484C10.395 6.21484 10.7143 6.24913 10.7143 6.64413V9.78627H13.8564C14.2514 9.78627 14.2857 10.1056 14.2857 10.5006Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusGreenRoundIcon;
