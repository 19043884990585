import { FunctionComponent } from 'react';

import AuthContainer from '@webapp/components/auth/auth-container';
import ForgotPassword from '@webapp/components/auth/forgot-password';

interface ForgotPasswordPageProps {
  className?: string;
}

const ForgotPasswordPage: FunctionComponent<ForgotPasswordPageProps> = ({ className }) => {
  return (
    <AuthContainer className={className || ''}>
      <ForgotPassword className="content flex justify-center" />
    </AuthContainer>
  );
};

export default ForgotPasswordPage;
