import * as api from '@services/business/inventory/catalog/api';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';
import { Category, Inventory, Service } from './state';

export class ListProduct {
  readonly type = actions.LIST_PRODUCT;

  constructor(public items: Service[]) {}
}

export class ListCategory {
  readonly type = actions.LIST_CATEGORY;

  constructor(public items: Category[]) {}
}

export class ListInventory implements LoadingAction {
  readonly type = actions.LIST_INVENTORY;
  readonly isLoading = true;
}

export class ListInventorySuccess implements LoadingAction {
  readonly type = actions.LIST_INVENTORY_SUCCESS;
  readonly isLoading = false;

  constructor(public items: Inventory[]) {}
}

export class ListInventoryError implements ErrorAction {
  readonly type = actions.LIST_INVENTORY_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class InsertInventory implements LoadingAction {
  readonly type = actions.INSERT_INVENTORY;
  readonly isLoading = true;

  constructor(public item: api.createManagement.Interface['body'], public productId: string) {}
}

export class InsertInventorySuccess implements LoadingAction {
  readonly type = actions.INSERT_INVENTORY_SUCCESS;
  readonly isLoading = false;
  constructor(public item: api.createManagement.Interface['body'], public productId: string) {}
}

export class InsertInventoryError implements ErrorAction {
  readonly type = actions.INSERT_INVENTORY_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateInventory implements LoadingAction {
  readonly type = actions.UPDATE_INVENTORY;
  readonly isLoading = true;

  constructor(public item: api.updateInventory.Interface['body'], public productId: string) {}
}

export class UpdateInventorySuccess implements LoadingAction {
  readonly type = actions.UPDATE_INVENTORY_SUCCESS;
  readonly isLoading = false;
  constructor(public item: api.updateInventory.Interface['body'], public productId: string) {}
}

export class UpdateInventoryError implements ErrorAction {
  readonly type = actions.UPDATE_INVENTORY_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type ProductActions =
  | ListProduct
  | ListCategory
  | ListInventory
  | ListInventorySuccess
  | ListInventoryError
  | InsertInventory
  | InsertInventorySuccess
  | InsertInventoryError
  | UpdateInventory
  | UpdateInventorySuccess
  | UpdateInventoryError;
