export const LIST_LASHISTA = '[Lashista] List Lashista';
export const LIST_LASHISTA_SUCCESS = '[Lashista] List Lashista Success';
export const LIST_LASHISTA_ERROR = '[Lashista] List Lashista Error';

export const CREATE_LASHISTA = '[Lashista] Create Lashista';
export const CREATE_LASHISTA_SUCCESS = '[Lashista] Create Lashista Success';
export const CREATE_LASHISTA_ERROR = '[Lashista] Create Lashista Error';

export const UPDATE_LASHISTA = '[Lashista] Update Lashista';
export const UPDATE_LASHISTA_SUCCESS = '[Lashista] Update Lashista Success';
export const UPDATE_LASHISTA_ERROR = '[Lashista] Update Lashista Error';

export const DELETE_LASHISTA = '[Lashista] Delete Lashista';
export const DELETE_LASHISTA_SUCCESS = '[Lashista] Delete Lashista Success';
export const DELETE_LASHISTA_ERROR = '[Lashista] Delete Lashista Error';

export const SET_CURRENT_LASHISTA = '[Lashista] Current Lashista';
