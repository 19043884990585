const UserGreenIcon = () => {
  return (
    <svg width="45" height="35" viewBox="-5 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
      <path
        d="M5.8 23.9991V23.992L5.63999 23.872C4.0271 22.6624 2.71515 21.0969 1.80629 19.2974C0.898046 17.4991 0.416826 15.5155 0.400021 13.5009C0.445234 10.0405 1.83999 6.7344 4.28719 4.28719C6.73417 1.84021 10.0399 0.445488 13.5 0.400034C16.9601 0.445488 20.2658 1.84021 22.7128 4.28719C25.16 6.7344 26.5548 10.0405 26.6 13.501C26.5832 15.5155 26.102 17.4991 25.1937 19.2974C24.2848 21.0969 22.9729 22.6624 21.36 23.872L21.2 23.992V23.9991C18.988 25.6866 16.2827 26.6009 13.5 26.6009C10.7173 26.6009 8.01198 25.6866 5.8 23.9991ZM7.80371 22.7702L7.74188 23.0533L7.99166 23.2003C9.66117 24.1825 11.563 24.7005 13.5 24.7005C15.437 24.7005 17.3388 24.1825 19.0083 23.2003L19.2581 23.0533L19.1963 22.7702C18.9797 21.7785 18.4307 20.8906 17.6402 20.2539C16.8496 19.6172 15.8652 19.2698 14.8501 19.2695H14.85H12.15H12.1499C11.1348 19.2698 10.1504 19.6172 9.35985 20.2539C8.56933 20.8906 8.02025 21.7785 7.80371 22.7702ZM20.7045 21.2684L20.9326 21.8136L21.354 21.3992C22.4031 20.3674 23.2385 19.1389 23.8126 17.7841C24.3868 16.4293 24.6883 14.9746 24.7 13.5032L24.7001 13.5032L24.7 13.4951C24.6637 10.5373 23.4725 7.71079 21.3809 5.61912C19.2892 3.52746 16.4627 2.33633 13.5049 2.30003L13.5049 2.29991L13.4951 2.30003C10.5373 2.33633 7.71079 3.52746 5.61912 5.61912C3.52746 7.71079 2.33633 10.5373 2.30003 13.4951L2.29995 13.4951L2.30001 13.5032C2.31171 14.9746 2.61323 16.4293 3.18735 17.7841C3.76147 19.1389 4.59694 20.3674 5.64601 21.3992L6.06737 21.8136L6.2955 21.2684C6.77857 20.1139 7.59202 19.1279 8.63367 18.4342C9.67532 17.7405 10.8987 17.3701 12.1502 17.3695H14.8498C16.1013 17.3701 17.3247 17.7405 18.3663 18.4342C19.408 19.1279 20.2214 20.1139 20.7045 21.2684Z"
        fill="#56C4C7"
        stroke="#FAFAFA"
        strokeWidth="0.8"
      />
      <path
        d="M13.4907 5.79988L13.5001 5.8001L13.5094 5.79988C14.169 5.78452 14.8248 5.90311 15.4373 6.14848C16.0498 6.39385 16.6061 6.7609 17.0726 7.22744C17.5392 7.69398 17.9062 8.2503 18.1516 8.86275C18.3969 9.47521 18.5155 10.1311 18.5002 10.7907L18.5 10.8L18.5002 10.8093C18.5155 11.4689 18.3969 12.1248 18.1516 12.7372C17.9062 13.3497 17.5392 13.906 17.0726 14.3725C16.6061 14.8391 16.0498 15.2061 15.4373 15.4515C14.8248 15.6969 14.169 15.8155 13.5094 15.8001L13.5001 15.7999L13.4907 15.8001C12.8311 15.8155 12.1753 15.6969 11.5628 15.4515C10.9504 15.2061 10.394 14.8391 9.92751 14.3725C9.46098 13.906 9.09392 13.3497 8.84855 12.7372C8.60318 12.1248 8.48459 11.4689 8.49996 10.8093L8.50017 10.8L8.49996 10.7907C8.48459 10.1311 8.60318 9.47521 8.84855 8.86275C9.09392 8.2503 9.46098 7.69398 9.92751 7.22744C10.394 6.7609 10.9504 6.39385 11.5628 6.14848C12.1753 5.90311 12.8311 5.78452 13.4907 5.79988ZM12.3025 13.703C12.684 13.8503 13.0916 13.9175 13.5001 13.9004C13.9085 13.9175 14.3161 13.8503 14.6976 13.703C15.0851 13.5534 15.437 13.3243 15.7307 13.0306C16.0244 12.7369 16.2534 12.385 16.4031 11.9975C16.5504 11.6161 16.6176 11.2084 16.6005 10.8C16.6176 10.3916 16.5504 9.98392 16.4031 9.60245C16.2534 9.21497 16.0244 8.86308 15.7307 8.56937C15.437 8.27565 15.0851 8.04662 14.6976 7.89698C14.3161 7.74966 13.9085 7.6825 13.5001 7.69959C13.0916 7.6825 12.684 7.74966 12.3025 7.89698C11.915 8.04662 11.5631 8.27565 11.2694 8.56937C10.9757 8.86308 10.7467 9.21497 10.5971 9.60245C10.4497 9.98392 10.3826 10.3916 10.3997 10.8C10.3826 11.2084 10.4497 11.6161 10.5971 11.9975C10.7467 12.385 10.9757 12.7369 11.2694 13.0306C11.5631 13.3243 11.915 13.5534 12.3025 13.703Z"
        fill="#56C4C7"
        stroke="#FAFAFA"
        strokeWidth="0.8"
      />
    </svg>
  );
};

export default UserGreenIcon;
