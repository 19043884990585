import * as api from '@services/business/client/catalog/api';
import * as apiHistory from '@services/business/history/catalog/api';
import { Action } from 'redux';
import { Appointment } from '../appointments/state';
import { ErrorAction } from '../common/error-action';
import { LoadingAction } from '../common/loading-action';
import * as actions from './actions';
import { Client, FinanceHistory, FinanceHistoryCreate } from './state';

export class ListClient implements LoadingAction {
  readonly type = actions.LIST_CLIENT;
  readonly isLoading = true;
  constructor(public search?: string) {}
}

export class ListClientSuccess implements LoadingAction {
  readonly type = actions.LIST_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public items: Client[]) {}
}

export class ListClientError implements ErrorAction {
  readonly type = actions.LIST_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetClient implements LoadingAction {
  readonly type = actions.GET_CLIENT;
  readonly isLoading = true;
  constructor(public id: string) {}
}

export class GetClientSuccess implements LoadingAction {
  readonly type = actions.GET_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public items: Client) {}
}

export class GetClientError implements ErrorAction {
  readonly type = actions.GET_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class SetCurrentClient implements Action {
  readonly type = actions.SET_CURRENT_CLIENT;

  constructor(public item: Client | undefined) {}
}

export class CreateClient implements LoadingAction {
  readonly type = actions.CREATE_CLIENT;
  readonly isLoading = true;
  constructor(public client: api.createdClient.Interface['body'], public updateCurrentId?: boolean) {}
}

export class CreateClientSuccess implements LoadingAction {
  readonly type = actions.CREATE_CLIENT_SUCCESS;
  readonly isLoading = false;
  constructor(public client: Client) {}
}

export class CreateClientError implements ErrorAction {
  readonly type = actions.CREATE_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class UpdateClient implements LoadingAction {
  readonly type = actions.UPDATE_CLIENT;
  readonly isLoading = true;
  constructor(public client: api.updatedClient.Interface['body'], public clientId: string) {}
}

export class UpdateClientSuccess implements LoadingAction {
  readonly type = actions.UPDATE_CLIENT_SUCCESS;
  readonly isLoading = false;
}

export class UpdateClientError implements ErrorAction {
  readonly type = actions.UPDATE_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class DeleteClient implements LoadingAction {
  readonly type = actions.DELETE_CLIENT;
  readonly isLoading = true;
  constructor(public clientId: string) {}
}

export class DeleteClientSuccess implements LoadingAction {
  readonly type = actions.DELETE_CLIENT_SUCCESS;
  readonly isLoading = false;
}

export class DeleteClientError implements ErrorAction {
  readonly type = actions.DELETE_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class CreateNoteCredit implements LoadingAction {
  readonly type = actions.CREATE_NOTE_CREDIT;
  readonly isLoading = true;
  constructor(public noteCredit: FinanceHistoryCreate, public clientId: string) {}
}

export class CreateNoteCreditSuccess implements LoadingAction {
  readonly type = actions.CREATE_NOTE_CREDIT_SUCCESS;
  readonly isLoading = false;
  constructor(public noteCredit: FinanceHistoryCreate, public clientId: string) {}
}

export class CreateNoteCreditError implements ErrorAction {
  readonly type = actions.CREATE_NOTE_CREDIT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class ListHistoryNoteCredit implements LoadingAction {
  readonly type = actions.LIST_HISTORY_NOTE_CREDIT;
  readonly isLoading = true;
  constructor(public clientId: string) {}
}

export class ListHistoryNoteCreditSuccess implements LoadingAction {
  readonly type = actions.LIST_HISTORY_NOTE_CREDIT_SUCCESS;
  readonly isLoading = false;

  constructor(public items: FinanceHistory[]) {}
}

export class ListHistoryNoteCreditError implements ErrorAction {
  readonly type = actions.LIST_HISTORY_NOTE_CREDIT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class SetBalanceClient implements Action {
  readonly type = actions.SET_BALANCE_CLIENT;
}

export class GetBalanceClient implements LoadingAction {
  readonly type = actions.GET_BALANCE_CLIENT;
  readonly isLoading = true;
  constructor(public clientId: string) {}
}

export class GetBalanceClientSuccess implements LoadingAction {
  readonly type = actions.GET_BALANCE_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public item: number) {}
}

export class GetBalanceClientError implements ErrorAction {
  readonly type = actions.GET_BALANCE_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class SetRewardClient implements Action {
  readonly type = actions.SET_REWARD_CLIENT;
}

export class GetRewardClient implements LoadingAction {
  readonly type = actions.GET_REWARD_CLIENT;
  readonly isLoading = true;
  constructor(public clientId: string) {}
}

export class GetRewardClientSuccess implements LoadingAction {
  readonly type = actions.GET_REWARD_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public item: number) {}
}

export class GetRewardClientError implements ErrorAction {
  readonly type = actions.GET_REWARD_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export class GetHistoryAppointmentClient implements LoadingAction {
  readonly type = actions.GET_HISTORY_APPOINTMENT_CLIENT;
  readonly isLoading = true;
  constructor(public body: apiHistory.historyClient.Interface['body']) {}
}

export class GetHistoryAppointmentClientSuccess implements LoadingAction {
  readonly type = actions.GET_HISTORY_APPOINTMENT_CLIENT_SUCCESS;
  readonly isLoading = false;

  constructor(public item: Appointment[] | undefined) {}
}

export class GetHistoryAppointmentClientError implements ErrorAction {
  readonly type = actions.GET_HISTORY_APPOINTMENT_CLIENT_ERROR;
  readonly isError = true;

  constructor(public code: string, public description: string | string[]) {}
}

export type ClientActions =
  | ListClient
  | ListClientSuccess
  | ListClientError
  | GetClient
  | GetClientSuccess
  | GetClientError
  | SetCurrentClient
  | CreateClient
  | CreateClientSuccess
  | UpdateClientError
  | UpdateClient
  | UpdateClientSuccess
  | UpdateClientError
  | DeleteClient
  | DeleteClientSuccess
  | DeleteClientError
  | CreateNoteCredit
  | CreateNoteCreditSuccess
  | CreateNoteCreditError
  | ListHistoryNoteCredit
  | ListHistoryNoteCreditSuccess
  | ListHistoryNoteCreditError
  | SetBalanceClient
  | GetBalanceClient
  | GetBalanceClientSuccess
  | GetBalanceClientError
  | SetRewardClient
  | GetRewardClient
  | GetRewardClientSuccess
  | GetRewardClientError
  | GetHistoryAppointmentClient
  | GetHistoryAppointmentClientSuccess
  | GetHistoryAppointmentClientError;
