import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from 'schemy-ts';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Discount, DiscountCreate } from '../../entities/discounts';
export type Interface = ApiInterface<{
  response: Discount;
  pathParams: EmptyObject;
  body: Omit<DiscountCreate, 'status'>;
  claims: { tenantId: string; 'cognito:username': string };
  queryParams: { nameId?: string };
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-discounts',
  method: 'POST',
  basePath: 'discounts',
  path: '/',
  tablePermission: 'readWrite',
  scope: 'SUPER_ADMIN',
  schema: Schemy.schema<Interface['body']>({
    name: { type: String, required: true },
    amount: { type: Number, required: false, min: 0 },
    percentage: { type: Number, required: false, min: 0 },
    products: { type: [String] },
    type: {
      type: String,
      required: true,
      enum: ['PERCENTAGE', 'AMOUNT', 'PERCENTAGE_AMOUNT', 'AMOUNT_PERCENTAGE'],
      min: 1,
    },
    dateInitial: { type: Number, required: false },
    dateEnd: { type: Number, required: false },
  }),
};
