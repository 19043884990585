import { generateId as generateIds } from '@stetamalo/cloud-platform/lib/generate-id';

export enum TypeIdShort {
  order = 'or',
  orderItem = 'oi',
  client = 'cus',
  reward = 'rew',
  rewardPayment = 'rewp',
  inventoryManagement = 'im',
  lashista = 'la',
  productCategory = 'cate',
  productService = 'serv',
  financeHistory = 'finhistoy',
  group = 'gro',
  discount = 'dis',
  invoice = 'inv',
  noteClient = 'notecus',
  registerCash = 'regcash',
}

export const generateId = <K extends keyof typeof TypeIdShort>(
  id: K,
  customId?: string
): `${typeof TypeIdShort[K]}_${string}` => {
  const getId = generateIds(Object.values(TypeIdShort))(TypeIdShort[id]);
  if (customId) {
    return `${getId.split('_')[0]}_${customId}` as `${typeof TypeIdShort[K]}_${string}`;
  }

  return getId;
};
