import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as calendar from '../../../sdk/calendar';
import { Actions } from '../../actions';
import { GetOrden, GetOrdenError, GetOrdenSuccess } from '../action-creators';
import { GET_ORDEN } from '../actions';

export const getOrden = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, GetOrden['type'], GetOrden>(GET_ORDEN),
    mergeMap(async (action) => {
      try {
        const result = await calendar.getOrder(action.id);
        return new GetOrdenSuccess(result);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new GetOrdenError(err.message, err.description);
      }
    })
  );
