//import { useState } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useAppState } from '../../hooks/useAppState';
import { useWindowResize } from '../../hooks/useWindowResize';
import { DefaultRecourse } from '../../types';

interface ResourceHeaderProps {
  resource: DefaultRecourse;
  pos: number;
}
const ResourceHeader = ({ resource, pos }: ResourceHeaderProps) => {
  const { defaultIcon, recourseHeaderComponent, resourceFields, direction, resourceViewMode } = useAppState();
  const { width } = useWindowResize();
  //const [openDate, setOpenDate] = useState(false);

  const DefaultIcon = () => {
    if (defaultIcon) {
      return defaultIcon;
    } else {
      return <></>;
    }
  };

  const text = resource[resourceFields.textField];
  //const subtext = resource[resourceFields.subTextField || ``];
  const avatar = resource[resourceFields.avatarField || ''];
  const color = resource[resourceFields.colorField || ''];

  if (recourseHeaderComponent instanceof Function) {
    return recourseHeaderComponent(resource);
  }

  const headerBorders =
    resourceViewMode === 'tabs'
      ? {}
      : {
          borderColor: 'var(--light-dark-gray)',
          borderStyle: 'solid',
          borderWidth: '1px 1px 0 1px',
        };
  return (
    <div className="bg-veryDarkGray">
      <ListItem
        id={`col${pos}`}
        sx={{
          padding: `10px 10px 10px ${pos == 1 ? '60px' : '10px'}`,
          textAlign: direction === 'rtl' ? 'center' : 'center',
          ...headerBorders,
        }}
        component="span"
      >
        <ListItemAvatar>
          {avatar.length > 0 ? <Avatar style={{ background: color }} alt={text} src={avatar} /> : <DefaultIcon />}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography sx={{ color: 'var(--txtWhite)' }} variant="body2" noWrap>
              {text}
            </Typography>
          }
          /*secondary={
            <Typography variant="caption" color="textSecondary" noWrap>
              {subtext}
            </Typography>
          }*/
          style={{ width: width / 12 }}
          className="-ml-4"
        />
      </ListItem>
    </div>
  );
};

export { ResourceHeader };
