import { combineEpics } from 'redux-observable';
import { Actions } from '../../actions';
import { listClientEpic } from './list-client';

import AppState from '../../state';
import { crudClientEpic } from './client';

const clientEpic = combineEpics<Actions, Actions, AppState>(listClientEpic, crudClientEpic);

export default clientEpic;
