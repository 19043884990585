import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import * as categories from '../../../sdk/categories';
import { Actions } from '../../actions';
import {
  ListCategories,
  ListCategoriesSuccess,
  ListCategoriesError,
  CreateCategoriesSuccess,
  UpdateCategoriesSuccess,
} from '../action-creators';
import { CREATE_CATEGORIES_SUCCESS, LIST_CATEGORIES, UPDATE_CATEGORIES_SUCCESS } from '../actions';

export const listCategoriesEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      ListCategories['type'] | CreateCategoriesSuccess['type'] | UpdateCategoriesSuccess['type'],
      ListCategories | CreateCategoriesSuccess | UpdateCategoriesSuccess
    >(LIST_CATEGORIES, CREATE_CATEGORIES_SUCCESS, UPDATE_CATEGORIES_SUCCESS),
    mergeMap(async (action) => {
      try {
        if (action.type === '[Categories] List Categories' && action.search?.length) {
          const result = await categories.listCategories(action.search);
          return new ListCategoriesSuccess(result.items);
        }
        const result = await categories.listCategories();
        return new ListCategoriesSuccess(result.items);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListCategoriesError(err.message, err.description);
      }
    })
  );
