import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useMemo, useRef } from 'react';

import { UpdatePreAppointmentPayment } from '@webapp/store/appointments/action-creators';
import AppState from '@webapp/store/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import {
  limitNumberWithinRangeFromZeroToMax,
  rounded,
  validatePositiveNumberValue,
} from '@webapp/utils/validateNumber';

interface IModalPayment {
  title?: string;
  onCancel?: any;
  onOk?: any;
  importToPay: number;
}

const ModalPaymentCash: FunctionComponent<IModalPayment> = (props) => {
  const cancelButton = useRef(null);
  const {
    ui: { modalOpened },
    appointments: { updateAppointmentPayment },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const { title, onCancel, onOk, importToPay } = props;

  const handleFocus = (event: any) => event.target.select();

  const { open } = useMemo(() => {
    return {
      open: modalOpened?.['PAYMENT_CASH_MODAL']?.open ?? false,
    };
  }, [modalOpened?.['PAYMENT_CASH_MODAL']]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('PAYMENT_CASH_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <div className="bg-primary py-3 pl-9 flex justify-left">
                <Dialog.Title
                  as="span"
                  className="flex justify-between items-center w-full text-white text-xl md:text-2xl font-bold tracking-widest leading-tight"
                >
                  {title ?? 'Información'}
                </Dialog.Title>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-y-2 py-3 md:py-5 px-4 md:px-8 mb-5 text-left">
                <div className="col-span-2 text-lg font-normal">Importe Pendiente:</div>
                <span className="font-medium text-[22px] leading-[28px] tracking-[0.185em]">
                  {rounded(importToPay)}
                </span>
                <div className="col-span-2 text-lg font-normal ">Importe abonado:</div>
                <div className="flex border rounded-[3px] border-grey">
                  <input
                    className="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border-none focus:border-none w-full"
                    type="number"
                    value={updateAppointmentPayment?.methods.find((tmp) => tmp.type === 'CASH')?.amount}
                    onChange={(e) =>
                      dispatch(
                        new UpdatePreAppointmentPayment('CASH', {
                          id: 'CASH',
                          type: 'CASH',
                          amount: limitNumberWithinRangeFromZeroToMax(
                            validatePositiveNumberValue(e.target.value),
                            rounded(importToPay)
                          ),
                        })
                      )
                    }
                    onFocus={(event) => handleFocus(event)}
                    min={0}
                    max={importToPay}
                    disabled={importToPay === 0 ? true : false}
                  />
                  <button
                    type="button"
                    className="py-1 px-3"
                    onClick={() => {
                      dispatch(
                        new UpdatePreAppointmentPayment('CASH', {
                          id: 'CASH',
                          type: 'CASH',
                          amount: 0,
                        })
                      );
                      onOk ? onOk() : null;
                    }}
                  >
                    X
                  </button>
                </div>
              </div>
              <div className="flex justify-center gap-4 items-center mb-6 px-5">
                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    onCancel ? onCancel() : null;
                    dispatch(new CloseModal('PAYMENT_CASH_MODAL'));
                  }}
                >
                  Cancelar
                </button>

                <button
                  type="button"
                  className="flex justify-center items-center p-0 border bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                  onClick={() => {
                    onOk ? onOk() : null;
                    dispatch(new CloseModal('PAYMENT_CASH_MODAL'));
                  }}
                >
                  Hecho
                </button>
              </div>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalPaymentCash;
