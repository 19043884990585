import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as calendar from '../../../sdk/calendar';
import { Actions } from '../../actions';
import {
  CreateAppointment,
  CreateAppointmentError,
  CreateAppointmentSuccess,
  FinishAppointment,
  FinishAppointmentError,
  FinishAppointmentSuccess,
  UpdateAppointment,
  UpdateAppointmentError,
  UpdateAppointmentSuccess,
} from '../action-creators';
import { CREATE_APPOINTMENT, FINISH_APPOINTMENT, UPDATE_APPOINTMENT } from '../actions';

export const crudAppointmentEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      CreateAppointment['type'] | UpdateAppointment['type'] | FinishAppointment['type'],
      CreateAppointment | UpdateAppointment | FinishAppointment
    >(CREATE_APPOINTMENT, UPDATE_APPOINTMENT, FINISH_APPOINTMENT),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case UPDATE_APPOINTMENT: {
            await calendar.updateCalendar(action.appointment, action.ordenItemId);

            return new UpdateAppointmentSuccess();
          }
          case CREATE_APPOINTMENT: {
            await calendar.createCalendar(action.item);

            return new CreateAppointmentSuccess();
          }
          case FINISH_APPOINTMENT: {
            await calendar.finishCalendar(action.item, action.ordenId);

            return new FinishAppointmentSuccess();
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case UPDATE_APPOINTMENT: {
            return new UpdateAppointmentError(err.message, err.description);
          }
          case CREATE_APPOINTMENT: {
            return new CreateAppointmentError(err.message, err.description);
          }
          case FINISH_APPOINTMENT: {
            return new FinishAppointmentError(err.message, err.description);
          }
        }
      }
    })
  );
