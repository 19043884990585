import * as api from '@services/business/history/catalog/api';
import { CallApi, handleJSONResponse } from './common';

const callAPI = CallApi('history/client/ingestion');

export const listNewClient = async (): Promise<api.historyOldOrNewClient.Interface['response']> => {
  const response = await callAPI('', 'GET', {
    type: 'RECENT',
    tenantId: 'ALL',
  });

  const body: api.historyOldOrNewClient.Interface['response'] = await handleJSONResponse(response);
  return body;
};

export const listOldClient = async (): Promise<api.historyOldOrNewClient.Interface['response']> => {
  const response = await callAPI('', 'GET', {
    type: 'OLD',
    tenantId: 'ALL',
  });

  const body: api.historyOldOrNewClient.Interface['response'] = await handleJSONResponse(response);
  return body;
};
