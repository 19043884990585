import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Client } from '../../entities/client';

export type Interface = ApiInterface<{
  response: Client;
  pathParams: EmptyObject;
  body: Omit<Client, 'tenantId' | 'createdAt' | 'updatedAt' | 'sucursalAt'> & { id?: string };
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-client',
  method: 'POST',
  basePath: 'client',
  path: '/',
  tablePermission: 'readWrite',
  skipValidation: true,
  schema: Schemy.schema<Interface['body']>({
    id: { type: String, required: false },
    firstName: { type: String, required: true },
    lastName: { type: String, required: false },
    phone: { type: String, required: false },
    sex: { type: String, required: false, enum: ['FEMALE', 'MALE'] },
    additionalData: { type: Object, required: false },
    health: { type: Object, required: false },
    status: { type: String, enum: ['ACTIVE', 'INACTIVE'], required: false, default: 'ACTIVE' },
  }),
};
