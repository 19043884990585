import { EmployeeActions } from './action-creators';
import * as actions from './actions';
import { employeeInitialState } from './initial-state';
import { EmployeeState } from './state';

const employeeReducer = (state: EmployeeState = employeeInitialState, action: EmployeeActions): EmployeeState => {
  switch (action.type) {
    case actions.LIST_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        listEmployee: action.items.filter((employee) => employee.Enabled === true),
      };
    }
    case actions.SET_CURRENT_EMPLOYEE: {
      return {
        ...state,
        currentEmployee: action.item,
      };
    }

    default:
      return state;
  }
};

export default employeeReducer;
