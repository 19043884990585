export const LIST_CALENDAR = '[Calendar] List Calendar';
export const LIST_CALENDAR_SUCCESS = '[Calendar] List Calendar Success';
export const ADD_CALENDAR = '[Calendar] List ADD Calendar';
export const LIST_CALENDAR_ERROR = '[Calendar] List Calendar Error';
export const CURRENT_NOTE_MODAL = '[Modal] Note Calendar';
export const CREATE_APPOINTMENT = '[Calendar] Create Appointment';
export const CREATE_PRE_APPOINTMENT = '[Calendar] Create Pre Appointment';
export const CREATE_APPOINTMENT_SUCCESS = '[Calendar] Create Appointment Success';
export const CREATE_APPOINTMENT_ERROR = '[Calendar] Create Appointment Error';
export const UPDATE_APPOINTMENT = '[Calendar] Update Appointment';
export const UPDATE_APPOINTMENT_SUCCESS = '[Calendar] Update Appointment Success';
export const UPDATE_APPOINTMENT_ERROR = '[Calendar] Update Appointment Error';
export const FINISH_APPOINTMENT = '[Calendar] Finish Appointment';
export const FINISH_APPOINTMENT_SUCCESS = '[Calendar] Finish Appointment Success';
export const FINISH_APPOINTMENT_ERROR = '[Calendar] Finish Appointment Error';
export const DELETE_APPOINTMENT = '[Calendar] Delete Appointment';
export const DELETE_APPOINTMENT_SUCCESS = '[Calendar] Delete Appointment Success';
export const DELETE_APPOINTMENT_ERROR = '[Calendar] Delete Appointment Error';
export const LIST_ORDEN = '[Orden] List Orden';
export const LIST_ORDEN_SUCCESS = '[Orden] List Orden Success';
export const LIST_ORDEN_ERROR = '[Orden] List Orden Error';
export const GET_ORDEN_ERROR = '[Orden] Get Orden Error';
export const GET_ORDEN_SUCCESS = '[Orden] Get Orden Success';
export const GET_ORDEN = '[Orden] Get Orden';
export const GET_ORDEN_ITEM_ERROR = '[Orden] Get Orden Item Error';
export const GET_ORDEN_ITEM_SUCCESS = '[Orden] Get Orden Item Success';
export const GET_ORDEN_ITEM = '[Orden] Get Orden Item';
export const UPDATE_DISCOUNT_PRE_APPOINTMENT = '[Orden] UPDATE Discount PRE Appointment';
export const UPDATE_PRE_APPOINTMENT = '[Orden] UPDATE PRE Appointment';
export const UPDATE_PRE_APPOINTMENT_PAYMENT = '[Orden] UPDATE PRE Appointment Payment';
export const UPDATE_PRE_APPOINTMENT_PAYMENT_REWARD_POINT = '[Orden] UPDATE PRE Appointment Payment Reward Point';
export const DELETE_PRE_APPOINTMENT_PAYMENT = '[Orden] DELETE PRE Appointment Payment';
export const RESET_PRE_APPOINTMENT_PAYMENT = '[Orden] RESET PRE Appointment Payment';
export const RESET_PRE_APPOINTMENT_ITEMS = '[Orden] RESET PRE Appointment Items';
export const UPDATE_PRE_APPOINTMENT_ITEM = '[OrderItem] UPDATE PRE Appointment Item';
export const CREATE_PRE_APPOINTMENT_ITEM = '[OrderItem] CREATE PRE Appointment';
export const LIST_PRE_APPOINTMENT_ITEM = '[OrderItem]  LIST PRE Appointment';
export const GET_APPOINTMENT_BY_DATE_ERROR = '[Orden] Get Appointment By Date Error';
export const GET_APPOINTMENT_BY_DATE_SUCCESS = '[Orden] Get Appointment By Date Success';
export const GET_APPOINTMENT_BY_DATE = '[Orden] Get Appointment By Date';

export const SET_CURRENT_APPOINTMENT = '[Orden] Set Current Appointment';
