import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as employee from '../../../sdk/employee';
import { Actions } from '../../actions';
import { CreateEmployeeSuccess, ListEmployee, ListEmployeeError, ListEmployeeSuccess } from '../action-creators';
import { CREATE_EMPLOYEE_SUCCESS, LIST_EMPLOYEE } from '../actions';

export const listEmployeeEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, ListEmployee['type'] | CreateEmployeeSuccess['type'], ListEmployee | CreateEmployeeSuccess>(
      LIST_EMPLOYEE,
      CREATE_EMPLOYEE_SUCCESS
    ),
    mergeMap(async (action) => {
      try {
        if (action.type === LIST_EMPLOYEE && action.search?.length) {
          const result = await employee.listEmployee(action.search);
          return new ListEmployeeSuccess(result);
        }
        const res = await employee.listEmployee();

        return new ListEmployeeSuccess(res);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return new ListEmployeeError(err.message, err.description);
      }
    })
  );
