import { InputHTMLAttributes } from 'react';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  ref?: any;
  label: string;
  onChange?: any;
  onBlur?: any;
  error?: string | boolean;
  classlabel?: string;
  classinput?: string;
  classnamecontainer?: string;
  mode?: string;
  classoption?: string;
  dataoption?: Array<{ id: string | number; value: string | number }>;
}

const Select = (props: SelectProps) => {
  return (
    <div className={`-mb-2 ${props.classnamecontainer ?? ''}`}>
      {props.type != 'checkbox' && (
        <span
          className={`mb-1 flex items-center tracking-wide text-white text-sm md:text-base font-medium
        ${props.classlabel ?? ''}`}
        >
          {props.label}
        </span>
      )}
      <div className="flex items-start">
        <select
          {...props}
          className={`border block shadow-sm hover:ring-1 focus:ring-1 text-sm md:text-base
            ${
              props.error
                ? 'border-red hover:ring-red focus:ring-red'
                : 'border-grey hover:ring-primary focus:ring-primary'
            }
            ${props.classinput ?? ''}`}
          onChange={props.onChange}
          disabled={props.mode == 'view' ? true : false}
        >
          {props.dataoption ? (
            <>
              <option value="0" className={props.classoption ?? ''}>
                Seleccione...
              </option>
              {props.dataoption?.map((option, i) => (
                <option key={i} value={option.id} className={props.classoption ?? ''}>
                  {option.value}
                </option>
              ))}
            </>
          ) : (
            <option value="" className={props.classoption ?? ''}>
              Seleccione...
            </option>
          )}
        </select>
      </div>
      <span className="flex items-center font-medium tracking-wide text-red text-xs mt-1 ml-1">{props.error}</span>
    </div>
  );
};

export default Select;
