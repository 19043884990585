const PlusGreenIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.91667 9.66667H9.66667V9.91667V16.1486C9.66667 16.3106 9.63398 16.4131 9.59251 16.4806C9.55202 16.5464 9.4913 16.5983 9.39967 16.6395C9.19962 16.7294 8.89892 16.75 8.5 16.75C8.10108 16.75 7.80038 16.7294 7.60033 16.6395C7.5087 16.5983 7.44798 16.5464 7.40749 16.4806C7.36602 16.4131 7.33333 16.3106 7.33333 16.1486V9.91667V9.66667H7.08333H0.851416C0.689353 9.66667 0.58687 9.63398 0.519408 9.59251C0.453553 9.55202 0.401664 9.4913 0.360506 9.39967C0.270641 9.19962 0.25 8.89892 0.25 8.5C0.25 8.10108 0.270641 7.80038 0.360506 7.60033C0.401664 7.5087 0.453553 7.44798 0.519408 7.40749C0.58687 7.36602 0.689353 7.33333 0.851416 7.33333H7.08333H7.33333V7.08333V0.851416C7.33333 0.688913 7.36605 0.586253 7.40752 0.518756C7.44799 0.452905 7.50864 0.401095 7.60019 0.360034C7.80023 0.270315 8.10085 0.25 8.5 0.25C8.89915 0.25 9.19977 0.270315 9.39981 0.360034C9.49135 0.401095 9.55201 0.452905 9.59247 0.518756C9.63395 0.586253 9.66667 0.688913 9.66667 0.851416V7.08333V7.33333H9.91667H16.1486C16.3111 7.33333 16.4137 7.36605 16.4812 7.40752C16.5471 7.44799 16.5989 7.50865 16.64 7.60019C16.7297 7.80023 16.75 8.10085 16.75 8.5C16.75 8.89915 16.7297 9.19977 16.64 9.39981C16.5989 9.49135 16.5471 9.55201 16.4812 9.59247C16.4137 9.63395 16.3111 9.66667 16.1486 9.66667H9.91667Z"
        fill="#56C4C7"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default PlusGreenIcon;
