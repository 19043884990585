import * as apiInventory from '@services/business/inventory/catalog/api';
import * as apiCategory from '@services/business/products/catalog/api/category';
import * as apiProduct from '@services/business/products/catalog/api/servicios';
import { parseCommonFields } from './common';
import { CallApi } from './common-v2';

const callAPIservicios = CallApi('servicios');
const callAPIcategory = CallApi('category');
const callAPIinventory = CallApi('inventory');

export const listProduct = async (nextToken?: string): Promise<apiProduct.listServicios.Interface['response']> => {
  try {
    const response = await callAPIservicios<apiProduct.listServicios.Interface>(
      { ...apiProduct.listServicios.config, withCache: true },
      {
        queryParams: { nextToken },
      }
    );
    const body: apiProduct.listServicios.Interface['response'] = await parseCommonFields(response);
    return body;
  } catch (error) {
    return {
      items: [],
      nextToken: '',
    };
  }
};

export const listCategory = async (nextToken?: string): Promise<apiCategory.listCategory.Interface['response']> => {
  try {
    const response = await callAPIcategory<apiCategory.listCategory.Interface>(
      { ...apiCategory.listCategory.config, withCache: true },
      {
        queryParams: { nextToken },
      }
    );
    const body: apiCategory.listCategory.Interface['response'] = await parseCommonFields(response);

    return body;
  } catch (error) {
    return {
      items: [],
      nextToken: '',
    };
  }
};

export const listInventory = async (tenantId?: string): Promise<apiInventory.listInventory.Interface['response']> => {
  try {
    const response = await callAPIinventory<apiInventory.listInventory.Interface>(
      { ...apiInventory.listInventory.config },
      {
        queryParams: { tenantId },
      }
    );
    const body: apiInventory.listInventory.Interface['response'] = await parseCommonFields(response);
    return body;
  } catch (error) {
    return {
      items: [],
      nextToken: '',
    };
  }
};

export const createManagement = async (
  productId: string,
  createManagement: apiInventory.createManagement.Interface['body']
): Promise<apiInventory.createManagement.Interface['response']> => {
  const response = await callAPIinventory<apiInventory.createManagement.Interface>(
    { ...apiInventory.createManagement.config },
    {
      body: createManagement,
      pathParams: { productId },
    }
  );
  const body = await parseCommonFields(response);

  return body;
};

export const updateInventory = async (
  inventoryId: string,
  updateInventory: apiInventory.updateInventory.Interface['body']
): Promise<apiInventory.updateInventory.Interface['response']> => {
  const response = await callAPIinventory<apiInventory.updateInventory.Interface>(
    { ...apiInventory.updateInventory.config },
    {
      body: updateInventory,
      pathParams: { inventoryId },
    }
  );
  const body = await parseCommonFields(response);

  return body;
};
