import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { ApiConfig } from 'services/support/utils/types/api-config';
export type Interface = ApiInterface<{
  response: { success: true };
  pathParams: { identityId: string };
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-delete-employee',
  method: 'DELETE',
  basePath: 'identity',
  path: '/employee/{identityId}',
  scope: 'ADMIN',
  tablePermission: 'readWrite',
};
