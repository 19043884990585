export const LIST_PRODUCT = '[Product] List Product';
export const LIST_CATEGORY = '[Category] List Category';
export const LIST_INVENTORY = '[Inventory] List Inventory';
export const LIST_INVENTORY_SUCCESS = '[Inventory] List Inventory Success';
export const LIST_INVENTORY_ERROR = '[Inventory] List Inventory Error';
export const INSERT_INVENTORY = '[Inventory] Insert Inventory';
export const INSERT_INVENTORY_SUCCESS = '[Inventory] Insert Inventory Success';
export const INSERT_INVENTORY_ERROR = '[Inventory] Insert Inventory Error';
export const UPDATE_INVENTORY = '[Inventory] Update Inventory';
export const UPDATE_INVENTORY_SUCCESS = '[Inventory] Update Inventory Success';
export const UPDATE_INVENTORY_ERROR = '[Inventory] Update Inventory Error';
