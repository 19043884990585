import { api } from '@services/business/note-client/catalog';
import { NoteClient } from '@services/business/note-client/entities';
import { CallApi as CallApiV2, parseCommonFields } from './common-v2';
import { dbStore } from './models/db';

const callAPI2 = CallApiV2('notes-client');

export const listNotes = async (clientId: string): Promise<api.listNoteClient.Interface['response']> => {
  try {
    const response = await callAPI2<api.listNoteClient.Interface>(
      { ...api.listNoteClient.config, withCache: true },
      {
        queryParams: {
          clientId,
        },
      }
    );
    const body: api.listNoteClient.Interface['response'] = await parseCommonFields(response);

    return body;
  } catch (error) {
    const item = await dbStore.notes
      .where('clientId')
      .equals(clientId as string)
      .toArray();

    return { items: item as NoteClient[], nextToken: '' };
  }
};

export const createNotes = async (
  notes: api.createNoteClient.Interface['body']
): Promise<api.createNoteClient.Interface['response']> => {
  // await dbStore.notes.add({ ...notes, startWeb: new Date().getTime() });

  // try {
  const response = await callAPI2<api.createNoteClient.Interface>(
    { ...api.createNoteClient.config, withCache: true },
    {
      body: notes,
    }
  );

  const body: api.createNoteClient.Interface['response'] = await parseCommonFields(response);

  return body;
  // } catch (error) {
  //   return notes;
  // }
};

export const getLastNote = async (clientId: string): Promise<api.getNoteClient.Interface['response']> => {
  const response = await callAPI2<api.getNoteClient.Interface>(
    { ...api.getNoteClient.config },
    {
      queryParams: { clientId },
    }
  );

  const body: api.getNoteClient.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const updateNotes = async (
  notes: api.updateNoteClient.Interface['body'],
  noteClientId: string,
  clientId: string
): Promise<api.updateNoteClient.Interface['response']> => {
  const response = await callAPI2<api.updateNoteClient.Interface>(
    { ...api.updateNoteClient.config, withCache: true },
    {
      body: notes,
      pathParams: { noteClientId },
      queryParams: { clientId },
    }
  );

  const body: api.updateNoteClient.Interface['response'] = await parseCommonFields(response);

  return body;
};

export const deleteNotes = async (
  noteClientId: string,
  clientId: string
): Promise<api.deleteNoteClient.Interface['response']> => {
  const response = await callAPI2<api.deleteNoteClient.Interface>(
    { ...api.deleteNoteClient.config, withCache: true },
    {
      pathParams: { noteClientId },
      queryParams: { clientId },
    }
  );

  const body: api.deleteNoteClient.Interface['response'] = await parseCommonFields(response);

  return body;
};
