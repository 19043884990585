import { ApiInterface, EmptyObject } from '@stetamalo/cloud-platform/catalog/api';
import { Schemy } from '@stetamalo/cloud-platform/lib/schemy';
import { ApiConfig } from 'services/support/utils/types/api-config';
import { Cognito, UserPermission } from '../../entities';

export type Interface = ApiInterface<{
  response: Cognito;
  pathParams: EmptyObject;
  body: {
    username: string;
    name: string;
    lastName: string;
    password: string;
    permission: UserPermission;
  };
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-create-employee',
  method: 'POST',
  basePath: 'identity',
  path: '/employee',
  tablePermission: 'readWrite',
  scope: 'ADMIN',
  schema: Schemy.schema<Interface['body']>({
    username: { type: String, required: true },
    name: { type: String, required: true },
    lastName: { type: String, required: true },
    password: { type: String, required: true },
    permission: { type: String, enum: ['ADMIN', 'RECEPTIONIST'], required: true },
  }),
};
