import {
  addMinutes,
  differenceInDays,
  eachMinuteOfInterval,
  format,
  isSameDay,
  isToday,
  setHours,
  setMinutes,
} from 'date-fns';
import { Fragment } from 'react';
import { Cell } from '../components/common/Cell';
import { WithResources } from '../components/common/WithResources';
import TodayEvents from '../components/events/TodayEvents';
import { calcCellHeight, calcMinuteHeight, getResourcedEvents } from '../helpers/generals';
import { useAppState } from '../hooks/useAppState';
import { TableGrid } from '../styles/styles';
import { CellRenderedProps, DayHours, DefaultRecourse } from '../types';
export interface DayProps {
  startHour: DayHours;
  startMinute: 0 | 30;
  endHour: DayHours;
  endMinute: 0 | 30;
  step: number;
  cellRenderer?(props: CellRenderedProps): JSX.Element;
}

export interface WeekProps {
  startHour: DayHours;
  endHour: DayHours;
  step: number;
  cellRenderer?(props: CellRenderedProps): JSX.Element;
}

const Day = () => {
  const { day, selectedDate, events, triggerDialog, resources, resourceFields, fields, direction, locale } =
    useAppState();

  const startHour = day?.startHour;
  const startMinute = day?.startMinute;
  const endHour = day?.endHour;
  const endMinute = day?.endMinute;
  const step = day?.step;
  const cellRenderer = day?.cellRenderer;
  const daysList = [selectedDate];

  const START_TIME = setMinutes(setHours(selectedDate, startHour ?? 8), startMinute ?? 0);
  const END_TIME = setMinutes(setHours(selectedDate, endHour ?? 18), endMinute ?? 0);
  const hours = eachMinuteOfInterval(
    {
      start: START_TIME,
      end: END_TIME,
    },
    { step: step }
  );
  const CELL_HEIGHT = calcCellHeight();
  const MINUTE_HEIGHT = calcMinuteHeight();

  const renderTable = (resource?: DefaultRecourse, pos?: number) => {
    let recoupedEvents = events;
    if (resource) {
      recoupedEvents = getResourcedEvents(events, resource, resourceFields, fields);
    }

    return (
      <TableGrid days={daysList ? daysList.length : 0} pos={pos}>
        {hours.map((h, i) => (
          <Fragment key={i}>
            <span style={{ height: CELL_HEIGHT }} className="rs__cell rs__header rs__time">
              {pos === 1 && (
                <div
                  className={`text-[10px]
                    ${h.getMinutes() === 0 ? 'font-semibold' : 'font-normal'}`}
                >
                  {h.getMinutes() === 0 ? format(h, 'HH:mm', { locale: locale }) : format(h, 'mm', { locale: locale })}
                </div>
              )}
            </span>
            {daysList?.map((date, ii) => {
              const start = new Date(`${format(date, 'yyyy MM dd')} ${format(h, 'HH:mm')}`);

              const end = new Date(`${format(date, 'yyyy MM dd')} ${format(addMinutes(h, step ?? 60), 'HH:mm')}`);

              const field = resourceFields.idField;
              return (
                <span
                  style={{ height: '100%' }}
                  key={ii}
                  className={`rs__cell ${isToday(date) ? 'rs__today_cell' : ''}`}
                >
                  {i === 0 && (
                    <TodayEvents
                      todayEvents={recoupedEvents
                        .filter((e) => isSameDay(date, e.start) && !differenceInDays(e.end, e.start))
                        .sort((a, b) => a.end.getTime() - b.end.getTime())}
                      today={date}
                      minuteHeight={MINUTE_HEIGHT}
                      startHour={startHour ?? 8}
                      step={step ?? 60}
                      direction={direction}
                      startMinute={0}
                    />
                  )}
                  {cellRenderer ? (
                    cellRenderer({
                      day: date,
                      start,
                      end,
                      height: CELL_HEIGHT,
                      onClick: () =>
                        triggerDialog(true, {
                          start,
                          end,
                          [field]: resource ? resource[field] : null,
                        }),
                      [field]: resource ? resource[field] : null,
                    })
                  ) : (
                    <Cell start={start} end={end} resourceKey={field} resourceVal={resource ? resource[field] : null} />
                  )}
                </span>
              );
            })}
          </Fragment>
        ))}
      </TableGrid>
    );
  };
  return resources.length ? <WithResources renderChildren={renderTable} /> : renderTable();
};

export { Day };
