import { categoriesInitial } from './initial-state';
import { CategoriesActions } from './action-creators';
import { CategoriesState } from './state';
import * as actions from './actions';

const categoriesReducer = (state: CategoriesState = categoriesInitial, action: CategoriesActions): CategoriesState => {
  switch (action.type) {
    case actions.LIST_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categoriesList: action.items,
      };
    }

    default:
      return state;
  }
};

export default categoriesReducer;
