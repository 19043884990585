import { Reward } from '@services/business/rewards/entities/rewards';
import { ApiConfig } from '@services/support/utils/types/api-config';
import { ApiInterface, EmptyObject, PaginatedResponse } from '@stetamalo/cloud-platform/catalog/api';

export type Interface = ApiInterface<{
  response: PaginatedResponse<Reward> & { available: number };
  pathParams: { clientId: string };
  body: EmptyObject;
  claims: { tenantId: string };
  queryParams: EmptyObject;
}>;

export const config: ApiConfig<Interface> = {
  name: 'api-get-rewards',
  method: 'GET',
  basePath: 'reward',
  path: '/{clientId}',
  tablePermission: 'readWrite',
  skipValidation: true,
};
