import { useAppState } from '../../hooks/useAppState';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import IconButton from '../common/iconButton';
import { MouseEvent } from 'react';

interface LocaleArrowProps {
  type: 'prev' | 'next';
  etiqueta?: string;
  onClick?(e?: MouseEvent): void;
}
const LocaleArrow = ({ type, etiqueta = '', onClick }: LocaleArrowProps) => {
  const { direction } = useAppState();

  let Arrow = NavigateNextRoundedIcon;
  if (type === 'prev') {
    Arrow = direction === 'rtl' ? NavigateNextRoundedIcon : NavigateBeforeRoundedIcon;
  } else if (type === 'next') {
    Arrow = direction === 'rtl' ? NavigateBeforeRoundedIcon : NavigateNextRoundedIcon;
  }

  const CustomArrow = () => <Arrow fontSize="large" sx={{ color: 'var(--primary)' }} />;

  return (
    <IconButton
      onClick={onClick}
      onDragOver={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
    >
      {/* DARK MODE */}

      <div className="flex justify-start items-center text-xs md:text-sm font-semibold text-txtWhite">
        {type === 'prev' ? (
          direction === 'rtl' ? (
            <>
              {etiqueta} <CustomArrow />
            </>
          ) : (
            <>
              <CustomArrow /> {etiqueta}
            </>
          )
        ) : direction === 'rtl' ? (
          <>
            <CustomArrow /> {etiqueta}
          </>
        ) : (
          <>
            {etiqueta} <CustomArrow />
          </>
        )}
      </div>
    </IconButton>
  );
};

export { LocaleArrow };
