import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import Input from '@webapp/components/ui/forms/input';
import Select from '@webapp/components/ui/forms/select';

import { CreateNoteCredit } from '@webapp/store/client/action-creators';
import AppState from '@webapp/store/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface IModal {
  title: string;
}

const validationSchema = yup.object().shape({
  note: yup.string().required('Ingrese una nota/razón del adelanto'),
  method: yup.string().required('Ingrese el método de pago'),
  amount: yup.number().positive().min(1),
});

const methods = [
  { id: 'CASH', value: 'Efectivo' },
  { id: 'CARD', value: 'Tarjeta' },
  { id: 'TRANSFER', value: 'Transferencia' },
];

const ModalCreateNoteCredit: FunctionComponent<IModal> = (props) => {
  const cancelButton = useRef(null);
  const {
    auth: { user },
    client: { currentClient },
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state: AppState) => state);
  const { title } = props;
  const [isSaving, setIsSaving] = useState(false);

  const { open } = useMemo(() => {
    return {
      open: modalOpened?.['CREATE_NOTE_CREDIT_MODAL']?.open ?? false,
    };
  }, [modalOpened?.['CREATE_NOTE_CREDIT_MODAL']]);

  /**
   * change the focus of the current input to the next when it press the button enter
   * @param event
   */
  const handleEnter = (event: any) => {
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  /**
   * change the focus of the input
   * @param field string
   */
  const handleFocus = (field: string) => {
    document.getElementById(field)?.focus();
  };

  const saveNoteCredit = (values: any) => {
    dispatch(
      new CreateNoteCredit(
        {
          note: values.note,
          method: values.method,
          amount: Number(values.amount),
          currency: 'MEX',
          type: 'INCOME',
          ejectBy: user?.attributes['email'],
        },
        currentClient?.id || ''
      )
    );
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      note: '',
      method: '',
      amount: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveNoteCredit(values);
    },
  });

  useEffect(() => {
    handleFocus('note');
  }, []);

  useEffect(() => {
    const JobErrors = JSON.parse(JSON.stringify(formik.errors));

    const errors = [];
    for (const x in JobErrors) {
      errors.push(x);
    }

    if (errors[0] && isSaving) {
      handleFocus(errors[0]);
    }
  }, [formik.errors, isSaving]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('CREATE_NOTE_CREDIT_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* start modal body */}
            <div className="inline-block bg-darkGray rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-3xl w-full">
              <form onSubmit={formik.handleSubmit}>
                <div className="bg-primary py-3 pl-9 flex justify-left">
                  <Dialog.Title
                    as="span"
                    className="flex justify-between items-center w-full text-white text-xl md:text-2xl font-bold tracking-widest leading-tight"
                  >
                    {title}
                  </Dialog.Title>
                </div>
                <div className="py-3 md:py-5 px-4 md:px-8 flex flex-col mb-5">
                  <span className="text-[18px] font-normal tracking-[0.185em] leading-[23px] text-left mb-4 text-white">
                    Información general
                  </span>
                  <div className="flex flex-col border border-grey2 rounded-[3px] p-[22px] mb-[40px]">
                    <div className="grid grid-cols-2 gap-[24px] mb-[20px]">
                      <Input
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                        classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-grey"
                        id="note"
                        name="note"
                        label="* Nota"
                        type="text"
                        value={formik.values.note}
                        mode={'add'}
                        onKeyDown={handleEnter}
                        onChange={formik.handleChange}
                        error={formik.touched.note && formik.errors.note}
                      />
                      <Input
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                        classinput="text-base font-light tracking-[0.185em] leading-[20px] py-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-grey"
                        id="amount"
                        name="amount"
                        label="* Monto"
                        type="text"
                        value={formik.values.amount}
                        mode={'add'}
                        onKeyDown={handleEnter}
                        onChange={formik.handleChange}
                        error={formik.touched.amount && formik.errors.amount}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-[24px] mb-[20px]">
                      <Select
                        classlabel="text-base font-light tracking-[0.185em] leading-[20px] text-left text-white mb-[11px]"
                        classinput="text-base font-light tracking-[0.185em] p-1 px-3 border rounded-[3px] border-[#878A8A] w-full bg-darkGray text-white"
                        id="method"
                        name="method"
                        label="* Método de pago"
                        dataoption={methods}
                        value={formik.values.method}
                        mode={'add'}
                        onKeyDown={handleEnter}
                        onChange={formik.handleChange}
                        error={formik.touched.method && formik.errors.method}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center gap-4 items-center mb-6 px-5">
                  <button
                    type="button"
                    className="flex justify-center items-center p-0 border bg-red rounded-md hover:border-red focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                    onClick={() => {
                      formik.resetForm();
                      dispatch(new CloseModal('CREATE_NOTE_CREDIT_MODAL'));
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="flex justify-center items-center p-0 border bg-primary rounded-md hover:border-primary focus:outline-none min-h-[40px] md:min-h-[50px] min-w-[160px] px-3 py-2 shadow-sm text-base text-white font-normal leading-4 tracking-widest"
                    onClick={() => {
                      setIsSaving(true);
                      formik.submitForm();
                      dispatch(new CloseModal('CREATE_NOTE_CREDIT_MODAL'));
                    }}
                  >
                    Hecho
                  </button>
                </div>
              </form>
            </div>
            {/* end modal body */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalCreateNoteCredit;
