import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as reports from '../../../sdk/reports';
import { Actions } from '../../actions';
import {
  ListFirstYearReport,
  ListFirstYearReportError,
  ListFirstYearReportSuccess,
  ListReports,
  ListReportsError,
  ListReportsSuccess,
  ListSecondYearReport,
  ListSecondYearReportError,
  ListSecondYearReportSuccess,
} from '../action-creators';
import { LIST_FIRST_REPORTS, LIST_REPORTS, LIST_SECOND_REPORTS } from '../actions';

export const listReportsEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<
      Actions,
      ListReports['type'] | ListFirstYearReport['type'] | ListSecondYearReport['type'],
      ListReports | ListFirstYearReport | ListSecondYearReport
    >(LIST_REPORTS, LIST_FIRST_REPORTS, LIST_SECOND_REPORTS),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case LIST_REPORTS: {
            const result = await reports.listReports(action.data);
            return new ListReportsSuccess(result);
          }
          case LIST_FIRST_REPORTS: {
            const result = await reports.listReports(action.data);
            return new ListFirstYearReportSuccess(result);
          }
          case LIST_SECOND_REPORTS: {
            const result = await reports.listReports(action.data);
            return new ListSecondYearReportSuccess(result);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case LIST_REPORTS: {
            return new ListReportsError(err.message, err.description);
          }
          case LIST_FIRST_REPORTS: {
            return new ListFirstYearReportError(err.message, err.description);
          }
          case LIST_SECOND_REPORTS: {
            return new ListSecondYearReportError(err.message, err.description);
          }
        }
      }
    })
  );
