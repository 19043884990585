import * as api from '@services/business/discount/catalog/api';
import { parseCommonFields } from './common';
import { CallApi } from './common-v2';

const callAPIservicios = CallApi('discounts');

export const listDiscounts = async (nextToken?: string): Promise<api.listDiscounts.Interface['response']> => {
  try {
    const response = await callAPIservicios<api.listDiscounts.Interface>(
      { ...api.listDiscounts.config, withCache: true },
      {
        queryParams: {
          nextToken,
        },
      }
    );
    const body: api.listDiscounts.Interface['response'] = await parseCommonFields(response);

    return body;
  } catch (error) {
    return {
      items: [],
      nextToken: '',
    };
  }
};
