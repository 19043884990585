import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as product from '../../../sdk/product';
import { Actions } from '../../actions';
import {
  InsertInventory,
  InsertInventoryError,
  InsertInventorySuccess,
  UpdateInventory,
  UpdateInventoryError,
  UpdateInventorySuccess,
} from '../action-creators';
import { INSERT_INVENTORY, UPDATE_INVENTORY } from '../actions';

export const crudInventoryEpic = (action$: Observable<Actions>): Observable<Actions> =>
  action$.pipe(
    ofType<Actions, InsertInventory['type'] | UpdateInventory['type'], InsertInventory | UpdateInventory>(
      INSERT_INVENTORY,
      UPDATE_INVENTORY
    ),
    mergeMap(async (action) => {
      try {
        switch (action.type) {
          case UPDATE_INVENTORY: {
            await product.updateInventory(action.productId, action.item);

            return new UpdateInventorySuccess(action.item, action.productId);
          }
          case INSERT_INVENTORY: {
            await product.createManagement(action.productId, action.item);

            return new InsertInventorySuccess(action.item, action.productId);
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        switch (action.type) {
          case UPDATE_INVENTORY: {
            return new UpdateInventoryError(err.message, err.description);
          }
          case INSERT_INVENTORY: {
            return new InsertInventoryError(err.message, err.description);
          }
        }
      }
    })
  );
