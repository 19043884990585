import { Dialog, Transition } from '@headlessui/react';
import { CheckGreenIcon, CheckRedIcon, DeleteSmallIcon } from '@webapp/components/ui/shared/icons';
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// store state
import { generateId } from '@utils/generate-id';
import { dbStore } from '@webapp/sdk/models/db';
import {
  GetAppointmentByDate,
  UpdatePreAppointment,
  UpdatePreAppointmentItem,
  UpdatePreAppointmentPayment,
  UpdatePreAppointmentPaymentRewardPoint,
} from '@webapp/store/appointments/action-creators';
import { Appointment, MethodPayment } from '@webapp/store/appointments/state';
import { GetRewardClientSuccess } from '@webapp/store/client/action-creators';
import { Category, Service } from '@webapp/store/products/state';
import { CloseModal } from '@webapp/store/ui/action-creators';
import { useOpStore } from '@webapp/store/useOpStore';
import {
  checkIfSucursalIsOpen,
  formatDateTime,
  formatFullDate,
  formatFullDateToView,
  formatFullDateUS,
  lastAppointmentBeforeClose,
  sumSecondsDate,
} from '@webapp/utils/formatDate';
import { useLiveQuery } from 'dexie-react-hooks';

interface PageProps {
  products: (Service | undefined)[];
  categories: Category[];
  onOk?: any;
}

type Errors = 'EXIST' | 'DATE';

const RewardModalBodyComponent: FunctionComponent<PageProps> = ({ products, categories }) => {
  const cancelButton = useRef(null);
  const {
    sucursal: { sucursal },
    client: { rewardClient },
    appointments: { preAppointment, updateAppointmentPayment, appointmentsOfTheDay },
    ui: { modalOpened },
    dispatch,
  } = useOpStore((state) => state);
  const client = useLiveQuery(() => dbStore.client.get(preAppointment?.clientId ?? ''));
  const { modalData } = useMemo(() => {
    return {
      modalData: (modalOpened?.['SELECT_REWARD_MODAL']?.value as { orderItemId: string; serviceId: string }) ?? {},
    };
  }, [modalOpened?.['SELECT_REWARD_MODAL']]);

  const [currentServiceList, setCurrentServiceList] = useState<Appointment[]>([]);
  const [servicesAvailableSearch, setServicesAvailableSearch] = useState<string>('');
  const [servicesSelectedSearch, setServicesSelectedSearch] = useState<string>('');
  const [messageError, setMessageError] = useState({
    EXIST: { status: false, value: '' },
    DATE: { status: false, value: '' },
  });
  const [initialDate, setInitialDate] = useState<number | undefined>(
    preAppointment?.initialDate ?? new Date().getTime()
  );
  const [inputDate, setInputDate] = useState<string>(
    formatFullDate(preAppointment?.initialDate ?? new Date().getTime())
  );
  const [inputTime, setInputTime] = useState<string>(
    formatDateTime(preAppointment?.initialDate ?? new Date().getTime())
  );
  const [availability, setAvailability] = useState<boolean>(true);

  /**
   * check if return true only when the service ID and orden Item ID exist
   * @returns boolean
   */
  const onlyRead = (): boolean => {
    // eslint-disable-next-line no-prototype-builtins
    return !modalData.hasOwnProperty('orderItemId');
  };

  /**
   * delete the selected service on the list
   * @param serviceId string
   */
  const deleteService = (serviceId: string) => {
    setCurrentServiceList((prev) => prev.filter((item) => item.id !== serviceId));
  };

  /**
   * cancel the appointment and go back to the client page
   */
  const cancelAppointment = () => {
    dispatch(new CloseModal('SELECT_REWARD_MODAL'));
  };

  /**
   * set the error to show
   * @param type Errors
   * @param status boolean
   * @param value string
   */
  const setErrors = (type: Errors, status: boolean, value: string) => {
    setMessageError((prev) => ({
      ...prev,
      [type]: { status: status, value: value },
    }));
  };

  /**
   * sent the data to the backend to create a appointment
   */
  const createAppointment = useCallback(() => {
    if (!onlyRead()) {
      if (currentServiceList.length === 0) {
        setErrors('EXIST', true, 'No tiene un servicio seleccionado, Seleccione un servicio');
        return;
      }

      if (!initialDate || !currentServiceList[0]?.dateInitial) {
        setErrors('DATE', true, 'Fecha Invalida / No Seleccionada');
        return;
      }

      if (lastAppointmentBeforeClose(sucursal?.workingHours, new Date(), currentServiceList[0]?.duration || 0)) {
        setErrors('EXIST', true, 'El servicio no se puede crear/editar ya que excede el horario de cierre del trabajo');
        return;
      }

      if (checkIfSucursalIsOpen(sucursal?.workingHours, new Date(currentServiceList[0]?.dateInitial ?? 0))) {
        setErrors('EXIST', true, 'No se puede crear/editar servicios fuera del horario de trabajo');
        return;
      }

      setErrors('DATE', false, '');
      setErrors('EXIST', false, '');

      dispatch(new GetRewardClientSuccess((rewardClient ?? 0) - (currentServiceList[0].rewardPoint ?? 0)));
      dispatch(
        new UpdatePreAppointment({
          payment: { ...updateAppointmentPayment, rewardUsed: currentServiceList[0].rewardPoint ?? 0 },
        })
      );
      dispatch(new UpdatePreAppointmentPaymentRewardPoint(currentServiceList[0].rewardPoint ?? 0));
      dispatch(
        new UpdatePreAppointmentItem(
          {
            ...currentServiceList[0],
            detailClient: {
              firstName: client?.firstName,
              lastName: client?.lastName,
            },
          },
          modalData.orderItemId
        )
      );
    }
    dispatch(new CloseModal('SELECT_REWARD_MODAL'));
  }, [preAppointment, currentServiceList]);

  /**
   * prepare the services for the posible create/update appointment
   * @param service Appointment | Service | undefined
   * @param initialDate number | undefined
   */
  const selectService = (service: Appointment | Service | undefined, initialDate: number | undefined) => {
    const dataService = JSON.parse(JSON.stringify(service));

    setCurrentServiceList([]);

    // the number of rewards available
    if ((rewardClient ?? 0) < (dataService?.rewardPoint ?? 0)) {
      setErrors('EXIST', true, `El ${dataService.name} seleccionado Excede el numero de puntos disponible`);
      return;
    }

    // check if the selected servicies exist in the current list
    if (
      preAppointment?.orden?.some((ordenItem) => {
        return ordenItem.serviceId === dataService.id;
      })
    ) {
      setErrors('EXIST', true, 'El servicio seleccionado ya existe en el listado de productos/servicios');
      return;
    } else {
      setErrors('EXIST', false, '');
    }

    if (lastAppointmentBeforeClose(sucursal?.workingHours, new Date(), service?.duration || 0)) {
      setErrors('EXIST', true, 'El servicio no se puede crear/editar ya que excede el horario de cierre del trabajo');
      return;
    }

    if (checkIfSucursalIsOpen(sucursal?.workingHours, new Date(initialDate ?? 0))) {
      setErrors('EXIST', true, 'No se puede crear/editar servicios fuera del horario de trabajo');
      return;
    }

    // avoid having more than one service as principal
    switch (service?.isPrincipal) {
      case 'ACTIVE':
        if (
          preAppointment?.orden?.some((ordenItem) => {
            return (
              ordenItem.isPrincipal === 'ACTIVE' &&
              ordenItem.serviceId !== dataService.id &&
              modalData.orderItemId !== ordenItem.id
            );
          })
        ) {
          setErrors('EXIST', true, 'Ya existe un servicio principal seleccionado, cambie o borre el existente');
          return;
        } else {
          setErrors('EXIST', false, '');
        }

        dispatch(new GetAppointmentByDate(formatFullDateUS(initialDate ?? 0)));

        setCurrentServiceList((prev) => {
          delete dataService.id;
          delete dataService.status;
          delete dataService.categoryName;
          delete dataService.availableSucursal;
          delete dataService.createdAt;
          delete dataService.updatedAt;
          delete dataService.lashistaId;
          delete dataService.initialDate;
          return prev.concat({
            ...dataService,
            categoryName: categories.find((c) => c.id === service?.categoryId)?.name ?? '',
            serviceId: service?.id,
            dateInitial: initialDate,
            dateEnd: sumSecondsDate(new Date(initialDate ?? 0), service?.duration ?? 0).getTime(),
            clientId: preAppointment?.clientId,
            id: generateId('orderItem'),
            status: 'IN_PROCESS',
          });
        });

        dispatch(
          new UpdatePreAppointmentPayment('REWARD', {
            id: generateId('rewardPayment', service?.id),
            type: 'REWARD',
            productId: service?.id ?? '',
            amount: service.price,
            rewardPoint: service.rewardPoint,
          } as MethodPayment)
        );
        break;
      case 'INACTIVE':
        setCurrentServiceList((prev) => {
          delete dataService.id;
          delete dataService.status;
          delete dataService.categoryName;
          delete dataService.availableSucursal;
          delete dataService.createdAt;
          delete dataService.updatedAt;
          delete dataService.lashistaId;
          delete dataService.initialDate;
          const dateInitial = new Date().getTime();
          return prev.concat({
            ...dataService,
            categoryName: categories.find((c) => c.id === service?.categoryId)?.name ?? '',
            serviceId: service?.id,
            dateInitial: dateInitial,
            dateEnd: sumSecondsDate(new Date(dateInitial ?? 0), service?.duration ?? 0).getTime(),
            clientId: preAppointment?.clientId,
            id: generateId('orderItem'),
            status: 'IN_PROCESS',
          });
        });

        dispatch(
          new UpdatePreAppointmentPayment('REWARD', {
            id: generateId('rewardPayment', service?.id),
            type: 'REWARD',
            productId: service?.id ?? '',
            amount: service.price,
            rewardPoint: service.rewardPoint,
          } as MethodPayment)
        );
        break;

      default:
        break;
    }
  };

  /**
   * update the initialDate and endDate of the current service
   * @param rawDate string
   * @param serviceId string | undefined
   * @param serviceDuration number | undefined
   */
  const setDMY = (
    rawDate: string,
    type: 'DATE' | 'TIME',
    serviceId: string | undefined,
    serviceDuration: number | undefined
  ) => {
    // use the new value depending of the type with the last of the other type
    const newDate = type === 'DATE' ? rawDate : inputDate,
      newTime = type === 'TIME' ? rawDate : inputTime,
      dateInitial = new Date(`${newDate} ${newTime}`);

    // replace the value of the input to save the last when the input used is different type(update date and keep time and vice versa)
    switch (type) {
      case 'DATE':
        setInputDate(rawDate);
        break;
      case 'TIME':
        setInputTime(rawDate);
        break;
    }

    const today = new Date();

    if (lastAppointmentBeforeClose(sucursal?.workingHours, today, serviceDuration || 0)) {
      setErrors('EXIST', true, 'El servicio no se puede crear/editar ya que excede el horario de cierre del trabajo');
      return;
    }

    if (checkIfSucursalIsOpen(sucursal?.workingHours, dateInitial)) {
      setErrors('EXIST', true, 'No se puede crear/editar servicios fuera del horario de trabajo');
      return;
    }

    if (dateInitial.getTime() > today.getTime()) {
      // set the initial date if is not set
      if (!initialDate) setInitialDate(dateInitial.getTime());

      setErrors('DATE', false, '');

      setCurrentServiceList((preState) => {
        const newState = preState.map((obj) => {
          // update the lashista id if is the service
          if (obj.id === serviceId) {
            return {
              ...obj,
              dateInitial: dateInitial.getTime(),
              dateEnd: sumSecondsDate(new Date(`${newDate} ${newTime}`), serviceDuration ?? 0).getTime(),
            };
          }

          // 👇️ otherwise return object as is
          return obj;
        });

        return newState;
      });
    } else {
      setErrors(
        'DATE',
        true,
        `Fecha seleccionada ${dateInitial.toDateString()} ${dateInitial.getHours()}:${dateInitial.getMinutes()} es menor que la fecha actual ${formatFullDateToView(
          today.getTime()
        )} ${today.getHours()}:${today.getMinutes()}`
      );
    }
  };

  useEffect(() => {
    setCurrentServiceList([]);
    setServicesAvailableSearch('');
    setServicesSelectedSearch('');
    setErrors('DATE', false, '');
    setErrors('EXIST', false, '');
  }, [modalOpened?.['SELECT_REWARD_MODAL']]);

  // show only the reward selected(existing) in the payment page
  const productsFilter = useMemo(() => {
    if (onlyRead()) {
      return products.filter((prod) => {
        if (preAppointment?.orden?.some((app) => app.serviceId === prod?.id)) {
          return prod;
        }
      });
    } else {
      return products;
    }
  }, [modalOpened?.['SELECT_REWARD_MODAL']]);

  useEffect(() => {
    if ((appointmentsOfTheDay?.length ?? 0) > 0) {
      const noAvailability = appointmentsOfTheDay?.filter((a) => a.lashistaId === preAppointment?.lashistaId);
      if ((noAvailability?.length ?? 0) > 0) {
        noAvailability?.forEach((w) => {
          if (
            !(
              currentServiceList[0]?.dateInitial >= w?.dateInitial && currentServiceList[0]?.dateInitial < w?.dateEnd
            ) && //fechaInicialActual dentro del bloque
            !(currentServiceList[0]?.dateEnd < w?.dateEnd && currentServiceList[0]?.dateEnd > w?.dateInitial) && //fechaFinalActual dentro del bloque
            !(currentServiceList[0]?.dateEnd > w?.dateEnd && currentServiceList[0]?.dateInitial < w?.dateInitial)
          ) {
            setAvailability(true);
            return;
          }
          setAvailability(false);
          return;
        });
      }
    }
  }, [appointmentsOfTheDay]);

  return (
    <Transition.Root show={modalOpened?.['SELECT_REWARD_MODAL'] ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButton}
        onClose={() => dispatch(new CloseModal('SELECT_REWARD_MODAL'))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
          {/* background transparent with opacity */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#6b7280] bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-black rounded-[8px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[90%] sm:w-full">
              <div className="bg-primary py-[25px] pl-[36px] flex justify-left">
                <Dialog.Title as="span" className="text-white text-[24px] font-bold tracking-[0.185em] leading-[30px]">
                  Seleccione el Reward - numero de puntos de reward disponibles: {rewardClient ?? 0}
                </Dialog.Title>
              </div>
              <div className="flex justify-between gap-[19px]">
                <div className="flex-1 flex flex-col gap-[19px]">
                  <div className="flex flex-col rounded-[3px] bg-darkGray">
                    <div className="flex justify-start p-[17px] border-b border-grey2 gap-[24px]">
                      <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                        Servicios disponibles
                      </span>
                      <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                        <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                          <label
                            className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                            htmlFor="servicesAvailableSearch"
                          >
                            Buscar:
                          </label>
                        </div>
                        <div className="flex-1">
                          <input
                            type="text"
                            className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-GREY border-0 w-full h-full"
                            name="servicesAvailableSearch"
                            id="servicesAvailableSearch"
                            value={servicesAvailableSearch}
                            onChange={(e) => setServicesAvailableSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          type="button"
                          className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-RED"
                          onClick={() => {
                            setServicesAvailableSearch('');
                          }}
                        >
                          <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex-1 p-4 max-h-[200px] overflow-y-auto">
                      <table className="table-auto w-full border-separate text-left">
                        <thead>
                          <tr className="bg-lightDarkGrey rounded-[3px] h-[33px]">
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                              Categoría
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                              Nombre
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                              Puntos de Reward
                            </th>
                            <th className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] text-txtWhite">
                              Último comprado
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productsFilter
                            ?.filter((list) => new RegExp(`${servicesAvailableSearch}.*`, 'i').test(list?.name ?? ''))
                            .map((service) =>
                              service?.status === 'ACTIVE' ? (
                                <tr
                                  key={service?.id}
                                  onClick={() => {
                                    if (!onlyRead()) {
                                      selectService(service, initialDate);
                                    }
                                  }}
                                  className={!onlyRead() ? 'cursor-pointer hover:bg-primary' : ''}
                                >
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                                    {categories.find((category) => category.id == service?.categoryId)?.name}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                                    {service?.name}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                                    {service?.rewardPoint}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] pl-[12px] p-2 text-txtWhite">
                                    -
                                  </td>
                                </tr>
                              ) : null
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!onlyRead() && (
                    <div className="flex flex-col rounded-[3px] bg-darkGray">
                      <div className="flex justify-start gap-[24px] p-[17px] border-b border-grey2">
                        <span className="text-[18px] font-medium leading-[23px] tracking-[0.185em] text-txtWhite">
                          Servicios bonos seleccionados
                        </span>
                        <div className="flex-1 flex rounded-[3px] border-[1px] border-[#A8ACAC]">
                          <div className="flex items-center p-1.5 rounded-[3px] bg-veryDarkGray">
                            <label
                              className="text-base font-light leading-[20px] tracking-[0.185em] text-white"
                              htmlFor="servicesSelectedSearch"
                            >
                              Buscar:
                            </label>
                          </div>
                          <div className="flex-1">
                            <input
                              type="text"
                              className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-GREY border-0 w-full h-full"
                              name="servicesSelectedSearch"
                              id="servicesSelectedSearch"
                              value={servicesSelectedSearch}
                              onChange={(e) => setServicesSelectedSearch(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="flex">
                          <button
                            type="button"
                            className="flex justify-center min-w-[135px] p-2 rounded-[3px] bg-RED"
                            onClick={() => {
                              setServicesSelectedSearch('');
                            }}
                          >
                            <span className="text-base leading-[20px] tracking-[0.185em] text-white">limpiar</span>
                          </button>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col p-4 max-h-[200px] overflow-auto gap-2">
                        <table className="table-auto w-full border-separate text-left">
                          <thead>
                            <tr className="bg-lightDarkGrey rounded-[3px] h-[33px]">
                              <th></th>
                              <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                                Categoría
                              </th>
                              <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                                Nombre
                              </th>
                              <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                                Precio
                              </th>
                              <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                                Duración
                              </th>
                              <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                                Fecha de Inicio
                              </th>
                              <th className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite pl-[12px]">
                                Disponible
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentServiceList
                              .filter((list) => new RegExp(`${servicesSelectedSearch}.*`, 'i').test(list.name ?? ''))
                              .map((service, i) => (
                                <tr key={i}>
                                  <td onClick={() => deleteService(service.id ?? '')} className="cursor-pointer">
                                    <DeleteSmallIcon />
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                    {categories.find((category) => category.id == service.categoryId)?.name}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                    {service.name}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                    ${service.price}
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2 pl-[12px]">
                                    {Number((service.duration ?? 0) / 60)} min
                                  </td>
                                  <td className="flex flex-col gap-2 text-center text-base font-light tracking-[0.185em] leading-[20px] text-txtWhite p-2">
                                    {messageError.DATE.status && (
                                      <span className="text-[18px] font-medium tracking-[0.185em] leading-[20px] text-red">
                                        {messageError.DATE.value}
                                      </span>
                                    )}
                                    <div className="flex flex-row gap-2">
                                      <input
                                        type="date"
                                        className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-txtWhite border-0 w-full h-full"
                                        name="inputDate"
                                        id="inputDate"
                                        value={formatFullDate(new Date(service.dateInitial ?? 0).getTime())}
                                        onChange={(e) => {
                                          setDMY(e.target.value, 'DATE', service.id, service.duration);
                                        }}
                                        disabled={service.isPrincipal === 'INACTIVE'}
                                      />
                                      <input
                                        type="time"
                                        className="text-base font-light leading-[19px] tracking-[0.185em] bg-darkGray text-txtWhite border-0 w-full h-full"
                                        name="inputTime"
                                        id="inputTime"
                                        value={formatDateTime(new Date(service.dateInitial ?? 0).getTime())}
                                        onChange={(e) => {
                                          setDMY(e.target.value, 'TIME', service.id, service.duration);
                                        }}
                                        disabled={service.isPrincipal === 'INACTIVE'}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-base font-light tracking-[0.185em] leading-[20px] p-2 pl-[12px]">
                                    {availability ? <CheckGreenIcon /> : <CheckRedIcon />}
                                  </td>
                                </tr>
                              ))}
                            <tr></tr>
                          </tbody>
                        </table>
                        {messageError.EXIST.status && (
                          <span className="text-[18px] font-medium tracking-[0.185em] leading-[20px] text-red">
                            {messageError.EXIST.value}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end my-[29px] px-[36px]">
                <button
                  type="button"
                  className="flex justify-center min-w-[161px] py-5 border-red rounded-[8px] border-2 bg-red"
                  onClick={() => cancelAppointment()}
                >
                  <span className="text-base leading-[19px] tracking-[0.08rem] text-txtWhite">Cancelar</span>
                </button>
                <button
                  type="button"
                  className="flex justify-center min-w-[161px] py-5 ml-[29px] border-primary rounded-[8px] border-2 bg-primary"
                  onClick={() => createAppointment()}
                >
                  <span className="text-base leading-[19px] tracking-[0.08rem] text-txtWhite">Hecho</span>
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RewardModalBodyComponent;
