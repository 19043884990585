const ThreeDotButtonGreenIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="18.5" stroke="#56C4C7" strokeWidth="3" />
      <circle cx="13.6219" cy="20.545" r="1.95513" fill="#56C4C7" />
      <circle cx="20.139" cy="20.545" r="1.95513" fill="#56C4C7" />
      <circle cx="26.6561" cy="20.545" r="1.95513" fill="#56C4C7" />
    </svg>
  );
};

export default ThreeDotButtonGreenIcon;
