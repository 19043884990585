const sortJS = (arreglo: any, orderBy: string, ascDes: boolean) => {
  function compare(a: any, b: any) {
    return (ascDes ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1;
  }

  arreglo.sort(compare);
  return arreglo;
};

export { sortJS };
